<template>
	<v-row
		align="start"
		align-content="start"
		justify="start"
	>
		<v-overlay :model-value="!!loading">
			<v-progress-circular
				indeterminate
				size="64"
			/>
		</v-overlay>

		<v-col
			v-if="displayPaperTable"
			cols="12"
			order="1"
		>
			<paper-table
				queue-mode
				qc-mode
				:in-progress="inProgress"
			/>
		</v-col>
		<v-col
			v-else
			cols="6"
			order="2"
		>
			<paper-staging
				qc-mode
				:single-mode="!displayPaperTable"
				:in-progress="inProgress"
			/>
		</v-col>
	</v-row>
</template>

<script>
import { mapMutations } from "vuex";

import PaperStaging from "@/pages/DactylDashboard/PaperStaging";
import PaperTable from "@/pages/PaperManager/PaperTable";

export default {
	name: "QCDashboard",

	components: {
		PaperStaging,
		PaperTable,
	},

	data() {
		return {
			displayPaperTable: false,
			loading: false,
			inProgress: false,
		};
	},

	computed: {
		paper() {
			return this.$store.getters.qcPaper;
		},
		saveUUID() {
			return this.$store.getters.qcSaveUUID;
		},
		user() {
			return this.$store.getters.user;
		},
	},

	watch: {
		paper(val, oldVal) {
			if (val && !oldVal) {
				this.refresh();
			}
		},
	},

	created() {
		this.checkUserPaperTablePermission();
		this.refresh();
	},

	methods: {
		...mapMutations([
			"clearQCPaper",
			"clearQCSaveUUID",
			"setQCPaper",
			"setQCSaveUUID",
		]),
		checkUserPaperTablePermission() {
			this.loading = "secondary";

			this.$http
				.get(`/users/${this.user.uuid}/can-all`, {
					params: {
						permissions: ["access_work_queues"],
					},
				})
				.then((response) => {
					if (response.data.allowed) {
						this.displayPaperTable = true;
					} else {
						this.displayPaperTable = false;
					}
				})
				.catch(() => {
					// do not display errors on permission checks
					this.displayPaperTable = false;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		refresh() {
			const params = {
				done: false,
			};

			if (this.paper) {
				params.paperUUID = this.paper.uuid;
			}
			if (this.user) {
				params.userUUID = this.user.uuid;
			}

			this.$http
				.get("/data-entry/search", { params: params })
				.then((response) => {
					if (response.data) {
						// If more than one in QC status, take the latest one (server sort by date desc)
						const firstDataEntry =
							response.data.dataEntries.data[0];
						if (firstDataEntry) {
							this.setQCSaveUUID(firstDataEntry.uuid);

							// cache the paper if necessary
							if (!this.paper) {
								this.setQCPaper(firstDataEntry.paper);
							}

							this.inProgress = true;
						} else {
							this.inProgress = false;
						}
					} else {
						this.clearQCPaper();
						this.clearQCSaveUUID();

						this.inProgress = false;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>

<style scoped lang="scss">
.col {
	flex-grow: 0;
}
</style>
