/**
 * Initializes the graphics widget for WPD.
 */
function init(wpd, wpdDocument) {
	/* Multi-layered canvas widget to display plot, data, graphics etc. */

	class GraphicsWidget {
		$mainCanvas; // original picture is displayed here
		$dataCanvas; // data points
		$drawCanvas; // selection region graphics etc
		$hoverCanvas; // temp graphics while drawing
		$topCanvas; // top level, handles mouse events
		$oriImageCanvas;
		$oriDataCanvas;
		$tempImageCanvas;
		$canvasDiv;

		mainCtx;
		dataCtx;
		drawCtx;
		hoverCtx;
		topCtx;
		oriImageCtx;
		oriDataCtx;
		tempImageCtx;

		width;
		height;
		originalWidth;
		originalHeight;
		aspectRatio;
		displayAspectRatio;
		originalImageData;
		zoomRatio;
		extendedCrosshair = false;
		hoverTimer;
		activeTool;
		repaintHandler;
		isCanvasInFocus = false;
		rotation = 0;

		#posn(ev) {
			// get screen pixel from event
			let mainCanvasPosition = this.$mainCanvas.getBoundingClientRect();
			return {
				x: parseInt(
					ev.pageX - (mainCanvasPosition.left + window.pageXOffset),
					10
				),
				y: parseInt(
					ev.pageY - (mainCanvasPosition.top + window.pageYOffset),
					10
				),
			};
		}

		// get image pixel when screen pixel is provided
		imagePx(screenX, screenY) {
			const imageX = screenX / this.zoomRatio;
			const imageY = screenY / this.zoomRatio;

			if (this.rotation === 0) {
				// this function is often called frequently
				// do not do extra work if canvases have not been rotated
				return {
					x: imageX,
					y: imageY,
				};
			} else {
				// rotate given x and y after dividing by zoom ratio
				return this.getRotatedCoordinates(
					this.rotation,
					0,
					imageX,
					imageY
				);
			}
		}

		// get screen pixel when image pixel is provided
		screenPx(imageX, imageY) {
			return {
				x: imageX * this.zoomRatio,
				y: imageY * this.zoomRatio,
			};
		}

		screenLength(imageLength) {
			return imageLength * this.zoomRatio;
		}

		getDisplaySize() {
			return {
				width: this.width,
				height: this.height,
			};
		}

		getImageSize() {
			return {
				width: this.originalWidth,
				height: this.originalHeight,
			};
		}

		getAllContexts() {
			return {
				mainCtx: this.mainCtx,
				dataCtx: this.dataCtx,
				drawCtx: this.drawCtx,
				hoverCtx: this.hoverCtx,
				topCtx: this.topCtx,
				oriImageCtx: this.oriImageCtx,
				oriDataCtx: this.oriDataCtx,
			};
		}

		#resize(cwidth, cheight) {
			cwidth = parseInt(cwidth, 10);
			cheight = parseInt(cheight, 10);

			this.$canvasDiv.style.width = cwidth + "px";
			this.$canvasDiv.style.height = cheight + "px";

			this.$mainCanvas.width = cwidth;
			this.$dataCanvas.width = cwidth;
			this.$drawCanvas.width = cwidth;
			this.$hoverCanvas.width = cwidth;
			this.$topCanvas.width = cwidth;

			this.$mainCanvas.height = cheight;
			this.$dataCanvas.height = cheight;
			this.$drawCanvas.height = cheight;
			this.$hoverCanvas.height = cheight;
			this.$topCanvas.height = cheight;

			this.displayAspectRatio = cwidth / (cheight * 1.0);

			this.width = cwidth;
			this.height = cheight;
		}

		#resetAllLayers() {
			this.$mainCanvas.width = this.$mainCanvas.width;
			this.#resetDrawingLayers();
		}

		#resetDrawingLayers() {
			this.$dataCanvas.width = this.$dataCanvas.width;
			this.$drawCanvas.width = this.$drawCanvas.width;
			this.$hoverCanvas.width = this.$hoverCanvas.width;
			this.$topCanvas.width = this.$topCanvas.width;
			this.$oriDataCanvas.width = this.$oriDataCanvas.width;
		}

		#drawImage(dx, dy) {
			if (this.originalImageData == null) return;

			this.mainCtx.fillStyle = wpd.Colors.transparentA;
			this.mainCtx.fillRect(0, 0, dx, dy);
			this.mainCtx.drawImage(this.$oriImageCanvas, 0, 0, dx, dy);

			if (
				this.repaintHandler != null &&
				this.repaintHandler.onRedraw != undefined
			) {
				this.repaintHandler.onRedraw();
			}

			if (
				this.activeTool != null &&
				this.activeTool.onRedraw != undefined
			) {
				this.activeTool.onRedraw();
			}
		}

		forceHandlerRepaint() {
			if (
				this.repaintHandler != null &&
				this.repaintHandler.onForcedRedraw != undefined
			) {
				this.repaintHandler.onForcedRedraw();
			}
		}

		setRepainter(fhandle) {
			if (
				this.repaintHandler != null &&
				this.repaintHandler.onRemove != undefined
			) {
				this.repaintHandler.onRemove();
			}
			this.repaintHandler = fhandle;
			if (
				this.repaintHandler != null &&
				this.repaintHandler.onAttach != undefined
			) {
				this.repaintHandler.onAttach();
			}
		}

		getRepainter() {
			return this.repaintHandler;
		}

		removeRepainter() {
			if (
				this.repaintHandler != null &&
				this.repaintHandler.onRemove != undefined
			) {
				this.repaintHandler.onRemove();
			}
			this.repaintHandler = null;
		}

		copyImageDataLayerToScreen() {
			if (this.rotation % 180 === 0) {
				this.dataCtx.drawImage(
					this.$oriDataCanvas,
					0,
					0,
					this.width,
					this.height
				);
			} else {
				this.dataCtx.drawImage(
					this.$oriDataCanvas,
					0,
					0,
					this.height,
					this.width
				);
			}
		}

		getRotationMatrix(degrees, dx, dy) {
			// determine translation (moves origin)
			let xTranslation, yTranslation;
			switch (degrees) {
				case 90:
					xTranslation = dy ?? 0;
					yTranslation = 0;
					break;
				case 180:
					xTranslation = dx ?? 0;
					yTranslation = dy ?? 0;
					break;
				case 270:
					xTranslation = 0;
					yTranslation = dx ?? 0;
					break;
				default:
					xTranslation = 0;
					yTranslation = 0;
					break;
			}

			// convert degrees to radians
			const radians = (degrees * Math.PI) / 180;

			// define transformation matrix [a, b, c, d, e, f]
			// matrix format:
			//   a c e 0
			//   b d f 0
			//   0 0 1 0
			//   0 0 0 1
			return new DOMMatrix([
				Math.cos(radians),
				Math.sin(radians),
				-Math.sin(radians),
				Math.cos(radians),
				xTranslation,
				yTranslation,
			]);
		}

		rotateClockwise() {
			this.rotateAndResize(90);
		}

		rotateCounterClockwise() {
			this.rotateAndResize(-90);
		}

		rotateAndResize(deltaDegrees = 0, newWidth = null, newHeight = null) {
			// do nothing if delta degrees value is not a multiple of 90
			if (Math.abs(deltaDegrees) % 90 !== 0) {
				return;
			}

			// use provided width and height, if available
			// otherwise, use current zoomed width and height values
			const displayWidth =
				newWidth ?? this.originalWidth * this.zoomRatio;
			const displayHeight =
				newHeight ?? this.originalHeight * this.zoomRatio;

			// add delta degrees to rotation
			// if rotation is 0 start at 360
			// modulo to make sure it is 0 <= d < 360
			this.rotation = ((this.rotation || 360) + deltaDegrees) % 360;

			// determine if it is necessary to swap canvas width and height
			const dimensions =
				this.rotation % 180 === 0
					? [displayWidth, displayHeight]
					: [displayHeight, displayWidth];

			// setting size clears canvases, update the size of the canvases before transforming
			this.#resize(...dimensions);

			// get transformation matrix and set transform on canvas context
			const matrix = this.getRotationMatrix(
				this.rotation,
				displayWidth,
				displayHeight
			);
			this.mainCtx.setTransform(matrix);
			this.dataCtx.setTransform(matrix);
			this.drawCtx.setTransform(matrix);
			this.hoverCtx.setTransform(matrix);
			this.topCtx.setTransform(matrix);

			// draw the image with the rotation independent dimensions
			this.#drawImage(displayWidth, displayHeight);

			// fire rotation event if image has been rotated
			if (deltaDegrees !== 0) {
				wpd.events.dispatch("wpd.image.rotate", {
					rotation: this.rotation,
				});
			}
		}

		getRotation() {
			return this.rotation;
		}

		setRotation(degrees) {
			this.rotation = degrees;
		}

		zoomIn() {
			this.setZoomRatio(this.zoomRatio * 1.2);
		}

		zoomOut() {
			this.setZoomRatio(this.zoomRatio / 1.2);
		}

		zoomFit() {
			let viewportSize = wpd.layoutManager.getGraphicsViewportSize();
			let newAspectRatio =
				viewportSize.width / (viewportSize.height * 1.0);

			if (newAspectRatio > this.aspectRatio) {
				this.zoomRatio =
					viewportSize.height / (this.originalHeight * 1.0);
				this.rotateAndResize(
					0,
					viewportSize.height * this.aspectRatio,
					viewportSize.height
				);
			} else {
				this.zoomRatio =
					viewportSize.width / (this.originalWidth * 1.0);
				this.rotateAndResize(
					0,
					viewportSize.width,
					viewportSize.width / this.aspectRatio
				);
			}
		}

		zoom100perc() {
			this.setZoomRatio(1.0);
		}

		setZoomRatio(zratio) {
			this.zoomRatio = zratio;
			this.rotateAndResize(
				0,
				this.originalWidth * this.zoomRatio,
				this.originalHeight * this.zoomRatio
			);
		}

		getZoomRatio() {
			return this.zoomRatio;
		}

		getScroll() {
			const container = wpdDocument.getElementById("graphicsContainer");

			return {
				scrollTop: container.scrollTop,
				scrollLeft: container.scrollLeft,
			};
		}

		setScroll({ scrollTop, scrollLeft }) {
			const container = wpdDocument.getElementById("graphicsContainer");

			container.scrollTop = scrollTop;
			container.scrollLeft = scrollLeft;
		}

		resetData() {
			this.$oriDataCanvas.width = this.$oriDataCanvas.width;
			this.$dataCanvas.width = this.$dataCanvas.width;

			// re-rotate canvases
			this.rotateAndResize();
		}

		resetHover() {
			this.$hoverCanvas.width = this.$hoverCanvas.width;
		}

		toggleExtendedCrosshairBtn() {
			// called directly when toolbar button is hit
			this.extendedCrosshair = !this.extendedCrosshair;
			let $crosshairBtn = wpdDocument.getElementById(
				"extended-crosshair-btn"
			);
			if (this.extendedCrosshair) {
				$crosshairBtn.classList.add("pressed-button");
			} else {
				$crosshairBtn.classList.remove("pressed-button");
			}
			this.$topCanvas.width = this.$topCanvas.width;
		}

		getRotatedCoordinates(sourceDegrees, targetDegrees, x, y) {
			// get the delta degrees
			const deltaDegrees = targetDegrees - sourceDegrees;

			// short-circuit
			// return original x and y if delta degrees is not a multiple of 90
			if (Math.abs(deltaDegrees) % 90 !== 0) {
				return {
					x: x,
					y: y,
				};
			}

			// determine source rotation image dimensions
			const dimensions =
				sourceDegrees % 180 === 0
					? {
							x: this.originalWidth,
							y: this.originalHeight,
					  }
					: {
							x: this.originalHeight,
							y: this.originalWidth,
					  };

			let rotatedX, rotatedY;
			switch (deltaDegrees) {
				case 90:
				case -270:
					rotatedX = dimensions.y - y;
					rotatedY = x;
					break;
				case 180:
				case -180:
					rotatedX = dimensions.x - x;
					rotatedY = dimensions.y - y;
					break;
				case 270:
				case -90:
					rotatedX = y;
					rotatedY = dimensions.x - x;
					break;
				case 360:
				case 0:
				default:
					rotatedX = x;
					rotatedY = y;
					break;
			}

			return {
				x: rotatedX,
				y: rotatedY,
			};
		}

		#setZoomImage(ix, iy) {
			var zsize = wpd.zoomView.getSize(),
				zratio = wpd.zoomView.getZoomRatio(),
				ix0,
				iy0,
				iw,
				ih,
				idata,
				ddata,
				ixmin,
				iymin,
				ixmax,
				iymax,
				zxmin = 0,
				zymin = 0,
				zxmax = zsize.width,
				zymax = zsize.height,
				xcorr,
				ycorr,
				alpha;

			iw = zsize.width / zratio;
			ih = zsize.height / zratio;

			ix0 = ix - iw / 2.0;
			iy0 = iy - ih / 2.0;

			ixmin = ix0;
			iymin = iy0;
			ixmax = ix0 + iw;
			iymax = iy0 + ih;

			if (ix0 < 0) {
				ixmin = 0;
				zxmin = -ix0 * zratio;
			}
			if (iy0 < 0) {
				iymin = 0;
				zymin = -iy0 * zratio;
			}
			if (ix0 + iw >= this.originalWidth) {
				ixmax = this.originalWidth;
				zxmax = zxmax - zratio * (this.originalWidth - (ix0 + iw));
			}
			if (iy0 + ih >= this.originalHeight) {
				iymax = this.originalHeight;
				zymax = zymax - zratio * (this.originalHeight - (iy0 + ih));
			}
			idata = this.oriImageCtx.getImageData(
				parseInt(ixmin, 10),
				parseInt(iymin, 10),
				parseInt(ixmax - ixmin, 10),
				parseInt(iymax - iymin, 10)
			);

			ddata = this.oriDataCtx.getImageData(
				parseInt(ixmin, 10),
				parseInt(iymin, 10),
				parseInt(ixmax - ixmin, 10),
				parseInt(iymax - iymin, 10)
			);

			for (var index = 0; index < ddata.data.length; index += 4) {
				if (
					ddata.data[index] != 0 ||
					ddata.data[index + 1] != 0 ||
					ddata.data[index + 2] != 0
				) {
					alpha = ddata.data[index + 3] / 255;
					idata.data[index] =
						(1 - alpha) * idata.data[index] +
						alpha * ddata.data[index];
					idata.data[index + 1] =
						(1 - alpha) * idata.data[index + 1] +
						alpha * ddata.data[index + 1];
					idata.data[index + 2] =
						(1 - alpha) * idata.data[index + 2] +
						alpha * ddata.data[index + 2];
				}
			}

			// Make this accurate to subpixel level
			xcorr = zratio * (parseInt(ixmin, 10) - ixmin);
			ycorr = zratio * (parseInt(iymin, 10) - iymin);

			wpd.zoomView.setZoomImage(
				idata,
				parseInt(zxmin + xcorr, 10),
				parseInt(zymin + ycorr, 10),
				parseInt(zxmax - zxmin, 10),
				parseInt(zymax - zymin, 10),
				this.getRotationMatrix(this.rotation, zxmax, zymax)
			);
		}

		updateZoomOnEvent(ev) {
			var pos = this.#posn(ev),
				xpos = pos.x,
				ypos = pos.y,
				imagePos = this.imagePx(xpos, ypos);
			this.#setZoomImage(imagePos.x, imagePos.y);
			wpd.zoomView.setCoords(imagePos.x, imagePos.y);
		}

		updateZoomToImagePosn(x, y) {
			this.#setZoomImage(x, y);
			wpd.zoomView.setCoords(x, y);
		}

		#init() {
			this.$mainCanvas = wpdDocument.getElementById("mainCanvas");
			this.$dataCanvas = wpdDocument.getElementById("dataCanvas");
			this.$drawCanvas = wpdDocument.getElementById("drawCanvas");
			this.$hoverCanvas = wpdDocument.getElementById("hoverCanvas");
			this.$topCanvas = wpdDocument.getElementById("topCanvas");

			this.$oriImageCanvas = wpdDocument.createElement("canvas");
			this.$oriDataCanvas = wpdDocument.createElement("canvas");
			this.$tempImageCanvas = wpdDocument.createElement("canvas");

			this.mainCtx = this.$mainCanvas.getContext("2d");
			this.dataCtx = this.$dataCanvas.getContext("2d");
			this.hoverCtx = this.$hoverCanvas.getContext("2d");
			this.topCtx = this.$topCanvas.getContext("2d");
			this.drawCtx = this.$drawCanvas.getContext("2d");

			this.oriImageCtx = this.$oriImageCanvas.getContext("2d", {
				willReadFrequently: true,
			});
			this.oriDataCtx = this.$oriDataCanvas.getContext("2d", {
				willReadFrequently: true,
			});
			this.tempImageCtx = this.$tempImageCanvas.getContext("2d");

			this.$canvasDiv = wpdDocument.getElementById("canvasDiv");

			// Extended crosshair
			wpdDocument.addEventListener(
				"keydown",
				this.#toggleExtendedCrosshair,
				false
			);

			// hovering over canvas
			this.$topCanvas.addEventListener(
				"mousemove",
				this.#hoverOverCanvasHandler,
				false
			);

			// drag over canvas
			this.$topCanvas.addEventListener(
				"dragover",
				(evt) => {
					evt.preventDefault();
				},
				true
			);
			// this.$topCanvas.addEventListener("drop", this.#dropHandler, true);

			this.$topCanvas.addEventListener(
				"mousemove",
				this.#onMouseMove,
				false
			);
			this.$topCanvas.addEventListener(
				"click",
				this.#onMouseClick,
				false
			);
			this.$topCanvas.addEventListener("mouseup", this.#onMouseUp, false);
			this.$topCanvas.addEventListener(
				"mousedown",
				this.#onMouseDown,
				false
			);
			this.$topCanvas.addEventListener(
				"mouseout",
				this.#onMouseOut,
				true
			);
			wpdDocument.addEventListener(
				"mouseup",
				this.#onDocumentMouseUp,
				false
			);

			wpdDocument.addEventListener(
				"mousedown",
				(ev) => {
					if (ev.target === this.$topCanvas) {
						this.isCanvasInFocus = true;
					} else {
						this.isCanvasInFocus = false;
					}
				},
				false
			);
			wpdDocument.addEventListener("keydown", this.#onKeyDown, true);

			wpd.zoomView.initZoom();

			// Paste image from clipboard
			// window.addEventListener("paste", this.#pasteHandler, false);
		}

		loadImage(originalImage, savedRotation) {
			if (this.$mainCanvas == null) {
				this.#init();
			}
			this.removeTool();
			this.removeRepainter();
			this.originalWidth = originalImage.width;
			this.originalHeight = originalImage.height;
			this.aspectRatio = this.originalWidth / (this.originalHeight * 1.0);
			this.$oriImageCanvas.width = this.originalWidth;
			this.$oriImageCanvas.height = this.originalHeight;
			this.$oriDataCanvas.width = this.originalWidth;
			this.$oriDataCanvas.height = this.originalHeight;
			this.oriImageCtx.drawImage(
				originalImage,
				0,
				0,
				this.originalWidth,
				this.originalHeight
			);
			this.originalImageData = this.oriImageCtx.getImageData(
				0,
				0,
				this.originalWidth,
				this.originalHeight
			);
			this.setRotation(savedRotation);
			this.#resetAllLayers();
			this.zoomFit();
			return this.originalImageData;
		}

		#loadImageFromData(idata, iwidth, iheight, keepZoom) {
			this.removeTool();
			this.removeRepainter();
			this.originalWidth = iwidth;
			this.originalHeight = iheight;
			this.aspectRatio = this.originalWidth / (this.originalHeight * 1.0);
			this.$oriImageCanvas.width = this.originalWidth;
			this.$oriImageCanvas.height = this.originalHeight;
			this.$oriDataCanvas.width = this.originalWidth;
			this.$oriDataCanvas.height = this.originalHeight;
			this.oriImageCtx.putImageData(idata, 0, 0);
			this.originalImageData = idata;
			this.#resetAllLayers();

			if (!keepZoom) {
				this.zoomFit();
			} else {
				this.setZoomRatio(this.zoomRatio);
			}
		}

		saveImage() {
			var exportCanvas = wpdDocument.createElement("canvas"),
				exportCtx = exportCanvas.getContext("2d"),
				exportData,
				di,
				dLayer,
				alpha;
			exportCanvas.width = this.originalWidth;
			exportCanvas.height = this.originalHeight;
			exportCtx.drawImage(
				this.$oriImageCanvas,
				0,
				0,
				this.originalWidth,
				this.originalHeight
			);
			exportData = exportCtx.getImageData(
				0,
				0,
				this.originalWidth,
				this.originalHeight
			);
			dLayer = this.oriDataCtx.getImageData(
				0,
				0,
				this.originalWidth,
				this.originalHeight
			);
			for (di = 0; di < exportData.data.length; di += 4) {
				if (
					dLayer.data[di] != 0 ||
					dLayer.data[di + 1] != 0 ||
					dLayer.data[di + 2] != 0
				) {
					alpha = dLayer.data[di + 3] / 255;
					exportData.data[di] =
						(1 - alpha) * exportData.data[di] +
						alpha * dLayer.data[di];
					exportData.data[di + 1] =
						(1 - alpha) * exportData.data[di + 1] +
						alpha * dLayer.data[di + 1];
					exportData.data[di + 2] =
						(1 - alpha) * exportData.data[di + 2] +
						alpha * dLayer.data[di + 2];
				}
			}
			exportCtx.putImageData(exportData, 0, 0);
			window.open(exportCanvas.toDataURL(), "_blank");
		}

		// run an external operation on the image data. this would normally mean a reset.
		runImageOp(operFn) {
			let opResult = operFn(
				this.originalImageData,
				this.originalWidth,
				this.originalHeight
			);
			this.#loadImageFromData(
				opResult.imageData,
				opResult.width,
				opResult.height,
				opResult.keepZoom
			);
		}

		setTool(tool) {
			if (
				this.activeTool != null &&
				this.activeTool.onRemove != undefined
			) {
				this.activeTool.onRemove();
			}
			this.activeTool = tool;
			if (
				this.activeTool != null &&
				this.activeTool.onAttach != undefined
			) {
				this.activeTool.onAttach();
			}
		}

		removeTool() {
			if (
				this.activeTool != null &&
				this.activeTool.onRemove != undefined
			) {
				this.activeTool.onRemove();
			}
			this.activeTool = null;
		}

		#toggleExtendedCrosshair = (ev) => {
			if (this.isCanvasInFocus) {
				// called when backslash is hit
				if (ev.keyCode === 220) {
					ev.preventDefault();
					this.toggleExtendedCrosshairBtn();
				}
			}
		};

		#hoverOverCanvasHandler = (ev) => {
			clearTimeout(this.hoverTimer);
			this.hoverTimer = setTimeout(this.#hoverOverCanvas(ev), 10);
		};

		#hoverOverCanvas = (ev) => {
			let pos = this.#posn(ev);
			let xpos = pos.x;
			let ypos = pos.y;
			let imagePos = this.imagePx(xpos, ypos);

			if (this.extendedCrosshair) {
				this.$topCanvas.width = this.$topCanvas.width;
				this.topCtx.strokeStyle = wpd.Colors.defaultA;
				this.topCtx.beginPath();
				this.topCtx.moveTo(xpos, 0);
				this.topCtx.lineTo(xpos, this.height);
				this.topCtx.moveTo(0, ypos);
				this.topCtx.lineTo(this.width, ypos);
				this.topCtx.stroke();
			}

			this.#setZoomImage(imagePos.x, imagePos.y);
			wpd.zoomView.setCoords(imagePos.x, imagePos.y);
		};

		// These handler modify the underlying source image, which shouldn't be possible
		// #dropHandler = (ev) => {
		// 	ev.preventDefault();
		// 	wpd.busyNote.show();
		// 	let allDrop = ev.dataTransfer.files;
		// 	if (allDrop.length === 1) {
		// 		wpd.imageManager.initializeFileManager(allDrop);
		// 		wpd.imageManager.loadFromFile(allDrop[0]);
		// 	}
		// };

		// #pasteHandler = (ev) => {
		// 	if (ev.clipboardData !== undefined) {
		// 		let items = ev.clipboardData.items;
		// 		if (items !== undefined) {
		// 			for (var i = 0; i < items.length; i++) {
		// 				if (items[i].type.indexOf("image") !== -1) {
		// 					wpd.busyNote.show();
		// 					var imageFile = items[i].getAsFile();
		// 					wpd.imageManager.initializeFileManager([imageFile]);
		// 					wpd.imageManager.loadFromFile(imageFile);
		// 				}
		// 			}
		// 		}
		// 	}
		// };

		#onMouseMove = (ev) => {
			if (
				this.activeTool != null &&
				this.activeTool.onMouseMove != undefined
			) {
				var pos = this.#posn(ev),
					xpos = pos.x,
					ypos = pos.y,
					imagePos = this.imagePx(xpos, ypos);
				this.activeTool.onMouseMove(ev, pos, imagePos);
			}
		};

		#onMouseClick = (ev) => {
			if (
				this.activeTool != null &&
				this.activeTool.onMouseClick != undefined
			) {
				var pos = this.#posn(ev),
					xpos = pos.x,
					ypos = pos.y,
					imagePos = this.imagePx(xpos, ypos);
				this.activeTool.onMouseClick(ev, pos, imagePos);
			}
		};

		#onDocumentMouseUp = (ev) => {
			if (
				this.activeTool != null &&
				this.activeTool.onDocumentMouseUp != undefined
			) {
				var pos = this.#posn(ev),
					xpos = pos.x,
					ypos = pos.y,
					imagePos = this.imagePx(xpos, ypos);
				this.activeTool.onDocumentMouseUp(ev, pos, imagePos);
			}
		};

		#onMouseUp = (ev) => {
			if (
				this.activeTool != null &&
				this.activeTool.onMouseUp != undefined
			) {
				var pos = this.#posn(ev),
					xpos = pos.x,
					ypos = pos.y,
					imagePos = this.imagePx(xpos, ypos);
				this.activeTool.onMouseUp(ev, pos, imagePos);
			}
		};

		#onMouseDown = (ev) => {
			if (
				this.activeTool != null &&
				this.activeTool.onMouseDown != undefined
			) {
				var pos = this.#posn(ev),
					xpos = pos.x,
					ypos = pos.y,
					imagePos = this.imagePx(xpos, ypos);
				this.activeTool.onMouseDown(ev, pos, imagePos);
			}
		};

		#onMouseOut = (ev) => {
			if (
				this.activeTool != null &&
				this.activeTool.onMouseOut != undefined
			) {
				var pos = this.#posn(ev),
					xpos = pos.x,
					ypos = pos.y,
					imagePos = this.imagePx(xpos, ypos);
				this.activeTool.onMouseOut(ev, pos, imagePos);
			}
		};

		#onKeyDown = (ev) => {
			if (this.isCanvasInFocus) {
				if (
					this.activeTool != null &&
					this.activeTool.onKeyDown != undefined
				) {
					this.activeTool.onKeyDown(ev);
				}
			}
		};

		// for use when downloading wpd project file
		// converts all images (except pdfs) to png
		getImageFiles() {
			let imageFiles = [];
			for (const file of wpd.appData.getFileManager().getFiles()) {
				let imageFile;
				if (file.type === "application/pdf") {
					imageFile = file;
				} else {
					imageFile = this.#convertToPNG(file);
				}
				imageFiles.push(imageFile);
			}
			return Promise.all(imageFiles);
		}

		#convertToPNG(imageFile) {
			return new Promise((resolve, reject) => {
				// reject any non-image files
				if (imageFile.type.match("image.*")) {
					let reader = new FileReader();
					reader.onload = () => {
						let url = reader.result;
						new Promise((resolve, reject) => {
							let image = new Image();
							image.onload = () => {
								this.$tempImageCanvas.width = image.width;
								this.$tempImageCanvas.height = image.height;
								this.tempImageCtx.drawImage(
									image,
									0,
									0,
									image.width,
									image.height
								);
								resolve();
							};
							image.src = url;
						}).then(() => {
							let imageURL =
								this.$tempImageCanvas.toDataURL("image/png");
							let bstr = atob(imageURL.split(",")[1]);
							let n = bstr.length;
							let u8arr = new Uint8Array(n);
							while (n--) {
								u8arr[n] = bstr.charCodeAt(n);
							}
							resolve(
								new File([u8arr], imageFile.name, {
									type: "image/png",
									encoding: "utf-8",
								})
							);
							this.tempImageCtx.clearRect(
								0,
								0,
								this.$tempImageCanvas.width,
								this.$tempImageCanvas.height
							);
						});
					};
					reader.readAsDataURL(imageFile);
				} else {
					reject();
				}
			});
		}
	}

	wpd.graphicsWidget = new GraphicsWidget();
}

export { init };
