import { JSONPath } from "jsonpath-plus";

const base64ToBlob = (base64Data, contentType = "", sliceSize = 512) => {
	const byteCharacters = atob(base64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, { type: contentType });
	return blob;
};

const isNumber = (key) => {
	if (!["number", "string"].includes(typeof key)) {
		return false;
	}
	return key !== "" && !isNaN(key);
};

const truncateText = (text, maxLength = 50) => {
	if (text.length <= maxLength) {
		return text;
	}
	const half = Math.floor((maxLength - 3) / 2);
	const start = text.slice(0, half);
	const end = text.slice(text.length - half);
	return `${start}...${end}`;
};

const queryJsonByUUID = ({ uuid, data, parentLevel }) => {
	const pathQuery =
		`$..items[?(@.uuid === "${uuid}")]` + "^".repeat(parentLevel);
	return JSONPath({
		path: pathQuery,
		json: data,
	})[0];
};

const queryJsonByType = ({ type, data }) => {
	return JSONPath({
		path: `$..items[?(@.type === "${type}")]`,
		json: data,
	});
};

export default {
	base64ToBlob,
	isNumber,
	truncateText,
	queryJsonByType,
	queryJsonByUUID,
};
