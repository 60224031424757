<template>
	<v-tooltip
		v-model="hint"
		:color="color"
		:open-on-click="false"
		:open-on-focus="false"
		:open-on-hover="false"
		:target="[position.x, position.y]"
		location="right"
		transition="fade-transition"
	>
		<div
			v-for="(message, index) in messages"
			:key="index"
			class="text-center"
		>
			{{ message }}
		</div>
	</v-tooltip>
</template>

<script>
export default {
	name: "VHint",

	props: {
		bounds: { type: Object, default: null },
	},

	data() {
		return {
			color: "",
			delay: 3000,
			hint: false,
			messages: [],
			position: {},
			timeout: null,
		};
	},

	watch: {
		hint(val) {
			if (!val) {
				// clear data
				this.$nextTick(function () {
					this.clear();
				});
			}
		},
	},

	methods: {
		clear: function () {
			this.color = "";
			this.messages = [];
		},
		open: function (args) {
			const { color, messages, position } = args;

			this.color = color;
			this.messages = messages;
			this.position = {
				x: position.x + this.bounds.left,
				y: position.y + this.bounds.top,
			};

			clearTimeout(this.timeout);

			// automatically close the hint after 5 seconds
			this.timeout = setTimeout(
				function () {
					this.hint = false;
				}.bind(this),
				this.delay
			);

			// show the hint
			this.hint = true;
		},
		close: function () {
			// hide the hint
			this.hint = false;

			clearTimeout(this.timeout);
			this.timeout = null;
		},
	},
};
</script>

<!-- not scoped since the dialog element is attached elsewhere -->
<style lang="scss"></style>
