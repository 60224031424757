import _ from "lodash";

/**
 * Initializes the manual selection tool in WPD.
 */
function init(wpd, wpdDocument) {
	// cannot pick and choose functions to override due to how it was written
	// completely overwrite the manual selection tool into a js class
	wpd.ManualSelectionTool = class {
		constructor(axis, dataset) {
			this.axis = axis;
			this.dataset = dataset;
		}

		static #$button = wpdDocument.getElementById("manual-select-button");

		onAttach() {
			// clear selected data points
			// prior data point selection is ignored by this tool
			this.dataset.unselectAll();

			wpd.ManualSelectionTool.#$button.classList.add("pressed-button");
			wpd.graphicsWidget.setRepainter(
				new wpd.DataPointsRepainter(this.axis, this.dataset)
			);

			if (wpd.tagGroups.activeTagGroup) {
				// display tag group ui
				wpd.tagGroups.showControls();
			} else {
				// show point group controls if set
				if (this.dataset.hasPointGroups()) {
					wpd.pointGroups.showControls();
					wpd.pointGroups.refreshControls();
				}
			}
		}

		onMouseClick(ev, pos, imagePos) {
			const addPixelArgs = [imagePos.x, imagePos.y];
			const hasPointGroups = this.dataset.hasPointGroups();

			const tupleIndex = wpd.pointGroups.getCurrentTupleIndex();
			const groupIndex = wpd.pointGroups.getCurrentGroupIndex();

			// handle bar axes labels
			let pointLabel = null;
			if (this.axis.dataPointsHaveLabels) {
				// only add a label if:
				// 1. point groups do not exist, or
				// 2. current group is a primary group (i.e. index 0)
				if (!hasPointGroups || groupIndex === 0) {
					const mkeys = this.dataset.getMetadataKeys();
					const labelKey = "label";

					// update metadata keys on the dataset, if necessary
					if (mkeys == null || !mkeys.length) {
						// first metadata entry
						this.dataset.setMetadataKeys([labelKey]);
					} else if (mkeys.indexOf(labelKey) < 0) {
						// first label entry (existing metadata)
						this.dataset.setMetadataKeys([labelKey, ...mkeys]);
					}

					// generate label
					let count = this.dataset.getCount();
					if (hasPointGroups) {
						if (tupleIndex === null) {
							count = this.dataset.getTupleCount();
						} else {
							count = tupleIndex;
						}
					}
					pointLabel = this.axis.dataPointsLabelPrefix + count;

					// include label as point metadata
					addPixelArgs.push({
						[labelKey]: pointLabel,
					});
				}
			}

			// add the pixel to the dataset
			const index = this.dataset.addPixel(...addPixelArgs);

			// draw the point
			wpd.graphicsHelper.drawPoint(
				imagePos,
				wpd.Colors.active,
				pointLabel
			);

			// update point group data
			if (hasPointGroups) {
				if (tupleIndex === null && groupIndex === 0) {
					// record the point as a new tuple
					const newTupleIndex = this.dataset.addTuple(index);
					wpd.pointGroups.setCurrentTupleIndex(newTupleIndex);
				} else {
					this.dataset.addToTupleAt(tupleIndex, groupIndex, index);
				}

				// switch to next point group
				wpd.pointGroups.nextGroup();
			}

			wpd.graphicsWidget.updateZoomOnEvent(ev);
			wpd.dataPointCounter.setCount(this.dataset.getCount());

			// If shiftkey was pressed while clicking on a point that has a label (e.g. bar charts),
			// then show a popup to edit the label
			if (this.axis.dataPointsHaveLabels && ev.shiftKey) {
				wpd.dataPointLabelEditor.show(
					this.dataset,
					this.dataset.getCount() - 1,
					this
				);
			}

			// dispatch point add event
			wpd.events.dispatch("wpd.dataset.point.add", {
				axes: this.axis,
				dataset: this.dataset,
				index: index,
			});
		}

		onRemove() {
			// do not clear selected data points to allow adjustment tool to
			// immediately edit the most recently added data point

			wpd.graphicsWidget.forceHandlerRepaint();

			wpd.ManualSelectionTool.#$button.classList.remove("pressed-button");

			// hide point group controls if set
			if (this.dataset.hasPointGroups()) {
				wpd.pointGroups.hideControls();
			}
		}

		onKeyDown(event) {
			const selectedIndexes = this.dataset.getSelectedPixels();

			if (selectedIndexes.length > 0) {
				const firstDataPoint = this.dataset.getPixel(
					selectedIndexes[0]
				);
				const stepSize = 0.5 / wpd.graphicsWidget.getZoomRatio();

				// rotate to current rotation
				const currentRotation = wpd.graphicsWidget.getRotation();
				let { x, y } = wpd.graphicsWidget.getRotatedCoordinates(
					0,
					currentRotation,
					firstDataPoint.x,
					firstDataPoint.y
				);

				if (wpd.keyCodes.isUp(event.keyCode)) {
					y = y - stepSize;
				} else if (wpd.keyCodes.isDown(event.keyCode)) {
					y = y + stepSize;
				} else if (wpd.keyCodes.isLeft(event.keyCode)) {
					x = x - stepSize;
				} else if (wpd.keyCodes.isRight(event.keyCode)) {
					x = x + stepSize;
				} else if (wpd.keyCodes.isComma(event.keyCode)) {
					wpd.pointGroups.previousGroup();
					return;
				} else if (wpd.keyCodes.isPeriod(event.keyCode)) {
					wpd.pointGroups.nextGroup();
					return;
				} else if (wpd.acquireData.isToolSwitchKey(event.keyCode)) {
					wpd.acquireData.switchToolOnKeyPress(
						String.fromCharCode(event.keyCode).toLowerCase()
					);
					return;
				} else {
					return;
				}

				// rotate back to original rotation
				({ x, y } = wpd.graphicsWidget.getRotatedCoordinates(
					currentRotation,
					0,
					x,
					y
				));

				_.each(selectedIndexes, (index) =>
					this.dataset.setPixelAt(index, x, y)
				);
				wpd.graphicsWidget.resetData();
				wpd.graphicsWidget.forceHandlerRepaint();
				wpd.graphicsWidget.updateZoomToImagePosn(
					firstDataPoint.x,
					firstDataPoint.y
				);
			}

			event.preventDefault();
		}
	};
}

export { init };
