<template>
	<v-table
		v-if="validationTableData"
		class="validation-table pa-3"
		dense
	>
		<template #default>
			<tbody>
				<tr
					v-for="(dataPoint, index) in validationTableData"
					:key="index"
					class="validation-table-row"
				>
					<td class="label">
						<v-card-text class="text-subtitle-2 ma-0 pa-0">
							{{ dataPoint.label }}
						</v-card-text>
					</td>
					<td
						:colspan="dataPointColspan(dataPoint)"
						class="value"
					>
						<v-card-text class="text-subtitle-2 ma-0 pa-0">
							{{ dataPoint.value }}
						</v-card-text>
					</td>
					<td>
						<v-card-text class="text-subtitle-2 ma-0 pa-0">
							{{ dataPoint.secondaryValue }}
						</v-card-text>
					</td>
				</tr>
			</tbody>
		</template>
	</v-table>
</template>

<script>
export default {
	name: "DataEntryValidationTable",

	props: {
		validationData: { type: Object, default: () => null },
	},

	computed: {
		validationTableData() {
			const data = this.validationData?.data;
			if (data && data.page) {
				const data = this.validationData.data;
				let count = 0;
				const dataPoints = data.dataPoints.map((point) => {
					count++;
					return {
						label: `Datapoint ${count}`,
						value: `${point.tag}`,
						secondaryValue: `${point.value}`,
					};
				});

				return [
					{
						label: "Page",
						value: data.page,
					},
					...(data.type
						? [
								{
									label: "Type",
									value: data.type,
								},
						  ]
						: []),
					...(data.x && data.y
						? [
								{
									label: "Coordinates",
									value: `(${parseFloat(
										data.x.toFixed(2)
									)}, ${parseFloat(data.y.toFixed(2))})`,
								},
						  ]
						: []),
					...(data.studyAbbreviation
						? [
								{
									label: "Study",
									value: data.studyAbbreviation,
								},
						  ]
						: []),
					...(data.studyPart
						? [
								{
									label: "Study Part ",
									value: data.studyPart,
								},
						  ]
						: []),
					...(data.studyArmAbbreviation
						? [
								{
									label: "Study Arm",
									value: data.studyArmAbbreviation,
								},
						  ]
						: []),
					...dataPoints,
					...(data.tagGroupInstanceUuid
						? [
								{
									label: "Tag group instance UUID",
									value: data.tagGroupInstanceUuid,
								},
						  ]
						: []),
				];
			}

			return undefined;
		},
	},

	methods: {
		dataPointColspan(dataPoint) {
			return dataPoint.secondaryValue ? 1 : 2;
		},
	},
};
</script>

<style scoped lang="scss">
.validation-table .label,
.value {
	width: 1px;
	white-space: nowrap;
}
.validation-table-row {
	white-space: pre-line;
	background-color: transparent !important;
}
</style>
