<template>
	<v-card
		class="d-flex flex-column pb-5"
		:disabled="disabled"
	>
		<v-toolbar
			color="primary"
			dark
			flat
		>
			<v-toolbar-title>{{ title }}</v-toolbar-title>

			<v-spacer />

			<v-btn
				class="mr-3 text-primary bg-grey"
				elevation="2"
				@click="displayDeleted = !displayDeleted"
			>
				<template v-if="!displayDeleted">
					<v-icon start> mdi-eye </v-icon>

					Show deleted
				</template>

				<template v-else>
					<v-icon start> mdi-eye-off </v-icon>

					Hide deleted
				</template>
			</v-btn>

			<v-dialog
				v-model="dialog"
				max-width="800px"
				@click:outside="clearSelectedPermission"
			>
				<template #activator="{ props }">
					<v-btn
						v-bind="props"
						class="bg-secondary text-white mr-3"
						elevation="2"
					>
						<v-icon start> mdi-plus </v-icon>

						Add new permission
					</v-btn>
				</template>

				<permission-edit
					:default-permission="selectedPermission"
					@cancel="closeDialog"
					@refresh="closeDialogAndRefresh"
				/>
			</v-dialog>

			<v-dialog
				v-model="deleteDialog"
				max-width="800px"
			>
				<v-card>
					<v-toolbar
						color="primary"
						dark
						flat
					>
						<v-toolbar-title>Delete permission</v-toolbar-title>
					</v-toolbar>

					<v-card-title class="text-center pb-4">
						Are you sure you want to delete this permission?
					</v-card-title>

					<v-divider />

					<v-card-subtitle class="text-center pt-5 pb-5">
						{{ getPermissionLabel(selectedPermission) }}
					</v-card-subtitle>

					<v-divider />

					<v-card-actions class="py-5">
						<v-spacer />

						<v-btn
							class="bg-secondary text-white"
							elevation="2"
							@click="deletePermission"
						>
							Yes
						</v-btn>

						<v-btn
							class="bg-grey"
							elevation="2"
							@click="closeDeleteDialog"
						>
							No
						</v-btn>

						<v-spacer />
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog
				v-model="restoreDialog"
				max-width="800px"
			>
				<v-card>
					<v-toolbar
						color="primary"
						dark
						flat
					>
						<v-toolbar-title>Restore permission</v-toolbar-title>
					</v-toolbar>

					<v-card-title class="text-center pb-4">
						Are you sure you want to restore this permission?
					</v-card-title>

					<v-divider />

					<v-card-subtitle class="text-center pt-5 pb-5">
						{{ selectedPermission.name }}
					</v-card-subtitle>

					<v-divider />

					<v-card-actions class="py-5">
						<v-spacer />
						<v-btn
							class="bg-secondary text-white"
							elevation="2"
							@click="restorePermission"
						>
							Yes
						</v-btn>
						<v-btn
							class="bg-grey"
							elevation="2"
							@click="closeRestoreDialog"
						>
							No
						</v-btn>
						<v-spacer />
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-toolbar>

		<v-data-table-server
			v-model:options="options"
			class="permission-table"
			item-key="id"
			:headers="headers"
			:items="permissions"
			:row-props="getRowProps"
			:loading="loading"
			:items-length="totalPermissions"
			:disabled="disabled"
		>
			<template #item.id="{ item }">
				{{ item.id }}
			</template>

			<template #item.created_at="{ item }">
				{{ getCreatedTimestamp(item) }}
			</template>

			<template #item.actions="{ item }">
				<v-tooltip
					v-if="!item.deleted_at"
					location="bottom"
					open-delay="500"
				>
					<template #activator="{ props }">
						<v-hover v-slot="{ hover }">
							<v-icon
								v-bind="props"
								class="mr-2"
								:color="hover ? 'secondary' : ''"
								@click="edit(item)"
							>
								mdi-pencil
							</v-icon>
						</v-hover>
					</template>

					<span>Edit</span>
				</v-tooltip>

				<v-tooltip
					v-if="!item.deleted_at"
					location="bottom"
					open-delay="500"
				>
					<template #activator="{ props }">
						<v-hover v-slot="{ hover }">
							<v-icon
								v-bind="props"
								:color="hover ? 'error' : ''"
								@click="remove(item)"
							>
								mdi-delete
							</v-icon>
						</v-hover>
					</template>

					<span>Delete</span>
				</v-tooltip>
				<v-tooltip
					v-if="item.deleted_at"
					location="bottom"
					open-delay="500"
				>
					<template #activator="{ props }">
						<v-hover v-slot="{ hover }">
							<v-icon
								v-bind="props"
								:color="hover ? 'secondary' : ''"
								@click="restore(item)"
							>
								mdi-restore
							</v-icon>
						</v-hover>
					</template>

					<span>Restore</span>
				</v-tooltip>
			</template>
		</v-data-table-server>

		<v-hover v-slot="{ isHovering, props }">
			<v-overlay
				v-bind="props"
				contained
				class="align-center justify-center"
				absolute
				scrim="white"
				:model-value="disabled"
			>
				<v-row
					class="align-center justify-center"
					style="text-align: center; min-height: 200px"
				>
					<v-col
						class="d-flex flex-column align-center justify-center"
					>
						<v-img
							v-show="isHovering"
							src="@/assets/images/konami_logo.png"
							class="hint-logo mb-2"
							width="300"
							height="56"
						/>
						<v-icon
							size="x-large"
							:color="
								isHovering ? 'grey-darken-5' : 'grey-darken-1'
							"
						>
							mdi-lock
						</v-icon>
						<div
							v-show="isHovering"
							style="height: 56px"
							class="pl-2 hint text-black-lighten-1 font-weight-black mt-2"
						>
							Enter the code...
						</div>
					</v-col>
				</v-row>
			</v-overlay>
		</v-hover>
	</v-card>
</template>

<script>
import { mapMutations } from "vuex";

import PermissionEdit from "@/pages/UserManager/PermissionEdit";

export default {
	name: "PermissionTable",

	components: {
		PermissionEdit,
	},

	props: {
		disabled: Boolean,
		refresh: Boolean,
	},

	data() {
		return {
			deleteDialog: false,
			restoreDialog: false,
			dialog: false,
			displayDeleted: false,
			loading: false,
			options: {},
			selectedPermission: {},
			title: "Permissions",
			totalPermissions: 0,
			permissions: [],
			headers: [
				{
					title: "ID",
					value: "id",
					width: "10%",
				},
				{
					title: "Name",
					value: "name",
					width: "20%",
				},
				{
					title: "Description",
					value: "description",
					align: "left",
					width: "25%",
				},
				{
					title: "Created at",
					value: "created_at",
					align: "center",
					divider: true,
					width: "200px",
				},
				{
					title: "Actions",
					value: "actions",
					align: "center",
					width: "95px",
					sortable: false,
				},
			],
		};
	},

	watch: {
		options: {
			deep: true,
			handler() {
				this.getPermissions();
			},
		},
		displayDeleted() {
			this.options.page = 1;
			this.getPermissions();
		},
	},

	methods: {
		...mapMutations(["showNotification"]),
		getPermissionLabel(permission) {
			return `${permission.name} [${permission.slug}]`;
		},
		getCreatedTimestamp(permission) {
			return new Date(permission.created_at).toLocaleString();
		},
		clearSelectedPermission() {
			setTimeout(() => {
				this.selectedPermission = {};
			}, 300);
		},
		getRowProps(permission) {
			return {
				class: this.isDeleted(permission),
			};
		},
		isDeleted(permission) {
			return permission.deleted_at ? "deleted" : "";
		},
		remove(item) {
			this.selectedPermission = Object.assign({}, item);

			this.deleteDialog = true;
		},
		restore(item) {
			this.selectedPermission = Object.assign({}, item);

			this.restoreDialog = true;
		},
		closeDeleteDialog() {
			this.deleteDialog = false;

			this.clearSelectedPermission();
		},
		deletePermission() {
			this.loading = "secondary";

			if (this.selectedPermission) {
				this.$http
					.delete(`/permissions/${this.selectedPermission.id}`)
					.then((response) => {
						// display success
						this.showNotification(response.data);

						// refresh table
						this.getPermissions();
					})
					.catch((error) => {
						// display error
						if (error.response) {
							if (error.response.data.message) {
								this.showNotification(error.response.data);
							} else {
								this.showNotification(
									error.response.data.error
								);
							}
						}

						this.loading = false;
					});
			} else {
				this.loading = false;
			}

			this.closeDeleteDialog();
		},
		restorePermission() {
			this.loading = "secondary";

			if (this.selectedPermission) {
				this.$http
					.put(`/permissions/${this.selectedPermission.id}`, {
						deletedAt: null,
					})
					.then((response) => {
						this.showNotification(response.data);
						this.getPermissions();
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.data.message) {
								this.showNotification(error.response.data);
							} else {
								this.showNotification(
									error.response.data.error
								);
							}
						}

						this.loading = false;
					});
			} else {
				this.loading = false;
			}

			this.closeRestoreDialog();
		},
		edit(item) {
			this.selectedPermission = Object.assign({}, item);

			this.dialog = true;
		},
		closeDialog() {
			this.dialog = false;

			this.clearSelectedPermission();
		},
		closeRestoreDialog() {
			this.restoreDialog = false;

			this.clearSelectedPermission();
		},
		closeDialogAndRefresh() {
			this.closeDialog();

			this.$nextTick(() => {
				this.getPermissions();
			});
		},
		getPermissions() {
			this.loading = "secondary";

			const { filter, itemsPerPage, page, sortBy, sortDesc } =
				this.options;

			this.$http
				.get("/permissions", {
					params: {
						...(this.displayDeleted ? { deletedOnly: true } : {}),
						...(!this.displayDeleted ? { omitDeleted: true } : {}),
						filter: filter,
						itemsPerPage: itemsPerPage,
						page: page,
						sortBy: sortBy,
						sortDesc: sortDesc,
					},
				})
				.then((response) => {
					this.permissions = response.data.permissions.data;
					this.totalPermissions = parseInt(
						response.data.permissions.meta.total,
						10
					);
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style scoped lang="scss">
:deep(.permission-table) {
	&.v-data-table .v-table__wrapper td {
		font-size: 12px;
	}

	&.v-data-table .v-table__wrapper th {
		font-size: 12px;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.6);
		height: 48px;
	}
}

.hint {
	transition: opacity 0.4s ease-in-out;
	animation: blinker 0.8s linear infinite;
}

.hint-logo {
	transition: opacity 0.4s ease-in-out;
}

.hidden {
	opacity: 0;
}

.lock {
	transition: opacity 0.4s ease-in-out;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}
</style>
