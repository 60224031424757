<template>
	<v-container class="pa-0">
		<v-text-field
			v-model="password"
			autocomplete="off"
			:error-messages="passwordErrors"
			:disabled="!!loading"
			:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
			:prepend-icon="prependIcon"
			:type="showPassword ? 'text' : 'password'"
			counter
			:label="label"
			:class="inputClass"
			:required="required"
			@click:append="showPassword = !showPassword"
			@update:model-value="validatePassword"
			@blur="blurPassword"
			@focus="focusPassword"
		/>
		<password
			v-show="showPasswordMeter && !disableMeter"
			v-model="password"
			:strength-meter-only="true"
			:strength-meter-class="passwordMeterStrengthClass"
		/>
	</v-container>
</template>

<script>
import { minLength, maxLength, required } from "@vuelidate/validators";
import zxcvbn from "zxcvbn";
import Password from "@/components/VPasswordStrengthMeter";
import { useVuelidate } from "@vuelidate/core";

const isPasswordValid = function (password) {
	if (!password) return false;
	const result = zxcvbn(password);
	return result.score > 2;
};

export default {
	name: "PasswordInput",

	components: {
		Password,
	},

	props: {
		modelValue: { type: String, default: "" },
		loading: Boolean,
		prependIcon: { type: String, default: "" },
		inputClass: { type: String, default: "" },
		label: { type: String, default: "" },
		disableMeter: Boolean,
		disableValidation: Boolean,
		disableStrengthCheck: Boolean,
		meterSize: { type: String, default: "" },
		errorMessages: { type: Array, default: () => [] },
		required: Boolean,
		requiredMessage: { type: String, default: "" },
	},
	emits: [
		"update:modelValue",
		"update:passwordValue",
		"update:password-dirty",
		"update:passwordValid",
		"update:password-value",
		"update:password-valid",
		"password-validated",
	],

	setup() {
		return {
			v$: useVuelidate(),
		};
	},

	data() {
		return {
			showPassword: false,
			showPasswordMeter: false,
			passwordMeterStrengthClass: `password_strength_meter ${this.meterSize}`,
		};
	},

	validations: {
		password: {
			required,
			minLength: minLength(8),
			maxLength: maxLength(60),
			valid: isPasswordValid(),
		},
	},
	computed: {
		password: {
			get() {
				return this.modelValue;
			},
			set(newValue) {
				this.$emit("update:modelValue", newValue);
			},
		},
		passwordErrors() {
			this.$emit("update:passwordValue", this.password);
			this.$emit("update:password-dirty", this.v$.password.$dirty);

			// Use error message passed in from parent component
			if (this.errorMessages && this.errorMessages.length > 0) {
				return this.errorMessages;
			}

			if (this.disableValidation || !this.v$.password.$dirty) {
				return [];
			}

			const errors = this.v$.password.$errors.map((error) => {
				switch (error.$validator) {
					case "required":
						return this.requiredMessage
							? this.requiredMessage
							: "Password is required";
					case "minLength":
						return "Password is too short";
					case "maxLength":
						return "Password is too long";
					default:
						return "Invalid password";
				}
			});

			if (this.disableStrengthCheck) {
				this.$emit(
					"update:passwordValid",
					errors.length > 0 ? false : true
				);
				return errors;
			}

			this.$emit("update:passwordValid", isPasswordValid(this.password));

			if (this.password) {
				const result = zxcvbn(this.password);
				if (result.feedback.warning) {
					errors.push(result.feedback.warning);
				}
				if (result.feedback.suggestions.length > 0) {
					result.feedback.suggestions.map((p) => errors.push(p));
				}
			}

			return errors;
		},
	},

	methods: {
		blurPassword() {
			this.showPasswordMeter = false;
			this.validatePassword();
		},
		focusPassword() {
			this.showPasswordMeter = true;
		},
		clearPassword() {
			this.v$.$reset();
			this.password = "";
			this.$emit("update:password-value", this.password);
		},
		validatePassword() {
			this.v$.password.$touch();
			this.$emit("update:password-dirty", this.v$.password.$dirty);
			this.$emit("update:password-valid", isPasswordValid(this.password));
			this.$emit("password-validated", isPasswordValid(this.password));
		},
	},
};
</script>

<style lang="scss">
.password_strength_meter {
	position: relative;
	height: 3px;
	background: #ddd;
	margin: 10px auto 20px;
	border-radius: 3px;
}

.password_strength_meter.small {
	width: 65%;
}

.password_strength_meter.large {
	width: 100%;
}

.password_strength_meter:before,
.password_strength_meter:after {
	content: "";
	height: inherit;
	background: transparent;
	display: block;
	border-color: #fff;
	border-style: solid;
	border-width: 0 5px 0 5px;
	position: absolute;
	width: calc((100% + 30px) / 5);
	z-index: 10;
}

.password_strength_meter:before {
	left: calc((100% - 20px) / 5);
}

.password_strength_meter:after {
	right: calc((100% - 20px) / 5);
}
</style>
