<template>
	<v-table
		fixed-header
		:height="height"
	>
		<template #default>
			<thead>
				<tr>
					<th class="text-left font-weight-regular pl-0">
						{{ label }}
					</th>
				</tr>
			</thead>

			<tbody v-if="things.length">
				<tr
					v-for="(thing, index) of things"
					:key="index"
				>
					<td>
						{{ thing.name }}

						<v-tooltip
							location="bottom"
							open-delay="500"
						>
							<template #activator="{ props }">
								<v-hover v-slot="{ hover }">
									<v-icon
										v-bind="props"
										class="float-right"
										:color="hover ? 'error' : ''"
										@click="removeThing(index)"
									>
										mdi-close
									</v-icon>
								</v-hover>
							</template>

							<span>Remove {{ name }}</span>
						</v-tooltip>
					</td>
				</tr>
			</tbody>

			<tbody v-else>
				<tr>
					<td>{{ noDataLabel }}</td>
				</tr>
			</tbody>
		</template>
	</v-table>
</template>

<script>
import pluralize from "pluralize";

export default {
	name: "VDialogTable",

	props: {
		name: { type: String, default: "" },
		modelValue: { type: Array, default: () => [] },
		customNoDataLabel: { type: String, default: "" },
	},
	emits: ["update:modelValue"],

	computed: {
		height() {
			const rowHeight = 48;
			const numRows = this.things.length + 1;

			let height = rowHeight * 2;
			if (numRows > 3) {
				height = rowHeight * 4;
			} else if (numRows > 2) {
				height = rowHeight * 3;
			}

			return height + "px";
		},
		namePlural() {
			return pluralize(this.name);
		},
		noDataLabel() {
			return (
				this.customNoDataLabel || `No associated ${this.namePlural}.`
			);
		},
		label() {
			const count = this.things.length ? ` (${this.things.length})` : "";

			return `Associated ${this.namePlural}${count}`;
		},
		things: {
			get() {
				return this.modelValue;
			},
			set(val) {
				this.$emit("update:modelValue", val);
			},
		},
	},

	methods: {
		removeThing(index) {
			this.things.splice(index, 1);
		},
	},
};
</script>

<style scoped lang="scss"></style>
