<template>
	<v-hover v-slot="{ hover }">
		<v-icon
			:class="iconClasses"
			:color="hover ? 'secondary' : ''"
		>
			mdi-drag-horizontal
		</v-icon>
	</v-hover>
</template>

<script>
export default {
	name: "VHoverIcon",

	props: {
		class: { type: String, default: "" },
	},
	emits: ["dialog-close", "dialog-delete"],

	computed: {
		iconClasses() {
			return `drag-handle ${this.class}`;
		},
	},
	methods: {},
};
</script>

<style scoped lang="scss"></style>
