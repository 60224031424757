<template>
	<v-row>
		<v-col>
			<v-autocomplete
				v-model:search="paperSearch"
				v-model="paperSelect"
				:disabled="disabled"
				:item-title="composedText"
				:items="papers"
				:loading="loading"
				:messages="noResults"
				append-icon=""
				chips
				clearable
				density="compact"
				hide-no-data
				hint="Type in keywords to begin searching"
				item-value="uuid"
				label="Search for a paper"
				no-filter
				persistent-hint
				prepend-icon="mdi-magnify"
				return-object
			>
				<template #chip="{ item }">
					<v-chip class="bg-secondary text-white">
						<v-icon
							v-if="!item.raw.name"
							class="cursor-default mr-1"
						>
							mdi-help-circle-outline
						</v-icon>

						{{ item.title }}
					</v-chip>
				</template>
			</v-autocomplete>
		</v-col>
	</v-row>
</template>

<script>
import _ from "lodash";
import utils from "@/utils";

import { mapMutations } from "vuex";

export default {
	name: "PaperSearch",

	props: {
		defaultPaper: { type: Object, default: () => null },
		disabled: Boolean,
		modelValue: { type: Object, default: () => null },
	},
	emits: ["update:modelValue"],

	data() {
		return {
			loading: false,
			noResults: "",
			papers: [],
			paperSearch: null,
		};
	},

	computed: {
		paperSelect: {
			get() {
				return this.modelValue;
			},
			set(val) {
				this.$emit("update:modelValue", val);
			},
		},
	},

	watch: {
		defaultPaper(val) {
			if (val) {
				this.setPaper();
			}
		},
		paperSearch(val) {
			if (val && val.length >= 2) {
				this.queryPapers(val);
			}
		},
	},

	mounted() {
		this.setPaper();
	},

	methods: {
		...mapMutations(["showNotification"]),
		clear() {
			this.loading = false;

			if (_.isEmpty(this.defaultPaper)) {
				this.paperResults = [];
				this.papers = [];
				this.paperSearch = null;
			} else {
				// revert to default paper if it exists
				this.setPaper();
			}
		},
		composedText(paper) {
			const name = paper.name || "Unnamed";
			const doi = paper.doi ? ` (${paper.doi})` : "";
			return utils.truncateText(name + doi, 100);
		},
		queryPapers: _.debounce(function (string) {
			this.loading = "secondary";

			this.$http
				.get("/papers/search", {
					params: {
						query: string,
						omitDeleted: true,
					},
				})
				.then((response) => {
					this.papers = response.data.papers;

					// display no results message if necessary
					if (this.papers.length) {
						this.noResults = "";
					} else {
						this.noResults = "No results";
					}
				})
				.finally(() => {
					this.loading = false;
				});
		}, 500),
		setPaper() {
			if (!_.isEmpty(this.defaultPaper)) {
				const paper = Object.assign({}, this.defaultPaper);
				this.papers = [paper];
				this.paperSearch = null;
			}
		},
	},
};
</script>

<style scoped lang="scss">
:deep(.v-field__input) {
	min-height: 60px;
	padding-top: 20px;
}
</style>
