import * as Elements from "./elements.js";

function overwrite(wpd, wpdDocument, tags) {
	// locally store the original functions
	const originalCloseSettingsPopup = wpd.pointGroups.closeSettingsPopup;
	const originalSaveSettings = wpd.pointGroups.saveSettings;

	// replace the point group popup to update the instructions
	Elements.replacePopup(wpdDocument);

	// overwrite point group settings save function
	wpd.pointGroups.saveSettings = () => {
		if (wpd.tree.inWizard()) {
			// get new point groups
			const $rows =
				wpdDocument.getElementById("point-group-table").children;

			// spread the htmlCollection to an array to iterate
			let newPointGroups = [...$rows].map(($row) => {
				return $row.querySelector(`#point-group-${$row.rowIndex}`)
					.value;
			});

			// only primary group collected, check for custom name
			if (newPointGroups.length === 1) {
				// no custom name, empty out array
				if (!newPointGroups[0]) {
					newPointGroups = [];
				}

				// hide point group controls
				wpd.pointGroups.hideControls();
			} else if (newPointGroups.length > 1) {
				// display point group controls
				wpd.pointGroups.showControls();
			}

			// define point groups for each of the datasets created via the wizard
			for (const dataset of wpd.tree.getWizardDatasets()) {
				dataset.setPointGroups(newPointGroups);
			}

			// reset tuple and group indexes
			wpd.pointGroups.setCurrentTupleIndex(null);
			wpd.pointGroups.setCurrentGroupIndex(0);

			// refresh control display
			wpd.pointGroups.refreshControls();

			// close popup
			wpd.pointGroups.closeSettingsPopup();
		} else {
			originalSaveSettings();
		}
	};

	wpd.pointGroups.cancelSettingsPopup = () => {
		wpd.pointGroups.closeSettingsPopup(true);
	};

	// overwrite point group close settings function
	wpd.pointGroups.closeSettingsPopup = () => {
		originalCloseSettingsPopup();

		// trigger xy-axis label collection
		if (wpd.tree.inWizard()) {
			// clear out the datasets created via the wizard
			wpd.tree.setWizardDatasets([]);

			// exit the wizard
			wpd.tree.exitWizard();
		}

		// update tree
		wpd.tree.refreshPreservingSelection();
	};

	// overwrite point group settings show popup function
	wpd.pointGroups.showSettingsPopup = () => {
		// populate UI with existing point groups
		const dataset = wpd.tree.getActiveDataset();
		const pointGroups = dataset.getPointGroups();
		pointGroups.forEach((name, index) => {
			if (index > 0) {
				// add a row for each except default group
				wpd.pointGroups.addSettingsRow(false, name);
			} else {
				// set input value on default group
				const firstInput = wpdDocument.getElementById("point-group-0");

				firstInput.value = name;

				wpd.pointGroups.updateSelectText(firstInput);
			}
		});

		wpd.popup.show("point-group-settings-popup");
	};

	// overwrite point group add row function
	wpd.pointGroups.addSettingsRow = (focus, tagUUID) => {
		const table = wpdDocument.getElementById("point-group-table");
		const nextIndex = table.lastElementChild.rowIndex + 1;
		const nextID = `point-group-${nextIndex}`;

		// create new input "row"
		const template = wpdDocument.createElement("template");
		template.innerHTML = Elements.generateRow(
			wpd,
			nextIndex,
			tags,
			tagUUID
		);

		// append new row
		table.appendChild(template.content.firstChild);

		// update spacing if loading value
		if (tagUUID) {
			wpd.pointGroups.updateSelectText(
				wpdDocument.getElementById(nextID)
			);
		}

		// focus on new input
		if (focus) {
			wpdDocument.getElementById(nextID).focus();
		}
	};

	// overwrite point group delete row function
	wpd.pointGroups.deleteSettingsRow = (el) => {
		const $targetRow = el.closest("tr");

		wpd.pointGroups.getDeletedIndexes().push($targetRow.rowIndex);

		// delete the target row
		$targetRow.remove();

		// re-index all rows
		const $rows = wpdDocument.getElementById("point-group-table").children;
		// spread the htmlCollection to an array to iterate
		[...$rows].forEach(($row) => {
			const $cells = $row.children;
			const index = $row.rowIndex;

			$cells[0].querySelector("span").innerText = index;
			$cells[1]
				.querySelector("select")
				.setAttribute("id", `point-group-${index}`);
		});
	};

	// overwrite tuple group display function
	wpd.pointGroups.refreshControls = () => {
		const currentGroupIndex = wpd.pointGroups.getCurrentGroupIndex();
		const currentTupleIndex = wpd.pointGroups.getCurrentTupleIndex();

		const dataset = wpd.tree.getActiveDataset();

		let tagUUID = dataset.getPointGroups()[currentGroupIndex];

		if (!tagUUID) {
			tagUUID =
				currentGroupIndex === 0
					? wpd.gettext("point-group-primary-group")
					: `${wpd.gettext(
							"point-group-group"
					  )} ${currentGroupIndex}`;
		}

		let tuple = currentTupleIndex;
		if (currentTupleIndex === null) {
			const nextTupleIndex = dataset.getTupleCount();
			tuple = `${nextTupleIndex} (${wpd.gettext(
				"point-group-new-tuple"
			)})`;
		}

		// find name of uuid
		const name = tags.find((tag) => tag.uuid === tagUUID)?.name.trim();

		wpdDocument.getElementById("current-point-group-name").innerText = name;
		wpdDocument.getElementById("current-point-tuple-index").innerText =
			tuple;
	};

	// add function to trim whitespace on selected options
	wpd.pointGroups.updateSelectText = (el) => {
		const offset = 0.35;
		const spaces = el.selectedOptions[0].text.match(/^\s+/g);
		const numSpaces = spaces ? spaces[0].length : 0;
		el.style.textIndent = `${-(numSpaces * offset)}em`;
	};
}

export { overwrite };
