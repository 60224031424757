<template>
	<v-row
		align="start"
		align-content="start"
		justify="start"
	>
		<v-overlay :model-value="!!loading">
			<v-progress-circular
				indeterminate
				size="64"
			/>
		</v-overlay>

		<v-col
			v-if="displayPaperTable"
			cols="12"
			order="1"
		>
			<paper-table queue-mode />
		</v-col>

		<v-col
			v-else
			cols="12"
			order="1"
		>
			<paper-staging />
		</v-col>
	</v-row>
</template>

<script>
import { mapMutations } from "vuex";

import PaperStaging from "@/pages/DactylDashboard/PaperStaging";
import PaperTable from "@/pages/PaperManager/PaperTable";

export default {
	name: "DactylDashboard",

	components: {
		PaperStaging,
		PaperTable,
	},

	data() {
		return {
			displayPaperTable: false,
			loading: false,
		};
	},

	computed: {
		paper() {
			return this.$store.getters.paper;
		},
		saveUUID() {
			return this.$store.getters.saveUUID;
		},
		user() {
			return this.$store.getters.user;
		},
	},

	created() {
		this.checkUserPaperTablePermission();
		this.clear();
	},

	methods: {
		...mapMutations([
			"clearPaper",
			"clearSaveUUID",
			"setPaper",
			"setSaveUUID",
		]),
		clear() {
			this.clearPaper();
			this.clearSaveUUID();
		},
		checkUserPaperTablePermission() {
			this.loading = "secondary";

			this.$http
				.get(`/users/${this.user.uuid}/can-all`, {
					params: {
						permissions: ["access_work_queues"],
					},
				})
				.then((response) => {
					if (response.data.allowed) {
						this.displayPaperTable = true;
					} else {
						this.displayPaperTable = false;
					}
				})
				.catch(() => {
					// do not display errors on permission checks
					this.displayPaperTable = false;
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style scoped lang="scss">
.col {
	flex-grow: 0;
}
</style>
