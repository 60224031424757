<template>
	<v-hover v-slot="{ hover }">
		<div
			class="file-upload-overlay pt-2 pr-2"
			:class="{ 'overlay-enabled': hover }"
			@dragover.capture.stop.prevent="onDragOver"
			@dragleave.capture.stop.prevent="onDragLeave"
			@drop.capture.stop.prevent="onDrop"
		>
			<v-file-input
				ref="fileInput"
				v-model="files"
				:disabled="disabled"
				:error="error"
				:hint="hint"
				:placeholder="placeholder"
				clearable
				color="secondary"
				label="Upload files"
				prepend-icon="mdi-paperclip"
				counter
				multiple
				variant="outlined"
				persistent-hint
				show-size
				@click:clear="onClear"
			>
				<template #selection="{ fileNames }">
					<template
						v-for="(fileName, index) in fileNames"
						:key="fileName"
					>
						<v-chip
							class="bg-secondary text-white chip-ellipsis"
							closable
							@click:close="removeFile(index)"
						>
							{{ truncateText(fileName, 50) }}
						</v-chip>
					</template>
				</template>
			</v-file-input>
		</div>
	</v-hover>
</template>

<script>
import _ from "lodash";
import { mapMutations } from "vuex";
import utils from "@/utils";

export default {
	name: "FileUpload",

	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		error: {
			type: Boolean,
			default: false,
		},
		success: {
			type: Boolean,
			default: false,
		},
		modelValue: { type: Array, default: () => [] },
	},

	emits: ["removed", "update:modelValue"],

	data() {
		return {
			hint: "Include all associated files, such as supplements. Removed files are not deleted from Dactyl.",
			placeholder: "Drag files here or click here to select files",
			overlay: false,
			removedFiles: [],
		};
	},

	computed: {
		files: {
			get() {
				return this.modelValue;
			},
			set(val) {
				const addedFiles = _.differenceWith(
					val,
					this.modelValue,
					_.isEqual
				);

				if (!_.isEmpty(addedFiles)) {
					addedFiles.forEach((newFile) => {
						const removedIndex = this.removedFiles.findIndex(
							(removedFile) => {
								// only look for files that exist on the server (those with a uuid)
								return (
									removedFile.uuid &&
									removedFile.name === newFile.name &&
									removedFile.size === newFile.size &&
									removedFile.mime_type === newFile.type
								);
							}
						);

						this.removedFiles = this.removedFiles.filter(
							(_, i) => i !== removedIndex
						);
					});

					this.$emit("update:modelValue", [
						...addedFiles,
						...this.modelValue,
					]);
				} else if (!_.isEmpty(this.removedFiles)) {
					this.$emit("removed", this.removedFiles);
					this.$emit("update:modelValue", val);
				}
			},
		},
	},

	watch: {
		error(val) {
			this.delayedReset(val);
		},
		success(val) {
			this.delayedReset(val);
		},
	},

	methods: {
		...mapMutations(["showNotification"]),
		truncateText(text) {
			return utils.truncateText(text);
		},
		removeFile(index) {
			if (this.files[index].uuid) {
				this.removedFiles.push(this.files[index]);
			}
			this.files = this.files.filter((_, i) => i !== index);
		},
		delayedReset(flag) {
			if (flag === true) {
				setTimeout(() => (flag = false), 1000);
			}
		},
		onClear() {
			// collect all removed files with a uuid
			this.removedFiles = this.files.filter((file) => file.uuid);
			this.files = [];
		},
		onDragOver() {
			if (this.dragGuard) {
				clearTimeout(this.dragGuard);
				this.dragGuard = null;
			}

			this.overlay = true;
		},
		onDragLeave() {
			if (!this.dragGuard) {
				this.dragGuard = setTimeout(() => {
					this.overlay = false;
				}, 100);
			}
		},
		onDrop() {
			// set files to new files
			this.files = [...event.dataTransfer.files];
			this.overlay = false;
		},
	},
};
</script>

<style scoped lang="scss">
.file-upload-overlay {
	position: relative;
	background: rgba($color-dactyl-orange, 0);
	border-radius: 5px;

	&.overlay-enabled {
		background: rgba($color-dactyl-orange, 0.3);
		outline: 3px dashed $color-dactyl-orange;
	}

	& .overlay-text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: $color-dactyl-blue;
		opacity: 0;

		&.overlay-enabled {
			opacity: 1;
			z-index: 100;
		}
	}
}

:deep(.chip-ellipsis) {
	z-index: 999;
	.v-chip__content {
		z-index: 999;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

:deep(.v-field__input) {
	z-index: 2;
}
</style>
