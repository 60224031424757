function apply(wpd, wpdDocument, ids, options) {
	addInfoPopup(wpdDocument, ids.infoPopup, options);
	addTweakCalibrationButton(wpdDocument, ids.tweakButton);
	addAreaCalibrationSidebar(wpdDocument, ids.areaSidebar);
	addRowsAndColumnsCalibrationSidebar(
		wpd,
		wpdDocument,
		ids.rcSidebar,
		ids.rowInput,
		ids.colInput,
		ids.tagGroupInput
	);
	addDetailsCalibrationSidebar(
		wpdDocument,
		ids.detailsSidebar,
		ids.headerlessInput,
		ids.schemaInput
	);
	addInvalidTableHeadersDialog(wpd, wpdDocument);
	addEmptyTableHeadersDialog(wpdDocument);
	addHasEmptyTableHeadersDialog(wpdDocument);
	addCancelTableCalibrationDialog(wpdDocument);
}

/**
 * Injects table axis set up information HTML.
 * @param {String} id - ID attribute of the table axis information popup div
 */
function addInfoPopup(wpdDocument, id, options) {
	const xyAxesInfo = wpdDocument.getElementById("xyAxesInfo");

	xyAxesInfo.insertAdjacentHTML(
		"beforebegin",
		`
		<div id="${id}" class="popup" style="width: 650px">
			<div class="popupheading">Align Table Axes</div>
			<p>&nbsp;</p>
			<p align="center">
				<img src="${options.tableAxisInstructionURL}">
			</p>
			<p align="center">Click and drag to select the area of the table.</p>
			<p align="center">Do not include column headers or row headers in the highlighted area.</p>
			<p align="center">Row and column headers will be added later.</p>
			<p align="center">⚠ Note: To mark a row or column as empty, omit the corresponding header.</p>
			<p>&nbsp;</p>
			<p align="center">
				<input type="button" value="Proceed" onclick="wpd.alignAxes.pickArea();">
			</p>
		</div>
	`
	);
}

/**
 * Injects table axis tweak calibration button HTML.
 * @param {String} id - ID attribute of the table axis tweak calibration button input
 */
function addTweakCalibrationButton(wpdDocument, id) {
	// get the default tweak button
	const tweakButton = wpdDocument.getElementById(
		"tweak-axes-calibration-button"
	);

	// insert button
	tweakButton.insertAdjacentHTML(
		"afterend",
		`
		<input
			type="button"
			value="Tweak Calibration"
			style="width: 150px"
			onclick="wpd.alignAxes.tweakTableCalibration();"
			id="${id}"
		>
	`
	);
}

/**
 * Injects table axis area calibration sidebar content
 * @param {String} id - ID attribute of the table axis calibration sidebar content div
 */
function addAreaCalibrationSidebar(wpdDocument, id) {
	// get the default calibration sidebar
	const axisSidebar = wpdDocument.getElementById("axes-calibration-sidebar");

	// insert table axis calibration sidebar
	axisSidebar.insertAdjacentHTML(
		"beforebegin",
		`
		<div id="${id}" class="sidebar" style="display: none;">
			<p class="sidebar-title">Table Area Calibration</p>
			<p>
				Click and drag to draw a border around the area of the tabular data, excluding headers.
				To redraw the area, simply click and drag again.
			</p>
			<p>
				Click next when finished.
			</p>
			<p align="center">
				<input
					type="button"
					value="Cancel"
					style="width: 120px;"
					onclick="wpd.tableAxis.showCancelTableCalibrationDialog();"
				>
				<input
					class="table-area-calibration-next-button"
					type="button"
					value="Next"
					style="width: 120px;"
					onclick="wpd.alignAxes.defineRowsAndColumns();"
					disabled
				>
			</p>
		</div>
	`
	);
}

/**
 * Injects table axis rows and columns calibration sidebar content
 * @param {String} rcSidebarID - ID attribute of the table axis rows and columns calibration sidebar content div
 * @param {String} rowInputID  - ID attribute of the table axis row calibration input
 * @param {String} colInputID  - ID attribute of the table axis column calibration input
 */
function addRowsAndColumnsCalibrationSidebar(
	wpd,
	wpdDocument,
	rcSidebarID,
	rowInputID,
	colInputID,
	tagGroupInputID
) {
	// get the default calibration sidebar
	const axisSidebar = wpdDocument.getElementById("axes-calibration-sidebar");

	const allTagGroups = wpd.tagGroups.getAllTagGroups();
	const outcomeTagGroup = allTagGroups.find(
		(group) => group.name === "Outcomes"
	);
	const baselineTagGroup = allTagGroups.find(
		(group) => group.name === "Baseline/Demographics"
	);
	const adverseEventTagGroup = allTagGroups.find(
		(group) => group.name === "Adverse events"
	);

	// insert table axis calibration sidebar
	axisSidebar.insertAdjacentHTML(
		"beforebegin",
		`
		<div id="${rcSidebarID}" class="sidebar" style="display: none;">
			<p class="sidebar-title">Table Content Calibration</p>
			<p>
				Click back to redraw the area, click complete when finished.
			</p>
			<p align="center">
				<input
					type="button"
					value="Back"
					style="width: 79px;"
					onclick="wpd.alignAxes.pickArea(true);"
				/>
				<input
					type="button"
					value="Cancel"
					style="width: 79px;"
					onclick="wpd.tableAxis.showCancelTableCalibrationDialog(this);"
				/>
				<input
					type="button"
					value="Complete"
					style="width: 79px;"
					onclick="wpd.alignAxes.completeTableAxisCalibration();"
					disabled
				/>
			</p>
			<br />
			<p class="sidebar-title">Table Row/Column Calibration</p>
			<p>
				Enter the numbers of rows and columns:
			</p>
			<p>
				<div style="display: inline-block; width: 60px;">Rows: </div>
				<input
					id="${rowInputID}"
					type="number"
					value="1"
					min="1"
					step="1"
					style="width: 30px;"
					onchange="wpd.alignAxes.updateRowsAndColumns(this);"
				>
				<br />
				<div style="display: inline-block; width: 60px;">Columns: </div>
				<input
					id="${colInputID}"
					type="number"
					value="1"
					min="1"
					step="1"
					style="width: 30px;"
					onchange="wpd.alignAxes.updateRowsAndColumns(this);"
				>
			</p>
			<p>
				Click and drag to adjust the spacing of rows and columns.
			</p>
			<br />
			<p class="sidebar-title">Table Data Tag Group</p>
			<p>
				Specify the tag group to which the table cell data will belong.  If more than one apply,
				you may need to create multiple tables.
			</p>
			<p>
				<div>Select tag group:</div>
				<select
					id="${tagGroupInputID}"
					style="width: 100%"
					onchange="wpd.alignAxes.updateTableTagGroup(this);"
				>
					<option value="${outcomeTagGroup.uuid}">${outcomeTagGroup.name}</option>
					<option value="${baselineTagGroup.uuid}">${baselineTagGroup.name}</option>
					<option value="${adverseEventTagGroup.uuid}">${adverseEventTagGroup.name}</option>
				</select>
			</p>
		</div>
	`
	);
}

/**
 * Injects table axis details calibration sidebar content
 * @param {String} detailsSidebarID - ID attribute of the table axis details calibration sidebar content div
 * @param {String} headerInputID    - ID attribute of the table axis no headers input
 * @param {String} schemaInputID    - ID attribute of the table axis schema input
 * @param {String} tagGroupInputID  - ID attribute of the table cell tag group input
 */
function addDetailsCalibrationSidebar(
	wpdDocument,
	detailsSidebarID,
	headerlessInputID,
	schemaInputID
) {
	// get the default calibration sidebar
	const axisSidebar = wpdDocument.getElementById("axes-calibration-sidebar");

	// insert table axis calibration sidebar
	axisSidebar.insertAdjacentHTML(
		"beforebegin",
		`
		<div id="${detailsSidebarID}" class="sidebar" style="display: none;">
			<p class="sidebar-title">Table Settings</p>
			<p>
				Click complete when finished modifying table settings.
			</p>
			<p align="center">
				<input
					type="button"
					value="Cancel"
					class="table-calibration-sidebar-cancel-button hidden"
					style="width: 120px;"
					onclick="wpd.tableAxis.showCancelTableCalibrationDialog();"
				/>
				<input
					type="button"
					value="Complete"
					style="width: 120px;"
					onclick="wpd.tree.completeTableCalibration();"
				/>
			</p>
			<br />
			<p class="sidebar-title">Table Headers Calibration</p>
			<p>
				Click on the highlighted areas to capture header information.
			</p>
			<p>
				<div>Select headers present on this table:</div>
				<select
					id="${headerlessInputID}"
					style="width: 100%"
					onchange="wpd.alignAxes.updateHeaderTypes(this);"
				>
					<option value="both">Both headers</option>
					<option value="rows">Row headers only</option>
					<option value="cols">Column headers only</option>
				</select>
			</p>
			<br />
			<p class="sidebar-title">Table Schema Settings</p>
			<p>
				Dactyl pre-fills new cells with all existing tags in the table by default.
				Specify the direction in which the data looks similar to pre-fill new cell tags by row or column.
			</p>
			<p>
				<div>Data looks similar across:</div>
				<select
					id="${schemaInputID}"
					style="width: 100%"
					onchange="wpd.alignAxes.updateTableSchema(this);"
				>
					<option value="both">Both rows and columns</option>
					<option value="rows">Rows only</option>
					<option value="cols">Columns only</option>
				</select>
			</p>
		</div>
	`
	);
}

function addInvalidTableHeadersDialog(wpd, wpdDocument) {
	wpdDocument.getElementById("point-group-settings-popup").insertAdjacentHTML(
		"afterend",
		`
		<div id="invalid-table-headers-dialog" class="popup" style="width: 650px; left: 533px; top: 60px;">
			<div class="popupheading">Invalid Table Headers</div>
			<p>&nbsp;</p>
			<p align="center">Each table cell that has a header must reference exactly one study branch from either its row or column header.  Please modify the row or column headers as needed.  The following tags are study branch tags:</p>
			<p>&nbsp;</p>
			<ul style="padding-left: 100px;">
			<li>${wpd.CompositeKeyUtils.studyTag}</li>
			<li>${wpd.CompositeKeyUtils.studyPartTag}</li>
			<li>${wpd.CompositeKeyUtils.studyArmTag}</li>
			<li>${wpd.CompositeKeyUtils.populationTag}</li>
			<li>${wpd.CompositeKeyUtils.subgroupTag}</li>
			</ul>
			<p>&nbsp;</p>
			<p align="center">Note that "${wpd.CompositeKeyUtils.referenceArmTag}" is <b>not</b> a valid study branch tag.</p>
			<p>&nbsp;</p>
			<p align="center">
				<input type="button" value="OK" onclick="wpd.tableAxis.closeInvalidTableHeadersDialog(this);">
			</p>
		</div>`
	);
}

function addEmptyTableHeadersDialog(wpdDocument) {
	wpdDocument.getElementById("point-group-settings-popup").insertAdjacentHTML(
		"afterend",
		`
		<div id="empty-table-headers-dialog" class="popup" style="width: 650px; left: 533px; top: 60px;">
			<div class="popupheading">Empty Table Headers</div>
			<p>&nbsp;</p>
			<p align="center">All table headers are empty; please begin by entering table header data.</p>
			<p>&nbsp;</p>
			<p align="center">
				<input type="button" value="OK" onclick="wpd.tableAxis.closeEmptyTableHeadersDialog(this);">
			</p>
		</div>`
	);
}

function addHasEmptyTableHeadersDialog(wpdDocument) {
	wpdDocument.getElementById("point-group-settings-popup").insertAdjacentHTML(
		"afterend",
		`
		<div id="has-empty-table-headers-dialog" class="popup" style="width: 650px; left: 533px; top: 60px;">
			<div class="popupheading">Empty Table Headers</div>
			<p>&nbsp;</p>
			<p align="center">Warning:  Table contains empty row or column headers; do you want to continue?</p>
			<p>&nbsp;</p>
			<p align="center">
				<input type="button" value="Cancel" onclick="wpd.tableAxis.closeHasEmptyTableHeadersDialog(this);">
				<input type="button" value="OK" onclick="wpd.tableAxis.acceptEmptyTableHeaders(this);">
			</p>
		</div>`
	);
}

function addCancelTableCalibrationDialog(wpdDocument) {
	wpdDocument.getElementById("point-group-settings-popup").insertAdjacentHTML(
		"afterend",
		`
		<div id="cancel-table-calibration-dialog" class="popup" style="width: 650px; left: 533px; top: 60px;">
			<div class="popupheading">Cancel Table Creation</div>
			<p>&nbsp;</p>
			<p align="center">Are you sure you want to cancel table creation? This will remove all in-progress table calibration.</p>
			<p>&nbsp;</p>
			<p align="center">
				<input type="button" value="Cancel" onclick="wpd.tableAxis.closeCancelTableCalibrationDialog(this);">
				<input type="button" value="OK" onclick="wpd.tableAxis.cancelTableCalibration(this);">
			</p>
		</div>`
	);
}

export { apply };
