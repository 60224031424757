<template>
	<v-card
		class="d-flex flex-column pb-5"
		:disabled="!!loading"
		:loading="loading"
	>
		<v-toolbar
			color="primary"
			dark
			flat
		>
			<v-toolbar-title v-if="editMode">
				{{ editTitle }}
			</v-toolbar-title>

			<v-toolbar-title v-else>
				{{ title }}
			</v-toolbar-title>

			<v-hover-icon class="mr-3" />
		</v-toolbar>
		<v-form @submit.prevent>
			<v-card-text class="pa-10">
				<v-row class="pb-2">
					<v-text-field
						v-model="name"
						:error-messages="nameErrors"
						:disabled="!!loading"
						label="Name"
						name="name"
						type="text"
						hint="Name of the role (e.g. Source Data Extract User)"
						persistent-hint
						required
						class="col-6"
						autocomplete="off"
						@blur="v$.name.$touch()"
					/>
				</v-row>
				<v-row class="pb-2">
					<v-text-field
						v-model.trim="slug"
						:error-messages="slugErrors"
						:disabled="!!loading || !!defaultRole.id"
						label="Slug"
						name="slug"
						type="text"
						hint="Lower-case name joined by underscores (e.g. data_entry_user)"
						persistent-hint
						required
						class="col-6"
						autocomplete="off"
						@blur="v$.slug.$touch()"
					/>
				</v-row>

				<v-row class="pb-2">
					<v-text-field
						v-model="description"
						:error-messages="descriptionErrors"
						:disabled="!!loading"
						label="Description"
						name="description"
						type="text"
						hint="Short description of the role (e.g. User with source data extraction level access)"
						persistent-hint
						required
						class="col-12"
						autocomplete="off"
						@blur="v$.description.$touch()"
					/>
				</v-row>

				<v-row>
					<v-col>
						<permission-search
							v-model="selectedPermissions"
							v-model:permissions-loading="permissionsLoading"
							:default-permissions="permissions"
							label="Add permissions"
							@permission-updated="handlePermissionUpdate"
						/>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn
					v-if="editMode"
					class="bg-secondary text-white"
					elevation="2"
					type="submit"
					:disabled="
						v$.$invalid ||
						!v$.$anyDirty ||
						!!loading ||
						permissionsLoading
					"
					@click="update"
				>
					Update
				</v-btn>

				<v-btn
					v-else
					class="bg-secondary text-white"
					elevation="2"
					type="submit"
					:disabled="v$.$invalid || permissionsLoading"
					@click="create"
				>
					Create
				</v-btn>

				<v-btn
					class="bg-grey"
					elevation="2"
					:disabled="!!loading"
					@click="clear"
				>
					Clear
				</v-btn>

				<v-btn
					class="bg-grey"
					elevation="2"
					:disabled="!!loading"
					@click="cancel"
				>
					Cancel
				</v-btn>

				<v-spacer />
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import _ from "lodash";
import { mapMutations } from "vuex";
import { required, maxLength, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import VHoverIcon from "@/components/VHoverIcon";

import PermissionSearch from "@/pages/UserManager/PermissionSearch";

export default {
	name: "RoleEdit",

	components: {
		PermissionSearch,
		VHoverIcon,
	},

	props: {
		defaultRole: {
			type: Object,
			default: () => ({}),
		},
	},
	emits: ["cancel", "refresh"],

	setup() {
		return {
			v$: useVuelidate(),
		};
	},

	data() {
		return {
			description: "",
			editMode: false,
			editTitle: "Edit role",
			ignorePermissionsChange: false,
			loading: false,
			name: "",
			permissions: [],
			permissionsLoading: true,
			selectedPermissions: [],
			slug: "",
			title: "Create role",
		};
	},

	computed: {
		slugErrors() {
			if (!this.v$.slug.$dirty) {
				return [];
			}

			return this.v$.slug.$errors.map((error) => {
				switch (error.$validator) {
					case "required":
						return "Slug is required";
					case "maxLength":
						return "Slug is too long";
					case "format":
						return "Slug can only contain _, lower-case letters, and numbers";
					default:
						return "Invalid slug";
				}
			});
		},
		nameErrors() {
			if (!this.v$.name.$dirty) {
				return [];
			}

			return this.v$.name.$errors.map((error) => {
				switch (error.$validator) {
					case "required":
						return "Name is required";
					case "maxLength":
						return "Name is too long";
					default:
						return "Invalid name";
				}
			});
		},
		descriptionErrors() {
			if (!this.v$.description.$dirty) {
				return [];
			}

			return this.v$.description.$errors.map((error) => {
				switch (error.$validator) {
					case "maxLength":
						return "Description is too long";
					default:
						return "Invalid description";
				}
			});
		},
		isAdmin() {
			return this.defaultRole.slug === "administrator";
		},
	},

	watch: {
		defaultRole() {
			this.ignorePermissionsChange = true;

			this.loadRole();
		},
		permissions: {
			deep: true,
			handler() {
				if (!this.ignorePermissionsChange) {
					// set dirty flag when permissions have been updated
					this.v$.permissions.$touch();
				}

				// reset ignore permissions change flag
				this.ignorePermissionsChange = false;
			},
		},
	},

	mounted() {
		this.ignorePermissionsChange = true;

		this.loadRole();
	},

	methods: {
		...mapMutations(["showNotification"]),
		clear() {
			this.ignorePermissionsChange = true;

			if (_.isEmpty(this.defaultRole)) {
				this.slug = "";
				this.name = "";
				this.description = "";

				this.permissions = [];
				this.selectedPermissions = [];

				this.editMode = false;

				// clear dirty flags
				this.v$.$reset();
			} else {
				this.loadRole();
			}
		},
		cancel() {
			this.clear();

			this.$emit("cancel");
		},
		update() {
			if (!this.v$.$invalid) {
				this.loading = "secondary";

				this.$http
					.put(`/roles/${this.defaultRole.id}`, {
						slug: this.slug,
						name: this.name.trim(),
						description: this.description.trim(),
					})
					.then((response) => {
						this.savePermissions(this.defaultRole.id).then(() => {
							// display success
							this.showNotification(response.data);

							this.clear();
						});
					})
					.catch((error) => {
						// set dirty flags
						this.v$.$touch();

						// display error
						if (error.response) {
							if (error.response.data.message) {
								this.showNotification(error.response.data);
							} else {
								this.showNotification(
									error.response.data.error
								);
							}
						}
					})
					.finally(() => {
						this.loading = false;

						// update roles table
						this.$emit("refresh");
					});
			} else {
				// set all to dirty
				this.v$.$touch();
			}
		},
		create: function () {
			if (!this.v$.$invalid) {
				this.loading = "secondary";

				this.$http
					.post("/roles", {
						slug: this.slug,
						name: this.name.trim(),
						description: this.description.trim(),
					})
					.then((response) => {
						const role = response.data.role;

						this.savePermissions(role.id).then(() => {
							// display success
							this.showNotification(response.data);

							this.clear();
						});
					})
					.catch((error) => {
						// set dirty flags
						this.v$.$touch();

						// display error
						if (error.response) {
							if (error.response.data.message) {
								this.showNotification(error.response.data);
							} else {
								this.showNotification(
									error.response.data.error
								);
							}
						}
					})
					.finally(() => {
						this.loading = false;

						// update roles table
						this.$emit("refresh");
					});
			} else {
				// set all to dirty
				this.v$.$touch();
			}
		},
		savePermissions(roleID) {
			const permissionIDs = this.selectedPermissions.map(
				(permission) => permission.id
			);

			return this.$http.put(`/roles/${roleID}/permissions`, {
				permissionIDs: permissionIDs,
			});
		},
		loadRole() {
			if (!_.isEmpty(this.defaultRole)) {
				this.slug = this.defaultRole.slug;
				this.name = this.defaultRole.name;
				this.description = this.defaultRole.description;

				this.permissions = [...this.defaultRole.permissions];

				this.editMode = true;

				this.v$.$reset();
			} else {
				this.clear();
			}
		},
		handlePermissionUpdate() {
			this.v$.selectedPermissions.$touch();
		},
	},

	validations: {
		slug: {
			required,
			maxLength: maxLength(255),
			format: helpers.regex(/^[a-z0-9_]+$/),
		},
		name: {
			required,
			maxLength: maxLength(255),
		},
		description: {
			required,
			maxLength: maxLength(500),
		},
		selectedPermissions: {},
	},
};
</script>

<style scoped lang="scss"></style>
