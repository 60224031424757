<template>
	<v-row
		align-content="start"
		justify="start"
	>
		<v-col cols="12">
			<completed-extractions-table />
		</v-col>
	</v-row>
</template>

<script>
import CompletedExtractionsTable from "@/pages/CompletedExtractions/CompletedExtractionTable";

export default {
	name: "CompletedExtractionsAdmin",
	components: {
		CompletedExtractionsTable,
	},
};
</script>

<style scoped lang="scss"></style>
