<template>
	<v-autocomplete
		ref="autocomplete"
		v-model="selectedOrganizations"
		:disabled="disabled"
		:error-messages="errorMessages"
		:item-title="composedText"
		:items="organizations"
		:label="label"
		:loading="loading"
		:messages="noResults"
		:rules="[requiredRule]"
		append-icon=""
		clearable
		closable-chips
		density="compact"
		hide-no-data
		hint="Type in keywords to begin searching"
		item-color="secondary"
		item-value="uuid"
		multiple
		persistent-hint
		prepend-icon="mdi-magnify"
		return-object
		@update:model-value="updateOrganizations"
	>
		<template #chip="{ item, index }">
			<v-chip
				class="bg-secondary text-white"
				closable
				@click:close="removeItem(index)"
			>
				{{ item.title }}
			</v-chip>
		</template>
	</v-autocomplete>
</template>

<script>
import _ from "lodash";
import utils from "@/utils";

import { mapMutations } from "vuex";

export default {
	name: "OrganizationSearch",

	props: {
		disabled: Boolean,
		label: {
			default: "Search for organizations",
			type: String,
		},
		modelValue: { type: Array, default: () => [] },
	},
	emits: [
		"update:organizations-loading",
		"organization-updated",
		"update:organizations-missing",
		"update:modelValue",
	],

	data() {
		return {
			errorMessages: [],
			isDirty: false,
			loading: true,
			noResults: "",
			organizations: [],
		};
	},

	computed: {
		selectedOrganizations: {
			get() {
				return this.modelValue;
			},
			set(newValue) {
				this.$emit("update:modelValue", newValue);
			},
		},
		requiredRule() {
			this.$emit(
				"update:organizations-missing",
				this.selectedOrganizations.length === 0
			);
			return (
				!this.isDirty ||
				this.selectedOrganizations.length > 0 ||
				"Organization is required"
			);
		},
	},
	mounted() {
		this.queryOrganizations();
	},

	methods: {
		...mapMutations(["showNotification"]),
		clear() {
			this.doneLoading();
		},
		queryOrganizations: _.debounce(function () {
			this.loading = "secondary";

			this.$http
				.get("/organizations")
				.then((response) => {
					this.organizations = response.data.organizations;

					// If there is no org set and there is only one option, default it
					if (
						this.selectedOrganizations.length === 0 &&
						this.organizations.length === 1
					) {
						this.selectedOrganizations = [this.organizations[0]];
					}
				})
				.finally(() => {
					this.doneLoading();
				});
		}, 200),
		composedText(item) {
			return utils.truncateText(item.name, 100);
		},
		updateOrganizations(val) {
			this.isDirty = true;
			this.$emit("organization-updated", val);
		},
		doneLoading() {
			this.loading = false;
			this.$emit("update:organizations-loading", false);
		},
		removeItem(index) {
			this.selectedOrganizations = this.selectedOrganizations.filter(
				(_, i) => i !== index
			);
		},
	},
};
</script>

<style scoped lang="scss">
:deep(.v-field__input) {
	min-height: 60px;
	padding-top: 20px;
}
</style>
