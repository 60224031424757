/**
 * Initializes WPD multiple dataset repainter.
 */
function init(wpd) {
	wpd.MultipltDatasetRepainter = class {
		constructor(axesList, datasetList) {
			this.painterName = "multipleDatasetsRepainter";

			this._datasetRepainters = [];

			const tableMap = new Map();

			// collect datasets
			for (let [dsIdx, ds] of datasetList.entries()) {
				const dsAxes = axesList[dsIdx];

				if (dsAxes.getMetadata().table) {
					// collect all table datasets
					if (!tableMap.has(dsAxes)) {
						tableMap.set(dsAxes, []);
					}

					tableMap.get(dsAxes).push(ds);
				} else {
					this._datasetRepainters.push(
						new wpd.DataPointsRepainter(dsAxes, ds)
					);
				}
			}

			// format column data
			for (const axis of tableMap.keys()) {
				const calibration = axis.getMetadata().calibration;

				// get pixels from all datasets for each axis
				let colData = [];
				const datasets = tableMap.get(axis);
				for (let i = 0; i < datasets.length; i++) {
					const pixels = datasets[i].getAllPixels();

					// set number of rows for the current column and initialize all rows to null
					colData[i] = [];
					colData[i].length = calibration.rows.num;
					colData[i].fill(null, 0);

					pixels.forEach((pixel) => {
						// fill in colData
						colData[i][pixel.metadata.rowIndex] = pixel;
					});

					// fill in undefined for empty cells
					const metadata = datasets[i].getMetadata();
					if (metadata.emptyCells) {
						metadata.emptyCells.forEach(
							(rowIndex) => (colData[i][rowIndex] = undefined)
						);
					}
				}

				// add table repainter to dataset repainters
				this._datasetRepainters.push(
					new wpd.TableRepainter({
						area: calibration.area,
						rows: calibration.rows,
						cols: calibration.cols,
						rotation: calibration.rotation,
						drawHeaders: false,
						activeCell: {
							row: null,
							col: -1,
						},
						colData: colData,
					})
				);
			}
		}

		drawPoints() {
			for (let dsRepainter of this._datasetRepainters) {
				dsRepainter.drawPoints();
			}
		}

		onAttach() {
			wpd.graphicsWidget.resetData();
		}

		onRedraw() {
			this.drawPoints();
		}

		onForcedRedraw() {
			wpd.graphicsWidget.resetData();
		}
	};
}

export { init };
