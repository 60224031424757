<template>
	<v-snackbar
		v-model="display"
		:color="color[notification.status || 'info']"
		:timeout="timeout"
		:transition="transition"
		class="pl-4 pt-0 pb-4 pr-0 notification-snackbar"
		location="bottom left"
		@update:model-value="dismissed"
	>
		<span class="text-white"> {{ notification.message }}</span>
		<template #actions="{ attrs }">
			<v-btn
				v-if="timeout > 1000"
				v-bind="attrs"
				class="text-white"
				@click="dismissNotification"
			>
				Close
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
import { mapMutations } from "vuex";

export default {
	name: "VNotification",

	data() {
		return {
			color: {
				200: "success",
				401: "error",
				404: "error",
				error: "error",
				info: "info",
				success: "success",
			},
			display: false,
			inQueue: false,
			notification: {},
			queue: [],
			timeout: 3000,
		};
	},

	computed: {
		transition() {
			return this.inQueue
				? "scroll-y-reverse-transition"
				: "v-snack-transition";
		},
	},

	mounted() {
		// watch store notification value for mutations
		this.$store.watch(
			(state) => state.notification,
			({ message, status = "info", persist = false, timeout = 3000 }) => {
				if (message && status) {
					// clone the notification
					const notification = { message, status, persist, timeout };

					// clear store
					this.clearNotification();

					if (this.display) {
						// push into queue if currently displaying
						this.queue.push(notification);
					} else {
						// display the notification
						this.displayNotification(notification);
					}
				}
			},
			{ deep: true }
		);
	},

	methods: {
		...mapMutations(["clearNotification"]),
		dismissed(value) {
			if (value === false) {
				// load next queued notification after 500 ms
				if (this.queue.length > 0) {
					setTimeout(() => {
						this.inQueue = true;
						this.displayNotification(this.queue.shift());
					}, 500);
				} else {
					this.inQueue = false;
					this.display = false;
				}
			}
		},
		displayNotification(notification) {
			this.timeout = this.getTimeout(notification);
			this.notification = notification;
			this.display = true;
		},
		dismissNotification() {
			this.dismissed(false);
		},
		getTimeout({ status, persist, timeout }) {
			if (persist) {
				return -1;
			} else if (timeout) {
				return timeout;
			}
			return this.color[status] === "error" ? -1 : 3000;
		},
	},
};
</script>
