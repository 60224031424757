<template>
	<v-card class="d-flex flex-column">
		<v-toolbar
			color="primary"
			dark
			flat
		>
			<v-toolbar-title>
				{{ title }}
			</v-toolbar-title>
		</v-toolbar>

		<v-data-table
			class="pa-5"
			:items="citationDisplay"
			:items-per-page="-1"
			hide-default-header
			hide-default-footer
			:loading="loading"
		>
			<template #item.label="{ item }">
				<td width="150px">
					{{ item.label }}
				</td>
			</template>
		</v-data-table>

		<v-card-actions class="my-8">
			<v-spacer />

			<v-btn
				class="bg-secondary text-white"
				:disabled="loading"
				elevation="2"
				@click="confirmCitation"
			>
				Use these values
			</v-btn>

			<v-btn
				class="bg-grey"
				elevation="2"
				@click="cancelCitation"
			>
				Cancel
			</v-btn>

			<v-spacer />
		</v-card-actions>
	</v-card>
</template>

<script>
const orderedList = [
	{
		key: "title",
		text: "Title",
	},
	{
		key: "shortTitle",
		text: "Short title",
	},
	{
		key: "creators",
		text: "Authors",
	},
	{
		key: "publicationTitle",
		text: "Publication",
	},
	{
		key: "pages",
		text: "Pages",
	},
	{
		key: "date",
		text: "Date",
	},
	{
		key: "url",
		text: "URL",
	},
	{
		key: "PMID",
		text: "PMID",
	},
	{
		key: "PMCID",
		text: "PMCID",
	},
	{
		key: "DOI",
		text: "DOI",
	},
	{
		key: "ISSN",
		text: "ISSN",
	},
];

export default {
	name: "PaperCitationConfirm",

	props: {
		loading: Boolean,
		citation: { type: Object, default: () => null },
	},
	emits: ["confirm", "cancel"],

	data() {
		return {
			title: "Confirm citation",
		};
	},

	computed: {
		citationDisplay() {
			const extras = {};

			// parse out pmid and pmcid
			if (this.citation.extra) {
				this.citation.extra.split("\n").forEach((line) => {
					const data = line.split(": ");
					extras[data[0]] = data[1];
				});
			}

			return [
				...orderedList.map((item) => {
					let value = "";

					if (this.citation[item.key]) {
						// zotero specific parsing logic
						if (item.key === "creators") {
							value = this.citation[item.key]
								.filter(
									(creator) =>
										creator.creatorType === "author"
								)
								.map(
									(author) =>
										`${author.firstName} ${author.lastName}`
								)
								.join(", ");
						} else {
							value = this.citation[item.key];
						}
					} else {
						// check extra field for pmid and pmcid
						if (["PMID", "PMCID"].includes(item.key)) {
							value = extras[item.key];
						}
					}

					return {
						label: item.text,
						value: value,
					};
				}),
			];
		},
	},

	methods: {
		confirmCitation() {
			this.$emit("confirm");
		},

		cancelCitation() {
			this.$emit("cancel");
		},
	},
};
</script>

<style lang="scss">
.v-skeleton-loader.table-cell-loader .v-skeleton-loader__table-cell {
	width: unset;
}
</style>
