import * as AlignmentCornersRepainter from "./repainters/alignment-corners-repainter.js";
import * as DataPointsRepainter from "./repainters/data-points-repainter.js";
import * as MultipleDatasetRepainter from "./repainters/multiple-dataset-repainter.js";

/**
 * Initializes WPD repainters.
 */
function init(wpd) {
	AlignmentCornersRepainter.init(wpd);
	DataPointsRepainter.init(wpd);
	MultipleDatasetRepainter.init(wpd);
}

export { init };
