/**
 * Initializes WPD alignAxes object.
 */
function init(wpd, wpdDocument, dataEntryVM) {
	const originalPickCorners = wpd.alignAxes.pickCorners;
	const awesompletes = [];

	const awesompleteSelectHandler = ({ originalEvent }) => {
		const input = originalEvent.target
			.closest(".awesomplete")
			.querySelector(".axis-data-type");

		wpd.alignAxes.validateDataType(input);
	};

	// clear sidebar on pick corners call
	wpd.alignAxes.pickCorners = function () {
		originalPickCorners();
		wpd.sidebar.clear();
	};

	wpd.alignAxes.pickHeaderCorners = function () {
		wpd.popup.close("bar-categorical-axes-info");
		wpd.sidebar.show("bar-header-calibration-sidebar");
		wpd.graphicsWidget.setTool(
			new wpd.BarCornersTool(wpd.tree.getActiveAxes())
		);
	};

	wpd.alignAxes.categoryChange = function (lockLines, resetTool) {
		const headerCountField = wpdDocument.getElementById(
			"bar-header-category-count"
		);

		const errorText = headerCountField
			.closest("p")
			.querySelector(".error-message");
		if (
			isNaN(headerCountField.value) ||
			headerCountField.value < 1 ||
			headerCountField > 50
		) {
			errorText.innerText = "Must be a number greater than zero";
			return;
		}

		const headerCount = parseInt(headerCountField.value, 10);
		const activeAxes = wpd.tree.getActiveAxes();
		const metadata = activeAxes.getMetadata();
		metadata.barHeaders.count = headerCount;

		if (lockLines) {
			metadata.barHeaders.linesLocked = true;
		}

		activeAxes.setMetadata(metadata);

		if (resetTool) {
			wpd.graphicsWidget.removeTool();
			wpd.graphicsWidget.forceHandlerRepaint();
			wpd.graphicsWidget.setTool(
				new wpd.BarHeadersTool(wpd.tree.getActiveAxes(), dataEntryVM)
			);
		}
	};

	wpd.alignAxes.completeHeaderCorners = function () {
		wpd.sidebar.clear();
		wpd.sidebar.show("bar-header-count-sidebar");
		const activeAxes = wpd.tree.getActiveAxes();
		const metadata = activeAxes.getMetadata();
		if (!metadata.barHeaders) {
			throw new Error("Bar chart corners not set");
		}

		wpd.alignAxes.categoryChange(false, true);
	};

	wpd.alignAxes.changeCategoryCount = function () {
		wpd.alignAxes.categoryChange(false, true);
	};

	wpd.alignAxes.completeCategoryCount = function () {
		wpd.alignAxes.categoryChange(true, false);
		wpd.graphicsWidget.forceHandlerRepaint();

		// reset category count
		const headerCountField = wpdDocument.getElementById(
			"bar-header-category-count"
		);
		headerCountField.value = "1";

		wpd.sidebar.clear();
		wpd.sidebar.show("bar-header-metadata-sidebar");
	};

	wpd.alignAxes.completeHeaderMetadata = function () {
		const activeAxes = wpd.tree.getActiveAxes();
		const metadata = activeAxes.getMetadata();
		const headers = metadata.barHeaders?.headers;
		if (!headers) return;

		const missingData = headers.find((header) => {
			if (!header.metadata) return true;
			const metadata = Object.keys(header.metadata).filter(
				(key) => !isNaN(key)
			);
			if (!metadata) return true;
			return false;
		});

		const el = wpdDocument.getElementById("bar-header-metadata-sidebar");
		const errorText = el.querySelector(".error-message");
		if (missingData) {
			errorText.innerText =
				"Missing header metadata; fill in metadata for each header and try again";
			return;
		} else {
			errorText.innerText = "";
		}

		wpd.dataSeriesManagement.showAddDataset();
	};

	// display all data after loading (for first load)
	wpd.alignAxes.renameAxes = function () {
		wpd.popup.close("rename-axes-popup");

		// check if this name already exists
		const name = wpdDocument
			.getElementById("rename-axes-name-input")
			.value.trim();
		const plotData = wpd.appData.getPlotData();
		if (plotData.getAxesNames().indexOf(name) >= 0 || name.length === 0) {
			wpd.messagePopup.show(
				"Rename Axis Error",
				"An axis with this name already exists, please pick a different name.",
				wpd.alignAxes.showRenameAxes
			);

			return;
		}

		const axis = wpd.tree.getActiveAxes();

		// find xy labels and update axis and dataset names
		plotData.getAxesColl().forEach((a) => {
			if (a.name === `${axis.name} Labels`) {
				a.name = `${name} Labels`;
			}
		});

		axis.name = name;

		wpd.tree.refreshPreservingSelection();
		// removed wpd.tree.selectPath call
	};

	// update dataset creation behavior, only applies to non-image and non-table
	// axes (only graphs)
	wpd.alignAxes.postProcessAxesAdd = async function (axis) {
		// figure out graph type base name
		const graphName = {
			bar: "Bar",
			xy: "XY",
		};
		const graphType = wpd.custom.identifyAxis(axis);

		// immediately update the axis name
		axis.name = wpd.custom.makeAxisName(
			`${graphName[graphType]} graph`,
			true
		);

		// immediately set the active axis on the tree widget
		wpd.tree.setActiveAxis(axis);

		// dispatch axes add event
		wpd.events.dispatch("wpd.axes.add", {
			axes: axis,
		});

		// reset graph add new button
		wpdDocument
			.getElementById("graph-add-button")
			.classList.remove("pressed-button");

		const fileManager = wpd.appData.getFileManager();
		const pageManager = wpd.appData.getPageManager();

		// add axis to appropriate collections
		fileManager.addAxesToCurrentFile([axis]);

		if (wpd.appData.isMultipage()) {
			pageManager.addAxesToCurrentPage([axis]);
		}

		if (graphType === "bar") {
			const barAxisDataTypeInput =
				wpdDocument.getElementById("bar-axis-data-type");
			awesompletes[0] = new Awesomplete(barAxisDataTypeInput, {
				list: dataEntryVM.getTags(),
				autoFirst: true,
				data: function (item) {
					return {
						label: item.name,
						value: item.uuid,
					};
				},
				replace: function (suggestion) {
					this.input.value = suggestion.label;
				},
			});

			barAxisDataTypeInput.removeEventListener(
				"awesomplete-selectcomplete",
				awesompleteSelectHandler
			);
			barAxisDataTypeInput.addEventListener(
				"awesomplete-selectcomplete",
				awesompleteSelectHandler
			);

			wpdDocument
				.getElementById("bar-axis-decimals")
				.removeEventListener("blur", wpd.alignAxes.validateDecimals);
			wpdDocument
				.getElementById("bar-axis-decimals")
				.addEventListener("blur", wpd.alignAxes.validateDecimals);

			wpd.popup.show("bar-graph-axes-label-popup");
		} else {
			const xAxisDataTypeInput =
				wpdDocument.getElementById("x-axis-data-type");
			const yAxisDataTypeInput =
				wpdDocument.getElementById("y-axis-data-type");

			awesompletes[0] = new Awesomplete(xAxisDataTypeInput, {
				list: dataEntryVM.getTags(),
				autoFirst: true,
				data: function (item) {
					return {
						label: item.name,
						value: item.uuid,
					};
				},
				replace: function (suggestion) {
					this.input.value = suggestion.label;
				},
			});
			awesompletes[1] = new Awesomplete(yAxisDataTypeInput, {
				list: dataEntryVM.getTags(),
				autoFirst: true,
				data: function (item) {
					return {
						label: item.name,
						value: item.uuid,
					};
				},
				replace: function (suggestion) {
					this.input.value = suggestion.label;
				},
			});

			xAxisDataTypeInput.removeEventListener(
				"awesomplete-selectcomplete",
				awesompleteSelectHandler
			);
			xAxisDataTypeInput.addEventListener(
				"awesomplete-selectcomplete",
				awesompleteSelectHandler
			);
			yAxisDataTypeInput.removeEventListener(
				"awesomplete-selectcomplete",
				awesompleteSelectHandler
			);
			yAxisDataTypeInput.addEventListener(
				"awesomplete-selectcomplete",
				awesompleteSelectHandler
			);

			// attach blur handler to decimal place inputs
			wpdDocument
				.getElementById("x-axis-decimals")
				.removeEventListener("blur", wpd.alignAxes.validateDecimals);
			wpdDocument
				.getElementById("y-axis-decimals")
				.removeEventListener("blur", wpd.alignAxes.validateDecimals);
			wpdDocument
				.getElementById("x-axis-decimals")
				.addEventListener("blur", wpd.alignAxes.validateDecimals);
			wpdDocument
				.getElementById("y-axis-decimals")
				.addEventListener("blur", wpd.alignAxes.validateDecimals);

			wpd.popup.show("graph-axes-label-popup");
		}
	};

	wpd.alignAxes.validateDataType = function (input) {
		const value = input.value.trim();
		const isEmpty = value === undefined || value === null || value === "";
		const errorText = input.closest("td").querySelector(".error-message");
		const tagNames = dataEntryVM.getTags().map((tag) => tag.name);

		if (isEmpty) {
			errorText.innerText = "Data type cannot be empty";
			input.classList.add("input-error");
			return false;
		} else if (!tagNames.includes(value)) {
			errorText.innerText = "Data type must be a value tag name";
			input.classList.add("input-error");
			return false;
		}

		errorText.innerText = "";
		input.classList.remove("input-error");

		return true;
	};

	wpd.alignAxes.validateUnit = function (input) {
		const value = input.value.trim();
		const isEmpty = value === undefined || value === null || value === "";
		const errorText = input.closest("td").querySelector(".error-message");

		if (isEmpty) {
			errorText.innerText = "Unit cannot be empty";
			input.classList.add("input-error");
			return false;
		}

		errorText.innerText = "";
		input.classList.remove("input-error");

		return true;
	};

	wpd.alignAxes.validateDecimals = function ({ target }) {
		if (!target.value) {
			// default to 2 if empty
			target.value = "2";
		}
	};

	wpd.alignAxes.validateAll = function () {
		const axis = wpd.tree.getActiveAxes();
		const graphType = wpd.custom.identifyAxis(axis);

		if (graphType === "bar") {
			const barAxisDataType =
				wpdDocument.getElementById("bar-axis-data-type");
			const barAxisDataTypeValid =
				wpd.alignAxes.validateDataType(barAxisDataType);
			const barAxisUnit = wpdDocument.getElementById("bar-axis-unit");
			const barAxisUnitValid = wpd.alignAxes.validateUnit(barAxisUnit);
			if (!barAxisDataTypeValid || !barAxisUnitValid) return false;
		} else {
			const xAxisDataType =
				wpdDocument.getElementById("x-axis-data-type");
			const xAxisDataTypeValid =
				wpd.alignAxes.validateDataType(xAxisDataType);

			const yAxisDataType =
				wpdDocument.getElementById("y-axis-data-type");
			const yAxisDataTypeValid =
				wpd.alignAxes.validateDataType(yAxisDataType);

			const xAxisUnit = wpdDocument.getElementById("x-axis-unit");
			const xAxisUnitValid = wpd.alignAxes.validateUnit(xAxisUnit);

			const yAxisUnit = wpdDocument.getElementById("y-axis-unit");
			const yAxisUnitValid = wpd.alignAxes.validateUnit(yAxisUnit);

			if (
				!xAxisDataTypeValid ||
				!yAxisDataTypeValid ||
				!xAxisUnitValid ||
				!yAxisUnitValid
			)
				return false;
		}

		return true;
	};

	wpd.alignAxes.destroyAwesompletes = function () {
		for (let i = 0; i < awesompletes.length; i++) {
			awesompletes[i].destroy();
		}

		awesompletes.length = 0;
	};

	wpd.alignAxes.clearAxesLabelValues = function () {
		wpdDocument.getElementById("x-axis-data-type").value = "";
		wpdDocument.getElementById("x-axis-unit").value = "";
		wpdDocument.getElementById("x-axis-decimals").value = "2";
		wpdDocument.getElementById(
			"x-axis-change-from-baseline"
		).checked = false;

		wpdDocument.getElementById("y-axis-data-type").value = "";
		wpdDocument.getElementById("y-axis-unit").value = "";
		wpdDocument.getElementById("y-axis-decimals").value = "2";
		wpdDocument.getElementById(
			"y-axis-change-from-baseline"
		).checked = false;

		wpdDocument.getElementById("bar-axis-data-type").value = "";
		wpdDocument.getElementById("bar-axis-unit").value = "";
		wpdDocument.getElementById("bar-axis-decimals").value = "2";
		wpdDocument.getElementById(
			"bar-axis-change-from-baseline"
		).checked = false;
	};

	wpd.alignAxes.closeAxesLabelPopup = function () {
		const axis = wpd.tree.getActiveAxes();
		const graphType = wpd.custom.identifyAxis(axis);
		if (graphType === "bar") {
			wpd.popup.close("bar-graph-axes-label-popup");
		} else {
			wpd.popup.close("graph-axes-label-popup");
		}
		wpd.alignAxes.clearAxesLabelValues();
		wpd.alignAxes.destroyAwesompletes();
		wpd.alignAxes.cancelGraphAdd();
	};

	// delete newly created axis because user bailed before calibration finished
	wpd.alignAxes.cancelGraphAdd = function () {
		// locally store active axis
		const axis = wpd.tree.getActiveAxes();

		// clear active axis
		wpd.tree.setActiveAxis(null);

		// exit wizard
		wpd.tree.exitWizard();

		// delete active axis from plot data
		wpd.appData.getPlotData().deleteAxes(axis);

		// delete active axis from file manager
		const fileManager = wpd.appData.getFileManager();
		fileManager.deleteAxesFromCurrentFile([axis]);

		// delete active axis from page manager
		if (wpd.appData.isMultipage()) {
			wpd.appData.getPageManager().deleteAxesFromCurrentPage([axis]);
		}
	};

	wpd.alignAxes.saveAxesData = function () {
		const validationResult = wpd.alignAxes.validateAll();
		if (!validationResult) return;
		const axis = wpd.tree.getActiveAxes();
		const metadata = axis.getMetadata();
		const graphType = wpd.custom.identifyAxis(axis);
		if (graphType === "bar") {
			const barAxisDataType =
				wpdDocument.getElementById("bar-axis-data-type");
			const barAxisUnit = wpdDocument.getElementById("bar-axis-unit");
			const barAxisDecimals =
				wpdDocument.getElementById("bar-axis-decimals");
			const barAxisIsChangeFromBaseline = wpdDocument.getElementById(
				"bar-axis-change-from-baseline"
			);

			metadata.axesLabelData = {
				bar: {
					dataType: barAxisDataType.value,
					unit: barAxisUnit.value,
					decimals: barAxisDecimals.value,
					changeFromBaseline: barAxisIsChangeFromBaseline.checked,
				},
			};
			axis.setMetadata(metadata);
			wpd.tree.setActiveAxis(axis);

			wpd.popup.close("bar-graph-axes-label-popup");
			wpd.alignAxes.clearAxesLabelValues();
			wpd.alignAxes.destroyAwesompletes();
			wpd.popup.show("bar-categorical-axes-info");
		} else {
			const xAxisDataType =
				wpdDocument.getElementById("x-axis-data-type");
			const xAxisUnit = wpdDocument.getElementById("x-axis-unit");
			const xAxisDecimals = wpdDocument.getElementById("x-axis-decimals");
			const xAxisIsChangeFromBaseline = wpdDocument.getElementById(
				"x-axis-change-from-baseline"
			);
			const yAxisDataType =
				wpdDocument.getElementById("y-axis-data-type");
			const yAxisUnit = wpdDocument.getElementById("y-axis-unit");
			const yAxisDecimals = wpdDocument.getElementById("y-axis-decimals");
			const yAxisIsChangeFromBaseline = wpdDocument.getElementById(
				"y-axis-change-from-baseline"
			);

			metadata.axesLabelData = {
				x: {
					dataType: xAxisDataType.value,
					unit: xAxisUnit.value,
					decimals: xAxisDecimals.value,
					changeFromBaseline: xAxisIsChangeFromBaseline.checked,
				},
				y: {
					dataType: yAxisDataType.value,
					unit: yAxisUnit.value,
					decimals: yAxisDecimals.value,
					changeFromBaseline: yAxisIsChangeFromBaseline.checked,
				},
			};

			axis.setMetadata(metadata);
			wpd.tree.setActiveAxis(axis);

			wpd.popup.close("graph-axes-label-popup");
			wpd.alignAxes.clearAxesLabelValues();
			wpd.alignAxes.destroyAwesompletes();
			wpd.dataSeriesManagement.showAddDataset();
		}
	};

	// override align function for xy axis to close popup before calling postProcessAxesAdd
	wpd.XYAxesCalibrator.prototype.align = function () {
		let xmin = wpdDocument.getElementById("xmin").value;
		let xmax = wpdDocument.getElementById("xmax").value;
		let ymin = wpdDocument.getElementById("ymin").value;
		let ymax = wpdDocument.getElementById("ymax").value;
		let xlog = wpdDocument.getElementById("xlog").checked;
		let ylog = wpdDocument.getElementById("ylog").checked;
		let noRotation = wpdDocument.getElementById(
			"xy-axes-no-rotation"
		).checked;
		let axes = this._isEditing
			? wpd.tree.getActiveAxes()
			: new wpd.XYAxes();

		// validate log scale values
		if (
			(xlog && (parseFloat(xmin) == 0 || parseFloat(xmax) == 0)) ||
			(ylog && (parseFloat(ymin) == 0 || parseFloat(ymax) == 0))
		) {
			wpd.popup.close("xyAlignment");
			wpd.messagePopup.show(
				wpd.gettext("calibration-invalid-log-inputs"),
				wpd.gettext("calibration-enter-valid-log"),
				wpd.alignAxes.getCornerValues
			);
			return false;
		}

		this._calibration.setDataAt(0, xmin, ymin);
		this._calibration.setDataAt(1, xmax, ymin);
		this._calibration.setDataAt(2, xmin, ymin);
		this._calibration.setDataAt(3, xmax, ymax);
		if (!axes.calibrate(this._calibration, xlog, ylog, noRotation)) {
			wpd.popup.close("xyAlignment");
			wpd.messagePopup.show(
				wpd.gettext("calibration-invalid-inputs"),
				wpd.gettext("calibration-enter-valid"),
				wpd.alignAxes.getCornerValues
			);
			return false;
		}

		// the alignemnt popup should close before post processing, since it may open another popup
		wpd.popup.close("xyAlignment");

		if (!this._isEditing) {
			axes.name = wpd.alignAxes.makeAxesName(wpd.XYAxes);
			let plot = wpd.appData.getPlotData();
			plot.addAxes(axes, wpd.appData.isMultipage());
			wpd.alignAxes.postProcessAxesAdd(axes);
		}

		return true;
	};

	// override align function for bar axis to close popup before calling postProcessAxesAdd
	wpd.BarAxesCalibrator.prototype.align = function () {
		let p1 = wpdDocument.getElementById("bar-axes-p1").value;
		let p2 = wpdDocument.getElementById("bar-axes-p2").value;
		let isLogScale =
			wpdDocument.getElementById("bar-axes-log-scale").checked;
		let isRotated = wpdDocument.getElementById("bar-axes-rotated").checked;
		let axes = this._isEditing
			? wpd.tree.getActiveAxes()
			: new wpd.BarAxes();

		this._calibration.setDataAt(0, 0, p1);
		this._calibration.setDataAt(1, 0, p2);
		if (!axes.calibrate(this._calibration, isLogScale, isRotated)) {
			wpd.popup.close("barAlignment");
			wpd.messagePopup.show(
				wpd.gettext("calibration-invalid-inputs"),
				wpd.gettext("calibration-enter-valid"),
				wpd.alignAxes.getCornerValues
			);
			return false;
		}

		// the alignemnt popup should close before post processing, since it may open another popup
		wpd.popup.close("barAlignment");

		if (!this._isEditing) {
			axes.name = wpd.alignAxes.makeAxesName(wpd.BarAxes);
			let plot = wpd.appData.getPlotData();
			plot.addAxes(axes, wpd.appData.isMultipage());
			wpd.alignAxes.postProcessAxesAdd(axes);
		}

		return true;
	};

	// override align function for polar axis to close popup before calling postProcessAxesAdd
	wpd.PolarAxesCalibrator.prototype.align = function () {
		let r1 = parseFloat(wpdDocument.getElementById("polar-r1").value);
		let theta1 = parseFloat(
			wpdDocument.getElementById("polar-theta1").value
		);
		let r2 = parseFloat(wpdDocument.getElementById("polar-r2").value);
		let theta2 = parseFloat(
			wpdDocument.getElementById("polar-theta2").value
		);
		let degrees = wpdDocument.getElementById("polar-degrees").checked;
		let orientation = wpdDocument.getElementById("polar-clockwise").checked;
		let rlog = wpdDocument.getElementById("polar-log-scale").checked;
		let axes = this._isEditing
			? wpd.tree.getActiveAxes()
			: new wpd.PolarAxes();
		let isDegrees = degrees;

		this._calibration.setDataAt(1, r1, theta1);
		this._calibration.setDataAt(2, r2, theta2);
		axes.calibrate(this._calibration, isDegrees, orientation, rlog);

		// the alignemnt popup should close before post processing, since it may open another popup
		wpd.popup.close("polarAlignment");

		if (!this._isEditing) {
			axes.name = wpd.alignAxes.makeAxesName(wpd.PolarAxes);
			let plot = wpd.appData.getPlotData();
			plot.addAxes(axes, wpd.appData.isMultipage());
			wpd.alignAxes.postProcessAxesAdd(axes);
		}

		return true;
	};

	// override align function for ternary axis to close popup before calling postProcessAxesAdd
	wpd.TernaryAxesCalibrator.prototype.align = function () {
		let range100 = wpdDocument.getElementById("range0to100").checked;
		let ternaryNormal = wpdDocument.getElementById("ternarynormal").checked;
		let axes = this._isEditing
			? wpd.tree.getActiveAxes()
			: new wpd.TernaryAxes();

		axes.calibrate(this._calibration, range100, ternaryNormal);

		// the alignemnt popup should close before post processing, since it may open another popup
		wpd.popup.close("ternaryAlignment");

		if (!this._isEditing) {
			axes.name = wpd.alignAxes.makeAxesName(wpd.TernaryAxes);
			let plot = wpd.appData.getPlotData();
			plot.addAxes(axes, wpd.appData.isMultipage());
			wpd.alignAxes.postProcessAxesAdd(axes);
		}
		return true;
	};

	// override align function for map axis to close popup before calling postProcessAxesAdd
	wpd.MapAxesCalibrator.prototype.align = function () {
		let scaleLength = parseFloat(
			wpdDocument.getElementById("scaleLength").value
		);
		let scaleUnits = wpdDocument.getElementById("scaleUnits").value;
		let isOriginLocationBottomLeft = wpdDocument.getElementById(
			"map-origin-bottom-left"
		).checked;
		let originLocation = isOriginLocationBottomLeft
			? "bottom-left"
			: "top-left";
		let imageHeight = wpd.graphicsWidget.getImageSize().height;
		let axes = this._isEditing
			? wpd.tree.getActiveAxes()
			: new wpd.MapAxes();

		axes.calibrate(
			this._calibration,
			scaleLength,
			scaleUnits,
			originLocation,
			imageHeight
		);

		// the alignemnt popup should close before post processing, since it may open another popup
		wpd.popup.close("mapAlignment");

		if (!this._isEditing) {
			axes.name = wpd.alignAxes.makeAxesName(wpd.MapAxes);
			let plot = wpd.appData.getPlotData();
			plot.addAxes(axes, wpd.appData.isMultipage());
			wpd.alignAxes.postProcessAxesAdd(axes);
		}

		return true;
	};

	// override align function for circular chart recorder axis to close popup before calling postProcessAxesAdd
	wpd.CircularChartRecorderCalibrator.prototype.align = function () {
		let t0 = wpdDocument.getElementById("circular-t0").value;
		let r0 = parseFloat(wpdDocument.getElementById("circular-r0").value);
		let r2 = parseFloat(wpdDocument.getElementById("circular-r2").value);
		let tstart = wpdDocument.getElementById("circular-tstart").value;
		let axes = this._isEditing
			? wpd.tree.getActiveAxes()
			: new wpd.CircularChartRecorderAxes();

		this._calibration.setDataAt(0, t0, r0);
		this._calibration.setDataAt(1, t0, 0);
		this._calibration.setDataAt(2, t0, r2);
		this._calibration.setDataAt(3, 0, r2);
		this._calibration.setDataAt(4, 0, r2);

		axes.calibrate(this._calibration, tstart);

		// the alignemnt popup should close before post processing, since it may open another popup
		wpd.popup.close("circularChartRecorderAlignment");

		if (!this._isEditing) {
			axes.name = wpd.alignAxes.makeAxesName(
				wpd.CircularChartRecorderAxes
			);
			let plot = wpd.appData.getPlotData();
			plot.addAxes(axes, wpd.appData.isMultipage());
			wpd.alignAxes.postProcessAxesAdd(axes);
		}

		return true;
	};
}

export { init };
