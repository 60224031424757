<template>
	<v-row
		align-content="start"
		justify="start"
	>
		<v-col cols="12">
			<tag-group-tabs />
		</v-col>
	</v-row>
</template>

<script>
import TagGroupTabs from "@/pages/TagGroupManager/TagGroupTabs";

export default {
	name: "TagGroupManager",

	components: {
		TagGroupTabs,
	},
};
</script>

<style scoped lang="scss">
.col {
	flex-grow: 0;
}
</style>
