<template>
	<v-dialog-table
		v-model="papers"
		name="paper"
	/>
</template>

<script>
import VDialogTable from "@/components/VDialogTable";

export default {
	name: "ProjectPaperTable",

	components: {
		VDialogTable,
	},

	props: {
		modelValue: { type: Array, default: () => [] },
	},
	emits: ["update:modelValue"],

	computed: {
		papers: {
			get() {
				return this.modelValue;
			},
			set(val) {
				this.$emit("update:modelValue", val);
			},
		},
	},
};
</script>

<style scoped lang="scss"></style>
