<template>
	<v-container>
		<v-row class="mb-5">
			<v-select
				v-model="attachAction"
				:items="attachActions"
				label="Attach to project"
				:persistent-hint="true"
				hint="Papers should be attached to at least one project."
				:menu-props="{ offsetY: true }"
				prepend-icon="mdi-file-tree-outline"
			/>
		</v-row>

		<v-row
			v-if="attachAction === 'EXIST'"
			class="my-5 mb-10"
		>
			<project-search
				v-model="projectSelects"
				:default-projects="projects"
				:disabled="!!submitting"
			/>
		</v-row>

		<v-row v-else-if="attachAction === 'NEW'">
			<v-col
				class="pa-0"
				cols="12"
			>
				<project-edit
					ref="projectEdit"
					v-model:dirty="subDirty"
					v-model:invalid="subInvalid"
					nested
					no-actions
				/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import _ from "lodash";
import { mapMutations, mapState } from "vuex";

import ProjectEdit from "@/pages/ProjectManager/ProjectEdit";
import ProjectSearch from "@/pages/ProjectManager/ProjectSearch";

export default {
	name: "ProjectSelect",

	components: {
		ProjectEdit,
		ProjectSearch,
	},

	props: {
		defaultProjects: { type: Array, default: () => [] },
		nested: Boolean,
		noActions: Boolean,
		useSessionProjects: Boolean,
	},
	emits: ["update:dirty", "update:invalid"],

	data() {
		return {
			attachAction: "EXIST",
			attachActions: [
				{
					title: "Create a new project",
					value: "NEW",
				},
				{
					title: "Add to an existing project",
					value: "EXIST",
				},
				{
					title: "Do not associate with project",
					value: "",
				},
			],
			invalid: true,
			projects: [],
			projectSelects: [], // project UUIDs
			subDirty: false,
			subInvalid: true,
			submitting: false,
		};
	},

	computed: {
		...mapState(["sessionProjects"]),
	},

	watch: {
		attachAction(val, oldVal) {
			if (val !== oldVal) {
				// clear selected projects if attach action changed
				this.projectSelects = [];
			}

			if (!val) {
				this.invalid = false;
			} else {
				// starting conditions
				if (val === "NEW" && this.subInvalid) {
					this.invalid = true;
				} else if (val === "EXIST" && !this.projectSelects.length) {
					this.invalid = true;
				} else {
					this.invalid = false;
				}
			}
		},
		defaultProjects(val) {
			this.selectProjects(val);
		},
		invalid(val) {
			if (val) {
				this.$emit("update:invalid", true);
			} else {
				this.$emit("update:invalid", false);
			}
		},
		projectSelects(val) {
			if (this.attachAction === "EXIST") {
				if (!val.length) {
					this.invalid = true;
				} else {
					this.invalid = false;

					this.$emit("update:dirty", true);
				}
			}
		},
		subDirty(val) {
			if (val && this.attachAction) {
				this.$emit("update:dirty", true);
			} else {
				this.$emit("update:dirty", false);
			}
		},
		subInvalid(val) {
			if (this.attachAction === "NEW") {
				if (val) {
					this.invalid = true;
				} else {
					this.invalid = false;
				}
			}
		},
	},

	mounted() {
		this.selectProjects();
	},

	methods: {
		...mapMutations([
			"clearSessionProjects",
			"setSessionProjects",
			"showNotification",
		]),
		clear() {
			this.selectProjects();

			if (this.$refs.projectEdit) {
				this.$refs.projectEdit.clear();
			}
		},
		select() {
			const context = this;

			return new Promise((resolve, reject) => {
				if (context.attachAction === "NEW") {
					// create project
					context.$refs.projectEdit
						.saveRequest("add")
						.then((response) => {
							const projects = [response.data.project];

							// store current session projects
							context.setSessionProjects(projects);

							resolve(projects);
						})
						.catch((error) => reject(error));
				} else if (context.attachAction === "EXIST") {
					// store current session projects
					context.setSessionProjects(context.projectSelects);

					// select a project
					resolve(context.projectSelects);
				} else {
					// clear current session projects
					context.clearSessionProjects();

					// do not select a project
					resolve([]);
				}
			});
		},
		selectProjects() {
			// select "EXIST" option for attach action
			this.attachAction = "EXIST";

			if (!_.isEmpty(this.defaultProjects)) {
				this.projects = [...this.defaultProjects];
				this.projectSelects = [...this.defaultProjects];
				this.invalid = false;
			} else {
				if (this.useSessionProjects) {
					// adding new paper
					if (!_.isEmpty(this.sessionProjects)) {
						// default to cached session project
						this.projects = [...this.sessionProjects];
						this.projectSelects = [...this.sessionProjects];

						this.invalid = false;
					} else {
						this.projects = [];
						this.projectSelects = [];

						this.invalid = true;
					}
				} else {
					// loading existing paper but no projects selected
					this.attachAction = "";
					this.projects = [];
					this.projectSelects = [];

					this.invalid = true;
				}
			}
		},
	},
};
</script>

<style scoped lang="scss"></style>
