function init(wpd, wpdDocument, wpdWindow, dataEntryVM) {
	wpd.barChartUtils = {};

	const mouseOverHitThreshold = 8;
	const headerLineMargin = 10;
	const headerBarLength = 200;
	const headerBarMargin = 1;

	const BarDirection = {
		UP: "UP",
		DOWN: "DOWN",
		LEFT: "LEFT",
		RIGHT: "RIGHT",
	};
	const LineOrientation = {
		HORIZONTAL: "HORIZONTAL",
		VERTICAL: "VERTICAL",
	};

	wpd.barChartUtils.drawHeaderDividers = function (ctx, dividers) {
		if (!dividers) return;

		const zoomRatio = wpd.graphicsWidget.getZoomRatio();
		for (let divider of dividers) {
			const headerDividerStart = [divider.startX, divider.startY].map(
				(pixel) => pixel * zoomRatio
			);
			const headerDividerEnd = [divider.endX, divider.endY].map(
				(pixel) => pixel * zoomRatio
			);
			const color = divider.hover
				? wpd.Colors.activeDark
				: wpd.Colors.headerActiveDarkA;
			wpd.barChartUtils.drawLine(
				ctx,
				headerDividerStart,
				headerDividerEnd,
				color
			);
		}
	};

	wpd.barChartUtils.drawLine = function (ctx, start, end, color) {
		ctx.beginPath();
		ctx.strokeStyle = color;
		ctx.lineWidth = 4;
		ctx.moveTo(...start);
		ctx.lineTo(...end);
		ctx.stroke();
	};

	wpd.barChartUtils.drawAxisLine = function (ctx, axes, metadata) {
		if (!metadata.barHeaders) return;

		const { p1, p2 } = metadata.barHeaders;
		const barP1 = axes.calibration.getPoint(0);

		if (!p1 || !p2 || !barP1) return;

		const headerOrientation = metadata.barHeaders.headerOrientation;
		const p1Dist = wpd.barChartUtils.calculateDistance(barP1, p1);
		const p2Dist = wpd.barChartUtils.calculateDistance(barP1, p2);

		const headerAxisStartPoint = p1Dist > p2Dist ? p2 : p1;
		const otherPoint = p1Dist > p2Dist ? p1 : p2;

		const zoomRatio = wpd.graphicsWidget.getZoomRatio();

		const headerAxisStart = [
			headerAxisStartPoint.px,
			headerAxisStartPoint.py,
		];

		const headerAxisEnd = [
			headerOrientation === LineOrientation.HORIZONTAL
				? otherPoint.px
				: headerAxisStartPoint.px,
			headerOrientation === LineOrientation.VERTICAL
				? otherPoint.py
				: headerAxisStartPoint.py,
		];

		metadata.barHeaders.headerAxisLineStart = headerAxisStart;
		metadata.barHeaders.headerAxisLineEnd = headerAxisEnd;

		axes.setMetadata(metadata);

		const color = wpd.Colors.headerActiveDarkA;
		wpd.barChartUtils.drawLine(
			ctx,
			headerAxisStart.map((val) => val * zoomRatio),
			headerAxisEnd.map((val) => val * zoomRatio),
			color
		);
	};

	wpd.barChartUtils.calculateDistance = function (p1, p2) {
		return Math.sqrt((p2.px - p1.px) ** 2 + (p2.py - p1.py) ** 2);
	};

	wpd.BarHeadersTool = class {
		constructor(axes, dataEntryVM) {
			this.axes = axes;
			this.metadata = axes.getMetadata();
			this.ctx = wpd.graphicsWidget.getAllContexts().dataCtx;
			this.vm = dataEntryVM;
			this.lock = false;

			const barP1 = this.axes.calibration.getPoint(0);
			const barP2 = this.axes.calibration.getPoint(1);
			const barAxisOrientation = this.getLineOrientation(barP1, barP2);
			this.metadata.barHeaders.barOrientation = barAxisOrientation;
			const { p1: headerP1, p2: headerP2 } = this.metadata.barHeaders;
			const headerAxisOrientation = this.getLineOrientation(
				headerP1,
				headerP2
			);
			this.metadata.barHeaders.headerOrientation = headerAxisOrientation;
			const barDirection = this.getBarDirection(barP1, barP2, headerP1);
			this.metadata.barHeaders.barDirection = barDirection;
			this.axes.setMetadata(this.metadata);

			this.drawPoints();
			wpd.barChartUtils.drawAxisLine(this.ctx, this.axes, this.metadata);
			this.buildHeaderDividers();
			wpd.barChartUtils.drawHeaderDividers(
				this.ctx,
				this.metadata.barHeaders.headerDividers
			);

			this.reworkEnabled = dataEntryVM.reworkEnabled;
		}

		onRedraw() {
			this.metadata = this.axes.getMetadata();

			this.drawPoints();
			wpd.barChartUtils.drawAxisLine(this.ctx, this.axes, this.metadata);
			wpd.barChartUtils.drawHeaderDividers(
				this.ctx,
				this.metadata.barHeaders?.headerDividers
			);

			if (this.linesLocked()) {
				this.buildHeaderAreas();
				this.drawHeaderGradients();
			}
		}

		onMouseMove(e, pos, imagePos) {
			if (this.lock) return;

			const boundaries = this.getGraphBoundaries();
			const isInArea =
				imagePos.y > boundaries.minY &&
				imagePos.y < boundaries.maxY &&
				imagePos.x > boundaries.minX &&
				imagePos.x < boundaries.maxX;

			if (this.linesLocked()) {
				this.onMouseMoveHeaders(e, pos, imagePos);
			} else if (isInArea) {
				this.onMouseMoveAxisLines(e, pos, imagePos);
			}
		}

		onMouseMoveHeaders(e, pos, imagePos) {
			const headers = this.metadata.barHeaders.headers;
			for (let header of headers) {
				const overlaps = this.rectOverlapTest(header, imagePos);
				if (overlaps) {
					header.hover = true;
				} else {
					header.hover = false;
				}
			}

			this.axes.setMetadata(this.metadata);
			wpd.graphicsWidget.forceHandlerRepaint();
		}

		onMouseMoveAxisLines(e, pos, imagePos) {
			const barLineOrientation = this.metadata.barHeaders.barOrientation;
			const dividers = this.metadata.barHeaders.headerDividers;
			const selectedDividerIndex = dividers.findIndex(
				(divider) => divider.selected
			);
			if (selectedDividerIndex !== -1) {
				e.target.style.cursor = "grabbing";
				this.moveLine(selectedDividerIndex, imagePos);
				return;
			} else {
				let isHovered = false;
				for (let i = 0; i < dividers.length; i++) {
					const divider = dividers[i];
					if (divider.disabled) continue;

					if (
						barLineOrientation === LineOrientation.HORIZONTAL &&
						this.horizontalLineOverlapTest(
							imagePos.y,
							divider.startY
						)
					) {
						divider.hover = true;
						isHovered = true;
						e.target.style.cursor = "grab";
					} else if (
						barLineOrientation === LineOrientation.VERTICAL &&
						this.verticalLineOverlapTest(imagePos.x, divider.startX)
					) {
						divider.hover = true;
						isHovered = true;
						e.target.style.cursor = "grab";
					} else {
						divider.hover = false;
					}
				}

				if (!isHovered) {
					e.target.style.cursor = "crosshair";
				}
			}
			this.axes.setMetadata(this.metadata);
			wpd.graphicsWidget.forceHandlerRepaint();
		}

		onMouseDown(e, pos, imagePos) {
			if (this.lock) return;

			const boundaries = this.getGraphBoundaries();
			const isInArea =
				imagePos.y > boundaries.minY &&
				imagePos.y < boundaries.maxY &&
				imagePos.x > boundaries.minX &&
				imagePos.x < boundaries.maxX;

			if (this.linesLocked()) {
				this.onMouseDownHeaders(e, pos, imagePos);
			} else if (isInArea) {
				this.onMouseDownAxisLines(e, pos, imagePos);
			}
		}

		onMouseDownAxisLines(e, pos, imagePos) {
			const barLineOrientation = this.metadata.barHeaders.barOrientation;
			const dividers = this.metadata.barHeaders.headerDividers;
			for (let i = 0; i < dividers.length; i++) {
				const divider = dividers[i];
				if (divider.disabled) continue;

				if (
					barLineOrientation === LineOrientation.HORIZONTAL &&
					this.horizontalLineOverlapTest(imagePos.y, divider.startY)
				) {
					divider.selected = true;
					e.target.style.cursor = "grabbing";
					break;
				}
				if (
					barLineOrientation === LineOrientation.VERTICAL &&
					this.verticalLineOverlapTest(imagePos.x, divider.startX)
				) {
					divider.selected = true;
					e.target.style.cursor = "grabbing";
					break;
				}
			}

			this.axes.setMetadata(this.metadata);
			wpd.graphicsWidget.forceHandlerRepaint();
		}

		async onMouseDownHeaders(e, pos, imagePos) {
			const headers = this.metadata.barHeaders.headers;

			for (let i = 0; i < headers.length; i++) {
				const header = headers[i];
				const overlaps = this.rectOverlapTest(header, imagePos);
				if (overlaps) {
					await this.collectHeaderData(i);
					break;
				}
			}
		}

		onMouseUp(e, pos, imagePos) {
			if (this.lock || this.linesLocked()) return;

			if (e.target.style.cursor === "grabbing") {
				e.target.style.cursor = "grab";
			} else {
				e.target.style.cursor = "crosshair";
			}

			this.metadata.barHeaders.headerDividers.map((divider) => {
				divider.selected = false;
			});

			this.axes.setMetadata(this.metadata);
		}

		async collectHeaderData(index) {
			const header = this.metadata.barHeaders.headers[index];
			const headerPosition = this.getHeaderDialogPosition(header);
			const barDirection = this.metadata.barHeaders.barDirection;
			const editing = !!header.metadata;

			// include offset and scroll positions from wpd graphics container
			const graphicsContainer =
				wpdDocument.getElementById("graphicsContainer");
			const canvasDiv = wpdDocument.getElementById("canvasDiv");
			const marginLeftText =
				wpdWindow.getComputedStyle(canvasDiv).marginLeft;
			const marginLeft = parseInt(marginLeftText.replace("px", ""), 10);
			const finalPosition = {
				x: headerPosition.x - graphicsContainer.scrollLeft + marginLeft,
				y: headerPosition.y - graphicsContainer.scrollTop,
			};

			let direction;
			if (barDirection === BarDirection.DOWN) {
				direction = "north";
			} else if (barDirection === BarDirection.UP) {
				direction = "south";
			} else if (barDirection === barDirection.LEFT) {
				direction = "east";
			} else {
				direction = "west";
			}
			const allTagGroups = wpd.tagGroups.getAllTagGroups();
			const filteredTagGroupUuids = allTagGroups
				.filter((group) =>
					["Outcomes", "Baseline/Demographics"].includes(group.name)
				)
				.map((group) => group.uuid);

			const defaultTags = this.getDefaultHeaderTags(
				this.metadata.barHeaders.headers
			);

			const defaultData = _.isEmpty(header.metadata)
				? defaultTags
				: header.metadata;

			this.lock = true;
			const headerMetadata = await this.vm
				.captureMetadata({
					axisType: "barGraphHeader",
					compositeKeys: wpd.tagGroups.compositeKeys,
					data: defaultData,
					forceDirection: direction,
					limitTagGroups: filteredTagGroupUuids,
					position: finalPosition,
					studyArmAbbreviations: wpd.tagGroups.getAllStudyArmKeys(),
				})
				.then(async (metadata) => {
					this.lock = false;
					return metadata;
				});

			// filter incomplete tags (missing either the tag or the value) from the set
			let startIndex = 0;
			const filteredMetadata = Object.entries(headerMetadata).reduce(
				(acc, [key, value]) => {
					if (wpd.utils.isWord(value, key)) {
						acc[key] = value;
					} else if (
						value.tag &&
						!_.isEmpty(value.tag) &&
						!wpd.utils.isEmptyValue(value.value) &&
						!value.headerTag
					) {
						acc[startIndex++] = value;
					}
					return acc;
				},
				{}
			);

			if (this.reworkEnabled) {
				const studyKeyTag = _.find(wpd.tagGroups.tags, {
					name: wpd.CompositeKeyUtils.studyTag,
				});
				const partKeyTag = _.find(wpd.tagGroups.tags, {
					name: wpd.CompositeKeyUtils.studyPartTag,
				});
				const armKeyTag = _.find(wpd.tagGroups.tags, {
					name: wpd.CompositeKeyUtils.studyArmTag,
				});
				const populationKeyTag = _.find(wpd.tagGroups.tags, {
					name: wpd.CompositeKeyUtils.populationTag,
				});

				let maxIndex = Math.max(
					...Object.keys(filteredMetadata)
						.filter((key) => !isNaN(key))
						.map((key) => parseInt(key, 10))
				);

				const extraData = [];
				Object.entries(filteredMetadata).forEach(([key, data]) => {
					if (wpd.utils.isWord(data, key)) {
						return;
					}
					const isKey = wpd.tagGroups.isKeyTag(data.tag.name);
					if (isKey) {
						if (!filteredMetadata.compositeKey) {
							filteredMetadata.compositeKey = data.value;
						}
						const parsedKey =
							wpd.CompositeKeyUtils.parseCompositeKey(data.value);
						data.isCompositeKey = true;
						switch (data.tag.name) {
							case wpd.CompositeKeyUtils.subgroupTag:
								data.value = parsedKey.subgroup;
								if (parsedKey.population) {
									extraData.push(
										_buildHiddenMetadataPoint(
											populationKeyTag,
											parsedKey.population
										)
									);
								}
								extraData.push(
									_buildHiddenMetadataPoint(
										armKeyTag,
										parsedKey.studyArm
									)
								);
								if (parsedKey.studyPart) {
									extraData.push(
										_buildHiddenMetadataPoint(
											partKeyTag,
											parsedKey.studyPart
										)
									);
								}
								extraData.push(
									_buildHiddenMetadataPoint(
										studyKeyTag,
										parsedKey.study
									)
								);
								break;
							case wpd.CompositeKeyUtils.populationTag:
								data.value = parsedKey.population;
								extraData.push(
									_buildHiddenMetadataPoint(
										armKeyTag,
										parsedKey.studyArm
									)
								);
								if (parsedKey.studyPart) {
									extraData.push(
										_buildHiddenMetadataPoint(
											partKeyTag,
											parsedKey.studyPart
										)
									);
								}
								extraData.push(
									_buildHiddenMetadataPoint(
										studyKeyTag,
										parsedKey.study
									)
								);
								break;
							case wpd.CompositeKeyUtils.studyArmTag:
								data.value = parsedKey.studyArm;
								if (parsedKey.studyPart) {
									extraData.push(
										_buildHiddenMetadataPoint(
											partKeyTag,
											parsedKey.studyPart
										)
									);
								}
								extraData.push(
									_buildHiddenMetadataPoint(
										studyKeyTag,
										parsedKey.study
									)
								);
								break;
							case wpd.CompositeKeyUtils.studyPartTag:
								data.value = parsedKey.studyPart;
								extraData.push(
									_buildHiddenMetadataPoint(
										studyKeyTag,
										parsedKey.study
									)
								);
								break;
							case wpd.CompositeKeyUtils.studyTag:
								data.value = parsedKey.study;
								break;
						}
					}
				});

				extraData.forEach((data) => {
					filteredMetadata[++maxIndex] = data;
				});
			}

			// check if there is any remaining valid metadata
			const hasValidMetadata = Object.keys(filteredMetadata).some(
				(key) => !wpd.utils.isWord(filteredMetadata[key], key)
			);

			// set both metadata and local calibration to enable repainting
			if (!hasValidMetadata) {
				header.metadata = null;
			} else {
				header.metadata = filteredMetadata;
			}

			this.axes.setMetadata(this.metadata);
			wpd.graphicsWidget.forceHandlerRepaint();

			if (dataEntryVM.fixMetadataAutoSave) {
				await dataEntryVM.autoSaveMetadata();
			}

			// trigger the metadata dialog for the next empty header
			if (!editing && hasValidMetadata) {
				let nextIndex = _.findIndex(
					this.metadata.barHeaders.headers,
					(header) => !header.metadata,
					index
				);

				// loop and start over from 0 if not found
				if (nextIndex < 0) {
					nextIndex = _.findIndex(
						this.metadata.barHeaders.headers,
						(header) => !header.metadata
					);
				}

				// next empty index found
				if (nextIndex > -1) {
					await this.collectHeaderData(nextIndex);
				}
			}
		}

		_buildHiddenMetadataPoint(tag, value) {
			return {
				tag: {
					name: tag.name,
					uuid: tag.uuid,
				},
				value: value,
				hidden: true,
			};
		}

		getDefaultHeaderTags(headers) {
			// get the set of default tags for new headers
			const existingTagUuids = Array.from(
				headers.reduce((acc, header) => {
					Object.values(
						_.omitBy(header.metadata, wpd.utils.isWord)
					).forEach((data) => {
						if (data.tag?.uuid && !data.hidden) {
							acc.add(data.tag.uuid);
						}
					});
					return acc;
				}, new Set())
			);

			return existingTagUuids.reduce((acc, tagUUID, tagIndex) => {
				acc[tagIndex] = { value: null, tagUUID };
				return acc;
			}, {});
		}

		buildHeaderDividers() {
			const count = this.metadata.barHeaders?.count || 0;
			if (count < 1) return;

			const { p1, p2 } = this.metadata.barHeaders;
			const barP1 = this.axes.calibration.getPoint(0);
			const barP2 = this.axes.calibration.getPoint(1);
			const barOrientation = this.metadata.barHeaders.barOrientation;
			const isHorizontal = barOrientation === LineOrientation.HORIZONTAL;
			const intervalSize = isHorizontal
				? Math.abs(p1.py - p2.py) / count
				: Math.abs(p1.px - p2.px) / count;

			const dividerLineLength = isHorizontal
				? Math.max(
						Math.abs(barP1.px - p1.px),
						Math.abs(barP2.px - p2.px)
				  )
				: Math.max(
						Math.abs(barP1.py - p1.py),
						Math.abs(barP2.py - p2.py)
				  );

			const barDirection = this.metadata.barHeaders.barDirection;
			const axisStart = this.metadata.barHeaders.headerAxisLineStart;
			const axisEnd = this.metadata.barHeaders.headerAxisLineEnd;

			let startPoint;
			switch (barDirection) {
				case BarDirection.DOWN:
					startPoint = {
						px: Math.min(axisStart[0], axisEnd[0]),
						py: axisStart[1],
					};
					break;
				case BarDirection.UP:
					startPoint = {
						px: Math.min(axisStart[0], axisEnd[0]),
						py: axisStart[1],
					};
					break;

				case BarDirection.LEFT:
					startPoint = {
						px: axisStart[0],
						py: Math.min(axisStart[1], axisEnd[1]),
					};
					break;

				case BarDirection.RIGHT:
					startPoint = {
						px: axisStart[0],
						py: Math.min(axisStart[1], axisEnd[1]),
					};
					break;
			}

			this.metadata.barHeaders.headerDividers = [];
			for (let i = 0; i < count + 1; i++) {
				const mult = i;
				const startX = isHorizontal
					? startPoint.px
					: startPoint.px + mult * intervalSize;
				const startY = isHorizontal
					? startPoint.py + mult * intervalSize
					: startPoint.py;

				const endX = isHorizontal
					? barDirection === BarDirection.RIGHT
						? startPoint.px + dividerLineLength
						: startPoint.px - dividerLineLength
					: startPoint.px + mult * intervalSize;
				const endY = isHorizontal
					? startPoint.py + mult * intervalSize
					: barDirection === BarDirection.DOWN
					? startPoint.py + dividerLineLength
					: startPoint.py - dividerLineLength;

				this.metadata.barHeaders.headerDividers.push({
					startX: startX,
					startY: startY,
					endX: endX,
					endY: endY,
					hover: false,
					selected: false,
					disabled: i === 0 || i === count,
				});
			}

			this.axes.setMetadata(this.metadata);
		}

		moveLine(selectedDividerIndex, cursorPos) {
			const dividers = this.metadata.barHeaders.headerDividers;
			const selectedLine = dividers[selectedDividerIndex];
			const headerOrientation =
				this.metadata.barHeaders.headerOrientation;

			if (headerOrientation === LineOrientation.HORIZONTAL) {
				const minX =
					dividers[selectedDividerIndex - 1].startX +
					headerLineMargin;
				const maxX =
					dividers[selectedDividerIndex + 1].startX -
					headerLineMargin;

				if (cursorPos.x > minX && cursorPos.x < maxX) {
					selectedLine.startX = cursorPos.x;
					selectedLine.endX = cursorPos.x;
				}
			} else {
				const minY =
					dividers[selectedDividerIndex - 1].startY +
					headerLineMargin;
				const maxY =
					dividers[selectedDividerIndex + 1].startY -
					headerLineMargin;
				if (cursorPos.y > minY && cursorPos.y < maxY) {
					selectedLine.startY = cursorPos.y;
					selectedLine.endY = cursorPos.y;
				}
			}

			this.axes.setMetadata(this.metadata);
			wpd.graphicsWidget.forceHandlerRepaint();
		}

		drawPoints() {
			const drawPoint = (point) => {
				if (point) {
					wpd.graphicsHelper.drawPoint(
						{ x: point.px, y: point.py },
						wpd.Colors.good,
						""
					);
				}
			};

			const p1 = this.axes.calibration.getPoint(0);
			drawPoint(p1);
			const p2 = this.axes.calibration.getPoint(1);
			drawPoint(p2);

			if (this.metadata.barHeaders) {
				drawPoint(this.metadata.barHeaders.p1);
				drawPoint(this.metadata.barHeaders.p2);
			}
		}

		buildHeaderAreas() {
			const existingHeaders = this.metadata.barHeaders.headers;
			if (existingHeaders && existingHeaders.length > 0) return;

			const dividers = this.metadata.barHeaders.headerDividers;
			const headerOrientation =
				this.metadata.barHeaders.headerOrientation;
			const barDirection = this.metadata.barHeaders.barDirection;

			const isHorizontal =
				headerOrientation === LineOrientation.HORIZONTAL;
			const headers = [];
			for (let i = 1; i < dividers.length; i++) {
				const divider = dividers[i];
				const prev = dividers[i - 1];
				const width = isHorizontal
					? divider.startX - prev.startX
					: headerBarLength;
				const height = !isHorizontal
					? divider.startY - prev.startY
					: headerBarLength;

				let gradientArgs, rectArgs, startX, startY, endX, endY;
				switch (barDirection) {
					case BarDirection.DOWN:
						gradientArgs = [
							0,
							prev.startY,
							0,
							prev.startY - height,
						];
						startX = prev.startX + headerBarMargin;
						startY = prev.startY - height;
						endX = startX + (width - headerBarMargin * 2);
						endY = startY + height;
						rectArgs = [
							prev.startX + headerBarMargin,
							prev.startY - height,
							width - headerBarMargin * 2,
							height,
						];
						break;
					case BarDirection.UP:
						gradientArgs = [
							0,
							prev.startY,
							0,
							prev.startY + height,
						];
						startX = prev.startX + headerBarMargin;
						startY = prev.startY;
						endX = startX + (width - headerBarMargin * 2);
						endY = startY + height;
						rectArgs = [
							prev.startX + headerBarMargin,
							prev.startY,
							width - headerBarMargin * 2,
							height,
						];
						break;
					case BarDirection.LEFT:
						gradientArgs = [prev.startX, 0, prev.startX + width, 0];
						startX = prev.startX;
						startY = prev.startY + headerBarMargin;
						endX = startX + width;
						endY = startY + (height - headerBarMargin * 2);
						rectArgs = [
							prev.startX,
							prev.startY + headerBarMargin,
							width,
							height - headerBarMargin * 2,
						];
						break;
					case BarDirection.RIGHT:
						gradientArgs = [prev.startX, 0, prev.startX - width, 0];
						startX = prev.startX - width;
						startY = prev.startY + headerBarMargin;
						endX = startX + width;
						endY = startY + (height - headerBarMargin * 2);
						rectArgs = [
							prev.startX - width,
							prev.startY + headerBarMargin,
							width,
							height - headerBarMargin * 2,
						];
						break;
				}

				headers.push({
					rectArgs,
					gradientArgs,
					startX,
					startY,
					endX,
					endY,
				});
			}

			this.metadata.barHeaders.headers = headers;
			this.axes.setMetadata(this.metadata);
		}

		drawHeaderGradients() {
			const headers = this.metadata.barHeaders.headers;
			for (let i = 0; i < headers.length; i++) {
				const header = headers[i];
				const gradientGood = this.ctx.createLinearGradient(
					...this.toScreenPixels(header.gradientArgs)
				);
				const gradientBad = this.ctx.createLinearGradient(
					...this.toScreenPixels(header.gradientArgs)
				);
				const gradientGoodHover = this.ctx.createLinearGradient(
					...this.toScreenPixels(header.gradientArgs)
				);
				const gradientBadHover = this.ctx.createLinearGradient(
					...this.toScreenPixels(header.gradientArgs)
				);

				wpd.Colors.addColorStop("good", gradientGood);
				wpd.Colors.addColorStop("bad", gradientBad);
				wpd.Colors.addColorStop("goodHover", gradientGoodHover);
				wpd.Colors.addColorStop("badHover", gradientBadHover);

				this.ctx.fillStyle = header.metadata
					? header.hover
						? gradientGoodHover
						: gradientGood
					: header.hover
					? gradientBadHover
					: gradientBad;
				this.ctx.fillRect(...this.toScreenPixels(header.rectArgs));
			}
		}

		// determines whether a line is oriented horizontally or vertically
		getLineOrientation(p1, p2) {
			return Math.abs(p1.px - p2.px) > Math.abs(p1.py - p2.py)
				? LineOrientation.HORIZONTAL
				: LineOrientation.VERTICAL;
		}

		// determines the direction the bars are extending from the axis (up, down, left, right)
		getBarDirection(barP1, barP2, p) {
			const barOrientation = this.metadata.barHeaders.barOrientation;
			const axis =
				barOrientation === LineOrientation.HORIZONTAL ? "px" : "py";

			// if horizontal, bp1 is the right point and bp2 is the left
			// if vertical, bp1 is the bottom point and bp2 is the top
			const bp1 = barP1[axis] > barP2[axis] ? barP1 : barP2;
			const bp2 = barP1[axis] > barP2[axis] ? barP2 : barP1;

			// get the distances from the provided header point to each of the bar points
			const d1 = wpd.barChartUtils.calculateDistance(p, bp1);
			const d2 = wpd.barChartUtils.calculateDistance(p, bp2);

			if (barOrientation === LineOrientation.HORIZONTAL) {
				// if the left point is closer to the headers, bars are going right
				return d1 > d2 ? BarDirection.RIGHT : BarDirection.LEFT;
			} else {
				// if the top point is closer to the headers, bars are going down
				return d1 > d2 ? BarDirection.DOWN : BarDirection.UP;
			}
		}

		getGraphBoundaries() {
			if (!this.metadata.barHeaders) return;

			const { p1, p2 } = this.metadata.barHeaders;
			const barP1 = this.axes.calibration.getPoint(0);
			const barP2 = this.axes.calibration.getPoint(1);

			const barOrientation = this.metadata.barHeaders.barOrientation;
			const isHorizontal = barOrientation === LineOrientation.HORIZONTAL;

			const minX = Math.min(
				isHorizontal ? barP1.px : p1.px,
				isHorizontal ? barP2.px : p2.px
			);
			const maxX = Math.max(
				isHorizontal ? barP1.px : p1.px,
				isHorizontal ? barP2.px : p2.px
			);
			const minY = Math.min(
				isHorizontal ? p1.py : barP1.py,
				isHorizontal ? p2.py : barP2.py
			);
			const maxY = Math.max(
				isHorizontal ? p1.py : barP1.py,
				isHorizontal ? p2.py : barP2.py
			);

			return { minX, maxX, minY, maxY };
		}

		horizontalLineOverlapTest(cursorY, lineY) {
			return (
				cursorY >= lineY - mouseOverHitThreshold &&
				cursorY <= lineY + mouseOverHitThreshold
			);
		}

		verticalLineOverlapTest(cursorX, lineX) {
			return (
				cursorX >= lineX - mouseOverHitThreshold &&
				cursorX <= lineX + mouseOverHitThreshold
			);
		}

		rectOverlapTest(header, imagePos) {
			return (
				imagePos.x > header.startX &&
				imagePos.x < header.endX &&
				imagePos.y > header.startY &&
				imagePos.y < header.endY
			);
		}

		linesLocked() {
			return this.metadata.barHeaders.linesLocked;
		}

		getHeaderDialogPosition(header) {
			const barDirection = this.metadata.barHeaders.barDirection;
			const zoomRatio = wpd.graphicsWidget.getZoomRatio();

			// bars up, dialog down
			if (barDirection === BarDirection.UP) {
				return {
					x: (zoomRatio * (header.startX + header.endX)) / 2,
					y: zoomRatio * header.endY,
				};
			} else if (barDirection === BarDirection.DOWN) {
				return {
					x: (zoomRatio * (header.startX + header.endX)) / 2,
					y: zoomRatio * header.startY,
				};
			} else if (barDirection === BarDirection.LEFT) {
				return {
					x: zoomRatio * header.endX,
					y: (zoomRatio * (header.startY + header.endY)) / 2,
				};
			} else if (barDirection === BarDirection.RIGHT) {
				return {
					x: zoomRatio * header.startX,
					y: (zoomRatio * (header.startY + header.endY)) / 2,
				};
			}
		}

		toScreenPixels(imagePixels) {
			const zoomRatio = wpd.graphicsWidget.getZoomRatio();

			return imagePixels.map((pixel) => pixel * zoomRatio);
		}
	};
}

export { init };
