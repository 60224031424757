<template>
	<v-card class="pb-5">
		<v-toolbar
			color="primary"
			dark
			flat
		>
			<v-toolbar-title>
				{{ formTitle }}
			</v-toolbar-title>

			<v-spacer />

			<v-hover-icon class="mr-3" />
		</v-toolbar>

		<v-card-text>
			<v-container class="pa-3 pt-5">
				<v-row dense>
					<v-col cols="12">
						<v-text-field
							v-model="editName"
							label="Tag group name"
							prepend-icon="mdi-tag-text-outline"
							required
							:error-messages="editNameErrors"
							@update:model-value="v$.editName.$touch()"
						/>
					</v-col>

					<v-col cols="12">
						<v-text-field
							v-model="editDescription"
							label="Description"
							prepend-icon="mdi-information-outline"
							:error-messages="editDescriptionErrors"
							@update:model-value="v$.editDescription.$touch()"
						/>
					</v-col>

					<v-col cols="12">
						<v-autocomplete
							v-model:search="baseTagSearchValue"
							v-model="editTags"
							:error-messages="editTagsErrors"
							:item-title="composedText"
							:items="baseTags"
							append-icon=""
							clearable
							hide-no-data
							hint="Selected tags can be reordered"
							item-color="secondary"
							item-value="uuid"
							label="Base tags"
							multiple
							persistent-hint
							placeholder="Enter keywords to search for base tags"
							prepend-icon="mdi-tag-multiple-outline"
							return-object
							@blur="v$.editTags.$touch()"
							@update:model-value="clearBaseTagSearchInput"
						>
							<template #chip="{ item, index }">
								<draggable
									v-bind="dragOptionsChips"
									:id="index"
									:item-key="item.value"
									:list="[item]"
									:move="dragMove"
									@change="dragChange"
								>
									<template #item>
										<v-chip
											:key="item.uuid"
											class="bg-secondary text-white"
											draggable
											closable
											:ripple="false"
											@mousedown.stop
											@click.stop
											@click:close="
												unselectBaseTag(index)
											"
										>
											{{ item.title }}
										</v-chip>
									</template>
								</draggable>
							</template>
						</v-autocomplete>
					</v-col>
				</v-row>
			</v-container>
		</v-card-text>

		<v-card-actions>
			<v-spacer />

			<v-btn
				class="bg-secondary text-white"
				elevation="2"
				:disabled="v$.$invalid"
				@click="save"
			>
				Save
			</v-btn>

			<v-btn
				class="bg-white"
				elevation="2"
				@click="close"
			>
				Cancel
			</v-btn>

			<v-spacer />
		</v-card-actions>
	</v-card>
</template>

<script>
import { maxLength, minLength, required } from "@vuelidate/validators";
import draggable from "vuedraggable";
import { useVuelidate } from "@vuelidate/core";
import utils from "@/utils";

import VHoverIcon from "@/components/VHoverIcon";

export default {
	name: "TagGroupEdit",

	components: {
		draggable,
		VHoverIcon,
	},

	props: {
		baseTags: { type: Array, default: () => [] },
		description: { type: String, default: "" },
		formTitle: { type: String, default: "" },
		level: { type: Number, default: 0 },
		name: { type: String, default: "" },
		tags: { type: Array, default: () => [] },
		uuid: { type: String, default: "" },
	},
	emits: ["dialog-close", "dialog-save"],

	setup() {
		return {
			v$: useVuelidate(),
		};
	},

	data() {
		return {
			baseTagSearchValue: "",
			dragged: {
				from: -1,
				to: -1,
				newIndex: -1,
			},
			editDescription: this.description,
			editName: this.name,
			editTags: this.tags,
		};
	},

	computed: {
		dragOptionsChips() {
			return {
				animation: 200,
				group: "editTags",
				disabled: false,
				ghostClass: "ghost",
				sort: true,
			};
		},
		editDescriptionErrors() {
			// clean, do not validate
			if (!this.v$.editDescription.$dirty) {
				return [];
			}

			return this.v$.editDescription.$errors.map((error) => {
				switch (error.$validator) {
					case "maxLength":
						return "Tag group description is too long";
					default:
						return "Tag group description is invalid";
				}
			});
		},
		editNameErrors() {
			// clean, do not validate
			if (!this.v$.editName.$dirty) {
				return [];
			}

			return this.v$.editName.$errors.map((error) => {
				switch (error.$validator) {
					case "required":
						return "Tag group name is required";
					case "maxLength":
						return "Tag group name is too long";
					default:
						return "Tag group name is invalid";
				}
			});
		},
		editTagsErrors() {
			// clean, do not validate
			if (!this.v$.editTags.$dirty) {
				return [];
			}

			const errors = [];
			if (this.editTags.length < 1) {
				errors.push("At least 1 tag is required");
			}

			return errors;
		},
	},

	validations: {
		editDescription: {
			maxLength: maxLength(255),
		},
		editName: {
			maxLength: maxLength(255),
			required,
		},
		editTags: {
			minLength: minLength(1),
			required,
		},
	},

	watch: {
		description(val) {
			this.editDescription = val;
		},
		name(val) {
			this.editName = val;
		},
	},

	methods: {
		close() {
			this.$emit("dialog-close");
		},
		save() {
			this.$emit("dialog-save", {
				description: this.editDescription,
				level: this.level,
				name: this.editName,
				tags: this.editTags,
				uuid: this.uuid,
			});

			this.reset();
		},
		reset() {
			this.editDescription = "";
			this.editName = "";
			this.editTags = [];

			this.dragged = {
				from: -1,
				to: -1,
				newIndex: -1,
			};

			// clear dirty flags
			this.v$.$reset();
		},
		unselectBaseTag(index) {
			if (index > -1 && index < this.editTags.length) {
				this.editTags = this.editTags.filter((_, i) => i !== index);
			}
		},
		dragMove(drag) {
			this.dragged = {
				from: parseInt(drag.from.id),
				to: parseInt(drag.to.id),
				newIndex: drag.draggedContext.futureIndex,
			};
		},
		dragChange(drag) {
			if (drag.removed) {
				const fromIndex = this.dragged.from;
				const toIndex = this.dragged.to;
				const draggedItem = this.editTags.splice(fromIndex, 1)[0];
				this.editTags.splice(toIndex, 0, draggedItem);
			}
		},
		clearBaseTagSearchInput() {
			this.baseTagSearchValue = "";
		},
		composedText(tag) {
			return utils.truncateText(tag.name, 100);
		},
	},
};
</script>
