<template>
	<v-card
		class="d-flex flex-column pb-5"
		:disabled="!!loading"
		:loading="loading"
	>
		<v-toolbar
			color="primary"
			dark
			flat
		>
			<v-toolbar-title v-if="editMode">
				{{ editTitle }}
			</v-toolbar-title>

			<v-toolbar-title v-else>
				{{ title }}
			</v-toolbar-title>

			<v-hover-icon class="mr-3" />
		</v-toolbar>
		<v-form @submit.prevent>
			<v-card-text class="pa-10">
				<v-row class="pb-2">
					<v-text-field
						v-model="name"
						:error-messages="nameErrors"
						:disabled="!!loading"
						label="Name"
						name="name"
						type="text"
						hint="Name of the permission (e.g. Source Data Extraction)"
						persistent-hint
						required
						class="col-6"
						autocomplete="off"
						@blur="v$.name.$touch()"
					/>
				</v-row>
				<v-row class="pb-2">
					<v-text-field
						v-model.trim="slug"
						:error-messages="slugErrors"
						:disabled="!!loading || !!defaultPermission.id"
						label="Slug"
						name="slug"
						type="text"
						hint="Lower-case name joined by underscores (e.g. data_entry)"
						persistent-hint
						required
						class="col-6"
						autocomplete="off"
						@blur="v$.slug.$touch()"
					/>
				</v-row>
				<v-row class="pb-2">
					<v-text-field
						v-model="description"
						:error-messages="descriptionErrors"
						:disabled="!!loading"
						label="Description"
						name="description"
						type="text"
						hint="Short description of the permission (e.g. Perform source data extraction)"
						persistent-hint
						required
						class="col-12"
						autocomplete="off"
						@blur="v$.description.$touch()"
					/>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn
					v-if="editMode"
					class="bg-secondary text-white"
					elevation="2"
					type="submit"
					:disabled="v$.$invalid || !v$.$anyDirty || !!loading"
					@click="update"
				>
					Update
				</v-btn>

				<v-btn
					v-else
					class="bg-secondary text-white"
					elevation="2"
					type="submit"
					:disabled="v$.$invalid"
					@click="create"
				>
					Create
				</v-btn>

				<v-btn
					class="bg-grey"
					elevation="2"
					:disabled="!!loading"
					@click="clear"
				>
					Clear
				</v-btn>

				<v-btn
					class="bg-grey"
					elevation="2"
					:disabled="!!loading"
					@click="cancel"
				>
					Cancel
				</v-btn>
				<v-spacer />
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import _ from "lodash";
import { mapMutations } from "vuex";
import { required, maxLength, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import VHoverIcon from "@/components/VHoverIcon";

export default {
	name: "PermissionEdit",

	components: { VHoverIcon },

	props: {
		defaultPermission: {
			type: Object,
			default: () => ({}),
		},
	},
	emits: ["cancel", "refresh"],

	setup() {
		return {
			v$: useVuelidate(),
		};
	},

	data() {
		return {
			editMode: false,
			editTitle: "Edit permission",
			slug: "",
			name: "",
			description: "",
			loading: false,
			title: "Create permission",
		};
	},

	computed: {
		slugErrors() {
			if (!this.v$.slug.$dirty) {
				return [];
			}

			return this.v$.slug.$errors.map((error) => {
				switch (error.$validator) {
					case "required":
						return "Slug is required";
					case "maxLength":
						return "Slug is too long";
					case "format":
						return "Slug can only contain _, lower-case letters, and numbers";
					default:
						return "Invalid slug";
				}
			});
		},
		nameErrors() {
			if (!this.v$.name.$dirty) {
				return [];
			}

			return this.v$.name.$errors.map((error) => {
				switch (error.$validator) {
					case "required":
						return "Name is required";
					case "maxLength":
						return "Name is too long";
					default:
						return "Invalid name";
				}
			});
		},
		descriptionErrors() {
			if (!this.v$.description.$dirty) {
				return [];
			}

			return this.v$.description.$errors.map((error) => {
				switch (error.$validator) {
					case "maxLength":
						return "Description is too long";
					default:
						return "Invalid description";
				}
			});
		},
	},

	watch: {
		defaultPermission() {
			this.loadPermission();
		},
	},

	mounted() {
		this.loadPermission();
	},

	methods: {
		...mapMutations(["showNotification"]),
		clear() {
			// clear dirty flags
			this.v$.$reset();

			if (_.isEmpty(this.defaultPermission)) {
				this.slug = "";
				this.name = "";
				this.description = "";

				this.editMode = false;
			} else {
				this.loadPermission();
			}
		},
		cancel() {
			this.clear();

			this.$emit("cancel");
		},
		update() {
			if (!this.v$.$invalid) {
				this.loading = "secondary";

				this.$http
					.put(`/permissions/${this.defaultPermission.id}`, {
						slug: this.slug,
						name: this.name.trim(),
						description: this.description.trim(),
					})
					.then((response) => {
						// display success
						this.showNotification(response.data);

						// redirect to home
						this.clear();
					})
					.catch((error) => {
						// set dirty flags
						this.v$.$touch();

						// display error
						if (error.response) {
							if (error.response.data.message) {
								this.showNotification(error.response.data);
							} else {
								this.showNotification(
									error.response.data.error
								);
							}
						}
					})
					.finally(() => {
						this.loading = false;

						// update permissions table
						this.$emit("refresh");
					});
			} else {
				// set all to dirty
				this.v$.$touch();
			}
		},
		create: function () {
			if (!this.v$.$invalid) {
				this.loading = "secondary";

				this.$http
					.post("/permissions", {
						slug: this.slug,
						name: this.name.trim(),
						description: this.description.trim(),
					})
					.then((response) => {
						// display success
						this.showNotification(response.data);

						this.clear();
					})
					.catch((error) => {
						// set dirty flags
						this.v$.$touch();

						// display error
						if (error.response) {
							if (error.response.data.message) {
								this.showNotification(error.response.data);
							} else {
								this.showNotification(
									error.response.data.error
								);
							}
						}
					})
					.finally(() => {
						this.loading = false;

						// update permissions table
						this.$emit("refresh");
					});
			} else {
				// set all to dirty
				this.v$.$touch();
			}
		},
		loadPermission() {
			if (!_.isEmpty(this.defaultPermission)) {
				this.slug = this.defaultPermission.slug;
				this.name = this.defaultPermission.name;
				this.description = this.defaultPermission.description;

				this.editMode = true;

				this.v$.$reset();
			} else {
				this.clear();
			}
		},
	},

	validations: {
		slug: {
			required,
			maxLength: maxLength(255),
			format: helpers.regex(/^[a-z0-9_]+$/),
		},
		name: {
			required,
			maxLength: maxLength(255),
		},
		description: {
			required,
			maxLength: maxLength(500),
		},
	},
};
</script>

<style scoped lang="scss"></style>
