<template>
	<v-card
		class="d-flex flex-column pb-5"
		:disabled="!!loading"
		:loading="loading"
	>
		<v-toolbar
			color="primary"
			dark
			flat
		>
			<v-toolbar-title v-if="editMode">
				{{ editTitle }}
			</v-toolbar-title>

			<v-toolbar-title v-else>
				{{ title }}
			</v-toolbar-title>

			<v-hover-icon class="mr-3" />
		</v-toolbar>

		<v-form autocomplete="off">
			<v-card-title
				v-if="editMode"
				class="px-8 py-5"
			>
				{{ defaultFile.name }}
			</v-card-title>

			<v-card-text
				class="px-8 pb-0"
				:class="{ 'pt-8': !editMode }"
			>
				<v-row v-if="!editMode">
					<v-col>
						<file-upload
							v-model="files"
							:disabled="!!loading"
							:error="error"
							:success="success"
						/>
					</v-col>
				</v-row>

				<v-divider :class="{ 'my-5': !editMode, 'mb-5': editMode }" />

				<paper-select
					ref="paperSelect"
					v-model:invalid="subInvalid"
					:default-paper="paper"
				/>
			</v-card-text>

			<v-card-actions>
				<v-spacer />

				<v-btn
					v-if="editMode"
					class="bg-secondary text-white"
					elevation="2"
					color="secondary"
					:disabled="invalid || !!loading"
					@click="update"
				>
					Save
				</v-btn>

				<v-btn
					v-else
					class="bg-secondary text-white"
					elevation="2"
					color="secondary"
					type="button"
					:disabled="invalid || !!loading"
					@click="add"
				>
					Upload
				</v-btn>

				<v-btn
					v-if="editMode"
					class="bg-grey"
					elevation="2"
					:disabled="!!loading"
					@click="clear"
				>
					Revert
				</v-btn>

				<v-btn
					v-else
					class="bg-grey"
					elevation="2"
					:disabled="!!loading"
					@click="clear"
				>
					Clear
				</v-btn>

				<v-btn
					class="bg-grey"
					elevation="2"
					:disabled="!!loading"
					@click="cancel"
				>
					Cancel
				</v-btn>

				<v-spacer />
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import _ from "lodash";

import { mapMutations } from "vuex";

import FileUpload from "@/pages/FileManager/FileUpload";
import PaperSelect from "@/pages/PaperManager/PaperSelect";
import VHoverIcon from "@/components/VHoverIcon";

export default {
	name: "FileEdit",

	components: {
		FileUpload,
		PaperSelect,
		VHoverIcon,
	},

	props: {
		defaultFile: { type: Object, default: () => null },
	},
	emits: ["cancel", "update:saving", "refresh"],

	data() {
		return {
			editMode: false,
			editTitle: "Edit file",
			error: false,
			files: [],
			loading: false,
			paper:
				this.defaultFile && this.defaultFile.paper
					? Object.assign({}, this.defaultFile.paper)
					: {},
			subInvalid: true,
			success: false,
			title: "Upload files",
		};
	},

	computed: {
		invalid() {
			if (this.subInvalid) {
				return true;
			} else {
				if (!this.editMode) {
					if (this.files.length) {
						return false;
					}
					return true;
				}
				return false;
			}
		},
	},

	watch: {
		defaultFile() {
			this.loadFile();
		},
	},

	mounted() {
		this.loadFile();
	},

	methods: {
		...mapMutations(["showNotification"]),
		clear() {
			if (!_.isEmpty(this.defaultFile)) {
				this.loadFile();
			} else {
				this.files = [];
				this.paper = {};

				this.error = false;
				this.loading = false;
				this.success = false;

				this.editMode = false;
			}
		},
		cancel() {
			this.$emit("cancel");
		},
		add() {
			this.save("add");
		},
		update() {
			this.save("update");
		},
		save(mode) {
			this.loading = "secondary";
			this.$emit("update:saving", true);

			const dispatch = {
				add: this.upload,
				update: this.attachToPaper,
			};

			this.$refs.paperSelect
				.select()
				.then((paper) => {
					dispatch[mode](paper).finally(() => {
						this.loading = false;
						this.$emit("update:saving", false);

						// update files table
						this.$emit("refresh");
					});
				})
				.catch((error) => {
					// display error
					if (mode === "add") {
						this.triggerState(this.error);
					}

					if (error && error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}

					this.loading = false;
					this.$emit("update:saving", true);

					this.cancel();
				});
		},
		upload(paper) {
			// create form data for file uploads
			const formData = new FormData();

			// load all files into form data
			this.files.forEach((file, index) => {
				formData.append(`files[${index}]`, file);
			});

			// include paper UUID
			formData.append("paperUUID", paper.uuid);

			return this.$http
				.post("/files", formData)
				.then((response) => {
					// display success
					this.success = true;
					this.showNotification(response.data);
				})
				.catch((error) => {
					// display error
					this.error = true;
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				});
		},
		attachToPaper(paper) {
			return this.$http
				.put(`/files/${this.defaultFile.uuid}/paper`, {
					paperUUID: paper.uuid,
				})
				.then((response) => {
					// display success
					this.showNotification(response.data);
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				});
		},
		loadFile() {
			if (!_.isEmpty(this.defaultFile)) {
				this.paper = Object.assign({}, this.defaultFile.paper);
				this.editMode = true;
			} else {
				this.clear();
			}
		},
	},
};
</script>

<style scoped lang="scss"></style>
