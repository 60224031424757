<template>
	<v-card
		class="d-flex flex-column"
		:class="{ 'pb-5': !nested }"
		:border="nested"
		:loading="submitting"
	>
		<v-toolbar
			color="primary"
			dark
			flat
			:dense="nested"
		>
			<v-toolbar-title v-if="editMode">
				{{ editTitle }}
			</v-toolbar-title>

			<v-toolbar-title v-else>
				<v-icon
					v-if="nested"
					class="mt-n1 mr-1"
				>
					mdi-folder-plus-outline
				</v-icon>

				{{ title }}
			</v-toolbar-title>

			<v-spacer />

			<v-hover-icon
				v-if="!nested"
				class="mr-3"
			/>
		</v-toolbar>

		<v-form
			autocomplete="off"
			@submit.prevent
		>
			<v-card-text
				:class="{ 'pa-10': !nested, 'pb-0': !nested, 'px-8': nested }"
			>
				<v-row>
					<v-text-field
						v-model="name"
						:error-messages="nameErrors"
						:disabled="!!submitting"
						label="Paper name"
						name="name"
						type="text"
						required
						class="col-12"
						@blur="v$.name.$touch()"
					/>
				</v-row>

				<v-row>
					<v-text-field
						v-model.trim="url"
						:error-messages="urlErrors"
						:disabled="!!submitting"
						label="Source URL"
						name="url"
						type="url"
						class="col-12"
						@blur="v$.url.$touch()"
					/>
				</v-row>

				<v-row>
					<v-text-field
						v-model="doi"
						:error-messages="doiErrors"
						:disabled="!!submitting"
						label="DOI"
						name="doi"
						class="col-12"
						@blur="v$.doi.$touch()"
					/>
				</v-row>

				<v-row>
					<v-text-field
						v-model="pmid"
						:error-messages="pmidErrors"
						:disabled="!!submitting"
						label="PubMed ID"
						name="pmid"
						class="col-6 mr-1"
						@blur="v$.pmid.$touch()"
					/>

					<v-text-field
						v-model="pmcid"
						:error-messages="pmcidErrors"
						:disabled="!!submitting"
						label="PubMed Central ID"
						name="pmcid"
						class="col-6 ml-1"
						@blur="v$.pmcid.$touch()"
					/>
				</v-row>

				<v-row>
					<v-col>
						<v-dialog
							v-model="citationDialog"
							max-width="700px"
							persistent
						>
							<template #activator="{ props }">
								<v-btn
									v-bind="props"
									block
									variant="outlined"
									class="mb-8"
									color="primary"
									:disabled="!(pmid || pmcid || doi || url)"
									@click="getCitation"
								>
									<v-icon start> mdi-tag-search </v-icon>

									Fetch Citation
								</v-btn>
							</template>

							<paper-citation-confirm
								:citation="citation"
								:loading="!!submitting"
								@confirm="confirmCitation"
								@cancel="cancelCitation"
							/>
						</v-dialog>

						<v-switch
							v-model="noIDs"
							color="secondary"
							label="This paper has no URL, DOI, PMID, or PMCID"
						/>

						<v-switch
							v-model="newVersion"
							color="secondary"
							label="This is a new version of an existing paper in Dactyl"
						/>

						<v-switch
							v-model="dataEntryEnabled"
							color="secondary"
							label="This paper is ready for source data extraction"
							@click="v$.dataEntryEnabled.$touch"
						/>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<file-upload
							v-model="files"
							:disabled="!!submitting"
							@removed="filesRemoved"
						/>
					</v-col>
				</v-row>

				<v-divider class="my-5" />

				<project-select
					ref="projectSelect"
					v-model:dirty="subDirty"
					v-model:invalid="subInvalid"
					:default-projects="projects"
					:use-session-projects="useSessionProjects"
				/>
			</v-card-text>

			<v-card-actions v-if="!noActions">
				<v-spacer />

				<v-btn
					v-if="editMode"
					class="bg-secondary text-white"
					elevation="2"
					type="submit"
					:disabled="
						(!v$.$anyDirty && !subDirty && !filesChanged) ||
						v$.$invalid ||
						!!submitting
					"
					@click="update"
				>
					Update
				</v-btn>

				<v-btn
					v-else
					class="bg-secondary text-white"
					elevation="2"
					type="submit"
					:disabled="v$.$invalid || !!submitting"
					@click="add"
				>
					Create
				</v-btn>

				<v-btn
					v-if="editMode"
					class="bg-grey"
					elevation="2"
					:disabled="!!submitting"
					@click="clear"
				>
					Revert
				</v-btn>

				<v-btn
					v-else
					:disabled="!!submitting"
					class="bg-grey"
					elevation="2"
					@click="clear"
				>
					Clear
				</v-btn>

				<v-btn
					:disabled="!!submitting"
					elevation="2"
					class="bg-grey"
					@click="cancel"
				>
					Cancel
				</v-btn>

				<v-spacer />
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import _ from "lodash";
import { mapMutations } from "vuex";
import { maxLength, requiredUnless, url } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import FileUpload from "@/pages/FileManager/FileUpload";
import PaperCitationConfirm from "@/pages/PaperManager/PaperCitationConfirm";
import ProjectSelect from "@/pages/ProjectManager/ProjectSelect";
import VHoverIcon from "@/components/VHoverIcon";

// custom validators
const isFalse = (value) => !value;
const isUnique = (orig, list) => (value) =>
	orig === value || !value || !list.includes(value);

// citation urls
const entrezURL = "https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi";
const citationFormat = "zotero";
const citoidURL = `https://en.wikipedia.org/api/rest_v1/data/citation/${citationFormat}/`;

export default {
	name: "PaperEdit",

	components: {
		FileUpload,
		PaperCitationConfirm,
		ProjectSelect,
		VHoverIcon,
	},

	props: {
		defaultPaper: { type: Object, default: () => null },
		dois: { type: Array, default: () => [] },
		nested: Boolean,
		noActions: Boolean,
		pmids: { type: Array, default: () => [] },
		pmcids: { type: Array, default: () => [] },
	},
	emits: ["cancelPaperEdit", "refresh", "update:invalid"],

	setup() {
		return {
			v$: useVuelidate(),
		};
	},

	data() {
		return {
			files:
				this.defaultPaper && this.defaultPaper.files
					? [...this.defaultPaper.files]
					: [],
			filesChanged: false,
			citation: {},
			citationDialog: false,
			dataEntryEnabled: true,
			doi: this.defaultPaper ? this.defaultPaper.doi : "",
			editMode: false,
			editTitle: "Edit paper",
			name: this.defaultPaper ? this.defaultPaper.name : "",
			newVersion: false,
			noIDs: false,
			pmid: this.defaultPaper ? this.defaultPaper.pmid : "",
			pmcid: this.defaultPaper ? this.defaultPaper.pmcid : "",
			projects:
				this.defaultPaper && this.defaultPaper.projects
					? [...this.defaultPaper.projects]
					: [],
			removedFiles: [],
			subDirty: false,
			subInvalid: true,
			submitting: false,
			title: "Create paper",
			url: this.defaultPaper ? this.defaultPaper.url : "",
			useSessionProjects: true,
			uuid: this.defaultPaper ? this.defaultPaper.uuid : "",
		};
	},

	computed: {
		nameErrors() {
			if (!this.v$.name.$dirty) {
				return [];
			}

			return this.v$.name.$errors.map((error) => {
				switch (error.$validator) {
					case "maxLength":
						return "Name is too long";
					default:
						return "Name is invalid";
				}
			});
		},
		urlErrors() {
			if (!this.v$.url.$dirty) {
				return [];
			}

			return this.v$.url.$errors.map((error) => {
				switch (error.$validator) {
					case "required":
						return "At least one of URL, DOI, PMID, or PMCID is required";
					case "maxLength":
						return "URL is too long";
					case "url":
						return "Invalid URL format";
					default:
						return "Invalid URL";
				}
			});
		},
		doiErrors() {
			if (!this.v$.doi.$dirty) {
				return [];
			}

			const errors = [];
			this.v$.doi.$errors.forEach((error) => {
				switch (error.$validator) {
					case "required":
						errors.push(
							"At least one of URL, DOI, PMID, or PMCID is required"
						);
						break;
					case "maxLength":
						errors.push("DOI is too long");
						break;
					case "unique":
						if (!this.newVersion) {
							errors.push("A paper with this DOI exists");
						}
						break;
					default:
						errors.push("Invalid DOI");
						break;
				}
			});

			return errors;
		},
		pmidErrors() {
			if (!this.v$.pmid.$dirty) {
				return [];
			}

			const errors = [];
			this.v$.pmid.$errors.forEach((error) => {
				switch (error.$validator) {
					case "required":
						errors.push(
							"At least one of URL, DOI, PMID, or PMCID is required"
						);
						break;
					case "maxLength":
						errors.push("PMID is too long");
						break;
					case "unique":
						if (!this.newVersion) {
							errors.push("A paper with this PubMed ID exists");
						}
						break;
					default:
						errors.push("Invalid PMID");
						break;
				}
			});

			return errors;
		},
		pmcidErrors() {
			if (!this.v$.pmcid.$dirty) {
				return [];
			}

			const errors = [];
			this.v$.pmcid.$errors.forEach((error) => {
				switch (error.$validator) {
					case "required":
						errors.push(
							"At least one of URL, DOI, PMID, or PMCID is required"
						);
						break;
					case "maxLength":
						errors.push("PMCID is too long");
						break;
					case "unique":
						if (!this.newVersion) {
							errors.push(
								"A paper with this PubMed Central ID exists"
							);
						}
						break;
					default:
						errors.push("Invalid PMCID");
						break;
				}
			});

			return errors;
		},
	},

	watch: {
		defaultPaper() {
			this.loadPaper();
		},
		"v$.$invalid": function (val) {
			if (val) {
				this.$emit("update:invalid", true);
			} else {
				this.$emit("update:invalid", false);
			}
		},
		files(val, oldVal) {
			if (
				val.length > oldVal.length &&
				val.length !== this.defaultPaper?.files?.length
			) {
				this.filesChanged = true;
			}
		},
	},

	mounted() {
		this.loadPaper();
	},

	methods: {
		...mapMutations(["showNotification"]),
		clear() {
			// clear dirty flags
			this.v$.$reset();

			// clear citation
			this.citation = {};

			if (_.isEmpty(this.defaultPaper)) {
				this.name = "";
				this.url = "";
				this.doi = "";
				this.pmid = "";
				this.pmcid = "";
				this.uuid = "";

				this.filesChanged = false;

				this.files = [];
				this.projects = [];

				this.useSessionProjects = true;

				this.editMode = false;
				this.newVersion = false;
				this.dataEntryEnabled = true;

				setTimeout(() => {
					if (this.$refs.projectSelect) {
						this.$refs.projectSelect.clear();
					}
				}, 250);
			} else {
				this.loadPaper();
			}
		},
		cancel() {
			this.$emit("cancelPaperEdit");
		},
		add() {
			this.save("add");
		},
		update() {
			this.save("update");
		},
		save(mode) {
			this.saveRequest(mode)
				.then((response) => {
					// display success
					this.showNotification(response.data);
					// update paper table
					this.$emit("refresh");
				})
				.catch((error) => {
					// set all to dirty
					this.v$.$touch();

					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				});
		},
		saveRequest(mode) {
			if (!this.v$.$invalid) {
				this.submitting = "secondary";

				// create or select project
				return this.$refs.projectSelect
					.select()
					.then((projects) => {
						const dispatch = {
							add: this.addPaperRequest,
							update: this.updatePaperRequest,
						};

						return dispatch[mode]()
							.then((response) => {
								return Promise.all([
									this.updatePaperProjectsRequest(
										response.data.paper,
										projects
									),
									this.updateFileProjectsRequest(
										response.data.paper
									),
								]).then(() => response);
							})
							.catch((error) => {
								// display error
								if (error.response) {
									if (error.response.data.message) {
										this.showNotification(
											error.response.data
										);
									} else {
										this.showNotification(
											error.response.data.error
										);
									}
								}
							});
					})
					.catch((error) => {
						// set dirty flags
						this.v$.$touch();

						// display error
						if (error && error.response) {
							if (error.response.data.message) {
								this.showNotification(error.response.data);
							} else {
								this.showNotification(
									error.response.data.error
								);
							}
						}
					})
					.finally(() => {
						this.submitting = false;
					});
			} else {
				return Promise.reject();
			}
		},
		updatePaperProjectsRequest(paper, projects) {
			return this.$http.put(`/papers/${paper.uuid}/projects`, {
				projectUUIDs: projects.map((project) => project.uuid),
			});
		},
		updateFileProjectsRequest(paper) {
			// create form data for file uploads
			const formData = new FormData();

			// load all new files into form data
			this.files.forEach((file, index) => {
				if (!file.uuid) {
					formData.append(`files[${index}]`, file);
				}
			});

			// only remove files that existed in the server (files with uuids)
			this.removedFiles.forEach((file, index) => {
				if (file.uuid) {
					formData.append(`removeFileUUIDs[${index}]`, file.uuid);
				}
			});

			return this.$http.post(`/papers/${paper.uuid}/files`, formData);
		},
		addPaperRequest() {
			return this.$http.post("/papers", {
				citation: JSON.stringify(this.citation),
				citation_format: _.isEmpty(this.citation)
					? undefined
					: citationFormat,
				doi: this.doi,
				name: this.name.trim(),
				pmid: this.pmid,
				pmcid: this.pmcid,
				source_url: this.url,
				status: this.dataEntryEnabled ? "DATA_ENTRY" : "NEW",
			});
		},
		updatePaperRequest() {
			return this.$http.put(`/papers/${this.uuid}`, {
				citation: JSON.stringify(this.citation),
				citation_format: _.isEmpty(this.citation)
					? undefined
					: citationFormat,
				doi: this.doi,
				name: this.name.trim(),
				pmid: this.pmid,
				pmcid: this.pmcid,
				source_url: this.url,
				status: this.dataEntryEnabled ? "DATA_ENTRY" : "NEW",
			});
		},
		loadPaper() {
			if (!_.isEmpty(this.defaultPaper)) {
				this.name = this.defaultPaper.name;
				this.url = this.defaultPaper.source_url;
				this.doi = this.defaultPaper.doi;
				this.pmid = this.defaultPaper.pmid;
				this.pmcid = this.defaultPaper.pmcid;
				this.uuid = this.defaultPaper.uuid;

				this.dataEntryEnabled =
					this.defaultPaper.status.key === "NEW" ? false : true;

				this.files = [...this.defaultPaper.files];
				this.projects = [...this.defaultPaper.projects];

				// do not load session projects if existing paper does not have
				// associated projects
				if (!this.projects.length) {
					this.useSessionProjects = false;
				}

				this.editMode = true;

				// (for now) always start loaded papers with empty citations
				this.citation = {};

				// default no IDs toggle to true if loading a paper without any IDs
				if (!this.url && !this.doi && !this.pmid && !this.pmcid) {
					this.noIDs = true;
				} else {
					this.noIDs = this.defaultPaper.noIDs;
				}
			} else {
				this.clear();
			}
		},
		filesRemoved(files) {
			this.removedFiles = files;
			this.filesChanged = true;
		},
		async getCitation() {
			this.submitting = "secondary";

			const queries = [];

			// attempt to fetch PMID from PubMed
			if (!this.pmid) {
				const pmid = await this.fetchPMID();

				if (pmid) {
					queries.push(pmid);
				}
			}

			// if PubMed PMID not found, fall back on user input PMID
			if (this.pmid) {
				queries.push(this.pmid);
			}
			if (this.pmcid) {
				queries.push(this.pmcid);
			}
			if (this.doi) {
				queries.push(this.doi);
			}
			if (this.url) {
				queries.push(this.url);
			}

			if (queries.length) {
				this.fetchCitation(queries)
					.then((response) => {
						this.citation = response.data[0];
					})
					.catch((error) => {
						// display error
						if (error.response.status === 404) {
							this.showNotification({
								message: "Citation not found, please try again",
								status: error.response.status,
							});
						}

						// close the citation confirmation dialog
						this.citationDialog = false;
					})
					.finally(() => {
						this.submitting = false;
					});
			}
		},
		fetchPMID() {
			const queries = [];

			// pubmed search does not support searching by URL
			if (this.doi) {
				queries.push(`${this.doi}[DOI]`);
			}
			if (this.pmcid) {
				queries.push(`${this.pmcid}[ALL]`);
			}

			// fetch PMID from pubmed
			return new Promise((resolve) => {
				this.$http
					.get("", {
						baseURL: entrezURL,
						params: {
							// to be updated with actual dactyldata.com address
							email: "chow.eric.william@gmail.com",
							retmode: "json",
							term: queries, // array to feed into custom serializer
							tool: "dactyl",
						},
						paramsSerializer: (params) => {
							// custom serializer needed to prevent "+AND+" between terms from being encoded
							return Object.keys(params).reduce((prev, curr) => {
								if (curr === "term") {
									const term = params[curr]
										.map((item) => encodeURIComponent(item))
										.join("+AND+");
									return prev + `&${curr}=${term}`;
								} else {
									return (
										prev +
										`&${curr}=${encodeURIComponent(
											params[curr]
										)}`
									);
								}
							}, "");
						},
						withCredentials: false,
					})
					.then((response) => {
						if (response.data.esearchresult.count > 0) {
							resolve(response.data.esearchresult.idlist[0]);
						} else {
							resolve();
						}
					})
					.catch((error) => {
						// log the error in the console and silently fail
						console.error(error);
						resolve();
					});
			});
		},
		fetchCitation(queries) {
			let index = 0;

			// fetch citation from citoid
			return new Promise((resolve, reject) => {
				const next = () => {
					if (index < queries.length) {
						this.$http
							.get(`${encodeURIComponent(queries[index])}`, {
								baseURL: citoidURL,
								headers: {
									accept: "application/json; charset=utf-8;",
								},
								withCredentials: false,
							})
							.then((response) => {
								// found citation, stop iterating
								resolve(response);
							})
							.catch((error) => {
								if (queries.length - index > 1) {
									// increment index
									index++;

									// make the next request
									next();
								} else {
									// reached the end of queries
									reject(error);
								}
							});
					} else {
						reject();
					}
				};

				// start iteration
				next();
			});
		},
		confirmCitation() {
			const extras = {};

			// parse out pmid and pmcid
			if (this.citation.extra) {
				this.citation.extra.split("\n").forEach((line) => {
					const data = line.split(": ");
					extras[data[0]] = data[1];
				});
			}

			// pmid and pmcid are not included as their own fields from zotero
			this.pmid = extras.PMID ? extras.PMID : this.pmid;
			this.pmcid = extras.PMCID ? extras.PMCID : this.pmcid;

			// set the values from the citation
			this.doi = this.citation.DOI ? this.citation.DOI : this.doi;
			this.url = this.citation.url ? this.citation.url : this.url;

			// special case for handling title (zotero specific)
			const lastName = !_.isEmpty(this.citation.creators)
				? this.citation.creators[0].lastName
				: "<Missing Last Name>";

			const date = !_.isEmpty(this.citation.date)
				? this.citation.date.slice(0, 4)
				: "<Missing Date>";
			this.name = `${lastName}, ${date}; ${this.citation.title}`;

			// set all to dirty
			this.v$.$touch();

			// close the citation confirmation dialog
			this.citationDialog = false;
		},
		cancelCitation() {
			// close the citation confirmation dialog
			this.citationDialog = false;

			// clear the fetched citation
			this.citation = {};
		},
	},

	validations() {
		if (this.newVersion) {
			return {
				name: {
					maxLength: maxLength(2048),
				},
				url: {
					url,
					maxLength: maxLength(2048),
					required: requiredUnless(function () {
						return (
							this.noIDs || this.doi || this.pmid || this.pmcid
						);
					}),
				},
				doi: {
					maxLength: maxLength(255),
					required: requiredUnless(function () {
						return (
							this.noIDs || this.pmid || this.pmcid || this.url
						);
					}),
				},
				pmid: {
					maxLength: maxLength(255),
					required: requiredUnless(function () {
						return this.noIDs || this.doi || this.pmcid || this.url;
					}),
				},
				pmcid: {
					maxLength: maxLength(255),
					required: requiredUnless(function () {
						return this.noIDs || this.doi || this.pmid || this.url;
					}),
				},
				dataEntryEnabled: {},
				subInvalid: {
					isFalse,
				},
			};
		} else {
			return {
				name: {
					maxLength: maxLength(2048),
				},
				url: {
					url,
					maxLength: maxLength(2048),
					required: requiredUnless(function () {
						return (
							this.noIDs || this.doi || this.pmid || this.pmcid
						);
					}),
				},
				doi: {
					maxLength: maxLength(255),
					required: requiredUnless(function () {
						return (
							this.noIDs || this.pmid || this.pmcid || this.url
						);
					}),
					unique: isUnique(this.defaultPaper?.doi, this.dois),
				},
				pmid: {
					maxLength: maxLength(255),
					required: requiredUnless(function () {
						return this.noIDs || this.doi || this.pmcid || this.url;
					}),
					unique: isUnique(this.defaultPaper?.pmid, this.pmids),
				},
				pmcid: {
					maxLength: maxLength(255),
					required: requiredUnless(function () {
						return this.noIDs || this.doi || this.pmid || this.url;
					}),
					unique: isUnique(this.defaultPaper?.pmcid, this.pmcids),
				},
				dataEntryEnabled: {},
				subInvalid: {
					isFalse,
				},
			};
		}
	},
};
</script>

<style scoped lang="scss"></style>
