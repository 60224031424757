<template>
	<v-row
		align="start"
		align-content="start"
		justify="start"
	>
		<v-col cols="12">
			<user-table />
		</v-col>

		<v-col
			v-if="isAdmin"
			cols="6"
		>
			<role-table />
		</v-col>

		<v-col
			v-if="isAdmin"
			cols="6"
		>
			<permission-table :disabled="disabled" />
		</v-col>
	</v-row>
</template>

<script>
import UserTable from "@/pages/UserManager/UserTable";
import RoleTable from "@/pages/UserManager/RoleTable";
import PermissionTable from "@/pages/UserManager/PermissionTable";

// konami keys
const up = "ArrowUp";
const down = "ArrowDown";
const left = "ArrowLeft";
const right = "ArrowRight";
const b = "b";
const a = "a";
const start = "Enter"; // enter

export default {
	name: "UserManager",

	components: {
		UserTable,
		RoleTable,
		PermissionTable,
	},

	data() {
		return {
			code: [up, up, down, down, left, right, left, right, b, a, start],
			delay: 1000,
			disabled: true,
			isAdmin: false,
			presses: [],
			timeout: null,
		};
	},

	computed: {
		user() {
			return this.$store.getters.user;
		},
	},

	created() {
		this.isAdminUser();
	},

	mounted() {
		document.addEventListener("keyup", this.konamiCodeHandler);
	},

	beforeUnmount() {
		document.removeEventListener("keyup", this.konamiCodeHandler);
	},

	methods: {
		konamiCodeHandler(event) {
			// stop timeout from triggering and clearing the users input
			clearTimeout(this.timeout);

			// record users input to the end of the combination
			this.presses.push(event.key);

			// check if the user has entered the code correctly
			let valid = true;
			this.presses.forEach((press, index) => {
				if (
					press &&
					this.code[index] &&
					this.code[index].toLowerCase() !== press.toLowerCase()
				) {
					valid = false;
				}
			});

			// enable permissions table if valid
			if (this.code.length === this.presses.length && valid) {
				this.enablePermissionsTable();
			}

			// reset the time limit on key presses
			this.timeout = setTimeout(() => {
				// reset user input if they took too long
				this.presses = [];
			}, this.delay);
		},
		enablePermissionsTable() {
			this.disabled = false;
		},
		isAdminUser() {
			this.$http
				.get(`/users/${this.user.uuid}/is-admin`)
				.then((response) => {
					this.isAdmin = response.data;
				});
		},
	},
};
</script>

<style scoped lang="scss">
.col {
	flex-grow: 0;
}
</style>
