/**
 * Initializes WPD Image Manager.
 */
function init(wpd) {
	// prevent fileManager from loading unsupported file
	wpd.imageManager.initializeFileManager = function (files, startIndex) {
		const fileManager = wpd.appData.getFileManager();
		fileManager.reset();
		fileManager.currentIndex = startIndex;
		fileManager.set(files);
	};
}

export { init };
