/**
 * Generates an HTML select element to replace point group inputs.
 * @param  {String}        id      - ID attribute of the generated select element
 * @param  {Array[String]} tags    - Values and texts of each option element
 * @param  {String}        tagUUID - Option value selected by default
 * @return {String}                  HTML string of the select element
 */
function generateSelect(id, tags, tagUUID) {
	const generateOption = (tag, selected) => {
		return `<option value="${tag?.uuid ?? ""}"${
			selected ? "selected" : ""
		}>${tag?.name ?? ""}</option>`;
	};

	const options = [
		generateOption(),
		...tags.map((tag) => generateOption(tag, tag.uuid === tagUUID)),
	];

	return `
		<select
			id="${id}"
			style="border: thin grey solid;"
			onchange="wpd.pointGroups.updateSelectText(this);"
		>
			${options.join("")}
		</select>
	`;
}

/**
 * Generates an HTML table row containing point group inputs.
 * @param  {Number}        index   - Point group input row index
 * @param  {Array[String]} tags    - Values and texts of each option element
 * @param  {String}        tagUUID - Option value selected by default
 * @return {String}                  HTML string of the table row
 */
function generateRow(wpd, index, tags, tagUUID) {
	const groupText = wpd.gettext("point-group-group");
	const deleteGroupText = wpd.gettext("point-group-delete-group");

	// html templates
	const labelHTML = `${groupText} <span>${index}</span>: `;
	const inputHTML = generateSelect(`point-group-${index}`, tags, tagUUID);
	const actionsHTML = `<input type="button" value="${deleteGroupText}" onclick="wpd.pointGroups.deleteSettingsRow(this)" />`;

	return `<tr><td>${labelHTML}</td><td>${inputHTML}</td><td>${actionsHTML}</td></tr>`;
}

/**
 * Update instructions on point group popup.
 */
function replacePopup(wpdDocument) {
	// get the point group popup element
	const pointGroupPopup = wpdDocument.getElementById(
		"point-group-settings-popup"
	);

	pointGroupPopup.innerHTML = `
		<div class="popupheading">Point Groups</div>
		<table style="margin: 0 auto;">
			<tr>
				<td style="vertical-align: top;">
					<p>
						Point groups are groups of related data points such as standard error or confidence intervals.
					</p>
					<br />
					<p>
						To define a point group, select a tag in the dropdown below. Create additional point groups as
						necessary.
					</p>
					<br />
					<p>
						E.g. Assume we have 3 point groups defined: Median, Standard error +1, and Standard error -1. Points
						would be entered in the order shown in the image to the right. For each set of points, the median value
						would first be recorded, then the standard error +1, and finally the standard error -1. The process
						would repeat for the next set of points.
					</p>
				</td>
				<td>
					<center>
						<img src="images/pointgroups.png" width="300px">
					</center>
				</td>
			</tr>
		</table>
		<br />
		<table style="margin: 0 auto;">
			<tbody id="point-group-table">
				<tr>
					<td>
						Group <span>0</span>:
					</td>
					<td>
						<input id="point-group-0" placeholder="Primary Group" type="text" />
					</td>
					<td>&nbsp;</td>
				</tr>
			</tbody>
		</table>
		<br />
		<p style="text-align: center;">
			<input id="point-group-add-button" type="button" value="Add New Group" onclick="wpd.pointGroups.addSettingsRow(true);" />
		</p>
		<p style="text-align: center;">
			<input id="point-group-save-button" type="button" value="Save" onclick="wpd.pointGroups.saveSettings();" />
			<input id="point-group-cancel-button" type="button" value="Cancel" onclick="wpd.pointGroups.cancelSettingsPopup();" />
		</p>
	`;
}

export { replacePopup, generateSelect, generateRow };
