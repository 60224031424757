function init(wpd) {
	wpd.BarCornersTool = class {
		constructor(axes, dataset) {
			this.axes = axes;
			this.dataset = dataset;

			this.drawPoints();
		}

		onRedraw() {
			this.drawPoints();
		}

		onMouseClick(ev, pos, imagePos) {
			const metadata = this.axes.getMetadata();
			if (!metadata.barHeaders) {
				metadata.barHeaders = {};
			}

			if (metadata.barHeaders.p1 && metadata.barHeaders.p2) {
				this.selectNearestPoint(imagePos.x, imagePos.y, metadata);
			} else if (!metadata.barHeaders.p1) {
				metadata.barHeaders.p1 = {
					px: imagePos.x,
					py: imagePos.y,
					pos: imagePos,
					isSelected: true,
				};
			} else if (!metadata.barHeaders.p2) {
				metadata.barHeaders.p1.isSelected = false;
				metadata.barHeaders.p2 = {
					px: imagePos.x,
					py: imagePos.y,
					pos: imagePos,
					isSelected: true,
				};
			}

			this.axes.setMetadata(metadata);

			this.drawPoints();
			wpd.graphicsWidget.updateZoomOnEvent(ev);
		}

		onKeyDown(ev) {
			const metadata = this.axes.getMetadata();

			let selectedPoint = this.getSelectedPoint(metadata);
			const stepSize =
				ev.shiftKey === true
					? 5 / wpd.graphicsWidget.getZoomRatio()
					: 0.5 / wpd.graphicsWidget.getZoomRatio();

			if (wpd.keyCodes.isUp(ev.keyCode)) {
				ev.preventDefault();
				selectedPoint.pos.y = selectedPoint.pos.y - stepSize;
				selectedPoint.py = selectedPoint.pos.y;
			} else if (wpd.keyCodes.isDown(ev.keyCode)) {
				ev.preventDefault();
				selectedPoint.pos.y = selectedPoint.pos.y + stepSize;
				selectedPoint.py = selectedPoint.pos.y;
			} else if (wpd.keyCodes.isLeft(ev.keyCode)) {
				ev.preventDefault();
				selectedPoint.pos.x = selectedPoint.pos.x - stepSize;
				selectedPoint.px = selectedPoint.pos.x;
			} else if (wpd.keyCodes.isRight(ev.keyCode)) {
				ev.preventDefault();
				selectedPoint.pos.x = selectedPoint.pos.x + stepSize;
				selectedPoint.px = selectedPoint.pos.x;
			} else {
				return;
			}

			this.axes.setMetadata(metadata);
			wpd.graphicsWidget.forceHandlerRepaint();
			wpd.graphicsWidget.updateZoomToImagePosn(
				selectedPoint.px,
				selectedPoint.py
			);
			this.drawPoints();
		}

		drawPoints() {
			const drawPoint = (point, label = "") => {
				if (point) {
					const color = point.isSelected
						? wpd.Colors.active
						: wpd.Colors.good;
					wpd.graphicsHelper.drawPoint(
						{ x: point.px, y: point.py },
						color,
						label
					);
				}
			};

			const barP1 = this.axes.calibration.getPoint(0);
			const barP2 = this.axes.calibration.getPoint(1);
			drawPoint(barP1);
			drawPoint(barP2);

			const metadata = this.axes.getMetadata();
			if (metadata.barHeaders) {
				drawPoint(metadata.barHeaders.p1, "P1");
				drawPoint(metadata.barHeaders.p2, "P2");
			}
		}

		getSelectedPoint(metadata) {
			if (!metadata || !metadata.barHeaders) return null;

			if (metadata.barHeaders.p1.isSelected) {
				return metadata.barHeaders.p1;
			} else if (metadata.barHeaders.p2.isSelected) {
				return metadata.barHeaders.p2;
			}

			return null;
		}
		selectNearestPoint(x, y, metadata) {
			const threshold = 50;
			const p1 = metadata.barHeaders.p1;
			const p1Dist = Math.sqrt(
				(x - p1.px) * (x - p1.px) + (y - p1.py) * (y - p1.py)
			);

			const p2 = metadata.barHeaders.p2;
			const p2Dist = Math.sqrt(
				(x - p2.px) * (x - p2.px) + (y - p2.py) * (y - p2.py)
			);

			if (p1Dist <= p2Dist && p1Dist < threshold) {
				p1.isSelected = true;
				p2.isSelected = false;
			} else if (p2Dist < threshold) {
				p1.isSelected = false;
				p2.isSelected = true;
			} else {
				p1.isSelected = false;
				p2.isSelected = false;
			}
		}
	};
}

export { init };
