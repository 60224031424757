import * as Elements from "./point-groups/elements.js";
import * as Functions from "./point-groups/functions.js";

/**
 * Initializes WPD point group inputs with metadata tag drop downs.
 * @param {VueComponent} dataEntryVM - DataEntry Vue instance
 */
function init(wpd, wpdDocument, dataEntryVM) {
	// get metadata tags from dactyl
	const tags = dataEntryVM.getTags();

	// overwrite point group functions
	Functions.overwrite(wpd, wpdDocument, tags);

	// replace the first input with a select element
	const pointGroupInput0 = wpdDocument.getElementById("point-group-0");
	pointGroupInput0.outerHTML = Elements.generateSelect("point-group-0", tags);
}

export { init };
