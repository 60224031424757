<template>
	<v-card
		class="d-flex flex-column pb-5"
		:disabled="!!loading"
		:loading="loading"
	>
		<v-toolbar
			color="primary"
			dark
			flat
		>
			<v-toolbar-title>
				{{ editMode ? editTitle : title }}
			</v-toolbar-title>
			<v-hover-icon class="mr-3" />
		</v-toolbar>

		<v-form @submit.prevent>
			<v-card-text class="pa-10">
				<v-row>
					<v-text-field
						v-model="name"
						:error-messages="nameErrors"
						label="Tag name"
						name="name"
						type="text"
						class="col-12"
						autocomplete="off"
						required
						@keyup="v$.name.$touch()"
					/>
				</v-row>

				<v-row align="center">
					<v-col cols="3">
						<v-btn
							class="mr-5"
							elevation="2"
							variant="outlined"
							@click="selectParent"
						>
							{{ editMode ? "Change" : "Select" }} parent
						</v-btn>
					</v-col>

					<v-col>
						<span class="text-body-1">
							{{ parent }}
						</span>
						<v-icon
							class="pb-0"
							end
							size="small"
							:class="{ 'text-secondary': isHovered }"
							@click="clearParent"
							@mouseenter="isHovered = true"
							@mouseleave="isHovered = false"
						>
							mdi-close
						</v-icon>
					</v-col>
				</v-row>
			</v-card-text>

			<v-card-actions>
				<v-spacer />

				<v-btn
					v-if="editMode"
					class="bg-secondary text-white"
					elevation="2"
					type="submit"
					:disabled="v$.$invalid || !v$.$anyDirty || !!loading"
					@click="update"
				>
					Update
				</v-btn>

				<v-btn
					v-else
					class="bg-secondary text-white"
					elevation="2"
					type="submit"
					:disabled="v$.$invalid"
					@click="create"
				>
					Create
				</v-btn>

				<v-btn
					class="bg-white"
					elevation="2"
					:disabled="!!loading"
					@click="cancel"
				>
					Cancel
				</v-btn>

				<v-btn
					v-if="editMode"
					class="bg-error text-white"
					elevation="2"
					type="submit"
					:disabled="disableDelete"
					@click="remove"
				>
					<v-icon>mdi-delete</v-icon>
				</v-btn>

				<v-spacer />
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import _ from "lodash";
import { mapMutations } from "vuex";
import { required, maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import VHoverIcon from "@/components/VHoverIcon";

const topLevelText = "(Top level)";

export default {
	name: "TagEdit",

	components: {
		VHoverIcon,
	},

	props: {
		tag: { type: Object, default: () => null },
		parentTag: { type: Object, default: () => null },
		dialogSelectParent: Boolean,
	},
	emits: ["update:cancel", "update:saved", "update:parent"],

	setup() {
		return {
			v$: useVuelidate(),
		};
	},

	data() {
		return {
			loading: false,
			name: this.tag ? this.tag.name : "",
			parent: this.parentTag ? this.parentTag.name : topLevelText,
			title: "Create tag",
			editTitle: "Edit tag",
			isHovered: false,
		};
	},

	computed: {
		disableDelete() {
			return (
				this.tag && this.tag.children && this.tag.children.length > 0
			);
		},
		editMode() {
			return !_.isEmpty(this.tag);
		},
		nameErrors() {
			if (!this.v$.name.$dirty) {
				return [];
			}

			return this.v$.name.$errors.map((error) => {
				switch (error.$validator) {
					case "required":
						return "Tag name is required";
					case "maxLength":
						return "Tag name is too long";
					default:
						return "Tag name is invalid";
				}
			});
		},
	},

	mounted() {
		// for first load
		if (this.dialogSelectParent) {
			this.v$.$touch();
		}
	},

	methods: {
		...mapMutations(["showNotification"]),
		cancel() {
			this.$emit("update:cancel");
		},
		clear() {
			this.name = "";
			this.parent = topLevelText;

			// clear dirty flags
			this.v$.$reset();
		},
		clearParent() {
			this.parent = topLevelText;

			this.v$.$touch();
		},
		create() {
			if (!this.v$.$invalid) {
				this.loading = "secondary";

				const parentUUID = this.parentTag ? this.parentTag.uuid : null;

				this.$http
					.post("/tags", {
						name: this.name.trim(),
						parentUUID: parentUUID,
					})
					.then((response) => {
						// display success
						this.showNotification(response.data);

						this.$emit("update:saved");
					})
					.catch((error) => {
						// set dirty flags
						this.v$.$touch();

						// display error
						if (error.response) {
							if (error.response.data.message) {
								this.showNotification(error.response.data);
							} else {
								this.showNotification(
									error.response.data.error
								);
							}
						}
					})
					.finally(() => {
						setTimeout(() => {
							this.loading = false;
						}, 250);
					});
			} else {
				// set all to dirty
				this.v$.$touch();
			}
		},
		update() {
			if (!this.v$.$invalid) {
				this.loading = "secondary";

				let parentUUID = null;
				if (this.parent !== topLevelText) {
					parentUUID = this.parentTag.uuid;
				}

				this.$http
					.put(`/tags/${this.tag.uuid}`, {
						name: this.name.trim(),
						parentUUID: parentUUID,
					})
					.then((response) => {
						// display success
						this.showNotification(response.data);
						this.$emit("update:saved");
					})
					.catch((error) => {
						// set dirty flags
						this.v$.$touch();

						// display error
						if (error.response) {
							if (error.response.data.message) {
								this.showNotification(error.response.data);
							} else {
								this.showNotification(
									error.response.data.error
								);
							}
						}
					})
					.finally(() => {
						setTimeout(() => {
							this.loading = false;
						}, 250);
					});
			} else {
				// set all to dirty
				this.v$.$touch();
			}
		},
		remove() {
			this.loading = "secondary";
			this.$http
				.delete(`/tags/${this.tag.uuid}`)
				.then((response) => {
					// display success
					this.showNotification(response.data);

					this.$emit("update:saved");
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					setTimeout(() => {
						this.loading = false;
					}, 250);
				});
		},
		selectParent() {
			this.$emit("update:parent", {
				name: this.name,
			});

			setTimeout(() => {
				// set dirty flag
				this.v$.$touch();
			}, 250);
		},
	},

	validations: {
		name: {
			required,
			maxLength: maxLength(254),
		},
	},
};
</script>

<style scoped lang="scss"></style>
