import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

/**
 * Adds tools for table axis calibration.
 */
function apply(wpd, wpdWindow, wpdDocument, dataEntryVM, ids) {
	/**
	 * Alignment tool for drawing a rectangle around tabular data.
	 * @return {Function} - WPD Tool
	 */
	wpd.AlignmentAreaTool = (function () {
		const Tool = function (calibration) {
			let isMouseDown = false;
			let isSelecting = false;
			let _drawTimer = null;

			const nextButton = wpdDocument
				.getElementById(ids.areaSidebar)
				.querySelector(".table-area-calibration-next-button");
			const rotationButtons = wpdDocument.querySelectorAll(
				"#topContainer button[title^='Rotate']"
			);

			this.onAttach = function () {
				wpd.graphicsWidget.resetData();

				// if calibration data passed in, load them
				if (calibration.area.length) {
					// enable complete button
					nextButton.disabled = false;
				}

				// paint the loaded data
				wpd.graphicsWidget.setRepainter(
					new wpd.TableRepainter({
						area: calibration.area,
						rotation: calibration.rotation,
						drawHeaders: false,
					})
				);
				wpd.graphicsWidget.forceHandlerRepaint();

				// disable rotation buttons
				rotationButtons.forEach((el) => (el.disabled = true));
			};

			this.onRemove = function () {
				wpd.graphicsWidget.resetData();

				// disable rotation buttons
				rotationButtons.forEach((el) => (el.disabled = false));
			};

			this.onMouseDown = function () {
				isMouseDown = true;
			};

			this.onMouseUp = function (e) {
				if (isSelecting === true) {
					// enable complete button
					nextButton.disabled = false;

					// clear the draw timer
					clearTimeout(_drawTimer);

					wpd.graphicsWidget.updateZoomOnEvent(e);

					// push these reset statements to the bottom of the events message queue
					isSelecting = false;
					isMouseDown = false;
				} else {
					isMouseDown = false;
				}
			};

			this.onMouseMove = function (e, pos, imagePos) {
				if (isMouseDown === true) {
					if (!isSelecting) {
						// new selection
						isSelecting = true;

						// disable complete button
						nextButton.disabled = true;

						// clear previous second rectangle point
						calibration.area[1] = null;

						// record the first rectangle point
						calibration.area[0] = imagePos;
					}

					// record the new position as the second selection rectangle point
					calibration.area[1] = imagePos;

					// refresh the rectangle every 1 ms
					clearTimeout(_drawTimer);
					_drawTimer = setTimeout(
						function () {
							wpd.graphicsWidget.forceHandlerRepaint();
						}.bind(this),
						1
					);
				}
			};
		};

		return Tool;
	})();

	/**
	 * Alignment tool for defining rows and columns of tabular data.
	 * @return {Function} WPD Tool
	 */
	wpd.AlignmentRowsAndColumnsTool = (function () {
		const Tool = function (calibration) {
			const hitTolerance = 8;

			const area = calibration.area;
			const rows = calibration.rows;
			const cols = calibration.cols;
			const rotation = calibration.rotation ?? 0;

			// relationship of directions of the x and y axes on the image to the rotated orientation
			const topLeft = ((rotation) => {
				const directionMap = {
					0: { x: "-", y: "-" },
					90: { x: "-", y: "+" },
					180: { x: "+", y: "+" },
					270: { x: "+", y: "-" },
				};

				return directionMap[rotation];
			})(rotation);

			// swap mouse x and y if calibration rotation is 90 or 270 degrees
			const swapXY = rotation % 180 === 90;

			// get area min/max values to always have the top-left and bottom-right corners
			const getMinMax = (points, mode) => {
				if (mode === "min") {
					return {
						x:
							points[0].x > points[1].x
								? points[1].x
								: points[0].x,
						y:
							points[0].y > points[1].y
								? points[1].y
								: points[0].y,
					};
				} else {
					return {
						x:
							points[0].x > points[1].x
								? points[0].x
								: points[1].x,
						y:
							points[0].y > points[1].y
								? points[0].y
								: points[1].y,
					};
				}
			};
			const areaMin = getMinMax(area, "min");
			const areaMax = getMinMax(area, "max");

			// html elements to manipulate
			const completeButton = wpdDocument
				.getElementById(ids.rcSidebar)
				.querySelector("input[value='Complete']");
			const rotationButtons = wpdDocument.querySelectorAll(
				"#topContainer button[title^='Rotate']"
			);

			const selectedIndexes = {
				row: null,
				col: null,
			};
			let isMouseDown = false;

			const rowHitTest = function (y, rowY) {
				// no need to test for x
				// x is guaranteed to be within areaX1 and areaX2
				return y >= rowY - hitTolerance && y <= rowY + hitTolerance;
			};

			const colHitTest = function (x, colX) {
				// no need to test for y
				// y is guaranteed to be within areaY1 and areaY2
				return x >= colX - hitTolerance && x <= colX + hitTolerance;
			};

			const moveLine = (i, imageP, imageDividers) => {
				if (i !== null) {
					imageDividers[i] = imageP;

					wpd.graphicsWidget.forceHandlerRepaint();
				}
			};

			const resizeRest = (i, imageP, imageDividers, min, max) => {
				const isAfterNextDivider =
					min < max
						? imageP > imageDividers[i + 1]
						: imageP < imageDividers[i + 1];
				const isBeforePreviousDivider =
					min < max
						? imageP < imageDividers[i - 1]
						: imageP > imageDividers[i - 1];

				// resize overlapped divisions, except first and last ones
				if (i < imageDividers.length && isAfterNextDivider) {
					// calculate the remaining space
					const delta = (max - imageP) / (imageDividers.length - i);

					// divide equally among the rest
					for (let j = i + 1; j < imageDividers.length; j++) {
						imageDividers[j] = imageP + delta * (j - i);
					}

					// repaint
					wpd.graphicsWidget.forceHandlerRepaint();
				} else if (i > 0 && isBeforePreviousDivider) {
					// calculate the remaining space
					const delta = (imageP - min) / (i + 1);

					// divide equally among the rest
					for (let j = 0; j < i; j++) {
						imageDividers[j] = min + delta * (j + 1);
					}

					// repaint
					wpd.graphicsWidget.forceHandlerRepaint();
				}
			};

			const updateDividers = () => {
				// if desired number of rows is different from the correct number of divisions, which is 1 less than the
				// desired number, refresh the divisions
				if (rows.dividers.length !== rows.num - 1) {
					// reset divisions
					rows.dividers.length = 0;

					// swap x and y if calibration is rotated by 90 or 270 degrees
					const axis = swapXY ? "x" : "y";
					const min = areaMin[axis];
					const max = areaMax[axis];

					// find y-bounds of area
					const totalHeight = max - min;

					// calculate the division's height
					const height = totalHeight / rows.num;

					for (let i = 1; i < rows.num; i++) {
						rows.dividers.push(min + height * i);
					}

					if (topLeft[axis] === "+") {
						rows.dividers.reverse();
					}
				}

				// ditto with columns
				if (cols.dividers.length !== cols.num - 1) {
					// reset divisions
					cols.dividers.length = 0;

					// swap x and y if calibration is rotated by 90 or 270 degrees
					const axis = swapXY ? "y" : "x";
					const min = areaMin[axis];
					const max = areaMax[axis];

					// find x-bounds of area
					const totalWidth = max - min;

					// calculate the division's width
					const width = totalWidth / cols.num;

					for (let i = 1; i < cols.num; i++) {
						cols.dividers.push(min + width * i);
					}

					if (topLeft[axis] === "+") {
						cols.dividers.reverse();
					}
				}

				// repaint
				wpd.graphicsWidget.forceHandlerRepaint();
			};

			this.onAttach = function () {
				wpd.graphicsWidget.resetData();

				// set the repainter
				wpd.graphicsWidget.setRepainter(
					new wpd.TableRepainter({
						area: area,
						rows: rows,
						cols: cols,
						rotation: rotation,
						drawHeaders: false,
						selecting: selectedIndexes,
					})
				);

				// paint the loaded data
				wpd.graphicsWidget.forceHandlerRepaint();

				// enable complete button
				completeButton.disabled = false;

				// disable rotation buttons
				rotationButtons.forEach((el) => (el.disabled = true));

				// listen for divider updates
				wpd.events.removeAllListeners("wpd.tableAxis.updateDividers");
				wpd.events.addListener(
					"wpd.tableAxis.updateDividers",
					updateDividers
				);
			};

			this.onRemove = function () {
				wpd.graphicsWidget.resetData();

				// enable rotation buttons
				rotationButtons.forEach((el) => (el.disabled = false));
			};

			this.onMouseDown = function (e, pos, imagePos) {
				isMouseDown = true;

				// test if mouse down is within the table area
				if (
					imagePos.x >= areaMin.x &&
					imagePos.x <= areaMax.x &&
					imagePos.y >= areaMin.y &&
					imagePos.y <= areaMax.y
				) {
					let rowAxis = imagePos.y;
					let colAxis = imagePos.x;

					if (swapXY) {
						rowAxis = imagePos.x;
						colAxis = imagePos.y;
					}

					// test for row mouse down
					if (selectedIndexes.col == null) {
						for (let i = 0; i < rows.dividers.length; i++) {
							if (rowHitTest(rowAxis, rows.dividers[i])) {
								selectedIndexes.row = i;

								// set cursor style to grabbing
								e.target.style.cursor = "grabbing";

								break;
							}
						}
					}

					if (selectedIndexes.row == null) {
						// test for column mouse down
						for (let i = 0; i < cols.dividers.length; i++) {
							if (colHitTest(colAxis, cols.dividers[i])) {
								selectedIndexes.col = i;

								// set cursor style to grabbing
								e.target.style.cursor = "grabbing";

								break;
							}
						}
					}
				}
			};

			this.onMouseMove = function (e, pos, imagePos) {
				// test for table area
				if (
					imagePos.y > areaMin.y &&
					imagePos.y < areaMax.y &&
					imagePos.x > areaMin.x &&
					imagePos.x < areaMax.x
				) {
					let rowAxis = imagePos.y;
					let colAxis = imagePos.x;

					if (swapXY) {
						rowAxis = imagePos.x;
						colAxis = imagePos.y;
					}

					if (isMouseDown) {
						// move the row division line
						moveLine(selectedIndexes.row, rowAxis, rows.dividers);

						// move the column division line
						moveLine(selectedIndexes.col, colAxis, cols.dividers);
					} else {
						// update cursor and highlight hovered line if necessary

						// test for row mouse down
						if (selectedIndexes.col == null) {
							for (let i = 0; i < rows.dividers.length; i++) {
								if (rowHitTest(rowAxis, rows.dividers[i])) {
									selectedIndexes.row = i;

									// set cursor style to grab
									e.target.style.cursor = "grab";

									break;
								} else {
									selectedIndexes.row = null;

									// reset cursor style to crosshair
									e.target.style.cursor = "crosshair";
								}
							}
						}

						if (selectedIndexes.row == null) {
							// test for column mouse down
							for (let i = 0; i < cols.dividers.length; i++) {
								if (colHitTest(colAxis, cols.dividers[i])) {
									selectedIndexes.col = i;

									// set cursor style to grab
									e.target.style.cursor = "grab";

									break;
								} else {
									selectedIndexes.col = null;

									// reset cursor style to crosshair
									e.target.style.cursor = "crosshair";
								}
							}
						}

						// repaint
						wpd.graphicsWidget.forceHandlerRepaint();
					}
				}
			};

			this.onMouseUp = function (e, pos, imagePos) {
				let x, y, rowMinMax, colMinMax;
				if (swapXY) {
					x = imagePos.y;
					y = imagePos.x;

					rowMinMax =
						topLeft.x === "+"
							? [areaMax.x, areaMin.x]
							: [areaMin.x, areaMax.x];
					colMinMax =
						topLeft.y === "+"
							? [areaMax.y, areaMin.y]
							: [areaMin.y, areaMax.y];
				} else {
					x = imagePos.x;
					y = imagePos.y;

					rowMinMax =
						topLeft.y === "+"
							? [areaMax.y, areaMin.y]
							: [areaMin.y, areaMax.y];
					colMinMax =
						topLeft.x === "+"
							? [areaMax.x, areaMin.x]
							: [areaMin.x, areaMax.x];
				}

				// resize the overlapped row and column divisions
				if (selectedIndexes.row !== null) {
					resizeRest(
						selectedIndexes.row,
						y,
						rows.dividers,
						...rowMinMax
					);
				}
				if (selectedIndexes.col !== null) {
					resizeRest(
						selectedIndexes.col,
						x,
						cols.dividers,
						...colMinMax
					);
				}

				isMouseDown = false;
				selectedIndexes.row = null;
				selectedIndexes.col = null;

				// reset cursor style to crosshair
				e.target.style.cursor = "crosshair";
			};
		};

		return Tool;
	})();

	/**
	 * Table header definition tool for tabular data.
	 * @param  {VueComponent} vm - DataEntry Vue instance
	 * @return {Function}          WPD Tool
	 */
	wpd.TableHeaderTool = (function (vm) {
		const Tool = function (calibration) {
			const area = calibration.area;
			const rows = calibration.rows;
			const cols = calibration.cols;
			const rotation = calibration.rotation ?? 0;
			const reworkEnabled = dataEntryVM.reworkEnabled;

			// relationship of directions of the x and y axes on the image to the rotated orientation
			const topLeft = ((rotation) => {
				const directionMap = {
					0: { x: "-", y: "-" },
					90: { x: "-", y: "+" },
					180: { x: "+", y: "+" },
					270: { x: "+", y: "-" },
				};

				return directionMap[rotation];
			})(rotation);

			// swap mouse x and y if calibration rotation is 90 or 270 degrees
			const swapXY = rotation % 180 === 90;

			// get area min/max values to always have the top-left and bottom-right corners
			const getMinMax = (points, mode) => {
				if (mode === "min") {
					return {
						x:
							points[0].x > points[1].x
								? points[1].x
								: points[0].x,
						y:
							points[0].y > points[1].y
								? points[1].y
								: points[0].y,
					};
				} else {
					return {
						x:
							points[0].x > points[1].x
								? points[0].x
								: points[1].x,
						y:
							points[0].y > points[1].y
								? points[0].y
								: points[1].y,
					};
				}
			};
			const areaMin = getMinMax(area, "min");
			const areaMax = getMinMax(area, "max");

			// Note: x and y are swapped since these are used for getting widths for rows (y) and heights for columns (x)
			const avgCellDimensions = swapXY
				? {
						x: (areaMax.y - areaMin.y) / calibration.cols.num,
						y: (areaMax.x - areaMin.x) / calibration.rows.num,
				  }
				: {
						x: (areaMax.y - areaMin.y) / calibration.rows.num,
						y: (areaMax.x - areaMin.x) / calibration.cols.num,
				  };

			const hover = {
				header: {
					rows: null,
					cols: null,
				},
			};

			let selectedRow = null;
			let selectedCol = null;

			const getCellWidth = (index) => {
				const axis = swapXY ? "y" : "x";
				const ends =
					topLeft[axis] === "-"
						? [areaMin[axis], areaMax[axis]]
						: [areaMax[axis], areaMin[axis]];

				const x0 = index === 0 ? ends[0] : cols.dividers[index - 1];
				const x1 =
					index === cols.dividers.length
						? ends[1]
						: cols.dividers[index];

				return x1 - x0;
			};

			const getCellHeight = (index) => {
				const axis = swapXY ? "x" : "y";
				const ends =
					topLeft[axis] === "-"
						? [areaMin[axis], areaMax[axis]]
						: [areaMax[axis], areaMin[axis]];

				const y0 = index === 0 ? ends[0] : rows.dividers[index - 1];
				const y1 =
					index === rows.dividers.length
						? ends[1]
						: rows.dividers[index];

				return y1 - y0;
			};

			const getHeaderDialogPosition = (index, headerType) => {
				const positionSettings = {
					// use the mid-point of the row-y and start-x of the area
					rows: {
						0: {
							x: areaMin.x,
							y:
								[...rows.dividers, areaMax.y][index] -
								getCellHeight(index) / 2,
						},
						90: {
							x:
								[...rows.dividers, areaMax.x][index] -
								getCellHeight(index) / 2,
							y: areaMax.y,
						},
						180: {
							x: areaMax.x,
							y:
								[...rows.dividers, areaMin.y][index] -
								getCellHeight(index) / 2,
						},
						270: {
							x:
								[...rows.dividers, areaMin.x][index] -
								getCellHeight(index) / 2,
							y: areaMin.y,
						},
					},
					// use the mid-point of the column-x and start-y of the area
					cols: {
						0: {
							x:
								[...cols.dividers, areaMax.x][index] -
								getCellWidth(index) / 2,
							y: areaMin.y,
						},
						90: {
							x: areaMin.x,
							y:
								[...cols.dividers, areaMin.y][index] -
								getCellWidth(index) / 2,
						},
						180: {
							x:
								[...cols.dividers, areaMin.x][index] -
								getCellWidth(index) / 2,
							y: areaMax.y,
						},
						270: {
							x: areaMax.x,
							y:
								[...cols.dividers, areaMax.y][index] -
								getCellWidth(index) / 2,
						},
					},
				};

				return positionSettings[headerType][rotation];
			};

			const getRotatedScreenCoordinates = (coordinates) => {
				const currentRotation = wpd.graphicsWidget.getRotation();
				const { x, y } = wpd.graphicsWidget.getRotatedCoordinates(
					0,
					currentRotation,
					coordinates.x,
					coordinates.y
				);

				return wpd.graphicsWidget.screenPx(x, y);
			};

			const removeHeaderDataFromDataPoint = (dataPoint) => {
				const keys = _.chain(dataPoint.metadata)
					.omitBy(wpd.utils.isWord)
					.keys()
					.value();

				// delete all existing header data points within
				// each cell
				_.each(keys, (key) => {
					// existing data only had hidden flag, not
					// headerTag flag
					if (dataPoint.metadata[key].hidden) {
						delete dataPoint.metadata[key];
					}
				});

				if (reworkEnabled) {
					// delete any composite keys on the data point
					delete dataPoint.metadata.compositeKey;
					delete dataPoint.metadata.referenceCompositeKey;
				}
			};

			const addHeaderDataToDataPoint = (
				dataPoint,
				currentXHeader,
				currentYHeader,
				indexes
			) => {
				const xHeaderValues = Object.values(
					_.omitBy(currentXHeader, wpd.utils.isWord)
				);
				const yHeaderValues = Object.values(
					_.omitBy(currentYHeader, wpd.utils.isWord)
				);
				const newHeaderValues = [...xHeaderValues, ...yHeaderValues];

				for (let i = 0; i < newHeaderValues.length; i++) {
					dataPoint.metadata[i + indexes.length] = {
						...newHeaderValues[i],
						hidden: true,
						headerTag: true,
					};
				}

				if (reworkEnabled) {
					const compositeKey =
						currentYHeader?.compositeKey ??
						currentXHeader?.compositeKey;

					if (compositeKey) {
						dataPoint.metadata.compositeKey = compositeKey;
					}

					const referenceCompositeKey =
						currentYHeader?.referenceCompositeKey ??
						currentXHeader?.referenceCompositeKey;

					if (referenceCompositeKey) {
						dataPoint.metadata.referenceCompositeKey =
							referenceCompositeKey;
					}
				}
			};

			const captureHeader = async (headerType) => {
				const index = headerType === "rows" ? selectedRow : selectedCol;

				const headerPosition = getHeaderDialogPosition(
					index,
					headerType
				);

				const { x: sx, y: sy } =
					getRotatedScreenCoordinates(headerPosition);

				// include offset and scroll positions from wpd graphics container
				const graphicsContainer =
					wpdDocument.getElementById("graphicsContainer");
				const canvasDiv = wpdDocument.getElementById("canvasDiv");
				const marginLeftText =
					wpdWindow.getComputedStyle(canvasDiv).marginLeft;
				const marginLeft = parseInt(
					marginLeftText.replace("px", ""),
					10
				);
				const finalPosition = {
					x: sx - graphicsContainer.scrollLeft + marginLeft,
					y: sy - graphicsContainer.scrollTop,
				};

				// fetch existing axis metadata to modify
				const axis = wpd.tree.getActiveAxes();
				const axisMetadata = axis.getMetadata();

				// pre-fill new header with tags
				if (_.isEmpty(calibration[headerType].headers[index])) {
					// collect unique set of header tags
					const uniqueHeaderTags = new Set();
					calibration[headerType].headers.forEach((header) => {
						Object.values(
							_.omitBy(header, wpd.utils.isWord)
						).forEach((data) => {
							if (data.tag?.uuid && !data.hidden) {
								uniqueHeaderTags.add(data.tag.uuid);
							}
						});
					});

					// convert set to array and pre-fill to new header
					Array.from(uniqueHeaderTags).forEach(
						(tagUUID, tagIndex) => {
							if (
								calibration[headerType].headers[index] === null
							) {
								calibration[headerType].headers[index] = {};
							}

							calibration[headerType].headers[index][tagIndex] = {
								value: null,
								tagUUID: tagUUID,
							};
						}
					);
				}

				const selectedTagGroup =
					axisMetadata.calibration?.cellTagGroup?.uuid;

				// trigger metadata dialog
				this.isDialogOpen = true;
				const header = await vm
					.captureMetadata({
						axisType: "tableHeader",
						compositeKeys: wpd.tagGroups.compositeKeys,
						data: calibration[headerType].headers[index],
						forceDirection:
							headerType === "rows" ? "east" : "south",
						position: finalPosition,
						limitTagGroups: selectedTagGroup
							? [selectedTagGroup]
							: [],
						studyArmAbbreviations:
							wpd.tagGroups.getAllStudyArmKeys(),
					})
					.then(async (metadata) => {
						this.isDialogOpen = false;
						return metadata;
					});

				// filter incomplete tags (missing either the tag or the value) from the set
				let startIndex = 0;
				const filteredHeader = Object.entries(header).reduce(
					(acc, [key, value]) => {
						if (wpd.utils.isWord(value, key)) {
							acc[key] = value;
						} else if (
							value.tag &&
							!_.isEmpty(value.tag) &&
							!wpd.utils.isEmptyValue(value.value) &&
							!value.headerTag
						) {
							acc[startIndex++] = value;
						}
						return acc;
					},
					{}
				);

				// update hidden data points on all corresponding datasets
				const axisDatasets = wpd.appData
					.getPlotData()
					.getDatasetsForAxis(axis);

				// handle composite key metadata
				if (reworkEnabled) {
					const studyKeyTag = _.find(wpd.tagGroups.tags, {
						name: wpd.CompositeKeyUtils.studyTag,
					});
					const partKeyTag = _.find(wpd.tagGroups.tags, {
						name: wpd.CompositeKeyUtils.studyPartTag,
					});
					const armKeyTag = _.find(wpd.tagGroups.tags, {
						name: wpd.CompositeKeyUtils.studyArmTag,
					});
					const populationKeyTag = _.find(wpd.tagGroups.tags, {
						name: wpd.CompositeKeyUtils.populationTag,
					});

					let maxIndex = Math.max(
						...Object.keys(filteredHeader)
							.filter((key) => !isNaN(key))
							.map((key) => parseInt(key, 10))
					);

					const extraData = [];
					let hasKey = false;
					Object.entries(filteredHeader).forEach(([key, data]) => {
						if (wpd.utils.isWord(data, key)) {
							return;
						}
						const isKey = wpd.tagGroups.isKeyTag(data.tag.name);
						if (isKey) {
							hasKey = true;
							filteredHeader.compositeKey = data.value;
							const parsedKey =
								wpd.CompositeKeyUtils.parseCompositeKey(
									data.value
								);
							data.isCompositeKey = true;
							switch (data.tag.name) {
								case wpd.CompositeKeyUtils.subgroupTag:
									data.value = parsedKey.subgroup;

									if (parsedKey.population) {
										extraData.push(
											_buildHeaderMetadataPoint(
												populationKeyTag,
												parsedKey.population
											)
										);
									}

									extraData.push(
										_buildHeaderMetadataPoint(
											armKeyTag,
											parsedKey.studyArm
										)
									);

									if (parsedKey.studyPart) {
										extraData.push(
											_buildHeaderMetadataPoint(
												partKeyTag,
												parsedKey.studyPart
											)
										);
									}

									extraData.push(
										_buildHeaderMetadataPoint(
											studyKeyTag,
											parsedKey.study
										)
									);

									break;
								case wpd.CompositeKeyUtils.populationTag:
									data.value = parsedKey.population;
									extraData.push(
										_buildHeaderMetadataPoint(
											armKeyTag,
											parsedKey.studyArm
										)
									);

									if (parsedKey.studyPart) {
										extraData.push(
											_buildHeaderMetadataPoint(
												partKeyTag,
												parsedKey.studyPart
											)
										);
									}

									extraData.push(
										_buildHeaderMetadataPoint(
											studyKeyTag,
											parsedKey.study
										)
									);

									break;
								case wpd.CompositeKeyUtils.studyArmTag:
									data.value = parsedKey.studyArm;
									if (parsedKey.studyPart) {
										extraData.push(
											_buildHeaderMetadataPoint(
												partKeyTag,
												parsedKey.studyPart
											)
										);
									}

									extraData.push(
										_buildHeaderMetadataPoint(
											studyKeyTag,
											parsedKey.study
										)
									);

									break;
								case wpd.CompositeKeyUtils.studyPartTag:
									data.value = parsedKey.studyPart;
									extraData.push(
										_buildHeaderMetadataPoint(
											studyKeyTag,
											parsedKey.study
										)
									);
									break;
								case wpd.CompositeKeyUtils.studyTag:
									data.value = parsedKey.study;
									break;
							}
						}
					});

					if (extraData.length > 0) {
						extraData.forEach((data) => {
							filteredHeader[++maxIndex] = data;
						});
					} else if (!hasKey) {
						// remove composite key if necessary
						delete filteredHeader.compositeKey;
					}
				}

				// check if there is any remaining valid metadata
				const hasValidMetadata = Object.keys(filteredHeader).some(
					(key) => !wpd.utils.isWord(filteredHeader[key], key)
				);

				// set both metadata and local calibration to enable repainting
				if (!hasValidMetadata) {
					// set header to null if nothing was specified
					axisMetadata.calibration[headerType].headers[index] = null;
					calibration[headerType].headers[index] = null;
				} else {
					axisMetadata.calibration[headerType].headers[index] =
						filteredHeader;
					calibration[headerType].headers[index] = filteredHeader;
				}

				// write the metadata to the axis
				axis.setMetadata(axisMetadata);

				const yHeaders = axisMetadata.calibration.cols.headers;
				const xHeaders = axisMetadata.calibration.rows.headers;

				// update all existing table cell data with newly set header
				// data
				switch (headerType) {
					case "cols":
						// get dataset associated with the axis and col index
						const dataset = _.find(axisDatasets, [
							"_metadata.colIndex",
							index,
						]);
						const currentYHeader = yHeaders[index];

						_.each(dataset.getAllPixels(), (dataPoint) => {
							// each data point here belongs to a separate row
							// delete header data from each cell down the column
							removeHeaderDataFromDataPoint(dataPoint);

							// tag-value was added by via header tag, update all
							// header tags to current column header and re-add
							// all row headers
							const currentXHeader =
								xHeaders[dataPoint.metadata.rowIndex];
							const indexes = Object.keys(
								_.omitBy(dataPoint.metadata, wpd.utils.isWord)
							);

							addHeaderDataToDataPoint(
								dataPoint,
								currentXHeader,
								currentYHeader,
								indexes
							);
						});

						break;
					case "rows":
						// each dataset associated with the axis is a column
						// loop through each column to access and update rows
						const currentXHeader = xHeaders[index];

						_.each(axisDatasets, (dataset) => {
							const datasetDataPoints = dataset.getAllPixels();

							if (
								_.findIndex(datasetDataPoints, [
									"metadata.rowIndex",
									index,
								]) < 0
							) {
								// skip the column (dataset) if there isn't a
								// data point in the current row
								return;
							}

							const dataPoint = _.find(datasetDataPoints, [
								"metadata.rowIndex",
								index,
							]);

							// delete header data from each cell down the row
							removeHeaderDataFromDataPoint(dataPoint);

							// tag-value was added by via header tag, update all
							// header tags to current row header and re-add
							// all column headers
							const currentYHeader =
								yHeaders[dataset.getMetadata().colIndex];
							const indexes = Object.keys(
								_.omitBy(dataPoint.metadata, wpd.utils.isWord)
							);

							addHeaderDataToDataPoint(
								dataPoint,
								currentXHeader,
								currentYHeader,
								indexes
							);
						});

						break;
				}

				// clear selected row and column
				selectedRow = null;
				selectedCol = null;

				if (dataEntryVM.fixMetadataAutoSave) {
					await dataEntryVM.autoSaveMetadata();
				}

				// trigger next empty header
				if (hasValidMetadata) {
					selectNextEmptyHeader(index, headerType);
				} else {
					// repaint if nothing was entered or existing data was deleted
					hover.header[headerType] = null;
					wpd.graphicsWidget.forceHandlerRepaint();
				}
			};

			const _buildHeaderMetadataPoint = (tag, value) => {
				return {
					tag: {
						name: tag.name,
						uuid: tag.uuid,
					},
					value: value,
					hidden: true,
					headerTag: true,
				};
			};

			const selectNextEmptyHeader = (index, headerType) => {
				// last header or index out of bounds, immediately repaint and exit
				if (index >= calibration[headerType].headers.length - 1) {
					hover.header[headerType] = null;
					wpd.graphicsWidget.forceHandlerRepaint();
					return;
				}

				for (
					let i = index;
					i < calibration[headerType].headers.length;
					i++
				) {
					if (calibration[headerType].headers[i] === null) {
						// set selected row or column
						if (headerType === "rows") {
							selectedRow = i;
						} else {
							selectedCol = i;
						}

						// set hover
						hover.header[headerType] = i;

						// repaint to update hover
						wpd.graphicsWidget.forceHandlerRepaint();

						// trigger header dialog
						setTimeout(function () {
							captureHeader(headerType);
						}, 200);

						return;
					}
				}
			};

			const intersectsRowHeaders = (imagePos, axis) => {
				const width = avgCellDimensions[axis];

				const tests = {
					0:
						imagePos.x > areaMin.x - width * 3 &&
						imagePos.x < areaMin.x &&
						imagePos.y > areaMin.y &&
						imagePos.y < areaMax.y,
					90:
						imagePos.y < areaMax.y + width * 3 &&
						imagePos.y > areaMax.y &&
						imagePos.x > areaMin.x &&
						imagePos.x < areaMax.x,
					180:
						imagePos.x < areaMax.x + width * 3 &&
						imagePos.x > areaMax.x &&
						imagePos.y > areaMin.y &&
						imagePos.y < areaMax.y,
					270:
						imagePos.y > areaMin.y - width * 3 &&
						imagePos.y < areaMin.y &&
						imagePos.x > areaMin.x &&
						imagePos.x < areaMax.x,
				};

				return tests[rotation];
			};

			const intersectsColHeaders = (imagePos, axis) => {
				const height = avgCellDimensions[axis];

				const tests = {
					0:
						imagePos.x > areaMin.x &&
						imagePos.x < areaMax.x &&
						imagePos.y > areaMin.y - height * 3 &&
						imagePos.y < areaMin.y,
					90:
						imagePos.y > areaMin.y &&
						imagePos.y < areaMax.y &&
						imagePos.x > areaMin.x - height * 3 &&
						imagePos.x < areaMin.x,
					180:
						imagePos.x > areaMin.x &&
						imagePos.x < areaMax.x &&
						imagePos.y < areaMax.y + height * 3 &&
						imagePos.y > areaMax.y,
					270:
						imagePos.y > areaMin.y &&
						imagePos.y < areaMax.y &&
						imagePos.x < areaMax.x + height * 3 &&
						imagePos.x > areaMax.x,
				};

				return tests[rotation];
			};

			const _getIndex = (position, start, end, dividers) => {
				// for ease
				const zones = [start, ...dividers, end];

				// change comparison based on direction
				const desc = end < start;
				const compare = (point, divider) =>
					desc ? divider >= point : point >= divider;

				let index = 0;
				let low = 0;
				let high = zones.length;

				// last column
				if (compare(position, zones[high])) {
					low = high;
				}

				// do a binary search
				while (high - low > 1) {
					index = low + Math.floor((high - low) / 2);

					if (compare(position, zones[index])) {
						low = index;
					} else {
						high = index;
					}
				}

				return low;
			};

			const getIndex = (imagePos, axis, dividers) => {
				// make sure start and end are in the correct order
				const endpoints =
					topLeft[axis] === "+"
						? [areaMax[axis], areaMin[axis]]
						: [areaMin[axis], areaMax[axis]];

				return _getIndex(imagePos[axis], ...endpoints, dividers);
			};

			this.onAttach = function () {
				wpd.graphicsWidget.resetData();

				// set the repainter
				wpd.graphicsWidget.setRepainter(
					new wpd.TableRepainter({
						area: area,
						rows: rows,
						cols: cols,
						hover: hover,
						rotation: rotation,
					})
				);

				// paint the loaded data
				wpd.graphicsWidget.forceHandlerRepaint();
			};

			this.onMouseMove = function (e, pos, imagePos) {
				const previousHoverRow = hover.header.rows;
				const previousHoverCol = hover.header.cols;

				// process row header hover
				if (!calibration.rows.headerless) {
					const axis = swapXY ? "x" : "y";

					if (intersectsRowHeaders(imagePos, axis)) {
						hover.header.rows = getIndex(
							imagePos,
							axis,
							rows.dividers
						);

						if (previousHoverRow !== hover.header.rows) {
							// repaint the table on hover change
							wpd.graphicsWidget.forceHandlerRepaint();
						}
					} else {
						if (previousHoverRow !== null) {
							hover.header.rows = null;

							// repaint the table on hover change
							wpd.graphicsWidget.forceHandlerRepaint();
						}
					}
				}

				// process column header hover
				if (!calibration.cols.headerless) {
					const axis = swapXY ? "y" : "x";

					if (intersectsColHeaders(imagePos, axis)) {
						hover.header.cols = getIndex(
							imagePos,
							axis,
							cols.dividers
						);

						if (previousHoverCol !== hover.header.cols) {
							// repaint the table on hover change
							wpd.graphicsWidget.forceHandlerRepaint();
						}
					} else {
						if (previousHoverCol !== null) {
							hover.header.cols = null;

							// repaint the table on hover change
							wpd.graphicsWidget.forceHandlerRepaint();
						}
					}
				}
			};

			this.onMouseClick = function (e, pos, imagePos) {
				const rowAxis = swapXY ? "x" : "y";
				const colAxis = swapXY ? "y" : "x";

				if (
					!calibration.rows.headerless &&
					intersectsRowHeaders(imagePos, rowAxis)
				) {
					// check if mouse position is currently over row headers
					selectedRow = getIndex(imagePos, rowAxis, rows.dividers);
					selectedCol = null;

					captureHeader("rows");
				} else if (
					!calibration.cols.headerless &&
					intersectsColHeaders(imagePos, colAxis)
				) {
					// check if mouse position is currently over column headers
					selectedCol = getIndex(imagePos, colAxis, cols.dividers);
					selectedRow = null;

					captureHeader("cols");
				}
			};
		};

		return Tool;
	})(dataEntryVM);

	/**
	 * Table cell editing tool for tabular data.
	 * @return {Function} WPD Tool
	 */
	wpd.TableCellSelectionTool = (function (vm) {
		const Tool = function (axis, activeCol) {
			const datasets = wpd.appData.getPlotData().getDatasetsForAxis(axis);
			const dataset = datasets[activeCol];

			const calibration = axis.getMetadata().calibration;
			const area = calibration.area;
			const rows = calibration.rows;
			const cols = calibration.cols;
			const rotation = calibration.rotation ?? 0;
			const numRows = calibration.rows.num;
			const numCols = calibration.cols.num;
			const schema = calibration.schema;
			const reworkEnabled = dataEntryVM.reworkEnabled;

			let lock = false;
			let nextCell = false;

			const container = wpdDocument.getElementById("graphicsContainer");

			// relationship of directions of the x and y axes on the image to the rotated orientation
			const topLeft = ((rotation) => {
				const directionMap = {
					0: { x: "-", y: "-" },
					90: { x: "-", y: "+" },
					180: { x: "+", y: "+" },
					270: { x: "+", y: "-" },
				};

				return directionMap[rotation];
			})(rotation);

			// swap mouse x and y if calibration rotation is 90 or 270 degrees
			const swapXY = (degrees) => degrees % 180 === 90;

			// get area min/max values to always have the top-left and bottom-right corners
			const getMinMax = (points, mode) => {
				if (mode === "min") {
					return {
						x:
							points[0].x > points[1].x
								? points[1].x
								: points[0].x,
						y:
							points[0].y > points[1].y
								? points[1].y
								: points[0].y,
					};
				} else {
					return {
						x:
							points[0].x > points[1].x
								? points[0].x
								: points[1].x,
						y:
							points[0].y > points[1].y
								? points[0].y
								: points[1].y,
					};
				}
			};
			const areaMin = getMinMax(area, "min");
			const areaMax = getMinMax(area, "max");

			const rowAxis = swapXY(rotation) ? "x" : "y";
			const colAxis = swapXY(rotation) ? "y" : "x";

			const getCellWidth = (index, screenMode) => {
				const ends =
					topLeft[colAxis] === "-"
						? [areaMin[colAxis], areaMax[colAxis]]
						: [areaMax[colAxis], areaMin[colAxis]];

				let x0 = index === 0 ? ends[0] : cols.dividers[index - 1];
				let x1 =
					index === cols.dividers.length
						? ends[1]
						: cols.dividers[index];

				if (screenMode) {
					const currentRotation = wpd.graphicsWidget.getRotation();
					x0 = getRotatedScreenCoordinates({ x: x0, y: 0 })[
						swapXY(currentRotation) ? rowAxis : colAxis
					];
					x1 = getRotatedScreenCoordinates({ x: x1, y: 0 })[
						swapXY(currentRotation) ? rowAxis : colAxis
					];
				}

				return x1 - x0;
			};

			const getCellHeight = (index, screenMode) => {
				const ends =
					topLeft[rowAxis] === "-"
						? [areaMin[rowAxis], areaMax[rowAxis]]
						: [areaMax[rowAxis], areaMin[rowAxis]];

				let y0 = index === 0 ? ends[0] : rows.dividers[index - 1];
				let y1 =
					index === rows.dividers.length
						? ends[1]
						: rows.dividers[index];

				if (screenMode) {
					const currentRotation = wpd.graphicsWidget.getRotation();
					y0 = getRotatedScreenCoordinates({ x: 0, y: y0 })[
						swapXY(currentRotation) ? colAxis : rowAxis
					];
					y1 = getRotatedScreenCoordinates({ x: 0, y: y1 })[
						swapXY(currentRotation) ? colAxis : rowAxis
					];
				}

				return y1 - y0;
			};

			// locally store data from all data sets, organized by columns and rows
			const colData = [];

			// initialize empty cells set
			// empty cells are locally stored as sets, but written to dataset metadata as arrays (json)
			const emptyCells = new Set(dataset.getMetadata().emptyCells || []);

			// maps row indexes to dataset indexes
			const activeIndexMap = [];

			const hover = {
				cell: {
					rows: null,
					cols: null,
				},
			};

			const activeCell = {
				row: -1,
				col: activeCol,
			};

			// existing tags cache
			const existingTags = [];

			const _getIndex = (position, start, end, dividers) => {
				// for ease
				const zones = [start, ...dividers, end];

				// change comparison based on direction
				const desc = end < start;
				const compare = (point, divider) =>
					desc ? divider >= point : point >= divider;

				let index = 0;
				let low = 0;
				let high = zones.length;

				// last column
				if (compare(position, zones[high])) {
					low = high;
				}

				// do a binary search
				while (high - low > 1) {
					index = low + Math.floor((high - low) / 2);

					if (compare(position, zones[index])) {
						low = index;
					} else {
						high = index;
					}
				}

				return low;
			};

			const getIndex = (imagePos, axis, dividers) => {
				// make sure start and end are in the correct order
				const endpoints =
					topLeft[axis] === "+"
						? [areaMax[axis], areaMin[axis]]
						: [areaMin[axis], areaMax[axis]];

				return _getIndex(imagePos[axis], ...endpoints, dividers);
			};

			const intersectsArea = (imagePos) => {
				return (
					imagePos.x > areaMin.x &&
					imagePos.x < areaMax.x &&
					imagePos.y > areaMin.y &&
					imagePos.y < areaMax.y
				);
			};

			const getCellImagePosition = () => {
				const cellHeight = getCellHeight(activeCell.row);
				const cellWidth = getCellWidth(activeCell.col);

				// center of cells based on rotation
				const cellPositions = {
					0: {
						x:
							(activeCell.col === 0
								? areaMin.x
								: cols.dividers[activeCell.col - 1]) +
							cellWidth / 2,
						y:
							(activeCell.row === 0
								? areaMin.y
								: rows.dividers[activeCell.row - 1]) +
							cellHeight / 2,
					},
					90: {
						x:
							(activeCell.row === 0
								? areaMin.x
								: rows.dividers[activeCell.row - 1]) +
							cellHeight / 2,
						y:
							(activeCell.col === 0
								? areaMin.y
								: cols.dividers[activeCell.col - 1]) +
							cellWidth / 2,
					},
					180: {
						x:
							(activeCell.col === 0
								? areaMax.x
								: cols.dividers[activeCell.col - 1]) +
							cellWidth / 2,
						y:
							(activeCell.row === 0
								? areaMax.y
								: rows.dividers[activeCell.row - 1]) +
							cellHeight / 2,
					},
					270: {
						x:
							(activeCell.row === 0
								? areaMax.x
								: rows.dividers[activeCell.row - 1]) +
							cellHeight / 2,
						y:
							(activeCell.col === 0
								? areaMax.y
								: cols.dividers[activeCell.col - 1]) +
							cellWidth / 2,
					},
				};

				return cellPositions[rotation];
			};

			const getPixel = () => {
				let pixel;

				if (colData[activeCell.col][activeCell.row]) {
					// cell has existing data
					pixel = colData[activeCell.col][activeCell.row];
				} else {
					// get center of cell
					const { x, y } = getCellImagePosition();

					// empty cell, create data point
					const pixelIndex = dataset.addPixel(x, y, {});
					pixel = dataset.getPixel(pixelIndex);

					// add to local data
					colData[activeCell.col][activeCell.row] = pixel;
					activeIndexMap[activeCell.row] = pixelIndex;
				}

				return pixel;
			};

			const hideNoHeaderHint = () => {
				vm.hideNoHeaderHint();
			};

			const showNoHeaderHint = (row, col) => {
				const cellPosition = getCellImagePosition();

				const screenPosition =
					getRotatedScreenCoordinates(cellPosition);

				// include scroll positions from wpd graphics container
				const finalPosition = adjustForScroll(screenPosition);

				let mode = "";
				if (rows.headers[row] === null && cols.headers[col] === null) {
					mode = "both";
				} else if (rows.headers[row] === null) {
					mode = "row";
				} else if (cols.headers[col] === null) {
					mode = "col";
				}

				vm.showNoHeaderHint({
					mode: mode,
					position: finalPosition,
				});
			};

			const selectColumn = (index, skipMetadataDialog) => {
				// only allow selection if column header is defined
				if (
					calibration.cols.headers[index] ||
					(calibration.cols.headerless &&
						calibration.rows.headers[activeCell.row])
				) {
					hideNoHeaderHint();

					// set rowIndex on the selected dataset
					datasets[index].setMetadata(
						_.assign(datasets[index].getMetadata(), {
							selectRowIndex: activeCell.row,
							skipMetadataDialog: skipMetadataDialog,
						})
					);

					// switch column, find all tree items and match on name
					wpd.tree.selectDataset(datasets[index].name);
				} else {
					showNoHeaderHint(activeCell.row, index);
				}
			};

			const selectNextCell = (index) => {
				// at last row index already, immediately repaint and exit
				if (index >= numRows - 1) {
					wpd.custom.repaintDataPoints(wpd.tree.getActiveDataset());

					// release lock and allow other interactions
					lock = false;

					return;
				}

				activeCell.row++;

				// set hover
				hover.cell.rows = activeCell.row;
				hover.cell.cols = activeCell.col;

				// update zoomed image
				const imagePos = getCellImagePosition();
				wpd.graphicsWidget.updateZoomToImagePosn(
					imagePos.x,
					imagePos.y
				);

				// repaint to update hover
				wpd.custom.repaintDataPoints(wpd.tree.getActiveDataset());

				// otherwise, release lock and allow other interactions
				lock = false;
			};

			const collectTags = (metadata) => {
				const tags = _.chain(metadata)
					.omitBy(wpd.utils.isWord)
					.filter((data) => !data.hidden)
					.map("tag")
					// filter out tags without meaningful data
					.filter((tag) => tag.name && tag.uuid)
					.value();

				if (existingTags.length === 0) {
					// empty existing tags, fill with tags
					existingTags.push(...tags);
				} else {
					if (!_.isEqual(existingTags, tags)) {
						// existingTags and tags are not deeply equal, find differences
						// cannot use _.difference since we want to detect duplicates as well
						const existingTagCounts = _.countBy(
							existingTags,
							"uuid"
						);
						const tagCounts = _.countBy(tags, "uuid");

						// compare counts based on the new tags, existingTags are additive only
						_.keys(tagCounts).forEach((uuid) => {
							const difference =
								tagCounts[uuid] >
								(existingTagCounts[uuid] || 0);

							if (difference) {
								// tag does not exist in existingTags or new count is greater than the existing
								// add the difference
								const newTags = new Array(difference);
								newTags.fill(_.find(tags, { uuid: uuid }));
								existingTags.push(...newTags);
							}
						});
					}
					// do nothing if tags and existingTags are deeply equal
				}
			};

			const refreshTags = () => {
				// clear cached existing tags and values and recalculate
				existingTags.length = 0;

				// loop through every dataset (column)
				datasets.forEach((dataset, index) => {
					// loop through each existing data point (row)
					dataset.getAllPixels().forEach((pixel) => {
						// collect all existing tags
						switch (schema) {
							case "rows":
								if (
									pixel.metadata.rowIndex === activeCell.row
								) {
									collectTags(pixel.metadata);
								}
								break;
							case "cols":
								if (index === activeCell.col) {
									collectTags(pixel.metadata);
								}
								break;
							default:
								collectTags(pixel.metadata);
								break;
						}
					});
				});
			};

			const refreshData = () => {
				// fill in colData with empty arrays, each representing a column
				// NOTE cannot use Array.prototype.fill here, it does not create a new array for each slot
				colData.length = datasets.length;
				for (let i = 0; i < colData.length; i++) {
					colData[i] = [];
				}

				// each dataset is a column
				for (let i = 0; i < datasets.length; i++) {
					const pixels = datasets[i].getAllPixels();

					// for the active column, initialize empty dataset if necessary
					if (i === activeCell.col && pixels.length < 1) {
						datasets[i].setMetadataKeys(["rowIndex"]);
					}

					// reset local column data to nulls
					colData[i].length = numRows;
					colData[i].fill(null, 0);

					// check for cells marked as empty
					const datasetMetadata = datasets[i].getMetadata();
					if (datasetMetadata.emptyCells) {
						datasetMetadata.emptyCells.forEach(
							(j) => (colData[i][j] = undefined)
						);
					}

					// for the active column, reset local column data to dataset index map and set to nulls
					if (i === activeCell.col) {
						activeIndexMap.length = numRows;
						activeIndexMap.fill(null, 0);
					}

					// organize local column data in order of row indexes
					pixels.forEach((pixel, index) => {
						// fill in colData
						colData[i][pixel.metadata.rowIndex] = pixel;

						// for the active column, store dataset pixel index in a map
						if (i === activeCell.col) {
							activeIndexMap[pixel.metadata.rowIndex] = index;
						}
					});
				}
			};

			const addEmptyCell = (row) => {
				// add to current column
				emptyCells.add(row);

				// save to dataset metadata immediately
				saveEmptyCells();
			};

			const removeEmptyCell = (row) => {
				emptyCells.delete(row);

				// save to dataset metadata immediately
				saveEmptyCells();
			};

			const saveEmptyCells = () => {
				const metadata = dataset.getMetadata();

				if (emptyCells.size) {
					// convert set to array and overwrite existing metadata with local data
					metadata.emptyCells = Array.from(emptyCells);
				} else {
					// remove empty cells key from metadata if no local data
					delete metadata.emptyCells;
				}

				dataset.setMetadata(metadata);
			};

			const adjustForScroll = (position) => {
				const canvasDiv = wpdDocument.getElementById("canvasDiv");
				const marginLeftText =
					wpdWindow.getComputedStyle(canvasDiv).marginLeft;
				const marginLeft = parseInt(
					marginLeftText.replace("px", ""),
					10
				);
				return {
					x: position.x - container.scrollLeft + marginLeft,
					y: position.y - container.scrollTop,
				};
			};

			const getRotatedScreenCoordinates = (coordinates) => {
				const currentRotation = wpd.graphicsWidget.getRotation();
				const { x, y } = wpd.graphicsWidget.getRotatedCoordinates(
					0,
					currentRotation,
					coordinates.x,
					coordinates.y
				);

				return wpd.graphicsWidget.screenPx(x, y);
			};

			const scrollCellIntoView = (position) => {
				const cellHeight = Math.abs(
					getCellHeight(activeCell.row, true)
				);
				const cellWidth = Math.abs(getCellWidth(activeCell.col, true));
				const currentRotation = wpd.graphicsWidget.getRotation();

				const rotatedHeight = swapXY(currentRotation)
					? cellWidth
					: cellHeight;
				const rotatedWidth = swapXY(currentRotation)
					? cellHeight
					: cellWidth;

				const cellTop = position.y - rotatedHeight / 2;
				const cellRight = position.x + rotatedWidth / 2;
				const cellBottom = position.y + rotatedHeight / 2;
				const cellLeft = position.x - rotatedWidth / 2;

				// define viewport boundaries
				const top = container.scrollTop;
				const right = container.scrollLeft + container.clientWidth;
				const bottom = container.scrollTop + container.clientHeight;
				const left = container.scrollLeft;

				let x = container.scrollLeft;
				let y = container.scrollTop;

				// determine y scroll
				const paddedCellBottom = cellBottom + rotatedHeight;
				const paddedCellTop = cellTop - rotatedHeight;
				if (paddedCellBottom > bottom) {
					y = paddedCellBottom - container.clientHeight;
				} else if (paddedCellTop < top) {
					y = paddedCellTop;
				}

				// y scroll safeguards
				// if negative, set to 0; if greater than max, set to max
				const maxY = container.scrollHeight - container.clientHeight;
				y = Math.floor(y < 0 ? 0 : y > maxY ? maxY : y);

				// determine x scroll
				const paddedCellRight = cellRight + rotatedWidth;
				const paddedCellLeft = cellLeft - rotatedWidth;
				if (paddedCellRight > right) {
					x = paddedCellRight - container.clientWidth;
				} else if (paddedCellLeft < left) {
					x = paddedCellLeft;
				}

				// x scroll safeguards
				// if negative, set to 0; if greater than max, set to max
				const maxX = container.scrollWidth - container.clientWidth;
				x = Math.floor(x < 0 ? 0 : x > maxX ? maxX : x);

				return new Promise((resolve, reject) => {
					const guard = setTimeout(() => {
						container.removeEventListener("scroll", scrollHandler);
						reject("Auto-scroll timeout");
					}, 2000);

					const scrollHandler = _.debounce(() => {
						// scrollTop and scrollLeft may not be exact, check if within +/- 5 pixels
						if (
							Math.abs(x - Math.floor(container.scrollLeft)) <
								5 &&
							Math.abs(y - Math.floor(container.scrollTop)) < 5
						) {
							// stop guard, remove listener, and resolve promise
							clearTimeout(guard);
							container.removeEventListener(
								"scroll",
								scrollHandler
							);
							resolve();
						}
					}, 100);

					// only scroll if necessary
					if (
						x !== Math.floor(container.scrollLeft) ||
						y !== Math.floor(container.scrollTop)
					) {
						// listen for scroll event and compare current scroll to target
						container.removeEventListener("scroll", scrollHandler);
						container.addEventListener("scroll", scrollHandler);

						// scroll
						container.scrollTo({
							top: y,
							left: x,
							behavior: "smooth",
						});
					} else {
						resolve();
					}
				});
			};

			const addHeaderTagsToCell = (axesMetadata, metadata) => {
				// gather x and y header data
				const yHeaders = axesMetadata.calibration.cols.headers;
				const currentYHeader = yHeaders[activeCell.col];
				const xHeaders = axesMetadata.calibration.rows.headers;
				const currentXHeader = xHeaders[activeCell.row];
				const xHeaderValues = Object.values(
					_.omitBy(currentXHeader, wpd.utils.isWord)
				);
				const yHeaderValues = Object.values(
					_.omitBy(currentYHeader, wpd.utils.isWord)
				);
				const indexes = Object.keys(
					_.omitBy(metadata, wpd.utils.isWord)
				);
				const nextIndex = Math.max(...indexes.map(Number)) + 1;

				const newHeaderValues = [...xHeaderValues, ...yHeaderValues];
				for (let i = 0; i < newHeaderValues.length; i++) {
					metadata[i + nextIndex] = {
						...newHeaderValues[i],
						hidden: true,
						headerTag: true,
					};
				}

				if (reworkEnabled) {
					const compositeKey =
						currentYHeader?.compositeKey ??
						currentXHeader?.compositeKey;

					if (compositeKey) {
						metadata.compositeKey = compositeKey;
					}
				}
			};

			const addTagGroupToCell = (axesMetadata, metadata) => {
				if (
					!axesMetadata.calibration ||
					!axesMetadata.calibration.cellTagGroup
				) {
					throw new Error("Tag group not assigned for table cells");
				}

				metadata.tagGroup = {
					instanceUuid: uuidv4(),
					name: axesMetadata.calibration.cellTagGroup.name,
					uuid: axesMetadata.calibration.cellTagGroup.uuid,
				};
			};

			const findReference = (header) =>
				Object.values(_.omitBy(header, wpd.utils.isWord)).find(
					(data) =>
						data.tag.name === wpd.CompositeKeyUtils.referenceArmTag
				);

			const captureData = (pixel) => {
				const cellPosition = getCellImagePosition();

				const screenPosition =
					getRotatedScreenCoordinates(cellPosition);

				// scroll cell into view if necessary
				scrollCellIntoView(screenPosition)
					.catch((error) => console.error(error))
					.finally(() => {
						// include scroll positions from wpd graphics container
						const finalPosition = adjustForScroll(screenPosition);

						// strip rowIndex from existing data point before sending it to VMetadataDialog
						delete pixel.metadata.rowIndex;

						// add point groups as necessary
						if (dataset.hasPointGroups()) {
							const pointGroupUUIDs = dataset.getPointGroups();

							if (!_.isEmpty(pixel.metadata)) {
								// check if all point groups are tags
								for (const uuid of pointGroupUUIDs) {
									const ok = Object.values(
										_.omitBy(
											pixel.metadata,
											wpd.utils.isWord
										)
									).some((data) => data?.tag?.uuid === uuid);

									// if a point group is not included as a tag on this data point, add it
									if (!ok) {
										// get largest index
										const indexes = Object.keys(
											_.omitBy(
												pixel.metadata,
												wpd.utils.isWord
											)
										);

										pixel.metadata[indexes.length] = {
											value: null,
											tagUUID: uuid,
										};
									}
								}
							} else {
								// new data point, insert point groups as tags
								for (const uuid of pointGroupUUIDs) {
									// get largest index
									const indexes = Object.keys(
										_.omitBy(
											pixel.metadata,
											wpd.utils.isWord
										)
									);

									pixel.metadata[indexes.length] = {
										value: null,
										tagUUID: uuid,
									};
								}
							}
						}

						// pre-fill new cells with tags
						let defaultTags = undefined;
						if (_.isEmpty(pixel.metadata)) {
							// refresh accelerator data
							refreshTags();

							if (existingTags.length > 0) {
								defaultTags = existingTags;
							}
						}

						// get existing non-numeric values for auto-suggest
						const suggestions =
							wpd.custom.getExistingNonNumericValues();

						// load marked as blank
						const blankTableCell = emptyCells.has(activeCell.row);

						// get x-offset
						const currentRotation =
							wpd.graphicsWidget.getRotation();
						const dX = swapXY(currentRotation)
							? getCellHeight(activeCell.row, true)
							: getCellWidth(activeCell.col, true);
						const xOffset = Math.abs(dX / 2);

						// setting a temp variable to row index to avoid out-of-
						// bounds indexes in other functions
						const rowIndex = activeCell.row;

						const axesMetadata = wpd.tree
							.getActiveAxes()
							.getMetadata();
						const selectedTagGroup =
							axesMetadata.calibration?.cellTagGroup?.uuid;

						const xReference = findReference(
							axesMetadata.calibration.rows.headers[
								activeCell.row
							]
						);
						const yReference = findReference(
							axesMetadata.calibration.cols.headers[
								activeCell.col
							]
						);

						// trigger metadata dialog
						this.isDialogOpen = true;

						const hasExistingHeaderData = Object.keys(
							pixel.metadata
						)
							.filter((key) => !isNaN(key))
							.find((key) => pixel.metadata[key].headerTag);

						vm.captureMetadata({
							axisType: "table",
							blankTableCell,
							compositeKeys: wpd.tagGroups.compositeKeys,
							defaultTags,
							data: pixel.metadata,
							excludeTags:
								reworkEnabled && (xReference || yReference)
									? [wpd.CompositeKeyUtils.referenceArmTag]
									: [],
							forceAxis: "x",
							offset: xOffset,
							position: finalPosition,
							container: dataset,
							indexes: [activeIndexMap[rowIndex]],
							rowIndex: rowIndex,
							suggestions,
							limitTagGroups: selectedTagGroup
								? [selectedTagGroup]
								: [],
							studyArmAbbreviations:
								wpd.tagGroups.getAllStudyArmKeys(),
						}).then(async (metadata) => {
							// set rowIndex on metadata
							metadata.rowIndex = rowIndex;
							if (reworkEnabled) {
								metadata.compositeKey =
									pixel.metadata.compositeKey;
							}

							const notEmpty =
								!_.isEmpty(metadata) &&
								Object.keys(
									_.omitBy(metadata, wpd.utils.isWord)
								).filter(
									(key) =>
										!metadata[key].hidden &&
										!wpd.utils.isEmptyValue(
											metadata[key].value
										)
								).length;

							if (notEmpty) {
								if (!hasExistingHeaderData) {
									addHeaderTagsToCell(axesMetadata, metadata);
								}
								addTagGroupToCell(axesMetadata, metadata);
								wpd.custom.saveDataPoint(
									dataset,
									activeIndexMap[rowIndex],
									metadata
								);

								// update empty cell map
								// metadata.blank should never be true in this case
								if (!metadata.blank) {
									removeEmptyCell(rowIndex);
								}

								if (reworkEnabled) {
									const referenceCompositeKey = Object.keys(
										_.omitBy(metadata, wpd.utils.isWord)
									).find(
										(key) =>
											metadata[key].tag.name ===
											wpd.CompositeKeyUtils
												.referenceArmTag
									);

									if (referenceCompositeKey) {
										metadata.referenceCompositeKey =
											metadata[
												referenceCompositeKey
											].value;
									}
								}
							} else {
								// no metadata, delete data point
								dataset.removePixelAtIndex(
									activeIndexMap[rowIndex]
								);

								// update empty cell map
								if (metadata.blank) {
									addEmptyCell(rowIndex);
								} else {
									removeEmptyCell(rowIndex);
								}
							}

							// sync metadata keys
							wpd.custom.syncMetadataKeys(dataset);

							// refresh local data
							refreshData();

							// move to next cell if necessary
							if (nextCell) {
								selectNextCell(activeCell.row);

								nextCell = false;
							} else {
								// repaint
								wpd.custom.repaintDataPoints(dataset);
							}

							if (dataEntryVM.fixMetadataAutoSave) {
								await dataEntryVM.autoSaveMetadata();
							}
							this.isDialogOpen = false;

							// release lock and allow other interactions
							lock = false;
						});
					});
			};

			const triggerActiveCell = () => {
				// trigger click handler with calculated cell position
				const imagePos = getCellImagePosition();
				this.onMouseMove(null, null, imagePos);
				this.onMouseClick(null, null, imagePos);
			};

			const hoverActiveCell = () => {
				hover.cell.rows = activeCell.row;
				hover.cell.cols = activeCell.col;
			};

			const nextCellHandler = () => {
				// event came from the vue app, the metadata
				// dialog was open, simply go to the next cell
				nextCell = true;
			};

			this.onAttach = function () {
				wpd.graphicsWidget.resetData();

				refreshData();

				// check for selected row index on active dataset
				const metadata = dataset.getMetadata();
				if (metadata.selectRowIndex !== undefined) {
					activeCell.row = metadata.selectRowIndex;

					hoverActiveCell();

					if (!metadata.skipMetadataDialog) {
						triggerActiveCell();
					}

					// delete selectRowIndex and set metadata
					delete metadata.selectRowIndex;
					delete metadata.skipMetadataDialog;
					dataset.setMetadata(metadata);
				}

				// set the repainter
				wpd.graphicsWidget.setRepainter(
					new wpd.TableRepainter({
						area: calibration.area,
						rows,
						cols,
						drawHeaders: false,
						activeCell,
						colData,
						hover,
						rotation,
					})
				);

				// paint the loaded data
				wpd.graphicsWidget.forceHandlerRepaint();

				// handle next-cell event
				wpdDocument.removeEventListener("next-cell", nextCellHandler);
				wpdDocument.addEventListener("next-cell", nextCellHandler);
			};

			this.onMouseMove = function (event, pos, imagePos) {
				if (lock) {
					return;
				}

				const previousHoverRow = hover.cell.rows;
				const previousHoverCol = hover.cell.cols;

				if (intersectsArea(imagePos)) {
					hover.cell.rows = getIndex(
						imagePos,
						rowAxis,
						rows.dividers
					);
					hover.cell.cols = getIndex(
						imagePos,
						colAxis,
						cols.dividers
					);

					if (
						previousHoverRow !== hover.cell.rows ||
						previousHoverCol !== hover.cell.cols
					) {
						// repaint the table on hover change
						wpd.graphicsWidget.forceHandlerRepaint();
					}
				} else {
					if (
						previousHoverRow !== null ||
						previousHoverCol !== null
					) {
						hover.cell.rows = null;
						hover.cell.cols = null;

						// repaint the table on hover change
						wpd.graphicsWidget.forceHandlerRepaint();
					}
				}
			};

			this.onMouseClick = function (event, pos, imagePos) {
				if (lock) {
					return;
				}

				if (intersectsArea(imagePos)) {
					const colIndex = getIndex(imagePos, colAxis, cols.dividers);
					activeCell.row = getIndex(imagePos, rowAxis, rows.dividers);

					if (colIndex === activeCell.col) {
						if (
							calibration.rows.headers[activeCell.row] ||
							calibration.rows.headerless
						) {
							// engage lock, prevent other interactions
							lock = true;

							hideNoHeaderHint();

							// trigger metadata dialog
							captureData(getPixel());
						} else {
							showNoHeaderHint(activeCell.row, colIndex);
						}

						wpd.graphicsWidget.forceHandlerRepaint();
					} else {
						// engage lock, prevent other interactions
						lock = true;

						// clicked on another column, switch to it
						selectColumn(colIndex);
					}
				} else {
					hideNoHeaderHint();
				}
			};

			this.onKeyDown = function (event) {
				lock = true;

				// default to row 0 if no active cell, assume there is always an
				// active column
				if (activeCell.row < 0) {
					if (
						_.includes(
							["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"],
							event.key
						)
					) {
						activeCell.row = 0;
						hoverActiveCell();
					}

					lock = false;
				} else {
					let moved = false;

					// handle keys
					switch (event.key) {
						case "ArrowUp":
							if (activeCell.row - 1 >= 0) {
								// move up by 1
								activeCell.row--;

								moved = true;
							}

							lock = false;

							break;
						case "ArrowDown":
							if (activeCell.row + 1 < numRows) {
								// move down by 1
								activeCell.row++;

								moved = true;
							}

							lock = false;

							break;
						case "ArrowLeft":
							if (activeCell.col - 1 >= 0) {
								// move left by 1
								activeCell.col--;

								moved = true;

								selectColumn(activeCell.col, true);
							} else {
								lock = false;
							}

							break;
						case "ArrowRight":
							if (activeCell.col + 1 < numCols) {
								// move right by 1
								activeCell.col++;

								moved = true;

								selectColumn(activeCell.col, true);
							} else {
								lock = false;
							}

							break;
						case "Enter":
							lock = false;

							if (!this.isDialogOpen) {
								triggerActiveCell();
							}

							break;
						case "Escape":
							lock = false;

							break;
						default:
							lock = false;
					}

					if (moved) {
						hoverActiveCell();
						const cellPosition = getCellImagePosition();
						const screenPosition =
							getRotatedScreenCoordinates(cellPosition);
						scrollCellIntoView(screenPosition);
					}
				}

				// redraw table
				wpd.graphicsWidget.forceHandlerRepaint();
			};
		};

		return Tool;
	})(dataEntryVM);
}

export { apply };
