<template>
	<v-card
		class="d-flex flex-column pb-5"
		:loading="loading"
	>
		<v-toolbar
			color="primary"
			dark
			flat
		>
			<v-toolbar-title>
				{{ selectParentMode ? selectParentTitle : title }}
			</v-toolbar-title>

			<v-spacer />

			<v-dialog
				v-model="dialog"
				max-width="800px"
				transition="fade-transition"
				@click:outside="clearSelectedTag"
			>
				<template #activator="{ props }">
					<v-btn
						v-show="!selectParentMode"
						v-bind="props"
						class="bg-secondary text-white mr-4"
						elevation="2"
					>
						<v-icon start> mdi-plus </v-icon>

						Add new tag
					</v-btn>
				</template>

				<tag-edit
					ref="tagEdit"
					:tag="activeTag"
					:parent-tag="activeParentTag"
					:dialog-select-parent="dialogSelectParent"
					@update:cancel="clearSelectedTag"
					@update:saved="clearSelectedTagAndRefresh"
					@update:parent="enterSelectParentMode"
				/>
			</v-dialog>
		</v-toolbar>

		<v-treeview
			ref="vTreeview"
			v-model:activated="active"
			:items="tags"
			:color="selectParentMode ? 'primary' : 'secondary'"
			item-disabled="disabled"
			item-title="name"
			item-children="children"
			activatable
			hoverable
			open-all
			return-object
			@update:activated="activated"
		>
			<template #append="{ item }">
				<div v-if="!selectParentMode">
					<v-btn
						v-if="!item.disabled"
						class="mr-3"
						color="primary"
						variant="outlined"
						@click="addChild($event, item)"
					>
						+ Add child
					</v-btn>

					<v-btn
						v-else
						class="mr-3"
						color="success"
						variant="outlined"
						@click="restore(item)"
					>
						Restore
					</v-btn>
				</div>
			</template>
		</v-treeview>
	</v-card>
</template>

<script>
import _ from "lodash";
import { mapMutations } from "vuex";
import TagEdit from "@/pages/BaseTagManager/TagEdit";
import { VTreeview } from "vuetify/labs/VTreeview";

export default {
	name: "TagTree",

	components: {
		TagEdit,
		VTreeview,
	},

	props: {
		tags: { type: Array, default: () => [] },
	},
	emits: [
		"update:refresh",
		"update:disable-tag",
		"update:parent-mode",
		"update:enable-tag",
	],

	data() {
		return {
			active: [],
			activeTag: null,
			activeParentTag: null,
			dialog: false,
			dialogSelectParent: false,
			isActivatedProcessing: false,
			loading: false,
			selectParentMode: false,
			selectParentTitle: "Select parent tag",
			title: "Base tags",
		};
	},

	methods: {
		...mapMutations(["showNotification"]),
		activated: function ([tag]) {
			if (this.isActivatedProcessing) return;
			this.isActivatedProcessing = true;
			if (tag) {
				if (this.selectParentMode) {
					if (this.activeTag) {
						this.activeTag.disabled = false;
						this.active = [this.activeTag.id];
					}

					this.activeParentTag = tag;

					this.exitSelectParentMode();
				} else {
					this.activeTag = tag;

					if (!_.isEmpty(tag)) {
						this.activeParentTag = this.findParentTag(
							this.tags,
							this.activeTag
						);
						this.dialogSelectParent = false;
						this.dialog = true;
					}
				}
			}

			this.$nextTick(() => {
				this.isActivatedProcessing = false;
			});
		},
		addChild: function (event, parent) {
			// prevent activation of tree item (parent)
			event.stopPropagation();

			if (parent) {
				this.activeTag = null;
				this.activeParentTag = parent;
				this.dialogSelectParent = false;
				this.dialog = true;
			}
		},
		clearSelectedTagAndRefresh: function () {
			this.clearSelectedTag();
			this.$emit("update:refresh");
		},
		clearSelectedTag: function () {
			this.dialog = false;
			setTimeout(() => {
				if (this.$refs.tagEdit) {
					this.$refs.tagEdit.clear();
				}

				this.active = [];
				this.activeTag = null;
				this.activeParentTag = null;
			}, 250);
		},
		enterSelectParentMode: function () {
			if (this.activeTag) {
				this.$emit("update:disable-tag", this.activeTag);
			}

			this.selectParentMode = true;
			this.dialog = false;
			this.active = [];

			this.$emit("update:parent-mode", this.selectParentMode);
		},
		exitSelectParentMode: function () {
			if (this.activeTag) {
				this.$emit("update:enable-tag", this.activeTag);
			}

			this.selectParentMode = false;
			this.dialogSelectParent = true;

			this.dialog = true;

			this.$emit("update:parent-mode", this.selectParentMode);
		},
		findParentTag: function (tags, activeTag) {
			for (const tag of tags) {
				if (tag.children) {
					if (tag.children.includes(activeTag)) {
						// found active tag, return current tag
						return tag;
					} else {
						// recurse
						const parent = this.findParentTag(
							tag.children,
							activeTag
						);

						if (parent) {
							return parent;
						}
					}
				}
			}

			return null;
		},
		refresh: function () {
			// this.$refs.vTreeview.updateAll(true);
		},
		setActiveTag: function (tag) {
			this.activeTag = tag;
		},
		restore(tag) {
			this.loading = "secondary";

			this.$http
				.put(`/tags/${tag.uuid}`, {
					undelete: true,
				})
				.then((response) => {
					// display success
					this.showNotification(response.data);

					this.clearSelectedTagAndRefresh();
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style scoped lang="scss">
:deep(.v-list.v-treeview .v-treeview-item:not(.v-list-group__header)) {
	padding-inline-start: calc(39px + var(--indent-padding)) !important;
}

:deep(.v-list.v-treeview .v-treeview-item .v-list-item__spacer) {
	width: 4px !important;
}
</style>
