<template>
	<v-autocomplete
		ref="autocomplete"
		v-model="userSelect"
		:disabled="disabled"
		:item-title="composedText"
		:items="users"
		:label="label"
		:loading="loading"
		:messages="noResults"
		append-icon=""
		clearable
		closable-chips
		hide-no-data
		hint="Type in keywords to begin searching"
		item-color="secondary"
		item-value="uuid"
		multiple
		persistent-hint
		prepend-icon="mdi-magnify"
		return-object
		density="compact"
	>
		<template #chip="{ item, index }">
			<v-chip
				class="bg-secondary text-white"
				closable
				@click:close="removeItem(index)"
			>
				{{ item.title }}
			</v-chip>
		</template>
	</v-autocomplete>
</template>

<script>
import _ from "lodash";
import utils from "@/utils";

import { mapMutations } from "vuex";

export default {
	name: "UserSearch",

	props: {
		defaultUsers: { type: Array, default: () => [] },
		disabled: Boolean,
		label: {
			default: "Search for users",
			type: String,
		},
		modelValue: { type: Array, default: () => [] },
	},
	emits: ["update:users-loading", "update:modelValue"],

	data() {
		return {
			loading: true,
			noResults: "",
			users: [],
			userSearch: null,
		};
	},

	computed: {
		userSelect: {
			get() {
				return this.modelValue;
			},
			set(newValue) {
				this.$emit("update:modelValue", newValue);
			},
		},
	},

	watch: {
		defaultUsers(val) {
			this.loadUsers(val);
		},
	},

	mounted() {
		this.queryUsers();
		this.loadUsers();
	},

	methods: {
		...mapMutations(["showNotification"]),
		clear() {
			this.loading = false;

			if (this.defaultUsers.length) {
				// revert to default projects if it exists
				this.loadUsers();
			}
		},
		queryUsers: _.debounce(function () {
			this.loading = "secondary";

			this.$http
				.get("/users")
				.then((response) => {
					this.users = response.data.users.data;
				})
				.finally(() => {
					this.loading = false;
				});
		}, 200),
		loadUsers() {
			if (this.defaultUsers.length) {
				this.userSelect = [...this.defaultUsers];
			}
		},
		composedText(user) {
			return utils.truncateText(
				`${user.last_name}, ${user.first_name} (${user.email})`,
				100
			);
		},
		doneLoading() {
			this.loading = false;
			this.$emit("update:users-loading", false);
		},
		removeItem(index) {
			this.userSelect = this.userSelect.filter((_, i) => i !== index);
		},
	},
};
</script>

<style scoped lang="scss">
:deep(.v-field__input) {
	min-height: 60px;
	padding-top: 20px;
}
</style>
