<template>
	<v-row
		align="start"
		align-content="start"
		justify="start"
		:loading="loading"
	>
		<v-col cols="12">
			<v-card class="d-flex flex-column pb-5">
				<v-toolbar
					color="primary"
					dark
					flat
				>
					<v-toolbar-title>{{ title }}</v-toolbar-title>
				</v-toolbar>

				<v-form
					ref="form"
					@submit.prevent="save"
				>
					<v-card-text>
						<v-expansion-panels
							:model-value="panel"
							multiple
						>
							<v-expansion-panel>
								<v-expansion-panel-title class="text-h6">
									Password
								</v-expansion-panel-title>

								<v-expansion-panel-text>
									<v-row>
										<v-col
											cols="4"
											style="min-width: 300px"
										>
											<v-container class="py-1 px-0">
												<password-input
													ref="password"
													v-model="password"
													v-model:password-valid="
														isPasswordValid
													"
													v-model:password-value="
														password
													"
													v-model:dirty="
														isPasswordDirty
													"
													:loading="!!loading"
													label="Current password"
													prepend-icon="mdi-lock"
													disable-meter
													disable-strength-check
													required
													@change="
														v$.password.$touch()
													"
												/>
											</v-container>
											<v-container class="py-1 px-0">
												<password-input
													ref="newPassword"
													v-model="newPassword"
													v-model:password-valid="
														isNewPasswordValid
													"
													v-model:password-value="
														newPassword
													"
													:loading="!!loading"
													label="New password"
													prepend-icon="mdi-lock-plus"
													meter-size="small"
													required
													@change="
														v$.newPassword.$touch()
													"
												/>
											</v-container>
											<v-container class="py-1 px-0">
												<password-input
													ref="newPasswordConfirm"
													v-model="newPasswordConfirm"
													v-model:password-valid="
														isNewPasswordConfirmValid
													"
													v-model:password-value="
														newPasswordConfirm
													"
													v-model:dirty="
														isNewPasswordConfirmDirty
													"
													:loading="!!loading"
													label="Confirm new password"
													prepend-icon="mdi-lock-plus"
													disable-meter
													disable-validation
													:error-messages="
														newPasswordConfirmErrors
													"
													required
													required-message="Re-enter new password"
													@change="
														v$.newPasswordConfirm.$touch()
													"
												/>
											</v-container>
										</v-col>
									</v-row>
								</v-expansion-panel-text>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-card-text>

					<v-card-actions>
						<v-spacer />

						<v-btn
							class="bg-secondary text-white"
							elevation="2"
							:disabled="
								v$.$invalid || !!loading || !isNewPasswordValid
							"
							@click="save"
						>
							Save
						</v-btn>

						<v-spacer />
					</v-card-actions>
				</v-form>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapMutations } from "vuex";
import { required, minLength, maxLength } from "@vuelidate/validators";
import PasswordInput from "@/components/VPasswordInput.vue";
import { useVuelidate } from "@vuelidate/core";

export default {
	name: "UserSettings",

	components: {
		PasswordInput,
	},

	setup() {
		return {
			v$: useVuelidate(),
		};
	},

	data() {
		return {
			isNewPasswordValid: false,
			isNewPasswordConfirmValid: false,
			isNewPasswordConfirmDirty: false,
			isPasswordDirty: false,
			isPasswordValid: false,
			loading: false,
			newPassword: "",
			newPasswordConfirm: "",
			panel: [0],
			password: "",
			reveal: false,
			showPasswordMeter: true,
			title: "Settings",
		};
	},

	computed: {
		newPasswordConfirmErrors() {
			const errors = [];
			if (!this.isNewPasswordConfirmDirty) {
				return errors;
			}

			if (!this.newPasswordConfirm) {
				errors.push("Re-enter new password");
			}

			return this.v$.newPasswordConfirm.$errors.map((error) => {
				switch (error.$validator) {
					case "minLength":
						return "Password is too short";
					case "maxLength":
						return "Password is too long";
					case "sameAsNewPassword":
						return "Does not match new password";
					default:
						return "";
				}
			});
		},
	},

	watch: {
		newPasswordConfirm() {
			this.isNewPasswordConfirmDirty = true;
		},
	},

	methods: {
		...mapMutations(["showNotification"]),
		clear: function () {
			// clear dirty flags
			this.v$.$reset();

			this.$refs.password.clearPassword();
			this.$refs.newPassword.clearPassword();
			this.$refs.newPasswordConfirm.clearPassword();

			this.isPasswordValid = true;
		},
		passwordState() {
			if (this.isPasswordValid === false) {
				this.isPasswordValid = true;
				this.v$.password.$touch();
			}
		},
		notify: function (data) {
			this.showNotification({
				message: data.message,
				status: data.status,
			});
		},
		save: function () {
			if (!this.v$.$invalid) {
				this.loading = "secondary";

				this.$http
					.post("/users/settings", {
						password: this.password,
						newPassword: this.newPassword,
					})
					.then((response) => {
						// display success
						this.notify(response.data);

						// clear the inputs
						this.clear();
					})
					.catch((error) => {
						// display error
						if (error.response) {
							if (error.response.data.message) {
								this.notify(error.response.data);
							} else {
								this.notify(error.response.data.error);
							}
						}
					})
					.finally(() => {
						this.loading = "";
					});
			} else {
				// set all to dirty
				this.v$.$touch();
			}
		},
	},

	validations() {
		return {
			newPassword: {
				required,
				minLength: minLength(8),
				maxLength: maxLength(60),
			},
			newPasswordConfirm: {
				required,
				minLength: minLength(8),
				maxLength: maxLength(60),
				sameAsNewPassword() {
					return this.newPassword === this.newPasswordConfirm;
				},
			},
			password: {
				required,
				minLength: minLength(8),
				maxLength: maxLength(60),
				invalid() {
					return this.isPasswordValid;
				},
			},
		};
	},
};
</script>

<style lang="scss"></style>
