import Cookies from "js-cookie";

const isAuthenticated = () => Cookies.get("authenticated");

const clearAuthenticated = () => Cookies.remove("authenticated");

export default {
	isAuthenticated,
	clearAuthenticated,
};
