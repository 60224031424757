/**
 * Initializes WPD PlotData class.
 */
function init(wpd) {
	wpd.PlotData.prototype.addDataset = function (ds) {
		this._datasetColl.push(ds);

		// by default bind ds to last non-table axes to prevent assignment of non-table datasets to table axes
		const axes = this._axesColl.filter((axis) => !axis.getMetadata().table);
		const axCount = axes.length;
		if (axCount > 0) {
			let axis = axes[axCount - 1];
			this.setAxesForDataset(ds, axis);
		}
	};

	wpd.PlotData.prototype.getDatasetsForAxis = function (axis) {
		const datasets = [];

		this._objectAxesMap.forEach((value, key) => {
			if (key instanceof wpd.Dataset && value === axis) {
				datasets.push(key);
			}
		});

		return datasets;
	};

	wpd.PlotData.prototype.getDatasetDisplayNames = function () {
		return this._datasetColl.map((ds) => {
			const metadata = ds.getMetadata();
			return metadata.name || ds.name;
		});
	};
}

export { init };
