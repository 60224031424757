<template>
	<v-row
		align-content="start"
		justify="start"
	>
		<v-col cols="12">
			<organization-table />
		</v-col>
	</v-row>
</template>

<script>
import OrganizationTable from "@/pages/Organizations/OrganizationTable";

export default {
	name: "OrganizationsAdmin",
	components: {
		OrganizationTable,
	},
};
</script>

<style scoped lang="scss"></style>
