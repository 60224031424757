/**
 * Add custom shortcut keys to WPD.
 */
function apply(wpd, wpdDocument) {
	wpdDocument.addEventListener("keydown", (event) => {
		// do not process keyboard shortcuts while entering data
		if (
			event.target &&
			!["INPUT", "SELECT", "TEXTAREA"].includes(event.target.tagName)
		) {
			switch (event.code) {
				case "KeyZ":
					event.preventDefault();
					wpd.tree.selectPath(`/${wpd.gettext("axes")}`);
					wpd.alignAxes.addCalibration();
					break;
				case "KeyX":
					event.preventDefault();
					wpd.tree.selectPath(`/${wpd.gettext("datasets")}`);
					wpd.dataSeriesManagement.showAddDataset("Series");
					break;
			}
		}
	});
}

export { apply };
