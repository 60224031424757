import Awesomplete from "awesomplete";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

/**
 * Initializes tag group inputs for WPD.
 */
function init(wpd, wpdDocument, dataEntryVM) {
	const centerToolbarContainerId = "center-toolbar-container";
	const tagGroupContainerId = "center-toolbar-content";

	class TagGroupCombobox {
		constructor(tagGroups, onSelect, reworkEnabled) {
			const tagGroupExclusions = new Set([
				TagGroups.studyTagGroupName,
				TagGroups.studyPartTagGroupName,
				TagGroups.studyArmTagGroupName,
			]);

			const reworkOnlyTagGroupExclusions = new Set([
				TagGroups.studyPartTagGroupName,
				TagGroups.studyPopulationTagGroupName,
				TagGroups.studySubgroupTagGroupName,
			]);

			// TODO: when reworkEnabled flag is being removed, we can just exclude
			// the full set of 5 tag groups above
			this.tagGroups = tagGroups.filter((tagGroup) => {
				const isExcluded = reworkOnlyTagGroupExclusions.has(
					tagGroup.name
				);
				if (reworkEnabled) {
					return (
						!isExcluded && !tagGroupExclusions.has(tagGroup.name)
					);
				}
				return !isExcluded;
			});

			this.container = wpdDocument.getElementById(tagGroupContainerId);
			this.input = this.container.querySelector(
				".tag-group-combobox-input"
			);
			this.onSelect = onSelect;
		}

		initialize() {
			if (this.#validate()) {
				// select the value
				this.value = this.tagGroups.find(
					function (tagGroup) {
						return tagGroup.name === this.input.value;
					}.bind(this)
				);
			}

			// initialize awesomplete
			this.awesomplete = new Awesomplete(this.input, {
				list: this.tagGroups,
				autoFirst: true,
				minChars: 0,
				maxItems: 20,
				data: function (item) {
					return {
						label: item.name,
						value: item.uuid,
						tags: item.tags,
					};
				},
				// insert label instead of value into the input
				replace: function (suggestion) {
					this.input.value = suggestion.label;
				},
			});

			// move ul so it can display over other elements
			this.#moveList();

			// position the list
			this.#positionList(true);

			// attach event listeners
			this.input.addEventListener("click", this.#onClick);
			this.input.addEventListener("keyup", this.#onKeyUp);
			this.input.addEventListener("awesomplete-open", this.#onOpen);
			this.input.addEventListener(
				"awesomplete-selectcomplete",
				this.#onSelect
			);
		}

		destroy() {
			// detach event listeners
			this.input.removeEventListener("click", this.#onClick);
			this.input.removeEventListener("keyup", this.#onKeyUp);
			this.input.removeEventListener("awesomplete-open", this.#onOpen);
			this.input.removeEventListener(
				"awesomplete-selectcomplete",
				this.#onSelect
			);

			// destroy awesomplete instance
			this.awesomplete.destroy();
		}

		#moveList() {
			// create new container with class "awesomplete" so the styles apply
			const ulContainer = wpdDocument.createElement("div");
			ulContainer.classList.add("awesomplete");

			// move the list element to the new container
			ulContainer.appendChild(this.awesomplete.ul);

			// attach the new container to the #mainContainer
			wpdDocument
				.getElementById("mainContainer")
				.appendChild(ulContainer);
		}

		#positionList(initialize) {
			let { left, top, width, height } = {
				left: 266,
				top: 819,
				width: 186,
				height: 22,
			};

			// position the container directly over the original
			if (!initialize) {
				({ left, top, width, height } =
					this.awesomplete.container.getBoundingClientRect());
			}

			const { height: pageHeight } = wpdDocument
				.getElementById("mainContainer")
				.getBoundingClientRect();

			Object.assign(this.awesomplete.ul.style, {
				position: "absolute",
				left: `${left - 5}px`,
				bottom: `${pageHeight - top + height}px`,
				width: `${width}px`,
			});
		}

		#validate() {
			return this.tagGroups.some(
				(tagGroup) =>
					tagGroup.name.toLowerCase().trim() ===
					this.input.value.toLowerCase().trim()
			);
		}

		// fat-arrow function allows "this" to be the instance of this class
		// instead of having it deteremined by the caller
		#onClick = () => {
			if (this.awesomplete.ul.childNodes.length === 0) {
				this.awesomplete.minChars = 0;
				this.awesomplete.evaluate();
			} else if (this.awesomplete.ul.hasAttribute("hidden")) {
				this.awesomplete.evaluate();
				this.awesomplete.open();
			} else {
				this.awesomplete.close();
			}
		};

		#onSelect = ({ text }) => {
			const studyTreeContainer =
				wpdDocument.getElementsByClassName("study-list")[0];

			if (studyTreeContainer) {
				const selectedBranch =
					studyTreeContainer.getElementsByClassName(
						"tree-selected"
					)[0];

				if (selectedBranch) {
					const compositeKey =
						selectedBranch.getAttribute("data-composite-key");
					const parsedKey =
						wpd.CompositeKeyUtils.parseCompositeKey(compositeKey);
					wpd.tagGroups.setActiveKeys(parsedKey);

					const tagGroupName =
						wpd.CompositeKeyUtils.getTagGroupName(compositeKey);
					const existing = wpd.tree.findCompositeKeyMatch(
						compositeKey,
						tagGroupName
					);

					if (existing) {
						wpd.tagGroups.loadTagGroupInstance(
							existing.metadata.tagGroup
						);
					} else {
						wpd.tagGroups.newTagGroupInstance(
							wpd.tagGroups.tagGroups.find(
								(group) => group.name === tagGroupName
							)
						);
					}
				}
			}
			// set value
			this.value = this.tagGroups.find(
				(tagGroup) => tagGroup.uuid === text.value
			);

			// call onSelect handler
			this.onSelect(this.value);

			// clear value
			this.input.value = null;
		};

		#onKeyUp = () => {
			if (!this.#validate()) {
				// clear value
				this.value = null;
			}
		};

		#onOpen = () => {
			this.#positionList();
		};
	}

	class TagGroups {
		static studyTagGroupName = "Study";
		static studyPartTagGroupName = "Study part";
		static studyArmTagGroupName = "Study arm";
		static studyPopulationTagGroupName = "Population";
		static studySubgroupTagGroupName = "Subgroup";

		static studyTagName = wpd.CompositeKeyUtils.studyTag;
		static studyPartTagName = wpd.CompositeKeyUtils.studyPartTag;
		static studyArmTagName = wpd.CompositeKeyUtils.studyArmTag;
		static studyPopulationTagName = wpd.CompositeKeyUtils.populationTag;
		static studySubgroupTagName = wpd.CompositeKeyUtils.subgroupTag;

		static requiredTags = {
			[TagGroups.studyTagGroupName]: [TagGroups.studyTagName],
			[TagGroups.studyPartTagGroupName]: [
				TagGroups.studyPartTagGroupName,
			],
			[TagGroups.studyArmTagGroupName]: [TagGroups.studyArmTagName],
		};

		#activeInstanceUuid = null;
		#activeTagGroup = null;
		#activeTag = null;
		#activeStudyKey = null;
		#activePartKey = null;
		#activeArmKey = null;

		#compositeKeys = [];

		#definitionDatasetIndex = 0;
		#definitionDataPointIndex = 0;

		#manualSelectionAddButton = wpdDocument.getElementById(
			"manual-select-button"
		);

		#originalAddButtonOnClick =
			this.#manualSelectionAddButton.getAttribute("onclick");

		constructor(tagGroups, tags) {
			this.tagGroups = tagGroups;
			this.tags = tags;

			this.awesomplete = null;

			this.studyTree = null;
			this.studyBranchParents = {};
			this.reworkEnabled = dataEntryVM.reworkEnabled;
		}

		// Study branch tag group names
		get studyTagGroupName() {
			return TagGroups.studyTagGroupName;
		}
		get studyPartTagGroupName() {
			return TagGroups.studyPartTagGroupName;
		}
		get studyArmTagGroupName() {
			return TagGroups.studyArmTagGroupName;
		}
		get studyPopulationTagGroupName() {
			return TagGroups.studyPopulationTagGroupName;
		}
		get studySubgroupTagGroupName() {
			return TagGroups.studySubgroupTagGroupName;
		}

		// Study branch tag names
		get studyTagName() {
			return TagGroups.studyTagName;
		}
		get studyPartTagName() {
			return TagGroups.studyPartTagName;
		}
		get studyArmTagName() {
			return TagGroups.studyArmTagName;
		}
		get studyPopulationTagName() {
			return TagGroups.studyPopulationTagName;
		}
		get studySubgroupTagName() {
			return TagGroups.studySubgroupTagName;
		}

		get activeTag() {
			return this.#activeTag;
		}

		set activeTag(tag) {
			if (!tag) {
				this.#activeTag = null;
			} else {
				const verifiedTag = _.find(this.tags, { uuid: tag.uuid });

				if (verifiedTag) {
					this.#activeTag = tag;
				} else {
					this.#activeTag = null;
				}
			}
		}

		get activeTagGroup() {
			return this.#activeTagGroup;
		}

		set activeTagGroup(tagGroup) {
			if (!tagGroup) {
				this.#activeTagGroup = null;
			} else {
				const verifiedTagGroup = _.find(this.tagGroups, {
					uuid: tagGroup.uuid,
				});

				if (verifiedTagGroup) {
					this.#activeTagGroup = verifiedTagGroup;
				} else {
					this.#activeTagGroup = null;
				}
			}
		}

		get compositeKeys() {
			return this.#compositeKeys;
		}

		set compositeKeys(keys) {
			this.#compositeKeys = keys;
		}

		get activeInstanceUuid() {
			return this.#activeInstanceUuid;
		}

		set activeInstanceUuid(uuid) {
			this.#activeInstanceUuid = uuid;
		}

		get activeStudyKey() {
			return this.#activeStudyKey;
		}

		set activeStudyKey(name) {
			this.#activeStudyKey = name;
		}

		get activePartKey() {
			return this.#activePartKey;
		}

		set activePartKey(name) {
			this.#activePartKey = name;
		}

		get activeArmKey() {
			return this.#activeArmKey;
		}

		set activeArmKey(name) {
			this.#activeArmKey = name;
		}

		initialize() {
			// destroy previous input
			if (this.awesomplete !== null) {
				this.awesomplete.destroy();
				this.awesomplete = null;
			}

			// reset any active uuids and detach tool
			this.reset();

			// create a new instance of the combobox
			this.awesomplete = new TagGroupCombobox(
				this.tagGroups,
				this.newTagGroupInstance.bind(this),
				this.reworkEnabled
			);
			this.awesomplete.initialize();
		}

		showControls() {
			if (!this.awesomplete) {
				// initialize the combobox
				this.initialize();
			}

			// disable add point button (appearance only)
			this.#manualSelectionAddButton.classList.add("disabled");

			// add tooltip for "Add Point (A)" button and change the onclick to
			// shake the tag group controls
			this.#manualSelectionAddButton.setAttribute(
				"title",
				"Select a tag group and tag in the bottom toolbar to add data points"
			);
			this.#manualSelectionAddButton.addEventListener(
				"click",
				wpd.tagGroups.shakeControls
			);
			this.#manualSelectionAddButton.removeAttribute("onclick");

			this.#toggleTagGroup("block");
		}

		hideControls() {
			this.#toggleTagGroup("none");

			// remove tooltip and click handler from "Add Point" button
			this.#manualSelectionAddButton.removeAttribute("title");
			this.#manualSelectionAddButton.removeEventListener(
				"click",
				wpd.tagGroups.shakeControls
			);
			this.#manualSelectionAddButton.setAttribute(
				"onclick",
				this.#originalAddButtonOnClick
			);

			// enable add point button (appearance only)
			this.#manualSelectionAddButton.classList.remove("disabled");

			this.reset();
		}

		newTagGroupInstance(selected) {
			try {
				// reset any active uuids and detach tool
				this.reset();

				// load adjust data point tool
				wpd.acquireData.load();
				wpd.acquireData.adjustPoints();

				// create a new tag group instance uuid
				this.activeInstanceUuid = uuidv4();

				// set active tag group
				this.activeTagGroup = selected;

				// fill in selected study/part/arm data
				const data = this.#setStudyKeys();

				// initialize the toolbar with the currently selected tag group
				this.#initializeTagGroupToolbar(
					this.activeTagGroup.name,
					this.activeTagGroup.tags,
					data
				);
				this.refreshButtons();
				this.refreshStudies();
			} catch (error) {
				console.error("Failed to add tag group: %o", error);
			}
		}

		loadDataPointTagGroup(dataPointIndex) {
			try {
				const dataset = wpd.tree.getActiveDataset();
				const pixel = dataset.getPixel(dataPointIndex);
				const tagGroup = dataset.getTagGroup(dataPointIndex);

				// set tag group instance uuid, tag group uuid, and select tag button
				this.activeInstanceUuid = tagGroup.instanceUuid;
				this.activeTagGroup = tagGroup;
				this.activeTag = pixel.metadata[0].tag;

				const data = this.#getActiveInstanceTagValues();

				this.#initializeTagGroupToolbar(
					this.activeTagGroup.name,
					this.activeTagGroup.tags,
					data
				);

				this.refreshButtons();
				this.refreshStudies();
			} catch (error) {
				console.error("Failed to load tag group: %o", error);
			}
		}

		async selectTag(currentButton, abbreviationOnly) {
			const uuid = currentButton.dataset.tagUuid;
			const tag = this.tags.find((tag) => tag.uuid === uuid);

			// allow toggling of tag buttons
			if (currentButton.classList.contains("pressed-button")) {
				// clear pressed buttons
				this.#unpressAllButtons();

				// clear active tag
				this.activeTag = null;

				// detach tool
				wpd.graphicsWidget.removeTool();
			} else {
				// clear pressed buttons
				this.#unpressAllButtons();

				// set active tag
				this.activeTag = tag;

				// color button as pressed
				currentButton.classList.add("pressed-button");

				const dataset = wpd.tree.getActiveDataset();
				const axis = wpd.appData
					.getPlotData()
					.getAxesForDataset(dataset);

				// attach the appropriate tool
				if (currentButton.classList.contains("collected")) {
					// tag already has data, show edit tool instead

					wpd.acquireData.adjustPoints();

					// trigger metadata dialog
					const index = this.#findDataPointIndexFromTagUuid(
						dataset,
						uuid
					);

					if (index > -1) {
						wpd.events.dispatch("wpd.dataset.point.select", {
							axes: axis,
							dataset: dataset,
							indexes: [index],
							abbreviationOnly,
						});
					}
				} else {
					if (
						this.isPositionlessTag(
							this.activeTagGroup.name,
							tag.name
						)
					) {
						// skip user data point add and add a position-less
						// data point
						const index = dataset.addPixel();
						await wpd.custom.processDataPoint(
							dataset,
							index,
							axis,
							abbreviationOnly
						);
					} else {
						// no data on tag, show add tool
						wpd.acquireData.manualSelection();
					}
				}
			}
		}

		shakeControls() {
			const container = wpdDocument.getElementById(tagGroupContainerId);
			container.classList.add("shake");

			setTimeout(() => {
				container.classList.remove("shake");
			}, 1000);
		}

		refreshButtons() {
			const collectedTagUuids = this.#getActiveInstanceTagUuids();

			const allButtons = wpdDocument.querySelectorAll(".tag-group-tag");
			allButtons.forEach((button) => {
				// clear all pressed buttons here instead of calling
				// this.#unpressAllButtons to save a querySelectorAll call
				button.classList.remove("pressed-button");

				// add "collected" class to all tag uuids with values
				if (collectedTagUuids.includes(button.dataset.tagUuid)) {
					button.classList.add("collected");
				} else {
					button.classList.remove("collected");
				}

				// add "pressed" class to active tag uuid
				if (button.dataset.tagUuid === this.activeTag?.uuid) {
					button.classList.remove("collected");
					button.classList.add("pressed-button");
				}
			});
		}

		refreshCollectedData() {
			const toolbar = wpdDocument.getElementById(tagGroupContainerId);
			const container = toolbar.querySelector(".data .content");

			const data = this.#getActiveInstanceTagValues();
			container.innerHTML = this.#generateCollectedDataList(data);
		}

		refreshStudies(previousPath) {
			const studyTreeContainer =
				wpdDocument.getElementsByClassName("study-list")[0];

			// clone and replace the container to remove all previous listeners
			const clone = studyTreeContainer.cloneNode();
			studyTreeContainer.parentNode.replaceChild(
				clone,
				studyTreeContainer
			);

			// handle currently selected data points
			const dataset = wpd.tree.getActiveDataset();
			const currentSelection = dataset?.getSelectedPixels();

			this.studyTree = new wpd.TreeWidget(clone);

			const studyTreeData = this.#generateStudyTree();
			this.compositeKeys = this.buildCompositeKeys({
				trees: studyTreeData,
			});

			this.studyTree.render(studyTreeData, null, "study-");

			// attach on item selection handler after selecting the branch
			this.studyTree.onItemSelection(this.#onStudyTreeSelect.bind(this));

			if (currentSelection?.length > 0) {
				dataset.selectPixels(currentSelection);
			}

			this.selectStudyTreeBranch({
				study: this.activeStudyKey,
				part: this.activePartKey,
				arm: this.activeArmKey,
				population: this.activePopulationKey,
				subgroup: this.activeSubgroupKey,
				previousPath,
			});
		}

		refreshAll() {
			this.refreshButtons();
			this.refreshCollectedData();
			this.refreshStudies();

			if (
				this.activeTagGroup &&
				!wpd.graphicsWidget.activeTool instanceof
					wpd.AdjustDataPointTool
			) {
				// attach the adjust data point tool
				wpd.acquireData.adjustPoints();
			}
		}

		isReworkOnlyKey(name) {
			return _.includes(
				[
					TagGroups.studyPopulationTagName,
					TagGroups.studySubgroupTagName,
				],
				name
			);
		}

		isKeyTag(name) {
			return _.includes(
				[
					TagGroups.studyTagName,
					TagGroups.studyPartTagName,
					TagGroups.studyArmTagName,
					TagGroups.studyPopulationTagName,
					TagGroups.studySubgroupTagName,
				],
				name
			);
		}

		isKeyTagGroup(name) {
			return _.includes(
				[
					TagGroups.studyTagGroupName,
					TagGroups.studyPartTagGroupName,
					TagGroups.studyArmTagGroupName,
					TagGroups.studyPopulationTagGroupName,
					TagGroups.studySubgroupTagGroupName,
				],
				name
			);
		}

		isRequiredTag(tagGroupName, tagName) {
			return _.includes(TagGroups.requiredTags[tagGroupName], tagName);
		}

		isPositionlessTag(tagGroupName, tagName) {
			let isPositionless = false;

			switch (tagGroupName) {
				case TagGroups.studyArmTagGroupName:
					isPositionless = this.reworkEnabled
						? _.includes(
								[
									TagGroups.studyTagName,
									TagGroups.studyPartTagName,
									TagGroups.studyArmTagName,
								],
								tagName
						  )
						: _.includes(
								[
									TagGroups.studyTagName,
									TagGroups.studyPartTagName,
								],
								tagName
						  );
					break;
				case TagGroups.studyPartTagGroupName:
					isPositionless = _.includes(
						[TagGroups.studyTagName, TagGroups.studyPartTagName],
						tagName
					);

					break;
				default:
					isPositionless = this.isKeyTag(tagName);
			}

			return isPositionless;
		}

		showClearTagPopup() {
			wpd.popup.show("clear-all-tags-confirm-dialog");
		}

		clearTags(clearAssociatedData) {
			const dataset = wpd.tree.getActiveDataset();
			const axis = wpd.appData.getPlotData().getAxesForDataset(dataset);
			const sortedIndexes = this.#getActiveInstanceDataPointIndexes();

			let allIndexes = sortedIndexes;

			if (this.reworkEnabled) {
				const emptyIndexes = dataset
					.getAllPixels()
					.reduce((acc, p, index) => {
						if (!p || _.isEmpty(p.metadata)) {
							acc.push(index);
						}
						return acc;
					}, []);

				const matchingCompositeKeyIndexes =
					clearAssociatedData && sortedIndexes.length > 0
						? dataset.getAllPixels().reduce((acc, p, index) => {
								if (
									p?.metadata?.compositeKey ===
									dataset.getPixel(sortedIndexes[0]).metadata
										.compositeKey
								) {
									acc.push(index);
								}
								return acc;
						  }, [])
						: [];

				allIndexes = _.uniq([
					...sortedIndexes,
					...emptyIndexes,
					...matchingCompositeKeyIndexes,
				]).sort((a, b) => b - a);
			}

			_.each(allIndexes, (index) => dataset.removePixelAtIndex(index));

			// clear active keys
			this.setActiveKeys({});

			// refresh UI
			this.refreshAll();
			wpd.graphicsWidget.resetData();
			wpd.graphicsWidget.forceHandlerRepaint();
			wpd.dataPointCounter.setCount(dataset.getCount());

			// dispatch point delete event
			allIndexes.forEach((index) => {
				wpd.events.dispatch("wpd.dataset.point.delete", {
					axes: axis,
					dataset,
					index,
				});
			});

			this.closeClearAllDialog();
		}

		closeClearAllDialog() {
			wpd.popup.close("clear-all-tags-confirm-dialog");
		}

		findTag({ name, uuid }) {
			let search;

			if (name && uuid) {
				search = { name, uuid };
			} else if (name) {
				search = { name };
			} else if (uuid) {
				search = { uuid };
			}

			return _.chain(this.tags)
				.find(search)
				.pick(["name", "uuid"])
				.value();
		}

		setActiveKeys(compositeKey) {
			wpd.tagGroups.activeStudyKey = compositeKey.study;
			wpd.tagGroups.activePartKey = compositeKey.studyPart;
			wpd.tagGroups.activeArmKey = compositeKey.studyArm;
			wpd.tagGroups.activePopulationKey = compositeKey.population;
			wpd.tagGroups.activeSubgroupKey = compositeKey.subgroup;
		}

		getAllTagGroups() {
			return this.tagGroups;
		}

		getAllStudyKeys() {
			return _.chain(wpd.appData.getPlotData().getDatasets())
				.flatMap((dataset) =>
					_.chain(dataset.getAllPixels())
						.filter(["metadata[0].tag.name", "Study abbreviation"])
						.map("metadata[0].value")
						.value()
				)
				.compact()
				.uniq()
				.value();
		}

		getAllStudyArmKeys() {
			return _.chain(wpd.appData.getPlotData().getDatasets())
				.flatMap((dataset) =>
					_.chain(dataset.getAllPixels())
						.filter([
							"metadata[0].tag.name",
							"Study arm abbreviation",
						])
						.map("metadata[0].value")
						.value()
				)
				.compact()
				.uniq()
				.value();
		}

		buildCompositeKeys() {
			const datasets = wpd.appData.getPlotData().getDatasets();
			const keys = new Set();

			datasets.forEach((dataset) => {
				dataset.getAllPixels().forEach((pixel) => {
					const compositeKey = pixel.metadata?.compositeKey;
					if (compositeKey) {
						keys.add(compositeKey);
					}
				});
			});

			return Array.from(keys).map((key) =>
				wpd.CompositeKeyUtils.parseCompositeKey(key)
			);
		}

		selectStudyTreeBranch({
			study,
			part,
			arm,
			population,
			subgroup,
			previousPath,
		}) {
			// unselect all data points
			wpd.tree.getActiveDataset()?.unselectAll();

			const path = this.reworkEnabled
				? wpd.CompositeKeyUtils.generateCompositeKey(
						study,
						part,
						arm,
						population,
						subgroup
				  )
				: `/${_.compact([
						wpd.utils.escapeSlashes(study),
						...(part || !this.reworkEnabled
							? [wpd.utils.escapeSlashes(part)]
							: []),
						...(arm || !this.reworkEnabled
							? [wpd.utils.escapeSlashes(arm)]
							: []),
				  ]).join("/")}`;

			const ignoreCallback = previousPath === path;
			this.studyTree.selectPath(`${path}`, null, null, ignoreCallback);
		}

		reset() {
			this.activeInstanceUuid = null;
			this.activeTagGroup = null;
			this.activeTag = null;

			// detach tool
			wpd.graphicsWidget.removeTool();

			// reset tag group toolbar
			this.#resetTagGroupToolbar();
		}

		async selectStudyBranch() {
			const dataset = wpd.tree.getActiveDataset();
			const axis = wpd.appData.getPlotData().getAxesForDataset(dataset);
			const index = dataset.addPixel();
			await wpd.custom.processDataPoint(dataset, index, axis, true, true);
		}

		addTagGroupInstance() {
			wpd.tagGroups.newTagGroupInstance(this.activeTagGroup);
		}

		#selectStudyTreeArmParentBranch() {
			if (this.activeArmKey) {
				if (this.activePartKey) {
					this.selectStudyTreeBranch({
						study: this.activeStudyKey,
						part: this.activePartKey,
					});
				} else {
					this.selectStudyTreeBranch({
						study: this.activeStudyKey,
					});
				}
			}
		}

		#getSelectedTreeElement() {
			return wpdDocument
				.getElementById("study-tree-display")
				.querySelector(".tree-selected");
		}

		#setStudyKeys() {
			if (!this.activeTagGroup) {
				console.warn("Cannot set study keys: No active tag group");
				return [];
			} else {
				const data = [];
				const dataset = wpd.tree.getActiveDataset();
				const selectedTreeElement = this.#getSelectedTreeElement();

				if (
					!selectedTreeElement ||
					(selectedTreeElement &&
						!selectedTreeElement.classList.contains("orphan") &&
						!selectedTreeElement.classList.contains(
							"missing-definition"
						))
				) {
					if (!this.reworkEnabled && this.activeArmKey) {
						// do not automatically fill in selected arm
						// select arm parent if an arm is currently selected
						this.#selectStudyTreeArmParentBranch();
						this.activeArmKey = null;
					}

					const keyTags = this.#getKeyTags();
					const compositeKeys = this.reworkEnabled
						? this.#getCompositeKeys()
						: {};
					const tagGroups = this.reworkEnabled
						? this.#getTagGroups()
						: {};
					const uuids = this.reworkEnabled ? this.#getUuids() : {};
					const hasExistingBranch = this.reworkEnabled
						? this.#getExistingBranches(dataset, compositeKeys)
						: {};

					if (keyTags.study && this.activeStudyKey) {
						this.#addPixelToDataset(
							dataset,
							keyTags.study,
							compositeKeys.subgroup,
							this.activeStudyKey
						);

						if (this.reworkEnabled) {
							let hasChild =
								this.activePartKey ||
								this.activeArmKey ||
								this.activePopulationKey ||
								this.activeSubgroupKey;
							if (hasChild && !hasExistingBranch.study) {
								this.#addPixelToDataset(
									dataset,
									keyTags.study,
									compositeKeys.study,
									this.activeStudyKey,
									uuids.study,
									tagGroups.study
								);
							}
							if (
								this.activeArmKey &&
								this.activePartKey &&
								!hasExistingBranch.part
							) {
								this.#addPixelToDataset(
									dataset,
									keyTags.study,
									compositeKeys.part,
									this.activeStudyKey,
									uuids.part,
									tagGroups.part
								);
							}
							if (
								(this.activePopulationKey ||
									this.activeSubgroupKey) &&
								!hasExistingBranch.arm
							) {
								this.#addPixelToDataset(
									dataset,
									keyTags.study,
									compositeKeys.arm,
									this.activeStudyKey,
									uuids.arm,
									tagGroups.arm
								);
							}
							if (
								this.activeSubgroupKey &&
								this.activePopulationKey &&
								!hasExistingBranch.population
							) {
								this.#addPixelToDataset(
									dataset,
									keyTags.study,
									compositeKeys.population,
									this.activeStudyKey,
									uuids.population,
									tagGroups.population
								);
							}
						}

						data.push({
							tag: keyTags.study.name,
							value: this.activeStudyKey,
						});
					}

					if (keyTags.part && this.activePartKey) {
						this.#addPixelToDataset(
							dataset,
							keyTags.part,
							compositeKeys.subgroup,
							this.activePartKey
						);
						if (this.reworkEnabled) {
							let hasChild =
								this.activeArmKey ||
								this.activePopulationKey ||
								this.activeSubgroupKey;
							if (hasChild && !hasExistingBranch.part) {
								this.#addPixelToDataset(
									dataset,
									keyTags.part,
									compositeKeys.part,
									this.activePartKey,
									uuids.part,
									tagGroups.part
								);
							}
							if (
								(this.activePopulationKey ||
									this.activeSubgroupKey) &&
								!hasExistingBranch.arm
							) {
								this.#addPixelToDataset(
									dataset,
									keyTags.part,
									compositeKeys.arm,
									this.activePartKey,
									uuids.arm,
									tagGroups.arm
								);
							}
							if (
								this.activeSubgroupKey &&
								this.activePopulationKey &&
								!hasExistingBranch.population
							) {
								this.#addPixelToDataset(
									dataset,
									keyTags.part,
									compositeKeys.population,
									this.activePartKey,
									uuids.population,
									tagGroups.population
								);
							}
						}
						data.push({
							tag: keyTags.part.name,
							value: this.activePartKey,
						});
					}

					if (
						this.reworkEnabled &&
						keyTags.arm &&
						this.activeArmKey
					) {
						this.#addPixelToDataset(
							dataset,
							keyTags.arm,
							compositeKeys.subgroup,
							this.activeArmKey
						);

						let hasChild =
							this.activePopulationKey || this.activeSubgroupKey;
						if (hasChild && !hasExistingBranch.arm) {
							this.#addPixelToDataset(
								dataset,
								keyTags.arm,
								compositeKeys.arm,
								this.activeArmKey,
								uuids.arm,
								tagGroups.arm
							);
						}

						if (
							this.activeSubgroupKey &&
							this.activePopulationKey &&
							!hasExistingBranch.population
						) {
							this.#addPixelToDataset(
								dataset,
								keyTags.arm,
								compositeKeys.population,
								this.activeArmKey,
								uuids.population,
								tagGroups.population
							);
						}
						data.push({
							tag: keyTags.arm.name,
							value: this.activeArmKey,
						});
					}

					if (
						this.reworkEnabled &&
						keyTags.population &&
						this.activePopulationKey
					) {
						this.#addPixelToDataset(
							dataset,
							keyTags.population,
							compositeKeys.subgroup,
							this.activePopulationKey
						);

						let hasChild = this.activeSubgroupKey;
						if (hasChild && !hasExistingBranch.population) {
							this.#addPixelToDataset(
								dataset,
								keyTags.population,
								compositeKeys.population,
								this.activePopulationKey,
								uuids.population,
								tagGroups.population
							);
						}
						data.push({
							tag: keyTags.population.name,
							value: this.activePopulationKey,
						});
					}

					if (
						this.reworkEnabled &&
						keyTags.subgroup &&
						this.activeSubgroupKey
					) {
						this.#addPixelToDataset(
							dataset,
							keyTags.subgroup,
							compositeKeys.subgroup,
							this.activeSubgroupKey
						);
						data.push({
							tag: keyTags.subgroup.name,
							value: this.activeSubgroupKey,
						});
					}
				}

				// refresh UI
				wpd.graphicsWidget.resetData();
				wpd.graphicsWidget.forceHandlerRepaint();
				wpd.dataPointCounter.setCount(dataset.getCount());

				return data;
			}
		}

		#addPixelToDataset(
			dataset,
			tag,
			compositeKey,
			value,
			instanceUuid,
			tagGroup
		) {
			dataset.addPixel(undefined, undefined, {
				0: {
					tag: {
						name: tag.name,
						uuid: tag.uuid,
					},
					value,
				},
				uuid: uuidv4(),
				...(this.reworkEnabled
					? {
							compositeKey: compositeKey,
					  }
					: {}),
				tagGroup: {
					name: tagGroup ? tagGroup.name : this.activeTagGroup.name,
					uuid: tagGroup ? tagGroup.uuid : this.activeTagGroup.uuid,
					instanceUuid: instanceUuid ?? this.activeInstanceUuid,
				},
			});
		}

		#getKeyTags() {
			return {
				study: _.find(this.activeTagGroup.tags, {
					name: TagGroups.studyTagName,
				}),
				part: _.find(this.activeTagGroup.tags, {
					name: TagGroups.studyPartTagName,
				}),
				arm: _.find(this.activeTagGroup.tags, {
					name: TagGroups.studyArmTagName,
				}),
				population: _.find(this.activeTagGroup.tags, {
					name: TagGroups.studyPopulationTagGroupName,
				}),
				subgroup: _.find(this.activeTagGroup.tags, {
					name: TagGroups.studySubgroupTagGroupName,
				}),
			};
		}

		#getCompositeKeys() {
			return {
				study: wpd.CompositeKeyUtils.generateCompositeKey(
					this.activeStudyKey
				),
				part: wpd.CompositeKeyUtils.generateCompositeKey(
					this.activeStudyKey,
					this.activePartKey
				),
				arm: wpd.CompositeKeyUtils.generateCompositeKey(
					this.activeStudyKey,
					this.activePartKey,
					this.activeArmKey
				),
				population: wpd.CompositeKeyUtils.generateCompositeKey(
					this.activeStudyKey,
					this.activePartKey,
					this.activeArmKey,
					this.activePopulationKey
				),
				subgroup: wpd.CompositeKeyUtils.generateCompositeKey(
					this.activeStudyKey,
					this.activePartKey,
					this.activeArmKey,
					this.activePopulationKey,
					this.activeSubgroupKey
				),
			};
		}

		#getTagGroups() {
			return {
				study: this.tagGroups.find(
					(group) => group.name === TagGroups.studyTagGroupName
				),
				part: this.tagGroups.find(
					(group) => group.name === TagGroups.studyPartTagGroupName
				),
				arm: this.tagGroups.find(
					(group) => group.name === TagGroups.studyArmTagGroupName
				),
				population: this.tagGroups.find(
					(group) =>
						group.name === TagGroups.studyPopulationTagGroupName
				),
			};
		}

		#getUuids() {
			return {
				study: uuidv4(),
				part: uuidv4(),
				arm: uuidv4(),
				population: uuidv4(),
			};
		}

		#getExistingBranches(dataset, compositeKeys) {
			const pixels = dataset
				.getAllPixels()
				.filter(
					(pixel) => pixel.metadata && pixel.metadata.compositeKey
				);
			return {
				study: pixels.some(
					(pixel) =>
						wpd.CompositeKeyUtils.isStudy(
							pixel.metadata.compositeKey
						) && pixel.metadata.compositeKey === compositeKeys.study
				),
				part: pixels.some(
					(pixel) =>
						wpd.CompositeKeyUtils.isStudyPart(
							pixel.metadata.compositeKey
						) && pixel.metadata.compositeKey === compositeKeys.part
				),
				arm: pixels.some(
					(pixel) =>
						wpd.CompositeKeyUtils.isStudyArm(
							pixel.metadata.compositeKey
						) && pixel.metadata.compositeKey === compositeKeys.arm
				),
				population: pixels.some(
					(pixel) =>
						wpd.CompositeKeyUtils.isStudyPopulation(
							pixel.metadata.compositeKey
						) &&
						pixel.metadata.compositeKey === compositeKeys.population
				),
			};
		}

		async #onStudyTreeSelect(
			el,
			path,
			suppressSecondaryActions,
			preserveActive,
			previousPath
		) {
			const branch = el.dataset.branch;
			const missingDefinition =
				el.classList.contains("missing-definition");

			if (this.reworkEnabled) {
				const parsedKey = wpd.CompositeKeyUtils.parseCompositeKey(path);
				const studyKey = parsedKey.study;
				const studyPart = parsedKey.studyPart;
				const studyArm = parsedKey.studyArm;
				const population = parsedKey.population;
				const subgroup = parsedKey.subgroup;

				if (path === previousPath) {
					let dataset, tagGroup, tagGroupName, key;

					// clear all active keys
					this.activePartKey = null;
					this.activeArmKey = null;
					this.activePopulationKey = null;
					this.activeSubgroupKey = null;

					this.activeStudyKey = studyKey;
					switch (branch) {
						case "study":
							key = studyKey;
							tagGroupName = TagGroups.studyTagGroupName;
							break;
						case "part":
							this.activePartKey = studyPart;
							key = studyPart;
							tagGroupName = TagGroups.studyPartTagGroupName;
							break;
						case "arm":
							this.activePartKey = studyPart;
							this.activeArmKey = studyArm;
							key = studyArm;
							tagGroupName = TagGroups.studyArmTagGroupName;
							break;
						case "population":
							this.activePartKey = studyPart;
							this.activeArmKey = studyArm;
							this.activePopulationKey = population;
							key = population;
							tagGroupName =
								TagGroups.studyPopulationTagGroupName;
							break;
						case "subgroup":
							this.activePartKey = studyPart;
							this.activeArmKey = studyArm;
							this.activePopulationKey = population;
							this.activeSubgroupKey = subgroup;
							key = subgroup;
							tagGroupName = TagGroups.studySubgroupTagGroupName;
							break;
					}

					const compositeKey =
						wpd.CompositeKeyUtils.generateCompositeKey(
							this.activeStudyKey,
							this.activePartKey,
							this.activeArmKey,
							this.activePopulationKey,
							this.activeSubgroupKey
						);

					({ dataset, tagGroup } = this.#findNextInstanceUuid(
						tagGroupName,
						key,
						compositeKey
					));

					if (!dataset) return;

					// switch to file, page, dataset type, and dataset
					// find the file the dataset belongs to
					const fileManager = wpd.appData.getFileManager();
					const datasetFileMap = fileManager.getDatasetNameMap();
					const currentFileIndex = fileManager.currentFileIndex();
					const datasetFileIndex = datasetFileMap[dataset.name];
					if (currentFileIndex !== datasetFileIndex) {
						// switch to file
						await fileManager.switch(datasetFileIndex);
					}

					// find the page the dataset belongs to
					if (wpd.appData.isMultipage()) {
						const pageManager = wpd.appData.getPageManager();
						const datasetPageMap = pageManager.getDatasetNameMap();
						const currentPage = pageManager.currentPage();
						const datasetPage = datasetPageMap[dataset.name];

						if (currentPage !== datasetPage) {
							// switch page
							await pageManager.switch(datasetPage);
						}
					}

					// select the dataset data type
					const axis = wpd.appData
						.getPlotData()
						.getAxesForDataset(dataset);
					if (axis instanceof wpd.ImageAxes) {
						if (axis.getMetadata().table) {
							// table data
							wpd.tree.selectPath("/Table");

							// select the dataset
							wpd.tree.selectDataset(dataset.name);
						} else {
							// text data
							wpd.tree.selectPath("/Text");

							// show the toolbar
							this.showControls();
						}
					} else {
						// consider everything else graph data
						wpd.tree.selectPath("/Graph");

						// select the dataset
						wpd.tree.selectDataset(dataset.name);
					}

					// load adjust data point tool
					wpd.acquireData.load();
					wpd.acquireData.adjustPoints();

					// load tag group instance
					this.loadTagGroupInstance(tagGroup, previousPath);
				} else {
					this.activeStudyKey = studyKey;
					switch (branch) {
						case "part":
							this.activePartKey = studyPart;
							break;
						case "arm":
							this.activePartKey = studyPart;
							this.activeArmKey = studyArm;
							break;
						case "population":
							this.activePartKey = studyPart;
							this.activeArmKey = studyArm;
							this.activePopulationKey = population;
							break;
						case "subgroup":
							this.activePartKey = studyPart;
							this.activeArmKey = studyArm;
							this.activePopulationKey = population;
							this.activeSubgroupKey = subgroup;
							break;
					}

					this.#definitionDatasetIndex = 0;
					this.#definitionDataPointIndex = 0;
				}
			} else {
				// parse the path and ignore the first match, it contains the entire
				// string
				let [, studyKey, partOrArmKey, armKey] =
					/^\/([^\/]+)(?:\/([^\/]+))?(?:\/([^\/]+))?$/g.exec(path);

				// unescape slashes
				studyKey = wpd.utils.unescapeSlashes(studyKey);
				partOrArmKey = wpd.utils.unescapeSlashes(partOrArmKey);
				armKey = wpd.utils.unescapeSlashes(armKey);

				if (path === previousPath) {
					// selecting the selected path should bring the user back to the
					// definition of the branch they clicked on
					let dataset, tagGroup, tagGroupName, key;

					// clear all active keys
					this.activeStudyKey = null;
					this.activePartKey = null;
					this.activeArmKey = null;

					if (!missingDefinition) {
						switch (branch) {
							case "study":
								this.activeStudyKey = studyKey;

								key = studyKey;

								tagGroupName = TagGroups.studyTagGroupName;

								break;
							case "part":
								this.activeStudyKey = studyKey;
								this.activePartKey = partOrArmKey;

								key = partOrArmKey;

								tagGroupName = TagGroups.studyArmTagGroupName;

								break;
							case "arm":
								this.activeStudyKey = studyKey;

								if (armKey) {
									this.activePartKey = partOrArmKey;
									this.activeArmKey = armKey;
									key = armKey;
								} else {
									this.activeArmKey = partOrArmKey;
									key = partOrArmKey;
								}

								tagGroupName = TagGroups.studyArmTagGroupName;

								break;
						}
					} else {
						// missing definition, get tag group data
						({ name: tagGroupName } = _.find(this.tagGroups, {
							uuid: el.dataset.tagGroup,
						}));

						switch (branch) {
							case "study":
								// studies can never be orphaned, only undefined
								// only need to check first key
								this.activeStudyKey = studyKey;
								key = studyKey;

								break;
							case "part":
								// parts can be:
								// 1. adopted and undefined
								// 2. orphaned and undefined
								// check first two keys in path
								if (studyKey && partOrArmKey) {
									this.activeStudyKey = studyKey;
									this.activePartKey = partOrArmKey;
									key = partOrArmKey;
								} else {
									this.activePartKey = studyKey;
									key = studyKey;
								}

								break;
							case "arm":
								// arms can be:
								// 1. orphaned and defined
								// 2. orphaned and undefined
								// only need to check first key
								this.activeArmKey = studyKey;
								key = studyKey;

								break;
						}
					}

					({ dataset, tagGroup } = this.#findNextInstanceUuid(
						tagGroupName,
						key
					));

					if (!dataset) return;

					// switch to file, page, dataset type, and dataset
					// find the file the dataset belongs to
					const fileManager = wpd.appData.getFileManager();
					const datasetFileMap = fileManager.getDatasetNameMap();
					const currentFileIndex = fileManager.currentFileIndex();
					const datasetFileIndex = datasetFileMap[dataset.name];
					if (currentFileIndex !== datasetFileIndex) {
						// switch to file
						await fileManager.switch(datasetFileIndex);
					}

					// find the page the dataset belongs to
					if (wpd.appData.isMultipage()) {
						const pageManager = wpd.appData.getPageManager();
						const datasetPageMap = pageManager.getDatasetNameMap();
						const currentPage = pageManager.currentPage();
						const datasetPage = datasetPageMap[dataset.name];

						if (currentPage !== datasetPage) {
							// switch page
							await pageManager.switch(datasetPage);
						}
					}

					// select the dataset data type
					const axis = wpd.appData
						.getPlotData()
						.getAxesForDataset(dataset);
					if (axis instanceof wpd.ImageAxes) {
						if (axis.getMetadata().table) {
							// table data
							wpd.tree.selectPath("/Table");

							// select the dataset
							wpd.tree.selectDataset(dataset.name);
						} else {
							// text data
							wpd.tree.selectPath("/Text");

							// show the toolbar
							this.showControls();
						}
					} else {
						// consider everything else graph data
						wpd.tree.selectPath("/Graph");

						// select the dataset
						wpd.tree.selectDataset(dataset.name);
					}

					// load adjust data point tool
					wpd.acquireData.load();
					wpd.acquireData.adjustPoints();

					// load tag group instance
					this.loadTagGroupInstance(tagGroup);
				} else {
					this.activeStudyKey = studyKey;

					switch (branch) {
						case "part":
							this.activePartKey = partOrArmKey;
							break;
						case "arm":
							if (armKey) {
								this.activeArmKey = armKey;
							} else {
								this.activeArmKey = partOrArmKey;
							}
							break;
					}

					// reset cycling dataset and data point indexes
					this.#definitionDatasetIndex = 0;
					this.#definitionDataPointIndex = 0;
				}
			}
		}

		loadTagGroupInstance(tagGroup, previousPath) {
			this.activeInstanceUuid = tagGroup.instanceUuid;
			this.activeTagGroup = tagGroup;
			this.activeTag = null;

			const data = this.#getActiveInstanceTagValues();

			this.#initializeTagGroupToolbar(
				this.activeTagGroup.name,
				this.activeTagGroup.tags,
				data
			);

			this.refreshButtons();
			this.refreshStudies(previousPath);
		}

		#findNextInstanceUuid(tagGroupName, key, compositeKey) {
			// the branch definition can be in any dataset
			const datasets = wpd.appData.getPlotData().getDatasets();
			let foundTagGroup;

			// find the first dataset that has the given study branch key and
			// tag group name
			// keep track of dataset and data point indexes to allow users to
			// loop through each definition tag group instance
			const findDataset = (datasetFromIndex) => {
				return _.chain(datasets)
					.find((dataset, dIndex) => {
						const dataPoints = dataset.getAllPixels();
						const findTagGroup = (dataPointFromIndex) => {
							return _.chain(dataPoints)
								.filter(
									(dataPoint) => dataPoint.metadata?.tagGroup
								)
								.find(({ metadata }, pIndex) => {
									const foundPoint =
										key === metadata[0].value &&
										metadata.tagGroup.name ===
											tagGroupName &&
										(!this.reworkEnabled ||
											metadata.compositeKey ===
												compositeKey);

									if (foundPoint) {
										this.#definitionDataPointIndex = pIndex;
									} else {
										this.#definitionDataPointIndex = 0;
									}

									if (foundPoint) {
										// start next search on next data point
										this.#definitionDataPointIndex =
											pIndex + 1;
									}

									return foundPoint;
								}, dataPointFromIndex)
								.get("metadata.tagGroup")
								.value();
						};

						foundTagGroup = findTagGroup(
							this.#definitionDataPointIndex
						);

						if (foundTagGroup) {
							// start next search on the same dataset since it is
							// possible for dataset to contain multiple
							// definition instances
							this.#definitionDatasetIndex = dIndex;
						} else {
							// did not find the data point containing the tag
							// group in this dataset, reset data point index to
							// 0 for the next search
							this.#definitionDataPointIndex = 0;
						}

						return foundTagGroup;
					}, datasetFromIndex)
					.value();
			};

			let foundDataset = findDataset(this.#definitionDatasetIndex);

			if (!foundDataset) {
				// did not find the dataset containing the tag group
				// try again after resetting dataset index to 0
				// a.k.a. start from the beginning again
				this.#definitionDatasetIndex = 0;

				foundDataset = findDataset(this.#definitionDatasetIndex);
			}

			return {
				dataset: foundDataset,
				tagGroup: foundTagGroup,
			};
		}

		#generateStudyTree() {
			// clear existing study branch parent map
			this.studyBranchParents = {};

			// get all data points with metadata and tag group data
			const datasets = wpd.appData.getPlotData().getDatasets();
			const dataPointsByDataset = datasets.map((dataset) =>
				dataset
					.getAllPixels()
					.filter((dataPoint) => dataPoint.metadata?.tagGroup)
			);

			// flatten data points and group data by tag group instances
			const instances = _.chain(dataPointsByDataset)
				.flatten()
				.groupBy("metadata.tagGroup.instanceUuid")
				.value();

			// create an array of the instance uuids sorted by:
			// 1. study tag groups
			// 2. study arm tag groups
			// 3. everything else
			const instanceOrder = _.chain(instances)
				.toPairs()
				.sortBy([
					(instancePair) => {
						switch (instancePair[1][0].metadata.tagGroup.name) {
							case TagGroups.studyTagGroupName:
								return 1;
							case TagGroups.studyPartTagGroupName:
								return 2;
							case TagGroups.studyArmTagGroupName:
								return 3;
							case TagGroups.studyPopulationTagGroupName:
								return 4;
							default:
								return 5;
						}
					},
				])
				.map((instancePair) => instancePair[0])
				.value();

			const studies = [];
			const orphans = [];

			// find study, part, arm data from all tag groups
			// loop through instances by instance order generated above
			// looks for study tag, then part tag, then arm tag within each
			// tag group instance
			// if a tag group is a definition, i.e. study tag group containing
			// study tags or study arm tag group containing study part/study arm
			// tags, the following rules apply:
			// 1. study abbreviation tag is required for study tag groups
			// 2. study abbreviation tag is required for study arm tag groups
			// 3. study arm abbreviation tag is required for study arm tag groups
			// definition tag groups that do not follow the above rules are
			// placed in the orphans tree instead
			// if a tag group is a reference, i.e. tag groups that are not study
			// or study arm containing study, study part, or study arm tags,
			// if the reference cannot be found, the tag group is also placed in
			// the orphans tree
			for (const uuid of instanceOrder) {
				const isStudyTagGroup = !!_.find(instances[uuid], [
					"metadata.tagGroup.name",
					TagGroups.studyTagGroupName,
				]);

				const isPartTagGroup = !!_.find(instances[uuid], [
					"metadata.tagGroup.name",
					TagGroups.studyPartTagGroupName,
				]);

				const isArmTagGroup = !!_.find(instances[uuid], [
					"metadata.tagGroup.name",
					TagGroups.studyArmTagGroupName,
				]);

				const isPopulationTagGroup = !!_.find(instances[uuid], [
					"metadata.tagGroup.name",
					TagGroups.studyPopulationTagGroupName,
				]);

				const isSubgroupTagGroup = !!_.find(instances[uuid], [
					"metadata.tagGroup.name",
					TagGroups.studySubgroupTagGroupName,
				]);

				const partCheck = this.reworkEnabled
					? isPartTagGroup
					: isArmTagGroup;

				const studyData = _.find(instances[uuid], [
					"metadata[0].tag.name",
					TagGroups.studyTagName,
				]);

				const partData = _.find(instances[uuid], [
					"metadata[0].tag.name",
					TagGroups.studyPartTagName,
				]);

				const armData = _.find(instances[uuid], [
					"metadata[0].tag.name",
					TagGroups.studyArmTagName,
				]);

				const populationData = _.find(instances[uuid], [
					"metadata[0].tag.name",
					TagGroups.studyPopulationTagName,
				]);

				const subgroupData = _.find(instances[uuid], [
					"metadata[0].tag.name",
					TagGroups.studySubgroupTagName,
				]);

				let studyKey = null;
				let partKey = null;
				let armKey = null;
				let populationKey = null;
				let subgroupKey = null;

				let studyIndex = -1;
				let partIndex = -1;
				let armIndex = -1;
				let populationIndex = -1;
				let subgroupIndex = -1;

				let orphanStudyKey = null;
				let orphanPartKey = null;

				let orphanStudyIndex = -1;
				let orphanPartIndex = -1;

				if (studyData) {
					const studyTagGroupData = {
						key: "tag-group",
						value: _.get(studyData, "metadata.tagGroup.uuid"),
					};
					studyKey = _.get(studyData, "metadata[0].value");
					studyIndex = _.findIndex(
						studies,
						(study) => study[studyKey]
					);

					// if study index found, do nothing
					if (studyIndex < 0) {
						// fill in missing study data
						if (isStudyTagGroup) {
							// definition tag group, add to studies
							studies.push({
								[studyKey]: {
									title: studyKey,
									children: [],
									data: [
										{ key: "branch", value: "study" },
										{
											key: "composite-key",
											value: _.get(
												studyData,
												"metadata.compositeKey"
											),
										},
									],
								},
							});

							studyIndex = studies.length - 1;
						} else {
							// reference tag group, add to orphans
							orphanStudyKey = studyKey;
							orphanStudyIndex = _.findIndex(
								orphans,
								(study) => study[orphanStudyKey]
							);

							// clear study key
							studyKey = null;

							// if found, do nothing
							if (orphanStudyIndex < 0) {
								// not found, add to orphanage
								orphans.push({
									[orphanStudyKey]: {
										title: `Definition for ${orphanStudyKey} is missing`,
										classes: ["missing-definition"],
										children: [],
										data: [
											{ key: "branch", value: "study" },
											studyTagGroupData,
										],
									},
								});

								orphanStudyIndex = orphans.length - 1;
							}
						}
					}
				}

				if (partData) {
					const partTagGroupData = {
						key: "tag-group",
						value: _.get(partData, "metadata.tagGroup.uuid"),
					};
					partKey = _.get(partData, "metadata[0].value");

					if (studyIndex > -1 && studyKey) {
						// parent study exists, find part index
						partIndex = _.findIndex(
							studies[studyIndex][studyKey].children,
							(part) => part[partKey]
						);

						// if part is found within study, do nothing
						if (partIndex < 0) {
							// parent study does not contain part
							if (partCheck) {
								// definition tag group, add to study children
								studies[studyIndex][studyKey].children.push({
									[partKey]: {
										title: partKey,
										children: [],
										data: [
											{ key: "branch", value: "part" },
											{
												key: "composite-key",
												value: _.get(
													partData,
													"metadata.compositeKey"
												),
											},
										],
									},
								});
							} else {
								// reference tag group, but since study exists
								// add to study children
								studies[studyIndex][studyKey].children.push({
									[partKey]: {
										title: `Definition for ${partKey} is missing`,
										classes: ["missing-definition"],
										children: [],
										data: [
											{
												key: "branch",
												value: "part",
											},
											{
												key: "composite-key",
												value: _.get(
													partData,
													"metadata.compositeKey"
												),
											},
											partTagGroupData,
										],
									},
								});
							}

							partIndex =
								studies[studyIndex][studyKey].children.length -
								1;

							this.studyBranchParents[partKey] = studyKey;
						}
					} else {
						// parent study not found, check orphanage
						orphanPartKey = partKey;

						// clear part key
						partKey = null;

						if (partCheck) {
							// definition tag group, check if there is an orphan
							// study in the instance
							if (orphanStudyIndex > -1 && orphanStudyKey) {
								// check orphan study for orphan part
								orphanPartIndex = _.findIndex(
									orphans[orphanStudyIndex][orphanStudyKey]
										.children,
									(part) => part[orphanPartKey]
								);

								if (orphanPartIndex < 0) {
									// not found, add to orphan study
									orphans[orphanStudyIndex][
										orphanStudyKey
									].children.push({
										[orphanPartKey]: {
											title: wpd.utils.oneLine(`
												Definition for ${orphanPartKey} is
												missing; no parent study
											`),
											classes: ["missing-definition"],
											children: [],
											data: [
												{
													key: "branch",
													value: "part",
												},
												partTagGroupData,
											],
										},
									});

									orphanPartIndex =
										orphans[orphanStudyIndex][
											orphanStudyKey
										].children.length - 1;

									this.studyBranchParents[orphanPartKey] =
										orphanStudyKey;
								}
							} else {
								// directly check orphans root
								orphanPartIndex = _.findIndex(
									orphans,
									(part) => part[orphanPartKey]
								);

								if (orphanPartIndex < 0) {
									// not found, add to orphanage
									orphans.push({
										[orphanPartKey]: {
											title: wpd.utils.oneLine(`
												Definition for ${orphanPartKey} is
												missing; no parent study
											`),
											classes: [
												"missing-definition",
												"orphan",
											],
											children: [],
											data: [
												{
													key: "branch",
													value: "part",
												},
												partTagGroupData,
											],
										},
									});

									orphanPartIndex = orphans.length - 1;
								}
							}
						} else {
							// not a branch definition
							// referencing tag group with only part data, try to
							// find part
							studyIndex = _.findIndex(studies, (study) => {
								const tempStudyKey = _.keys(study)[0];

								// try searching for part directly (assume no part)
								partIndex = _.findIndex(
									study[tempStudyKey].children,
									(part) => part[partKey]
								);

								if (partIndex > -1) {
									// part found
									studyKey = tempStudyKey;

									return true;
								} else {
									return false;
								}
							});

							// if study containing part found, do nothing
							if (studyIndex < 0) {
								// study containing part not found, check orphanage
								orphanStudyIndex = _.findIndex(
									orphans,
									(study) => {
										const tempStudyKey = _.keys(study)[0];

										// try searching for part directly (assume no part)
										orphanPartIndex = _.findIndex(
											study[tempStudyKey].children,
											(part) => part[orphanPartKey]
										);

										if (partIndex > -1) {
											// part found
											orphanStudyKey = tempStudyKey;

											return true;
										} else {
											return false;
										}
									}
								);

								if (orphanStudyIndex < 0) {
									// orphan study not found, add to orphanage root
									orphans.push({
										[orphanPartKey]: {
											title: wpd.utils.oneLine(`
													Definition for ${orphanPartKey} is
													missing; no parent study/part
												`),
											classes: [
												"missing-definition",
												"orphan",
											],
											children: [],
											data: [
												{
													key: "branch",
													value: "part",
												},
												partTagGroupData,
											],
										},
									});

									orphanPartIndex = orphans.length - 1;
								} else {
									// orphan study found, add to orphan study children
									orphans[orphanStudyIndex][
										orphanStudyKey
									].children.push({
										[orphanPartKey]: {
											title: wpd.utils.oneLine(`
													Definition for ${orphanPartKey} is
													missing; no parent study/part
												`),
											classes: ["missing-definition"],
											children: [],
											data: [
												{
													key: "branch",
													value: "part",
												},
												partTagGroupData,
											],
										},
									});

									orphanPartIndex =
										orphans[orphanStudyIndex][
											orphanStudyKey
										].children.length - 1;

									this.studyBranchParents[orphanPartKey] =
										orphanStudyKey;
								}
							}
						}
					}
				}

				if (armData) {
					const armTagGroupData = {
						key: "tag-group",
						value: _.get(armData, "metadata.tagGroup.uuid"),
					};
					armKey = _.get(armData, "metadata[0].value");

					if (studyIndex > -1 && studyKey) {
						// found the study containing the part containing the arm
						// or found the study containing the arm
						// parent study exists, find parent part
						if (!this.reworkEnabled) {
							partIndex = _.findIndex(
								studies[studyIndex][studyKey].children,
								(part) => {
									if (!partKey) {
										// attempt to find the index of the
										// part that contains the arm
										partKey = _.findKey(
											part,
											({ children: partChildren }) => {
												armIndex = _.findIndex(
													partChildren,
													(arm) => arm[armKey]
												);

												return armIndex > -1;
											}
										);
									}

									return part[partKey];
								}
							);
						}

						if (partIndex > -1 && partKey) {
							// found part containing arm, find arm index
							armIndex = _.findIndex(
								studies[studyIndex][studyKey].children[
									partIndex
								][partKey].children,
								(arm) => arm[armKey]
							);

							if (armIndex < 0) {
								let title = `Definition for ${armKey} is missing`;
								let classes = ["missing-definition"];

								if (isArmTagGroup) {
									// arm is defined in the study arm tag group
									title = armKey;
									classes = undefined;
								}

								studies[studyIndex][studyKey].children[
									partIndex
								][partKey].children.push({
									[armKey]: {
										title,
										classes,
										children: [],
										data: [
											{ key: "branch", value: "arm" },
											{
												key: "composite-key",
												value: _.get(
													armData,
													"metadata.compositeKey"
												),
											},
											armTagGroupData,
										],
									},
								});

								this.studyBranchParents[armKey] = partKey;
							}
						} else {
							// found the study but cannot find the part containing
							// the arm, attach to study
							armIndex = _.findIndex(
								studies[studyIndex][studyKey].children,
								(arm) => arm[armKey]
							);

							if (armIndex < 0) {
								let title = `Definition for ${armKey} is missing`;
								let classes = ["missing-definition"];

								if (isArmTagGroup) {
									// arm is defined in the study arm tag group
									title = armKey;
									classes = undefined;
								}

								studies[studyIndex][studyKey].children.push({
									[armKey]: {
										title,
										classes,
										children: [],
										data: [
											{
												key: "branch",
												value: "arm",
											},
											{
												key: "composite-key",
												value: _.get(
													armData,
													"metadata.compositeKey"
												),
											},
											armTagGroupData,
										],
									},
								});

								this.studyBranchParents[armKey] = studyKey;
							}
						}
					} else {
						if (isArmTagGroup) {
							// cannot find study containing the part containing the arm
							// or cannot find the study containing the arm
							const orphanArmKey = armKey;
							let orphanArmIndex = -1;

							if (orphanPartIndex < 0) {
								// attempt to find the orphaned part containing the arm
								orphanPartIndex = _.findIndex(
									orphans,
									(part) => {
										if (!orphanPartKey) {
											orphanPartKey = _.findKey(
												part,
												({
													children: partChildren,
												}) => {
													orphanArmIndex =
														_.findIndex(
															partChildren,
															(arm) =>
																arm[
																	orphanArmKey
																]
														);

													return orphanArmIndex > -1;
												}
											);
										}

										return part[orphanPartKey];
									}
								);
							}

							if (orphanPartIndex > -1 && orphanPartKey) {
								// found orphaned part containing the arm
								if (orphanArmIndex < 0) {
									// still not found, add to orphanage
									orphans[orphanPartIndex][
										orphanPartKey
									].children.push({
										[orphanArmKey]: {
											title: wpd.utils.oneLine(`
												Definition for ${orphanArmKey} is
												missing; parent part does not have a
												parent study
											`),
											classes: [
												"missing-definition",
												"orphan",
											],
											children: [],
											data: [
												{ key: "branch", value: "arm" },
												armTagGroupData,
											],
										},
									});

									this.studyBranchParents[orphanArmKey] =
										orphanPartKey;
								}
							} else {
								// did not find orphaned arm while looking for orphaned part
								// attempt to find orphaned arm directly
								orphanArmIndex = _.findIndex(
									orphans,
									(arm) => arm[orphanArmKey]
								);

								if (orphanArmIndex < 0) {
									// still not found, add to orphanage
									orphans.push({
										[orphanArmKey]: {
											title: wpd.utils.oneLine(`
												Definition for ${orphanArmKey} is
												missing; no parent study/part
											`),
											classes: [
												"missing-definition",
												"orphan",
											],
											children: [],
											data: [
												{ key: "branch", value: "arm" },
												armTagGroupData,
											],
										},
									});
								}
							}
						} else {
							// not a branch definition
							// referencing tag group with only arm data, try to
							// find arm
							studyIndex = _.findIndex(studies, (study) => {
								const tempStudyKey = _.keys(study)[0];

								// try searching for arm directly (assume no part)
								armIndex = _.findIndex(
									study[tempStudyKey].children,
									(arm) => arm[armKey]
								);

								if (armIndex > -1) {
									// direct arm found
									studyKey = tempStudyKey;

									return true;
								} else {
									// no direct arm found, try searching for
									// arms in parts
									partIndex = _.findIndex(
										study[tempStudyKey].children,
										(part) => {
											const tempPartKey = _.keys(part)[0];

											armIndex = _.findIndex(
												part[tempPartKey].children,
												(arm) => arm[armKey]
											);

											if (armIndex > -1) {
												// part containing arm found
												partKey = tempPartKey;
												studyKey = tempStudyKey;

												return true;
											} else {
												return false;
											}
										}
									);

									return partIndex > -1;
								}
							});

							if (studyIndex < 0) {
								// study not found, add to orphanage if not
								// already in there
								armIndex = _.findIndex(
									orphans,
									(arm) => arm[armKey]
								);

								if (armIndex < 0) {
									orphans.push({
										[armKey]: {
											title: wpd.utils.oneLine(`
													Definition for ${armKey} is
													missing; no parent study/part
												`),
											classes: [
												"missing-definition",
												"orphan",
											],
											children: [],
											data: [
												{ key: "branch", value: "arm" },
												armTagGroupData,
											],
										},
									});
								}
							}
						}
					}
				}

				if (this.reworkEnabled && populationData) {
					const populationTagGroupData = {
						key: "tag-group",
						value: _.get(populationData, "metadata.tagGroup.uuid"),
					};
					populationKey = _.get(populationData, "metadata[0].value");
					if (armIndex > -1 && armKey) {
						const parent =
							partIndex > -1 && partKey
								? studies[studyIndex][studyKey].children[
										partIndex
								  ][partKey].children[armIndex][armKey].children
								: studies[studyIndex][studyKey].children[
										armIndex
								  ][armKey].children;

						populationIndex = _.findIndex(
							parent,
							(population) => population[populationKey]
						);
						if (populationIndex < 0) {
							let title = `Definition for ${populationKey} is missing`;
							let classes = ["missing-definition"];

							if (isPopulationTagGroup) {
								// arm is defined in the study arm tag group
								title = populationKey;
								classes = undefined;
							}

							parent.push({
								[populationKey]: {
									title,
									classes,
									children: [],
									data: [
										{ key: "branch", value: "population" },
										{
											key: "composite-key",
											value: _.get(
												populationData,
												"metadata.compositeKey"
											),
										},
										populationTagGroupData,
									],
								},
							});

							this.studyBranchParents[populationKey] = armKey;
						}
					}
				}

				if (this.reworkEnabled && subgroupData) {
					const subgroupTagGroupData = {
						key: "tag-group",
						value: _.get(subgroupData, "metadata.tagGroup.uuid"),
					};
					subgroupKey = _.get(subgroupData, "metadata[0].value");

					const hasPopulation = populationIndex > -1 && populationKey;
					const hasPart = partIndex > -1 && partKey;
					const hasArm = armIndex > -1 && armKey;

					if (hasPart || hasArm) {
						let parent;
						if (!hasPart && !hasPopulation) {
							parent =
								studies[studyIndex][studyKey].children[
									armIndex
								][armKey].children;
						} else if (hasPart && !hasPopulation) {
							parent =
								studies[studyIndex][studyKey].children[
									partIndex
								][partKey].children[armIndex][armKey].children;
						} else if (!hasPart && hasPopulation) {
							parent =
								studies[studyIndex][studyKey].children[
									armIndex
								][armKey].children[populationIndex][
									populationKey
								].children;
						} else {
							parent =
								studies[studyIndex][studyKey].children[
									partIndex
								][partKey].children[armIndex][armKey].children[
									populationIndex
								][populationKey].children;
						}

						subgroupIndex = _.findIndex(
							parent,
							(subgroup) => subgroup[subgroupKey]
						);

						if (subgroupIndex < 0) {
							let title = `Definition for ${subgroupKey} is missing`;
							let classes = ["missing-definition"];

							if (isSubgroupTagGroup) {
								title = subgroupKey;
								classes = undefined;
							}

							parent.push({
								[subgroupKey]: {
									title,
									classes,
									children: [],
									data: [
										{ key: "branch", value: "subgroup" },
										{
											key: "composite-key",
											value: _.get(
												subgroupData,
												"metadata.compositeKey"
											),
										},
										subgroupTagGroupData,
									],
								},
							});

							this.studyBranchParents[subgroupKey] = hasPopulation
								? populationKey
								: armKey;
						}
					}
				}
			}

			return [...studies, ...orphans];
		}

		#findDataPointIndexFromTagUuid(dataset, uuid) {
			const tagGroupPointIndexes = _.keys(
				_.pickBy(
					dataset.getTagGroups(),
					(tagGroup) => tagGroup.uuid === this.activeTagGroup.uuid
				)
			).map((index) => parseInt(index, 10));

			return dataset.getAllPixels().findIndex((dataPoint, index) => {
				if (tagGroupPointIndexes.includes(index)) {
					const metadata = dataPoint.metadata;
					if (
						metadata.tagGroup.instanceUuid ===
						this.activeInstanceUuid
					) {
						return dataPoint.metadata[0].tag?.uuid === uuid;
					}
				}

				return false;
			});
		}

		#resetTagGroupToolbar() {
			const toolbar = wpdDocument.getElementById(tagGroupContainerId);

			// display no data text and clear the tag and data containers
			toolbar.querySelector(".tags .title").innerHTML = "Tags";
			toolbar.querySelector(".tags .content").innerHTML = "";
			toolbar.querySelector(".data .content").innerHTML = "";
		}

		#initializeTagGroupToolbar(tagGroupName, tags, collected) {
			const toolbar = wpdDocument.getElementById(tagGroupContainerId);

			// populate tag group toolbar
			toolbar.querySelector(".tags .title").innerHTML =
				this.#generateTagGroupTagTitle(tagGroupName);
			toolbar.querySelector(".tags .content").innerHTML =
				this.#generateTagGroupButtons(tagGroupName, tags);
			toolbar.querySelector(".data .content").innerHTML =
				this.#generateCollectedDataList(collected);
		}

		#getActiveInstanceTagValues() {
			return _.chain(this.#getActiveInstanceDataPoints())
				.map(({ metadata }) =>
					_.chain(Object.keys(metadata))
						// only include pairs that are keyed by numbers
						.filter((key) => !isNaN(key))
						.map((key) => ({
							tag: metadata[key].tag?.name,
							value: metadata[key].value,
						}))
						.value()
				)
				.flatten()
				.value();
		}

		#getActiveInstanceTagUuids() {
			return _.chain(this.#getActiveInstanceDataPoints())
				.map(({ metadata }) =>
					_.chain(Object.keys(metadata))
						// only include pairs that are keyed by numbers
						.filter((key) => !isNaN(key))
						.map((key) => metadata[key].tag?.uuid)
						.value()
				)
				.flatten()
				.value();
		}

		#getActiveInstanceDataPoints() {
			if (this.activeInstanceUuid) {
				// get all data points associated with the current tag group
				const dataset = wpd.tree.getActiveDataset();
				return (
					_.chain(dataset.getAllPixels())
						// filter to data points that are associated with the active
						// tag group instance
						.filter([
							"metadata.tagGroup.instanceUuid",
							this.activeInstanceUuid,
						])
						.value()
				);
			}

			return [];
		}

		#getActiveInstanceDataPointIndexes() {
			if (this.activeInstanceUuid) {
				// get all data points associated with the current tag group
				const dataset = wpd.tree.getActiveDataset();
				return _.chain(dataset.getAllPixels())
					.reduce((indexes, pixel, index) => {
						if (
							_.get(pixel, "metadata.tagGroup.instanceUuid") ===
							this.activeInstanceUuid
						) {
							indexes.push(index);
						}

						return indexes;
					}, [])
					.sortBy()
					.reverse()
					.value();
			}

			return [];
		}

		#toggleTagGroup(displayValue) {
			try {
				// toggle tag group work area (center toolbar)
				const toolbarContainer = wpdDocument.getElementById(
					centerToolbarContainerId
				);
				toolbarContainer.style.display = displayValue;

				// toggle tag group combobox
				if (this.awesomplete) {
					const combobox =
						this.awesomplete.container.querySelector(".combobox");
					combobox.style.display = displayValue;
				}
			} catch (error) {
				console.error("Failed to show tag group input: %o", error);
			}
		}

		#unpressAllButtons() {
			const allButtons = wpdDocument.querySelectorAll(".tag-group-tag");
			allButtons.forEach((button) =>
				button.classList.remove("pressed-button")
			);
		}

		#generateTagGroupTagTitle(tagGroupName) {
			return wpd.utils.oneLine(`
				${tagGroupName} tags
				${
					!this.reworkEnabled
						? `<button
					class="tag-group-clear-tags-button"
					onclick="wpd.tagGroups.showClearTagPopup()"
				>
					Clear all
				</button>`
						: ""
				}${
				this.reworkEnabled && !this.isKeyTagGroup(tagGroupName)
					? `<button
					class="tag-group-select-study-branch-button"
					onclick="wpd.tagGroups.selectStudyBranch()"
				>
					Select study branch
				</button>`
					: ""
			}${
				this.reworkEnabled && this.isKeyTagGroup(tagGroupName)
					? `<button
						class="tag-group-add-tag-group-instance-button"
						onclick="wpd.tagGroups.addTagGroupInstance()"
					>
						Add new instance
					</button>`
					: ""
			}
			`);
		}

		#generateTagGroupButtons(tagGroupName, tags) {
			return tags.reduce((acc, { name, uuid }) => {
				const baseClass = "tag-group-tag";

				const isKey = this.isKeyTag(name);
				if (this.reworkEnabled) {
					if (isKey) return acc + "";
				} else {
					if (this.isReworkOnlyKey(name)) return acc + "";
				}

				const keyClass = isKey ? "key" : "";

				const isRequired = this.isRequiredTag(tagGroupName, name);
				const requiredClass = isKey && isRequired ? "required" : "";

				const classList = _.chain([baseClass, keyClass, requiredClass])
					.compact()
					.join(" ");

				let tooltip = "";
				if (isKey) {
					if (isRequired) {
						tooltip = `${name} is a special identifier used in defining a branch in the study`;
					} else {
						tooltip = `${name} is a special identifier used in attaching data to a branch in the study`;
					}
				}

				return (
					acc +
					`
						<input
							class="${classList}"
							type="button"
							value="${name}"
							data-tag-uuid="${uuid}"
							onclick="wpd.tagGroups.selectTag(this, ${isKey && this.reworkEnabled})"
							title="${tooltip}"
						/>
					`
				);
			}, "");
		}

		#generateCollectedDataList(data) {
			return data.reduce((acc, { tag, value }) => {
				const tagDisplay = tag ? tag.trim() : "n/a";
				return (
					acc +
					`
						<div class="collected-data-row">
							<span class="tag">${tagDisplay}</span>: ${value}
						</div>
					`
				);
			}, "");
		}
	}

	wpd.tagGroups = new TagGroups(
		dataEntryVM.getTagGroups(),
		dataEntryVM.getTags()
	);
}

export { init };
