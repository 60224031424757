<template>
	<v-container id="data-entry" class="pa-0" height="100%" fluid>
		<v-overlay :model-value="loading" class="align-center justify-center">
			<v-progress-circular color="white" indeterminate size="64" />
		</v-overlay>

		<iframe :id="module" ref="iframe" src="/modules/wpd/app/index.html" />

		<v-metadata
			v-if="showMetadata"
			ref="metadata"
			:bounds="bounds"
			:tags="tags"
			:tag-groups="tagGroups"
		/>

		<v-hint ref="hint" :bounds="bounds" />

		<data-entry-menu @open-file-in-new-tab="openFileInNewTab" />

		<data-entry-validation-dialog
			v-model="validationDialog"
			:validation-data="validationData"
			:module-window="moduleWindow"
		/>
	</v-container>
</template>

<script>
import _ from "lodash";
import { mapMutations } from "vuex";

import interfaces from "@/plugins/interfaces";
import auth from "@/auth";
import utils from "@/utils";
import { useLDFlag } from "launchdarkly-vue-client-sdk";

import Timer from "@/assets/scripts/autosave-timer.worker.js";

import VHint from "@/components/VHint";
import VMetadata from "@/components/VMetadata";
import DataEntryMenu from "@/pages/DataEntry/DataEntryMenu";
import DataEntryValidationDialog from "@/pages/DataEntry/DataEntryValidationDialog";
import tableAxisInstruction from "@/assets/images/table_axis_instruction.gif";
import createCompositeKeyUtils from "@/compositeKeyUtils";
import { ref, onMounted } from "vue";

const autoSaveWait = 300000; // 5 mins

export default {
	name: "DataEntry",

	components: {
		DataEntryMenu,
		DataEntryValidationDialog,
		VHint,
		VMetadata,
	},

	async beforeRouteLeave(to, from, next) {
		if (!this.skipDirtyCheck && this.checkDirty()) {
			this.$emit("deactivate-activity-timer");

			// NOTE - The vue router beforeRouteLeave is only hit after the first attempt to
			// navigate away; a subsequent click will bypass this function.  To prevent data loss,
			// save after cancelling the first navigation.
			// NOTE - session timeouts can cause the code to reach here without
			// authentication
			if (auth.isAuthenticated()) {
				if (
					!confirm(
						"You have unsaved changes. Do you really want to leave?"
					)
				) {
					await this.timedAutoSave();
					this.$emit("activate-activity-timer");
					return next(false);
				}

				this.$emit("activate-activity-timer");
			}
		}

		document.removeEventListener("keydown", this.keyDownHandler);

		this.deactivateAutoSave();

		// clear dirty flag
		this.clearDirty();

		// destroy the autosave worker
		this.timer?.terminate();
		this.timer = null;

		// reset the rest of the component
		this.clear();

		// emit signal
		if (this.training) {
			this.$emit("leave-data-entry-training");
		} else {
			this.$emit("leave-data-entry");
		}

		return next();
	},

	props: {
		training: Boolean,
	},
	emits: [
		"enter-data-entry-training",
		"enter-data-entry",
		"leave-data-entry-training",
		"leave-data-entry",
		"activate-activity-timer",
		"deactivate-activity-timer",
		"check-dirty",
		"handle-logout-save",
	],

	setup() {
		const showMetadata = ref(false);
		onMounted(() => {
			setTimeout(() => {
				showMetadata.value = true;
			}, 100);
		});

		const fixMetadataAutoSaveFlag = useLDFlag(
			"wpd-autosave-open-metadata-fix"
		);
		const reworkEnabledFlag = useLDFlag("wpd-composite-key-rework");

		return {
			fixMetadataAutoSaveFlag,
			reworkEnabledFlag,
			showMetadata,
		};
	},

	data() {
		return {
			autoSaveComplete: true,
			bounds: null,
			compositeKeyUtils: null,
			existingData: null,
			fileMap: {},
			loading: true,
			paperData: null,
			menu: false,
			moduleWindow: null,
			skipDirtyCheck: false,
			tags: [],
			tagGroups: [],
			timer: null,
			validationData: null,
			validationDialog: false,
		};
	},

	computed: {
		dirty() {
			return this.$store.getters.dirty;
		},
		domain() {
			return process.env.VUE_APP_HOST;
		},
		done() {
			return this.$store.getters.done;
		},
		exit() {
			return this.$store.getters.exit;
		},
		fixMetadataAutoSave() {
			return this.fixMetadataAutoSaveFlag;
		},
		metadataCache() {
			return this.$store.getters.metadataCache;
		},
		module() {
			return this.$store.getters.module;
		},
		paper() {
			return this.$store.getters.paper;
		},
		port() {
			return parseInt(process.env.VUE_APP_API_PORT, 10) + 1;
		},
		protocol() {
			return process.env.NODE_ENV === "development" ? "http" : "https";
		},
		resetDataEntry() {
			return this.$store.getters.resetDataEntry;
		},
		resolve() {
			return this.$store.getters.resolve;
		},
		reworkEnabled() {
			const created = this.existingData
				? new Date(this.existingData.created_at)
				: null;
			const cutoff = new Date("2024-10-12T00:00:00Z");
			const isPastDateCutoff =
				!created || created.getTime() > cutoff.getTime();

			return isPastDateCutoff && this.reworkEnabledFlag;
		},
		save() {
			return this.$store.getters.save;
		},
		saveUUID() {
			return this.$store.getters.saveUUID;
		},
		validate() {
			return this.$store.getters.validate;
		},
	},

	watch: {
		resetDataEntry(val) {
			if (val) {
				this.resetJSON();
			}
		},
		save(val) {
			if (val) {
				this.saveJSON(interfaces[this.module].saveJSON());
			}
		},
		validate(json) {
			if (json) {
				this.validateJSON(interfaces[this.module].saveJSON());
			}
		},
	},

	async mounted() {
		// make sure tags and tag groups are resolved before initializing the
		// iframe
		await Promise.all([
			this.fetchTags(),
			this.fetchTagGroups(),
			this.waitForIframeLoad(),
		]);

		this.compositeKeyUtils = createCompositeKeyUtils(this.tags);
		this.iframeInit();

		this.activateAutoSave();
	},

	beforeUnmount() {
		interfaces[this.module].unobserve();
		// destroy the autosave worker
		this.timer?.terminate();
		this.timer = null;
	},
	created() {
		this.$emit("check-dirty", this.checkDirty);
		this.$emit("handle-logout-save", this.handleLogoutSave);
	},

	methods: {
		...mapMutations([
			"setHelp",
			"clearDirty",
			"clearPaper",
			"clearSaveUUID",
			"receiveSave",
			"receiveValidate",
			"clearMetadataCache",
			"setDirty",
			"setPaper",
			"showNotification",
		]),
		async iframeInit() {
			this.moduleWindow = window.frames[this.module].contentWindow;

			// forward keyboard events to the iframe
			document.removeEventListener("keydown", this.keyDownHandler);
			document.addEventListener("keydown", this.keyDownHandler);

			// forward custom events to the iframe
			document.removeEventListener("next-cell", this.nextCellHandler);
			document.addEventListener("next-cell", this.nextCellHandler);

			await this.getPaper();

			// set images for wpd
			if (this.module === "wpd") {
				const domain =
					process.env.NODE_ENV === "development"
						? `${this.domain}:8080`
						: this.domain;
				const url = `${this.protocol}://${domain}${tableAxisInstruction}`;

				interfaces[this.module].setOptions({
					tableAxisInstructionURL: url,
				});
			}

			interfaces[this.module].init(this.moduleWindow, this);

			this.loadFiles();

			if (this.training) {
				this.$emit("enter-data-entry-training");
			} else {
				this.$emit("enter-data-entry");
			}
		},
		clear() {
			this.autoSaveComplete = true;
			this.bounds = null;
			this.existingData = null;
			this.fileMap = {};
			this.loading = true;
			this.paperData = null;
			this.moduleWindow = null;
			this.skipDirtyCheck = false;
			this.tags = [];
			this.tagGroups = [];
			this.timer = null;
			this.userManualActive = false;
			this.validationData = null;
			this.validationDialog = false;
		},
		nextCellHandler() {
			this.moduleWindow.document?.dispatchEvent(new Event("next-cell"));
		},
		keyDownHandler(event) {
			const newEvent = new KeyboardEvent("keydown", {
				code: event.code,
				key: event.key,
				keyCode: event.keyCode,
				ctrlKey: event.ctrlKey,
				metaKey: event.metaKey,
				shiftKey: event.shiftKey,
			});

			this.moduleWindow.document?.dispatchEvent(newEvent);
		},
		resizeIframe() {
			this.bounds = interfaces[this.module].getBounds();
		},
		getPaper() {
			return this.$http
				.get(`/papers/${this.paper.uuid}`, {
					params: {
						saveUUID: this.saveUUID,
						...(this.training ? { isTraining: true } : {}),
					},
				})
				.then((response) => {
					this.paperData = response.data;
					if (response.data.saveData !== null) {
						this.existingData = response.data.saveData;
					}
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				});
		},
		loadFiles() {
			this.fileMap = {};

			// convert base64 data to File objects
			this.paperData.fileData.forEach((data) => {
				const file = new File(
					[utils.base64ToBlob(data.data, data.type)],
					data.name,
					{
						type: data.type,
					}
				);

				this.fileMap[data.uuid] = file;
			});

			// load files into module
			interfaces[this.module]
				.loadFiles(Object.values(this.fileMap))
				.then(async () => {
					// get image bounds
					this.bounds = interfaces[this.module].getBounds();

					// script injection for additional UIs
					interfaces[this.module].inject(this);

					// load json file if it exists
					if (this.existingData) {
						await interfaces[this.module].loadJSON(
							this,
							this.existingData.data
						);
					} else {
						this.loading = false;
					}
				});
		},
		resetJSON() {
			this.$http
				.post(`/data-entry/${this.saveUUID}`, {
					data: null,
					done: this.done,
					paperUUID: this.paper.uuid,
					dataFormat: "WPD",
					...(this.training ? { isTraining: true } : {}),
				})
				.then(() => {
					this.showNotification({
						message: "Data reset successfully",
						status: "success",
					});

					this.existingData = {};
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					// consume save event
					this.receiveSave();

					// resolve if caller is a promise
					if (this.resolve) {
						this.resolve();
					}

					window.location.reload();
				});
		},

		async saveJSON(json) {
			const versionedJSON = this.addDactylVersion(json);

			// save json to server
			return this.$http
				.post(`/data-entry/${this.saveUUID}`, {
					data: versionedJSON,
					done: this.done,
					paperUUID: this.paper.uuid,
					dataFormat: "WPD",
					...(this.training ? { isTraining: true } : {}),
				})
				.then((response) => {
					if (this.done) {
						this.showNotification({
							message: "Paper saved and marked as done",
							status: "success",
						});

						// clear paper from store
						this.clearPaper();

						// clear save UUID
						this.clearSaveUUID();
					} else {
						if (this.existingData) {
							this.existingData.data = JSON.parse(versionedJSON);
						} else {
							this.existingData = {
								data: JSON.parse(versionedJSON),
								created_at: new Date().toLocaleString(),
							};
						}
						// display success
						this.showNotification({
							message: response.data.message,
							status: response.data.status,
							timeout: 1000,
						});
					}
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					const navigate = this.done || this.exit;

					// consume save event
					this.receiveSave();

					// resolve if caller is a promise
					if (this.resolve) {
						this.resolve();
					}

					if (navigate) {
						// skip navigation confirmation
						this.skipDirtyCheck = true;

						// redirect to dashboard
						this.$router.push("/home/dashboard");
					}
				});
		},
		validateJSON(json) {
			this.$http
				.post(`/data-entry/${this.saveUUID}/validate`, {
					data: json,
				})
				.then((response) => {
					this.validationData = response.data;
					this.validationDialog = true;
				})
				.catch((error) => {
					this.showNotification(error.response.data);
				})
				.finally(() => {
					this.receiveValidate();
				});
		},
		addDactylVersion(json) {
			const object = JSON.parse(json);

			object.dactylVersion = this.$version;

			return JSON.stringify(object);
		},
		fetchTags() {
			return this.$http
				.get("/tags", {
					params: {
						flat: true,
						padded: true,
					},
				})
				.then((response) => {
					this.tags = [...response.data.tags];
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				});
		},
		getTags() {
			return this.tags;
		},
		fetchTagGroups() {
			return this.$http
				.get("/tag-groups", {
					params: {
						filteredByLevel: true,
						omitDeleted: true,
					},
				})
				.then((response) => {
					this.tagGroups = [...response.data.tagGroups];
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				});
		},
		getTagGroups() {
			return this.tagGroups;
		},
		captureMetadata(args) {
			return this.$refs.metadata.open(args);
		},
		showNoHeaderHint(args) {
			const article = {
				row: "row does",
				col: "column does",
				both: "row and column do",
			};

			const messages = [
				`This ${article[args.mode]} not have header information.`,
				"Add data to the headers by selecting the axis, and then you can add data here.",
			];

			this.$refs.hint.open({
				color: "error",
				messages: messages,
				position: args.position,
			});
		},
		hideNoHeaderHint() {
			this.$refs.hint.close();
		},
		checkDirty() {
			const currentJson = interfaces[this.module].saveJSON();
			const currentVersionedJson = this.addDactylVersion(currentJson);
			const currentVersionedData = JSON.parse(currentVersionedJson);

			// TODO figure out why some auto-saves wipes out all data
			// prevent setting dirty flag if axesColl and datasetColl is empty
			// the cause of this intermittent bug has not been identified
			const noData =
				currentVersionedData.axesColl.length === 0 &&
				currentVersionedData.datasetColl.length === 0;

			const existingData = this.existingData?.data;
			if (
				(noData && !existingData) ||
				_.isEqual(currentVersionedData, this.existingData?.data)
			) {
				this.clearDirty();
				return false;
			}

			// the store dirty flag is for warning user that unsaved changes
			// will be lost
			this.setDirty();

			return true;
		},
		async timedAutoSave() {
			if (
				!this.$refs ||
				!this.$refs.metadata ||
				(this.fixMetadataAutoSave && this.$refs.metadata?.dialog)
			)
				return;

			// update in-progress metadata
			this.$refs.metadata.updateMetadataCache();

			if (!_.isEmpty(this.metadataCache)) {
				// metadata populated, save to json
				interfaces[this.module].setMetadata(this.metadataCache);

				// clear dactyl store metadata
				this.clearMetadataCache();
			}

			if (this.checkDirty()) {
				// save json
				await this.saveJSON(interfaces[this.module].saveJSON());
				this.$refs.metadata.refreshMetadata();
			}
		},
		async autoSaveMetadata() {
			if (this.checkDirty()) {
				const saveData = interfaces[this.module].saveJSON(this);
				await this.saveJSON(saveData);
			}
			this.autoSaveComplete = true;
		},
		activateAutoSave() {
			// create the worker if necessary
			if (!this.timer) {
				this.timer = new Timer();
			}

			// listen for timer messages
			this.timer.onmessage = async ({ data }) => {
				// process interval ticks
				if (auth.isAuthenticated() && data === "interval_tick") {
					await this.timedAutoSave();
				}
			};

			// start the interval
			this.timer.postMessage({
				action: "start_interval",
				duration: autoSaveWait,
			});
		},
		deactivateAutoSave() {
			this.timer?.postMessage({ action: "stop_interval" });
		},
		hintKeydownHandler(event) {
			switch (event.key) {
				case "Escape":
					this.hideNoHeaderHint();
					break;
			}
		},
		waitForIframeLoad() {
			return new Promise((resolve) => {
				this.$refs.iframe.removeEventListener("load", resolve);
				this.$refs.iframe.addEventListener("load", resolve);

				this.$refs.iframe.removeEventListener(
					"resize",
					this.resizeIframe
				);
				this.$refs.iframe.addEventListener("resize", this.resizeIframe);
			});
		},
		openFileInNewTab() {
			// get current file index
			const { file: fileIndex, page: pageNumber } =
				interfaces[this.module].getCurrentLocationInfo();

			// get file at current file index
			const fileData = Object.values(this.fileMap)[fileIndex];

			// open file in new tab
			const objectUrl = URL.createObjectURL(fileData);
			window.open(`${objectUrl}#page=${pageNumber}`);
			URL.revokeObjectURL(objectUrl);
		},
		async handleLogoutSave() {
			if (this.$refs.metadata?.dialog) {
				// Close the metadata dialog and export the existing metadata
				this.autoSaveComplete = false;

				this.$refs.metadata.resolve(
					this.$refs.metadata.exportMetadata()
				);

				// This is some hackery - it's tough to synchronously wait for the post-processing and saving
				// that happens when we closed the metadata dialog.  Instead, simply wait for "autoSaveComplete"
				// variable to be set to true, which will happen after post-processing and after the save completes.
				await new Promise((resolve) => {
					const unwatch = this.$watch(
						"autoSaveComplete",
						(newValue) => {
							if (newValue) {
								unwatch();
								resolve();
							}
						},
						{ immediate: true }
					);
				});
			} else {
				await this.autoSaveMetadata();
			}
		},
	},
};
</script>

<style scoped lang="scss">
iframe {
	width: 100%;
	height: 100%;
	border: 0;
}

.v-btn--fab.v-size--small.v-btn--absolute.v-btn--bottom.menu-button {
	bottom: 25px;
	right: 25px;
	z-index: 204;
}
</style>
