<template>
	<v-autocomplete
		ref="autocomplete"
		v-model="selectedPermissions"
		:disabled="disabled"
		:items="permissions"
		:label="label"
		:loading="loading"
		:messages="noResults"
		append-icon=""
		clearable
		closable-chips
		hide-no-data
		hint="Type in keywords to begin searching"
		item-color="secondary"
		:item-title="composedText"
		item-value="uuid"
		multiple
		persistent-hint
		prepend-icon="mdi-magnify"
		return-object
		density="compact"
		@update:model-value="updatePermissions"
	>
		<template #chip="{ item, index }">
			<v-chip
				class="bg-secondary text-white"
				closable
				@click:close="removeItem(index)"
			>
				{{ item.title }}
			</v-chip>
		</template>
	</v-autocomplete>
</template>

<script>
import { mapMutations } from "vuex";
import utils from "@/utils";
import _ from "lodash";

export default {
	name: "PermissionSearch",

	props: {
		defaultPermissions: { type: Array, default: () => [] },
		disabled: Boolean,
		label: {
			default: "Search for permissions",
			type: String,
		},
		modelValue: { type: Array, default: () => [] },
	},
	emits: [
		"update:modelValue",
		"permission-updated",
		"update:permissions-loading",
	],

	data() {
		return {
			loading: true,
			noResults: "",
			permissions: [],
		};
	},

	computed: {
		selectedPermissions: {
			get() {
				return this.modelValue;
			},
			set(val) {
				this.$emit("update:modelValue", val);
			},
		},
	},

	watch: {
		defaultPermissions(val) {
			this.loadPermissions(val);
		},
	},

	mounted() {
		this.getAllPermissions();
		this.loadPermissions();
	},

	methods: {
		...mapMutations(["showNotification"]),
		clear() {
			this.doneLoading();

			if (this.defaultPermissions.length) {
				this.loadPermissions();
			}
		},
		getAllPermissions: _.debounce(function () {
			this.loading = "secondary";
			this.$http
				.get("/permissions", {
					params: {
						omitDeleted: true,
					},
				})
				.then((response) => {
					this.permissions = response.data.permissions.data;

					// display no results message if necessary
					if (this.permissions.length) {
						this.noResults = "";
					} else {
						this.noResults = "No results";
					}
				})
				.catch(({ response }) => {
					if (response.data.message) {
						this.showNotification(response.data);
					}
				})
				.finally(() => {
					this.doneLoading();
				});
		}, 200),
		loadPermissions() {
			if (this.defaultPermissions.length) {
				this.selectedPermissions = [...this.defaultPermissions];
			}
		},
		updatePermissions(val) {
			this.$emit("permission-updated", val);
		},
		doneLoading() {
			this.loading = false;
			this.$emit("update:permissions-loading", false);
		},
		removeItem(index) {
			this.selectedPermissions = this.selectedPermissions.filter(
				(_, i) => i !== index
			);
		},
		composedText(permission) {
			return utils.truncateText(permission.name, 100);
		},
	},
};
</script>

<style scoped lang="scss">
:deep(.v-field__input) {
	min-height: 60px;
	padding-top: 20px;
}
</style>
