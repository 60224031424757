<template>
	<v-table v-if="paper">
		<template #default>
			<tbody>
				<tr
					v-for="(value, key) in listItems"
					:key="key"
				>
					<td style="width: 200px">
						{{ value }}
					</td>

					<td v-if="key === 'projects'">
						<v-chip
							v-for="project in paper[key]"
							:key="project.uuid"
							size="small"
						>
							{{ project.name }}
						</v-chip>
					</td>

					<td v-else>
						{{ paper[key] }}
					</td>
				</tr>
			</tbody>
		</template>
	</v-table>
</template>

<script>
export default {
	name: "PaperInfo",

	props: {
		paper: { type: Object, default: () => null },
	},

	data() {
		return {
			listItems: {
				name: "Paper name",
				doi: "DOI",
				pmid: "PubMed ID",
				pmcid: "PubMed Central ID",
				url: "Source URL",
				projects: "Associated projects",
			},
		};
	},
};
</script>

<style scoped lang="scss"></style>
