<template>
	<v-card class="pb-5">
		<v-toolbar
			color="primary"
			dark
			flat
		>
			<v-toolbar-title>
				{{ title }}
			</v-toolbar-title>

			<v-spacer />

			<v-hover-icon class="mr-3" />
		</v-toolbar>

		<v-card-subtitle class="py-5 text-body-1 text-center">
			Are you sure you want to delete the
			<span class="bg-grey-lighten-2 rounded pa-1">{{ name }}</span>
			tag group?
		</v-card-subtitle>
		<v-card-actions>
			<v-spacer />

			<v-btn
				class="bg-secondary text-white"
				elevation="2"
				@click="softDelete"
			>
				Delete
			</v-btn>

			<v-btn
				class="bg-white"
				elevation="2"
				@click="close"
			>
				Cancel
			</v-btn>

			<v-spacer />
		</v-card-actions>
	</v-card>
</template>

<script>
import VHoverIcon from "@/components/VHoverIcon";

export default {
	name: "TagGroupDelete",
	components: {
		VHoverIcon,
	},
	props: {
		name: { type: String, default: "" },
	},
	emits: ["dialog-close", "dialog-delete"],

	data() {
		return {
			title: "Delete tag group",
		};
	},

	methods: {
		close() {
			this.$emit("dialog-close");
		},
		softDelete() {
			this.$emit("dialog-delete");
		},
	},
};
</script>
