<template>
	<v-autocomplete
		ref="autocomplete"
		v-model="projectSelects"
		:disabled="disabled"
		:items="projects"
		:label="label"
		:loading="loading"
		:messages="noResults"
		append-icon=""
		class="project-autocomplete"
		clearable
		closable-chips
		density="compact"
		hide-no-data
		hint="Type in keywords to begin searching"
		item-color="secondary"
		:item-title="composedText"
		item-value="uuid"
		multiple
		persistent-hint
		prepend-icon="mdi-magnify"
		return-object
	>
		<template #chip="{ item, index }">
			<v-chip
				class="bg-secondary text-white"
				closable
				@click:close="removeItem(index)"
			>
				{{ item.title }}
			</v-chip>
		</template>
	</v-autocomplete>
</template>

<script>
import { mapMutations } from "vuex";
import utils from "@/utils";
import _ from "lodash";

export default {
	name: "ProjectSearch",

	props: {
		defaultProjects: { type: Array, default: () => [] },
		disabled: Boolean,
		label: {
			default: "Search for projects",
			type: String,
		},
		modelValue: { type: Array, default: () => [] },
	},
	emits: ["update:projects-loading", "update:modelValue"],

	data() {
		return {
			loading: true,
			noResults: "",
			projects: [],
			projectSearch: null,
		};
	},

	computed: {
		// project UUIDs
		projectSelects: {
			get() {
				return this.modelValue;
			},
			set(newValue) {
				this.$emit("update:modelValue", newValue);
			},
		},
	},

	watch: {
		defaultProjects(val) {
			this.loadProjects(val);
		},
	},

	mounted() {
		this.getAllProjects();
		this.loadProjects();
	},

	methods: {
		...mapMutations(["showNotification"]),
		clear() {
			this.doneLoading();

			if (this.defaultProjects.length) {
				// revert to default projects if it exists
				this.loadProjects();
			}
		},
		removeItem(index) {
			this.projectSelects = this.projectSelects.filter(
				(_, i) => i !== index
			);
		},
		getAllProjects: _.debounce(function () {
			this.loading = "secondary";
			this.$http
				.get("/projects", {
					params: {
						omitDeleted: true,
						omitPapers: true,
						sortBy: ["name"],
						sortDesc: ["desc"],
					},
				})
				.then((response) => {
					this.projects = response.data.projects.data;

					// display no results message if necessary
					if (this.projects.length) {
						this.noResults = "";
					} else {
						this.noResults = "No results";
					}
				})
				.catch(({ response }) => {
					if (response.data.message) {
						this.showNotification(response.data);
					}
				})
				.finally(() => {
					this.doneLoading();
				});
		}, 200),
		composedText(project) {
			return utils.truncateText(project.name, 100);
		},
		loadProjects() {
			if (this.defaultProjects.length) {
				this.projectSelects = [...this.defaultProjects];
			}
		},
		doneLoading() {
			this.loading = false;
			this.$emit("update:projects-loading", false);
		},
	},
};
</script>

<style scoped lang="scss">
:deep(.v-field__input) {
	min-height: 60px;
	padding-top: 20px;
}
</style>
