function init(wpd) {
	wpd.Colors = class {
		static #defaultColorValues = "0, 0, 0";
		static #activeColorValues = "240, 157,  55";
		static #headerActiveColorValues = "52, 123, 153";
		static #transparentColorValues = "255, 255, 255";
		static #goodColorValues = "76, 175,  80";
		static #badColorValues = "255,  82,  82";

		static alpha = [
			"0.0",
			"0.1",
			"0.2",
			"0.3",
			"0.4",
			"0.5",
			"0.6",
			"0.7",
			"0.8",
			"0.9",
			"1.0",
		];

		static rgba(color, alphaIndex, lighten) {
			const delta = 1;

			let index = alphaIndex;
			if (index > delta) {
				index = lighten ? alphaIndex - delta : alphaIndex;
			}

			return `rgba(${color}, ${this.alpha[index]})`;
		}

		// black
		static default = this.rgba(this.#defaultColorValues, 10, true);

		// black 50% alpha
		static defaultA = this.rgba(this.#defaultColorValues, 5, true);

		// white 0% alpha
		static transparentA = this.rgba(this.#transparentColorValues, 0, true);

		// dactyl orange
		static active = this.rgba(this.#activeColorValues, 10, true);

		// dactyl orange dark
		static activeDark = this.rgba(this.#activeColorValues, 10, false);

		// dactyl orange 30% alpha
		static activeA = this.rgba(this.#activeColorValues, 3, true);

		// dactyl blue 30% alpha
		static headerActiveA = this.rgba(
			this.#headerActiveColorValues,
			3,
			true
		);

		// dactyl blue dark
		static headerActiveDarkA = this.rgba(
			this.#headerActiveColorValues,
			10,
			false
		);

		// dactyl green
		static good = this.rgba(this.#goodColorValues, 10, true);

		// dactyl green 30% alpha
		static goodA = this.rgba(this.#goodColorValues, 3, true);

		// dactyl green 60% alpha
		static goodHoverA = this.rgba(this.#goodColorValues, 6, true);

		// dactyl red
		static bad = this.rgba(this.#badColorValues, 10, true);

		// dactyl red 30% alpha
		static badA = this.rgba(this.#badColorValues, 3, true);

		// dactyl red 60% alpha
		static badHoverA = this.rgba(this.#badColorValues, 6, true);

		// black 30% alpha
		static nullA = this.rgba(this.#defaultColorValues, 3, true);

		// black 40% alpha
		static nullHoverA = this.rgba(this.#defaultColorValues, 4, true);

		static addColorStop(type, gradient) {
			switch (type) {
				case "good":
					gradient.addColorStop(0, this.goodA);
					gradient.addColorStop(1, this.transparentA);
					break;
				case "goodHover":
					gradient.addColorStop(0, this.goodHoverA);
					gradient.addColorStop(1, this.transparentA);
					break;
				case "bad":
					gradient.addColorStop(0, this.badA);
					gradient.addColorStop(1, this.transparentA);
					break;
				case "badHover":
					gradient.addColorStop(0, this.badHoverA);
					gradient.addColorStop(1, this.transparentA);
					break;
				case "active":
					gradient.addColorStop(0, this.headerActiveA);
					gradient.addColorStop(1, this.transparentA);
					break;
			}
		}

		static getValues(rbgaString) {
			return rbgaString
				.slice(5, -1)
				.split(",")
				.map((string) => parseFloat(string));
		}
	};
}

export { init };
