<template>
	<v-card class="d-flex flex-column pb-5">
		<v-toolbar
			color="primary"
			class="pr-3"
			dark
			flat
		>
			<v-toolbar-title>{{ title }}</v-toolbar-title>

			<v-spacer />

			<v-btn
				class="mr-3 bg-white text-primary"
				elevation="2"
				@click="displayDeleted = !displayDeleted"
			>
				<template v-if="!displayDeleted">
					<v-icon start> mdi-eye </v-icon>
					Show deleted
				</template>

				<template v-else>
					<v-icon start> mdi-eye-off </v-icon>
					Hide deleted
				</template>
			</v-btn>

			<v-dialog
				v-model="dialog"
				max-width="800px"
				transition="fade-transition"
				@click:outside="clearSelectedProject"
			>
				<template #activator="{ props }">
					<v-btn
						v-bind="props"
						class="bg-secondary text-white"
						elevation="2"
					>
						<v-icon start> mdi-plus </v-icon>
						Add new project
					</v-btn>
				</template>

				<project-edit
					:default-project="selectedProject"
					@cancel="closeDialog"
					@refresh="closeDialogAndRefresh"
				/>
			</v-dialog>

			<v-dialog
				v-model="deleteDialog"
				max-width="800px"
				transition="fade-transition"
			>
				<v-card>
					<v-toolbar
						color="primary"
						dark
						flat
					>
						<v-toolbar-title>Delete project</v-toolbar-title>
						<v-hover-icon class="mr-3" />
					</v-toolbar>

					<v-card-title class="text-center py-4">
						Are you sure you want to delete this project?
					</v-card-title>
					<v-divider />

					<v-card-subtitle class="text-center py-4">
						{{ selectedProject.name }}
					</v-card-subtitle>

					<v-divider />

					<v-card-actions class="py-5">
						<v-spacer />

						<v-btn
							class="bg-secondary text-white"
							elevation="2"
							@click="deleteProject"
						>
							Yes
						</v-btn>

						<v-btn
							class="bg-grey"
							elevation="2"
							@click="closeDeleteDialog"
						>
							No
						</v-btn>

						<v-spacer />
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog
				v-model="restoreDialog"
				max-width="800px"
				transition="fade-transition"
			>
				<v-card>
					<v-toolbar
						color="primary"
						dark
						flat
					>
						<v-toolbar-title>Restore project</v-toolbar-title>
						<v-hover-icon class="mr-3" />
					</v-toolbar>

					<v-card-title class="text-center py-4">
						Are you sure you want to restore this project?
					</v-card-title>

					<v-divider />

					<v-card-subtitle class="text-center py-4">
						{{ selectedProject.name }}
					</v-card-subtitle>

					<v-divider />

					<v-card-actions class="py-5">
						<v-spacer />
						<v-btn
							class="bg-secondary text-white"
							elevation="2"
							@click="restoreProject"
						>
							Yes
						</v-btn>
						<v-btn
							class="bg-grey"
							elevation="2"
							@click="closeRestoreDialog"
						>
							No
						</v-btn>
						<v-spacer />
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-toolbar>

		<v-data-table-server
			v-model:options="options"
			v-model:items-per-page="options.itemsPerPage"
			v-model:page="options.page"
			v-model:filter="options.filter"
			class="project-table"
			item-key="uuid"
			:headers="headers"
			:items="projects"
			:row-props="getRowProps"
			:header-props="getHeaderProps()"
			:loading="loading"
			:items-length="totalProjects"
		>
			<template #item.created_at="{ item }">
				{{ getCreatedTimestamp(item) }}
			</template>

			<template #item.organizations="{ item }">
				<div class="d-flex flex-wrap my-2">
					<v-chip
						v-for="organization of item.organizations"
						:key="organization.uuid"
						size="small"
						:disabled="!!item.deleted_at"
					>
						{{ organization.name }}
					</v-chip>

					<v-tooltip
						v-if="!item.organizations.length"
						location="bottom"
						open-delay="500"
					>
						<template #activator="{ props }">
							<v-icon
								v-bind="props"
								color="error"
								class="cursor-default"
							>
								mdi-alert-circle
							</v-icon>
						</template>

						<span>No associated organizations</span>
					</v-tooltip>
				</div>
			</template>

			<template #item.paper_count="{ item }">
				{{ getPaperCount(item) }}
			</template>

			<template #item.actions="{ item }">
				<v-tooltip
					v-if="!item.deleted_at"
					location="bottom"
					open-delay="500"
				>
					<template #activator="{ props }">
						<v-hover v-slot="{ hover }">
							<v-icon
								v-bind="props"
								class="mr-2"
								:color="hover ? 'secondary' : ''"
								@click="edit(item)"
							>
								mdi-pencil
							</v-icon>
						</v-hover>
					</template>

					<span>Edit</span>
				</v-tooltip>

				<v-tooltip
					v-if="!item.deleted_at"
					location="bottom"
					open-delay="500"
				>
					<template #activator="{ props }">
						<v-hover v-slot="{ hover }">
							<v-icon
								v-bind="props"
								:color="hover ? 'error' : ''"
								@click="remove(item)"
							>
								mdi-delete
							</v-icon>
						</v-hover>
					</template>

					<span>Delete</span>
				</v-tooltip>
				<v-tooltip
					v-if="item.deleted_at"
					location="bottom"
					open-delay="500"
				>
					<template #activator="{ props }">
						<v-hover v-slot="{ hover }">
							<v-icon
								v-bind="props"
								:color="hover ? 'secondary' : ''"
								@click="restore(item)"
							>
								mdi-restore
							</v-icon>
						</v-hover>
					</template>

					<span>Restore</span>
				</v-tooltip>
			</template>
		</v-data-table-server>
	</v-card>
</template>

<script>
import { mapMutations } from "vuex";

import ProjectEdit from "@/pages/ProjectManager/ProjectEdit";
import VHoverIcon from "@/components/VHoverIcon";

export default {
	name: "ProjectTable",

	components: {
		ProjectEdit,
		VHoverIcon,
	},

	data() {
		return {
			deleteDialog: false,
			dialog: false,
			displayDeleted: false,
			loading: false,
			options: {},
			projects: [],
			restoreDialog: false,
			selectedProject: {},
			title: "Projects",
			totalProjects: 0,
			headers: [
				{
					title: "Project name",
					value: "name",
					width: "25%",
				},
				{
					title: "Description",
					value: "description",
					align: "left",
					width: "25%",
				},
				{
					title: "Organizations",
					value: "organizations",
					align: "center",
					width: "150px",
				},
				{
					title: "Paper count",
					value: "paper_count",
					align: "center",
					width: "150px",
				},
				{
					title: "Created at",
					value: "created_at",
					align: "center",
					divider: true,
					width: "200px",
				},
				{
					title: "Actions",
					value: "actions",
					align: "center",
					width: "95px",
					sortable: false,
				},
			],
		};
	},

	watch: {
		options: {
			deep: true,
			handler() {
				this.getProjects();
			},
		},
		displayDeleted() {
			this.options.page = 1;
			this.getProjects();
		},
	},

	methods: {
		...mapMutations(["clearSessionProjects", "showNotification"]),
		getPaperCount(project) {
			return project.papers.length;
		},
		getCreatedTimestamp(project) {
			return new Date(project.created_at).toLocaleString();
		},
		clearSelectedProject() {
			setTimeout(() => {
				this.selectedProject = {};
			}, 300);
		},
		getHeaderProps() {
			return {
				class: "project-header-row",
			};
		},
		getRowProps(project) {
			return {
				class: `project-table-row ${this.isDeleted(project)}`,
			};
		},
		isDeleted(project) {
			return project.deleted_at ? "deleted" : "";
		},
		remove(item) {
			this.selectedProject = Object.assign({}, item);

			this.deleteDialog = true;
		},
		restore(item) {
			this.selectedProject = Object.assign({}, item);

			this.restoreDialog = true;
		},
		closeDeleteDialog() {
			this.deleteDialog = false;

			this.clearSelectedProject();
		},
		deleteProject() {
			this.loading = "secondary";

			if (this.selectedProject) {
				this.$http
					.delete(`/projects/${this.selectedProject.uuid}`)
					.then((response) => {
						// display success
						this.showNotification(response.data);

						// clear default session project
						this.clearSessionProjects();

						// refresh table
						this.getProjects();
					})
					.catch((error) => {
						// display error
						if (error.response) {
							if (error.response.data.message) {
								this.showNotification(error.response.data);
							} else {
								this.showNotification(
									error.response.data.error
								);
							}
						}

						this.loading = false;
					});
			} else {
				this.loading = false;
			}

			this.closeDeleteDialog();
		},
		restoreProject() {
			this.loading = "secondary";

			if (this.selectedProject) {
				this.$http
					.put(`/projects/${this.selectedProject.uuid}`, {
						deletedAt: null,
					})
					.then((response) => {
						this.showNotification(response.data);
						this.getProjects();
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.data.message) {
								this.showNotification(error.response.data);
							} else {
								this.showNotification(
									error.response.data.error
								);
							}
						}

						this.loading = false;
					});
			} else {
				this.loading = false;
			}

			this.closeRestoreDialog();
		},
		edit(item) {
			this.selectedProject = Object.assign({}, item);

			this.dialog = true;
		},
		closeDialog() {
			this.dialog = false;

			setTimeout(() => {
				this.clearSelectedProject();
			}, 250);
		},
		closeRestoreDialog() {
			this.restoreDialog = false;

			this.clearSelectedProject();
		},
		closeDialogAndRefresh() {
			this.closeDialog();

			this.$nextTick(() => {
				this.getProjects();
			});
		},
		getProjects() {
			this.loading = "secondary";

			const { filter, itemsPerPage, page, sortBy, sortDesc } =
				this.options;

			this.$http
				.get("/projects", {
					params: {
						...(this.displayDeleted ? { deletedOnly: true } : {}),
						...(!this.displayDeleted ? { omitDeleted: true } : {}),
						filter: filter,
						itemsPerPage: itemsPerPage,
						page: page,
						sortBy: sortBy,
						sortDesc: sortDesc,
					},
				})
				.then((response) => {
					this.projects = response.data.projects.data;
					this.totalProjects = parseInt(
						response.data.projects.meta.total,
						10
					);
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style scoped lang="scss">
:deep(.project-table) {
	&.v-data-table .v-table__wrapper td {
		font-size: 12px;
	}

	&.v-data-table .v-table__wrapper th {
		font-size: 12px;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.6);
		height: 48px;
	}
}
</style>
