<template>
	<v-card
		class="d-flex flex-column"
		:class="{ 'pb-5': !nested }"
		:border="nested"
		:loading="submitting"
	>
		<v-toolbar
			color="primary"
			dark
			flat
			:dense="nested"
		>
			<v-toolbar-title v-if="editMode">
				{{ editTitle }}
			</v-toolbar-title>

			<v-toolbar-title v-else>
				<v-icon
					v-if="nested"
					class="mt-n1 mr-1"
				>
					mdi-briefcase-plus-outline
				</v-icon>

				{{ title }}
			</v-toolbar-title>
			<v-hover-icon class="mr-3" />
		</v-toolbar>
		<v-form
			autocomplete="off"
			@submit.prevent
		>
			<v-card-text
				class="pb-0"
				:class="{ 'pa-10': !nested, 'px-8': nested }"
			>
				<v-row>
					<v-text-field
						v-model="name"
						:error-messages="nameErrors"
						:disabled="!!submitting"
						label="Organization name"
						name="name"
						type="text"
						required
						class="col-12"
						@blur="v$.name.$touch()"
					/>
				</v-row>

				<v-row>
					<v-col>
						<v-textarea
							v-model="description"
							:error-messages="descriptionErrors"
							:disabled="!name || !!submitting"
							label="Description"
							name="description"
							class="col-12"
							counter="255"
							rows="3"
							no-resize
							auto-grow
							clearable
							variant="outlined"
							@blur="v$.description.$touch()"
						/>
					</v-col>
				</v-row>

				<project-search
					v-model="selectedProjects"
					class="mb-4"
					:default-projects="projects"
					:disabled="!!submitting"
					label="Add projects"
				/>
				<user-search
					v-model="selectedUsers"
					:default-users="users"
					:disabled="!!submitting"
					label="Add users"
				/>
			</v-card-text>

			<v-card-actions v-if="!noActions">
				<v-spacer />

				<v-btn
					v-if="editMode"
					class="bg-secondary text-white"
					elevation="2"
					type="submit"
					:disabled="
						v$.$invalid || !v$.$anyDirty || !name || !!submitting
					"
					@click="update"
				>
					Update
				</v-btn>

				<v-btn
					v-else
					class="bg-secondary text-white"
					elevation="2"
					type="submit"
					:disabled="v$.$invalid || !name || !!submitting"
					@click="add"
				>
					Create
				</v-btn>

				<v-btn
					v-if="editMode"
					class="bg-grey"
					elevation="2"
					:disabled="!!submitting"
					@click="clear"
				>
					Revert
				</v-btn>

				<v-btn
					v-else
					class="bg-grey"
					elevation="2"
					:disabled="!!submitting"
					@click="clear"
				>
					Clear
				</v-btn>

				<v-btn
					class="bg-grey"
					elevation="2"
					:disabled="!!submitting"
					@click="cancel"
				>
					Cancel
				</v-btn>

				<v-spacer />
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import _ from "lodash";

import { mapMutations } from "vuex";
import { required, maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import ProjectSearch from "@/pages/ProjectManager/ProjectSearch";
import UserSearch from "@/pages/UserManager/UserSearch";
import VHoverIcon from "@/components/VHoverIcon";

export default {
	name: "OrganizationEdit",

	components: {
		ProjectSearch,
		UserSearch,
		VHoverIcon,
	},

	props: {
		defaultOrganization: {
			type: Object,
			default: () => ({}),
		},
		nested: Boolean,
		noActions: Boolean,
	},
	emits: ["refresh", "cancel", "update:dirty", "update:invalid"],

	setup() {
		return {
			v$: useVuelidate(),
		};
	},

	data() {
		return {
			editTitle: "Edit organization",
			description: this.defaultOrganization
				? this.defaultOrganization.description
				: "",
			editMode: false,
			loading: false,
			name: this.defaultOrganization ? this.defaultOrganization.name : "",
			projects: [],
			selectedProjects:
				this.defaultOrganizations && this.defaultOrganizations.projects
					? [...this.defaultOrganization.projects]
					: [],
			users: [],
			selectedUsers:
				this.defaultOrganizations && this.defaultOrganizations.users
					? [...this.defaultOrganization.users]
					: [],
			submitting: false,
			title: "Create organization",
			uuid: this.defaultOrganization ? this.defaultOrganization.uuid : "",
		};
	},

	computed: {
		nameErrors() {
			const errors = [];
			if (!this.v$.name.$dirty) {
				return errors;
			}

			return this.v$.name.$errors.map((error) => {
				switch (error.$validator) {
					case "required":
						return "Name is required";
					case "maxLength":
						return "Name is too long";
					default:
						return "Invalid first name";
				}
			});
		},
		descriptionErrors() {
			const errors = [];
			if (!this.v$.description.$dirty) {
				return errors;
			}

			return this.v$.description.$errors.map((error) => {
				switch (error.$validator) {
					case "maxLength":
						return "Description is too long";
					default:
						return "Invalid description";
				}
			});
		},
	},

	watch: {
		defaultOrganization() {
			this.loadOrganization();
		},
		"v$.$anyDirty": function (val) {
			this.$emit("update:dirty", val);
		},
		"v$.$anyError": function (val) {
			this.$emit("update:invalid", val);
		},
		"v$.$invalid": function (val) {
			this.$emit("update:invalid", val);
		},
	},

	mounted() {
		this.loadOrganization();
	},

	methods: {
		...mapMutations(["setSessionOrganizations", "showNotification"]),
		clear() {
			// clear dirty flags
			this.v$.$reset();

			if (_.isEmpty(this.defaultOrganization)) {
				this.name = "";
				this.description = "";
				this.projects = [];
				this.selectedProjects = [];
				this.users = [];
				this.selectedUsers = [];
				this.uuid = "";

				this.editMode = false;
			} else {
				this.loadOrganization();
			}
		},
		cancel() {
			this.$emit("cancel");
		},
		add() {
			this.save("add");
		},
		update() {
			this.save("update");
		},
		save(mode) {
			this.saveRequest(mode)
				.then((response) => {
					// display success
					this.showNotification(response.data);
				})
				.catch((error) => {
					// set all to dirty
					this.v$.$touch();

					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					this.$emit("refresh");

					this.submitting = false;
				});
		},
		saveRequest(mode) {
			if (!this.v$.$invalid) {
				this.submitting = true;

				const dispatch = {
					add: this.addOrganizationRequest,
					update: this.updateOrganizationRequest,
				};

				return dispatch[mode]();
			} else {
				return Promise.reject();
			}
		},
		addOrganizationRequest() {
			return this.$http.post("/organizations", {
				name: this.name.trim(),
				description: this.description?.trim(),
				users: this.selectedUsers,
				projects: this.selectedProjects,
			});
		},
		updateOrganizationRequest() {
			return this.$http
				.put(`/organizations/${this.uuid}`, {
					name: this.name.trim(),
					description: this.description?.trim(),
					users: this.selectedUsers,
					projects: this.selectedProjects,
				})
				.then((response) => {
					return Promise.resolve(response);
				})
				.catch((error) => {
					// set dirty flags
					this.v$.$touch();

					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				});
		},
		loadOrganization() {
			if (!_.isEmpty(this.defaultOrganization)) {
				this.name = this.defaultOrganization.name;
				this.description = this.defaultOrganization.description;
				this.uuid = this.defaultOrganization.uuid;

				this.selectedProjects = [...this.defaultOrganization.projects];
				this.selectedUsers = [...this.defaultOrganization.users];

				this.editMode = true;

				this.v$.$touch();
			} else {
				this.clear();
			}
		},
	},

	validations: {
		name: {
			required,
			maxLength: maxLength(255),
		},
		description: {
			maxLength: maxLength(255),
		},
	},
};
</script>

<style scoped lang="scss"></style>
