/**
 * Initializes WPD data points repainter.
 */
function init(wpd) {
	wpd.DataPointsRepainter = class {
		constructor(axis, dataset) {
			this.axis = axis;
			this.dataset = dataset;
			this.ctx = wpd.graphicsWidget.getAllContexts().dataCtx;
			this.painterName = "dataPointsRepainter";
		}

		drawHeaderDividers() {
			const dividers =
				this.axis.getMetadata()?.barHeaders?.headerDividers;
			if (!dividers) return;

			wpd.barChartUtils.drawHeaderDividers(this.ctx, dividers);
		}

		drawAxisLine() {
			const metadata = this.axis.getMetadata();
			if (!metadata.barHeaders) return;

			wpd.barChartUtils.drawAxisLine(this.ctx, this.axis, metadata);
		}

		drawLine(start, end, color) {
			wpd.barChartUtils.drawLine(this.ctx, start, end, color);
		}

		drawPoints() {
			let mkeys = this.dataset.getMetadataKeys();
			let hasLabels = false;

			if (this.axis == null) {
				return; // this can happen when removing widgets when a new file is loaded:
			}

			if (
				this.axis.dataPointsHaveLabels &&
				mkeys != null &&
				mkeys[0] === "label"
			) {
				hasLabels = true;
			}

			for (let dindex = 0; dindex < this.dataset.getCount(); dindex++) {
				let imagePos = this.dataset.getPixel(dindex);
				let isSelected =
					this.dataset.getSelectedPixels().indexOf(dindex) >= 0;

				let fillStyle = isSelected
					? wpd.Colors.active
					: wpd.Colors.good;

				if (hasLabels) {
					let pointLabel = null;
					if (this.dataset.hasPointGroups()) {
						// with point groups, bar labels only apply to points in the primary group (i.e. index 0)
						const tupleIndex = this.dataset.getTupleIndex(dindex);
						const groupIndex =
							this.dataset.getPointGroupIndexInTuple(
								tupleIndex,
								dindex
							);
						if (groupIndex <= 0) {
							pointLabel = imagePos.metadata?.label?.value;
							const index = tupleIndex > -1 ? tupleIndex : dindex;
							if (pointLabel === "") {
								pointLabel =
									this.axis.dataPointsLabelPrefix + index;
							}
						}
					} else {
						pointLabel = imagePos.metadata?.label?.value;
						if (pointLabel === "") {
							pointLabel =
								this.axis.dataPointsLabelPrefix + dindex;
						}
					}
					wpd.graphicsHelper.drawPoint(
						imagePos,
						fillStyle,
						pointLabel
					);
				} else {
					wpd.graphicsHelper.drawPoint(imagePos, fillStyle);
				}
			}
		}

		onAttach() {
			wpd.graphicsWidget.resetData();
			this.drawPoints();
			this.drawAxisLine();
			this.drawHeaderDividers();
		}

		onRedraw() {
			this.drawPoints();
			this.drawAxisLine();
			this.drawHeaderDividers();
		}

		onForcedRedraw() {
			wpd.graphicsWidget.resetData();
			this.drawPoints();
			this.drawAxisLine();
			this.drawHeaderDividers();
		}
	};
}

export { init };
