<template>
	<v-dialog
		v-model="dialog"
		max-width="600"
	>
		<template #activator="{ props }">
			<v-btn
				v-bind="props"
				id="login-button"
				class="bg-secondary text-white"
				elevation="2"
			>
				<span class="login mr-1">Log in</span>
				<v-icon size="small">mdi-login-variant</v-icon>
			</v-btn>
		</template>

		<v-card
			class="pb-5"
			:disabled="!!loading"
			:loading="loading"
		>
			<v-toolbar
				color="primary"
				dark
				flat
			>
				<v-toolbar-title>Log in</v-toolbar-title>
				<v-spacer />
				<v-tooltip location="bottom">
					<template #activator="{ props }">
						<v-btn
							v-bind="props"
							icon
							size="large"
							@click="close"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</template>
					<span>Close</span>
				</v-tooltip>
			</v-toolbar>
			<v-card-text class="pa-10">
				<v-form @submit.prevent.stop>
					<v-text-field
						v-model.trim="email"
						class="mb-2"
						:error-messages="emailErrors"
						label="Email address"
						name="email"
						prepend-icon="mdi-account"
						autocomplete="off"
						required
						autofocus
						@update:model-value="emailState"
						@blur="v$.email.$touch()"
						@keyup.enter="sendSsoRequest"
					/>

					<v-row
						v-if="ssoAvailable"
						justify="start"
					>
						<v-col
							class="mr-3 my-2 flex-grow-0"
							style="width: 24px"
						>
							<v-icon>mdi-lock</v-icon>
						</v-col>

						<v-col align-self="center">
							SSO sign-in is enabled for this domain
						</v-col>
					</v-row>

					<v-text-field
						v-else
						id="password"
						v-model="password"
						class="mb-2"
						:error-messages="passwordErrors"
						:append-inner-icon="reveal ? 'mdi-eye' : 'mdi-eye-off'"
						:type="reveal ? 'text' : 'password'"
						prepend-icon="mdi-lock"
						counter
						label="Password"
						name="password"
						required
						@click:append="reveal = !reveal"
						@update:model-value="passwordState"
						@blur="v$.password.$touch()"
						@keyup.enter="login"
					/>
				</v-form>

				<a
					v-if="!ssoAvailable"
					class="ml-8 text-primary text-subtitle-2"
					href="#"
					@click.prevent="forgotPassword"
				>
					Forgot your password?
				</a>
			</v-card-text>

			<v-card-actions>
				<v-spacer />

				<v-btn
					v-if="ssoAvailable"
					elevation="2"
					class="bg-secondary text-white"
					@click="sendSsoRequest"
				>
					Continue
				</v-btn>

				<v-btn
					v-else
					class="bg-secondary text-white"
					elevation="2"
					@click="login"
				>
					Log in
				</v-btn>

				<v-spacer />
			</v-card-actions>

			<form
				v-if="ssoAvailable"
				ref="samlForm"
				method="post"
				:action="entityEndpoint"
				autocomplete="off"
			>
				<input
					type="hidden"
					:name="type"
					:value="context"
				/>
				<input
					type="hidden"
					name="RelayState"
					:value="relayState"
				/>
				<input
					type="hidden"
					name="Id"
					:value="id"
				/>
				<input
					type="hidden"
					name="Email"
					:value="email"
				/>
			</form>
		</v-card>
	</v-dialog>
</template>

<script>
import * as Sentry from "@sentry/vue";
import { mapMutations } from "vuex";
import { required, email, minLength, maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

const ssoDomainRegexes = [/^.+@humanpredictions[.]com$/];

export default {
	name: "VLogin",
	emits: ["activate-activity-timer"],

	setup() {
		return {
			v$: useVuelidate(),
		};
	},

	data() {
		return {
			context: "",
			dialog: false,
			email: "",
			entityEndpoint: "",
			id: "",
			invalidEmail: false,
			invalidPassword: false,
			loading: false,
			notification: {
				message: "",
				status: "",
			},
			password: "",
			relayState: "",
			reveal: false,
			ssoAvailable: false,
			type: "",
		};
	},

	computed: {
		emailErrors() {
			if (!this.v$.email.$dirty) {
				return [];
			}

			return this.v$.email.$errors.map((error) => {
				switch (error.$validator) {
					case "required":
						return "E-mail address is required";
					case "invalid":
						return "E-mail address not found";
					case "email":
						return "Invalid e-mail address";
					case "maxLength":
						return "E-mail address is too long";
					default:
						return "Invalid e-mail address";
				}
			});
		},
		passwordErrors() {
			if (!this.v$.password.$dirty) {
				return [];
			}

			return this.v$.password.$errors.map((error) => {
				switch (error.$validator) {
					case "required":
						return "Password is required";
					case "invalid":
						return "Invalid password";
					case "minLength":
						return "Password is too short";
					case "maxLength":
						return "Password is too long";
					default:
						return "Invalid password";
				}
			});
		},
	},

	watch: {
		dialog(val) {
			if (val === false) {
				this.clear();
			} else {
				this.v$.$reset();
			}
		},
	},

	methods: {
		...mapMutations(["setToken", "setUser", "showNotification"]),
		clear() {
			this.email = "";
			this.password = "";

			this.invalidEmail = false;
			this.invalidPassword = false;

			this.ssoAvailable = false;
			this.context = "";
			this.entityEndpoint = "";
			this.id = "";
			this.relayState = "";
			this.type = "";
		},
		open() {
			this.dialog = true;
		},
		close() {
			this.dialog = false;
		},
		sendSsoRequest() {
			if (this.ssoAvailable && !this.v$.email.$invalid) {
				this.loading = "secondary";

				this.$http
					.post("/sso-login", {
						email: this.email.toLowerCase(),
					})
					.then(({ data }) => {
						// redirect to idp login
						this.context = data.context;
						this.entityEndpoint = data.entityEndpoint;
						this.id = data.id;
						this.relayState = data.relayState;
						this.type = data.type;

						setTimeout(() => {
							this.$refs.samlForm.submit();

							this.context = "";
							this.entityEndpoint = "";
							this.id = "";
							this.relayState = "";
							this.type = "";
						}, 1);
					})
					.catch((error) => {
						console.error(error);
						// display error
						if (error.response) {
							if (error.response.data.message) {
								this.notify(error.response.data);
							} else {
								this.notify(error.response.data.error);
							}
						}
					})
					.finally(() => {
						this.loading = false;
					});
			} else {
				// set all to dirty
				this.v$.$touch();
			}
		},
		login(token) {
			if (token || !this.v$.$invalid) {
				this.loading = "secondary";

				this.$http
					.post("/login", {
						email: this.email.toLowerCase(),
						password: this.password,
						token,
					})
					.then((response) => {
						const payload = response.data.payload;

						// activate activity tracker
						this.$emit("activate-activity-timer");

						// register logged in user
						this.setUser(payload.user);

						// set sentry user
						Sentry.setUser({ email: payload.user.email });

						// display success
						this.notify(response.data);

						// redirect to home
						this.$router.push("/home/dashboard");

						// close the dialog
						this.close();
					})
					.catch((error) => {
						// clear sentry user
						Sentry.setUser(null);

						// display error
						if (error.response) {
							if (error.response.data.message) {
								this.notify(error.response.data);
							} else {
								this.notify(error.response.data.error);
							}

							if (error.response.data.code) {
								switch (error.response.data.code) {
									case "E_INVALID_PASSWORD":
										// invalidate password field
										this.invalidPassword = true;
										this.v$.password.$touch();
										break;
									case "E_USER_NOT_FOUND":
										// invalidate email field
										this.invalidEmail = true;
										this.v$.email.$touch();
										break;
									case "E_NO_USER_ROLES":
										// close dialog
										this.close();
										break;
								}
							}
						}
					})
					.finally(() => {
						this.loading = false;
					});
			} else {
				// set all to dirty
				this.v$.$touch();
			}
		},
		notify(data) {
			this.showNotification({
				message: data.message,
				status: data.status,
			});
		},
		emailState() {
			if (this.invalidEmail === true) {
				this.invalidEmail = false;
				this.v$.email.$touch();
			}

			// toggle between sso authentication and password
			if (ssoDomainRegexes.some((regex) => regex.test(this.email))) {
				this.ssoAvailable = true;
			} else {
				this.ssoAvailable = false;
			}
		},
		passwordState() {
			if (this.invalidPassword === true) {
				this.invalidPassword = false;
				this.v$.password.$touch();
			}
		},
		forgotPassword() {
			if (this.v$.email.$invalid) {
				this.v$.email.$touch();

				return;
			}

			this.loading = "secondary";

			this.$http
				.post("/forgot-password", {
					email: this.email.toLowerCase(),
				})
				.then((response) => {
					// display success
					this.notify(response.data);

					// close the dialog
					this.close();
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.notify(error.response.data);
						} else {
							this.notify(error.response.data.error);
						}
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},

	validations: {
		email: {
			required,
			email,
			maxLength: maxLength(254),
			invalid() {
				return !this.invalidEmail;
			},
		},
		password: {
			required,
			minLength: minLength(8),
			maxLength: maxLength(60),
			invalid() {
				return !this.invalidPassword;
			},
		},
	},
};
</script>

<style scoped lang="scss">
.login {
	line-height: 36px;
}
</style>
