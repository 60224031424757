<template>
	<v-row
		align-content="start"
		justify="start"
	>
		<v-overlay
			:model-value="overlay"
			z-index="3000"
		/>

		<v-col
			cols="12"
			:style="overlay ? 'z-index: 30001;' : ''"
		>
			<tag-tree
				ref="tagTree"
				:tags="tags"
				@update:parent-mode="toggleOverlay"
				@update:enable-tag="enableTag"
				@update:disable-tag="disableTag"
				@update:refresh="getTags"
			/>
		</v-col>
	</v-row>
</template>

<script>
import _ from "lodash";
import { mapMutations } from "vuex";

import TagTree from "@/pages/BaseTagManager/TagTree";

export default {
	name: "BaseTagManager",

	components: {
		TagTree,
	},

	data() {
		return {
			overlay: false,
			tags: [],
		};
	},

	mounted() {
		this.getTags();
	},

	methods: {
		...mapMutations(["showNotification"]),
		toggleOverlay: function (value) {
			this.overlay = value;
		},
		enableTag: function (tag) {
			const path = this.findTagPath(this.tags, tag, []);

			const tagIndex = path.pop();

			// get around vue reactivity limitations, set the entire object to add keys
			tag = Object.assign({}, tag, { disabled: undefined });

			// update tag tree reference
			this.$refs.tagTree.setActiveTag(tag);

			// get around vue reactivity limitations, splice in the new value at index
			_.get(this.tags, `[${path.join("].children[")}].children`).splice(
				tagIndex,
				1,
				tag
			);
		},
		disableTag: function (tag) {
			const path = this.findTagPath(this.tags, tag, []);

			const tagIndex = path.pop();

			// get around vue reactivity limitations, set the entire object to add keys
			tag = Object.assign({}, tag, { disabled: true });

			// update tag tree reference
			this.$refs.tagTree.setActiveTag(tag);

			// get around vue reactivity limitations, splice in the new value at index
			_.get(this.tags, `[${path.join("].children[")}].children`).splice(
				tagIndex,
				1,
				tag
			);
		},
		findTagPath: function (tags, target, path) {
			const index = tags.indexOf(target);

			if (index > -1) {
				// found target, push index into path and return
				path.push(index);
				return path;
			} else {
				// go through each tag
				for (let i = 0; i < tags.length; i++) {
					// skip tags without children
					if (tags[i].children) {
						// add current index to path
						path.push(i);

						// recurse
						const subPath = this.findTagPath(
							tags[i].children,
							target,
							path
						);

						// if recursive calls returns null, target is not in this branch
						if (subPath === null) {
							// pop current index
							path.pop();
						} else {
							// found target
							return path;
						}
					}
				}

				return null;
			}
		},
		getTags: function () {
			this.$http
				.get("/tags")
				.then((response) => {
					this.tags = [...response.data.tags];

					// push the expand call to the bottom of the call stack
					setTimeout(() => this.$refs.tagTree.refresh(), 0);
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				});
		},
	},
};
</script>

<style scoped lang="scss">
.col {
	flex-grow: 0;
}
</style>
