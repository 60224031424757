<template>
	<v-card class="d-flex flex-column pb-5">
		<v-toolbar
			color="primary"
			dark
			flat
		>
			<v-toolbar-title>{{ title }}</v-toolbar-title>

			<v-spacer />

			<v-btn
				class="mr-3 text-primary bg-grey"
				elevation="2"
				@click="displayDeleted = !displayDeleted"
			>
				<template v-if="!displayDeleted">
					<v-icon start> mdi-eye </v-icon>

					Show deleted
				</template>

				<template v-else>
					<v-icon start> mdi-eye-off </v-icon>

					Hide deleted
				</template>
			</v-btn>

			<v-dialog
				v-model="dialog"
				max-width="800px"
				transition="fade-transition"
				@click:outside="clearSelectedOrganization"
			>
				<template #activator="{ props }">
					<v-btn
						v-bind="props"
						class="bg-secondary text-white mr-3"
						elevation="2"
					>
						<v-icon start> mdi-plus </v-icon>

						Add new organization
					</v-btn>
				</template>

				<organization-edit
					:default-organization="selectedOrganization"
					@cancel="closeDialog"
					@refresh="closeDialogAndRefresh"
				/>
			</v-dialog>

			<v-dialog
				v-model="deleteDialog"
				max-width="800px"
				transition="fade-transition"
			>
				<v-card>
					<v-toolbar
						color="primary"
						dark
						flat
					>
						<v-toolbar-title>Delete organization</v-toolbar-title>
						<v-hover-icon class="mr-3" />
					</v-toolbar>

					<v-card-title class="text-center py-4">
						Are you sure you want to delete this organization?
					</v-card-title>

					<v-divider />

					<v-card-subtitle class="text-center py-4">
						{{ selectedOrganization.name }}
					</v-card-subtitle>

					<v-divider />

					<v-card-actions class="py-5">
						<v-spacer />

						<v-btn
							class="bg-secondary text-white"
							elevation="2"
							@click="deleteOrganization"
						>
							Yes
						</v-btn>

						<v-btn
							class="bg-grey"
							elevation="2"
							@click="closeDeleteDialog"
						>
							No
						</v-btn>

						<v-spacer />
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog
				v-model="restoreDialog"
				max-width="800px"
				transition="fade-transition"
			>
				<v-card>
					<v-toolbar
						color="primary"
						dark
						flat
					>
						<v-toolbar-title>Restore organization</v-toolbar-title>
						<v-hover-icon class="mr-3" />
					</v-toolbar>

					<v-card-title class="text-center py-4">
						Are you sure you want to restore this organization?
					</v-card-title>

					<v-divider />

					<v-card-subtitle class="text-center py-4">
						{{ selectedOrganization.name }}
					</v-card-subtitle>

					<v-divider />

					<v-card-actions class="py-5">
						<v-spacer />
						<v-btn
							class="bg-secondary text-white"
							elevation="2"
							@click="restoreOrganization"
						>
							Yes
						</v-btn>
						<v-btn
							class="bg-grey"
							elevation="2"
							@click="closeRestoreDialog"
						>
							No
						</v-btn>
						<v-spacer />
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-toolbar>

		<v-data-table-server
			v-model:options="options"
			class="organization-table"
			item-key="uuid"
			:headers="headers"
			:items="organizations"
			:row-props="getRowProps"
			:loading="loading"
			:items-length="totalOrganizations"
		>
			<template #item.created_at="{ item }">
				{{ getCreatedTimestamp(item) }}
			</template>

			<template #item.paper_count="{ item }">
				{{ getUserCount(item) }}
			</template>

			<template #item.project_count="{ item }">
				{{ getProjectCount(item) }}
			</template>

			<template #item.actions="{ item }">
				<v-tooltip
					v-if="!item.deleted_at"
					location="bottom"
					open-delay="500"
				>
					<template #activator="{ props }">
						<v-hover v-slot="{ hover }">
							<v-icon
								v-bind="props"
								class="mr-2"
								:color="hover ? 'secondary' : ''"
								@click="edit(item)"
							>
								mdi-pencil
							</v-icon>
						</v-hover>
					</template>

					<span>Edit</span>
				</v-tooltip>

				<v-tooltip
					v-if="!item.deleted_at"
					location="bottom"
					open-delay="500"
				>
					<template #activator="{ props }">
						<v-hover v-slot="{ hover }">
							<v-icon
								v-bind="props"
								:color="hover ? 'error' : ''"
								@click="remove(item)"
							>
								mdi-delete
							</v-icon>
						</v-hover>
					</template>

					<span>Delete</span>
				</v-tooltip>

				<v-tooltip
					v-if="item.deleted_at"
					location="bottom"
					open-delay="500"
				>
					<template #activator="{ props }">
						<v-hover v-slot="{ hover }">
							<v-icon
								v-bind="props"
								:color="hover ? 'secondary' : ''"
								@click="restore(item)"
							>
								mdi-restore
							</v-icon>
						</v-hover>
					</template>

					<span>Restore</span>
				</v-tooltip>
			</template>
		</v-data-table-server>
	</v-card>
</template>

<script>
import { mapMutations } from "vuex";

import OrganizationEdit from "@/pages/Organizations/OrganizationEdit";
import VHoverIcon from "@/components/VHoverIcon";

export default {
	name: "OrganizationTable",

	components: {
		OrganizationEdit,
		VHoverIcon,
	},

	data() {
		return {
			deleteDialog: false,
			dialog: false,
			displayDeleted: false,
			loading: false,
			options: {},
			organizations: [],
			restoreDialog: false,
			selectedOrganization: {},
			title: "Organizations",
			totalOrganizations: 0,
			headers: [
				{
					title: "Organization name",
					value: "name",
					width: "25%",
				},
				{
					title: "Description",
					value: "description",
					align: "left",
					width: "55%",
				},
				{
					title: "User count",
					value: "paper_count",
					align: "center",
					width: "150px",
				},
				{
					title: "Project count",
					value: "project_count",
					align: "center",
					width: "150px",
				},
				{
					title: "Created at",
					value: "created_at",
					align: "center",
					divider: true,
					width: "200px",
				},
				{
					title: "Actions",
					value: "actions",
					align: "center",
					width: "95px",
					sortable: false,
				},
			],
		};
	},

	watch: {
		options: {
			deep: true,
			handler() {
				this.getOrganizations();
			},
		},
		displayDeleted() {
			this.options.page = 1;
			this.getOrganizations();
		},
	},

	methods: {
		...mapMutations(["clearSessionProjects", "showNotification"]),
		getUserCount(organization) {
			return organization.users.length;
		},
		getProjectCount(organization) {
			return organization.projects.length;
		},
		getCreatedTimestamp(organization) {
			return new Date(organization.created_at).toLocaleString();
		},
		getRowProps(organization) {
			return {
				class: this.isDeleted(organization),
			};
		},
		isDeleted(organization) {
			return organization.deleted_at ? "deleted" : "";
		},
		clearSelectedOrganization() {
			setTimeout(() => {
				this.selectedOrganization = {};
			}, 300);
		},
		remove(item) {
			this.selectedOrganization = Object.assign({}, item);

			this.deleteDialog = true;
		},
		closeDeleteDialog() {
			this.deleteDialog = false;

			this.clearSelectedOrganization();
		},
		deleteOrganization() {
			this.loading = "secondary";

			if (this.selectedOrganization) {
				this.$http
					.delete(`/organizations/${this.selectedOrganization.uuid}`)
					.then((response) => {
						this.showNotification(response.data);
						this.clearSelectedOrganization();
						this.getOrganizations();
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.data.message) {
								this.showNotification(error.response.data);
							} else {
								this.showNotification(
									error.response.data.error
								);
							}
						}

						this.loading = false;
					});
			} else {
				this.loading = false;
			}

			this.closeDeleteDialog();
		},
		restore(item) {
			this.selectedOrganization = Object.assign({}, item);

			this.restoreDialog = true;
		},
		closeRestoreDialog() {
			this.restoreDialog = false;

			this.clearSelectedOrganization();
		},
		restoreOrganization() {
			this.loading = "secondary";

			if (this.selectedOrganization) {
				this.$http
					.put(`/organizations/${this.selectedOrganization.uuid}`, {
						deletedAt: null,
					})
					.then((response) => {
						this.showNotification(response.data);
						this.getOrganizations();
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.data.message) {
								this.showNotification(error.response.data);
							} else {
								this.showNotification(
									error.response.data.error
								);
							}
						}

						this.loading = false;
					});
			} else {
				this.loading = false;
			}

			this.closeRestoreDialog();
		},
		edit(item) {
			this.selectedOrganization = Object.assign({}, item);
			this.dialog = true;
		},
		closeDialog() {
			this.dialog = false;

			setTimeout(() => {
				this.clearSelectedOrganization();
			}, 250);
		},
		closeDialogAndRefresh() {
			this.closeDialog();
			this.$nextTick(() => {
				this.getOrganizations();
			});
		},
		getOrganizations() {
			this.loading = "secondary";

			const { filter, itemsPerPage, page, sortBy, sortDesc } =
				this.options;

			this.$http
				.get("/organizations", {
					params: {
						...(this.displayDeleted ? { deletedOnly: true } : {}),
						...(!this.displayDeleted ? { omitDeleted: true } : {}),
						filter: filter,
						itemsPerPage: itemsPerPage,
						page: page,
						sortBy: sortBy,
						sortDesc: sortDesc,
					},
				})
				.then((response) => {
					this.organizations = response.data.organizations;
					this.totalOrganizations = parseInt(response.data.total, 10);
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style scoped lang="scss">
:deep(.organization-table) {
	&.v-data-table .v-table__wrapper td {
		font-size: 12px;
	}

	&.v-data-table .v-table__wrapper th {
		font-size: 12px;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.6);
		height: 48px;
	}
}
</style>
