<template>
	<v-dialog
		v-model="dialog"
		content-class="user-manual"
		transition="dialog-bottom-transition"
		:scrim="false"
		fullscreen
		scrollable
	>
		<user-manual
			dialog
			@close="close"
		/>
	</v-dialog>
</template>

<script>
import { mapMutations } from "vuex";

import UserManual from "@/pages/UserManual";

export default {
	name: "UserManualDialog",

	components: {
		UserManual,
	},

	data() {
		return {
			dialog: false,
		};
	},

	watch: {
		dialog(val) {
			if (!val) {
				this.clearHelp();
			}
		},
	},

	methods: {
		...mapMutations(["clearHelp"]),
		close() {
			this.dialog = false;
		},
		open(sectionID) {
			this.dialog = true;

			// scroll to section
			if (typeof sectionID === "string") {
				setTimeout(() => {
					// find the heading
					const header = document.getElementById(sectionID);

					// scroll to the heading if it exists
					header?.scrollIntoView();
				}, 0);
			} else {
				setTimeout(() => {
					// scroll to top
					document
						.getElementById("user-manual-content")
						.scrollTo(0, 0);
				}, 0);
			}
		},
	},
};
</script>

<style lang="scss">
@import "../assets/styles/github-markdown-light.css";

.user-manual.v-dialog--fullscreen.v-dialog--scrollable {
	top: 55%;
	height: 45%;
}
</style>
