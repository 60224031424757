<template>
	<v-container>
		<v-row
			align="start"
			align-content="start"
			justify="center"
			:loading="loading"
		>
			<v-col
				xl="4"
				lg="6"
				md="8"
				xs="12"
			>
				<v-card class="d-flex flex-column pb-5">
					<v-toolbar
						color="primary"
						dark
						flat
					>
						<v-toolbar-title>{{ title }}</v-toolbar-title>
					</v-toolbar>

					<v-card-text v-if="validLink">
						<v-row justify="center">
							<v-col
								cols="10"
								style="min-width: 300px"
							>
								<v-container class="py-1 px-0">
									<password-input
										ref="newPassword"
										v-model="newPassword"
										v-model:password-valid="
											isNewPasswordValid
										"
										v-model:password-value="newPassword"
										:loading="!!loading"
										label="New password"
										prepend-icon="mdi-lock-plus"
										meter-size="small"
										required
										@change="$v.newPassword.$touch()"
									/>
								</v-container>

								<v-container class="py-1 px-0">
									<password-input
										ref="newPasswordConfirm"
										v-model="newPasswordConfirm"
										v-model:password-valid="
											isNewPasswordConfirmValid
										"
										v-model:password-value="
											newPasswordConfirm
										"
										v-model:dirty="
											isNewPasswordConfirmDirty
										"
										:loading="!!loading"
										label="Confirm new password"
										prepend-icon="mdi-lock-plus"
										disable-meter
										disable-validation
										:error-messages="
											newPasswordConfirmErrors
										"
										required
										required-message="Re-enter new password"
										@change="$v.newPasswordConfirm.$touch()"
									/>
								</v-container>
							</v-col>
						</v-row>
					</v-card-text>

					<v-card-actions v-if="validLink">
						<v-spacer />

						<v-btn
							color="secondary"
							:disabled="
								$v.$invalid || !!loading || !isNewPasswordValid
							"
							@click="save"
						>
							Save
						</v-btn>

						<v-spacer />
					</v-card-actions>

					<v-card-text
						v-else
						class="pt-8"
					>
						Your password reset link has expired. Please try again
						by clicking on "Forgot your password?" at the login
						dialog.
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapMutations } from "vuex";
import { required, minLength, maxLength } from "@vuelidate/validators";
import PasswordInput from "@/components/VPasswordInput.vue";

export default {
	name: "PasswordReset",

	components: {
		PasswordInput,
	},

	beforeRouteEnter(to, from, next) {
		next(async (vm) => {
			// validate the link signature against the api server
			const valid = await vm.validateLink(
				to.params.token,
				to.query.signature
			);

			vm.validLink = valid;
		});
	},

	data() {
		return {
			isNewPasswordValid: false,
			isNewPasswordConfirmValid: false,
			isNewPasswordConfirmDirty: false,
			isPasswordDirty: false,
			isPasswordValid: false,
			loading: false,
			newPassword: "",
			newPasswordConfirm: "",
			panel: [0],
			password: "",
			reveal: false,
			showPasswordMeter: true,
			title: "Password reset",
			token: null,
			validLink: false,
		};
	},

	computed: {
		newPasswordConfirmErrors() {
			const errors = [];

			if (!this.isNewPasswordConfirmDirty) {
				return errors;
			}

			if (!this.newPasswordConfirm) {
				errors.push("Re-enter new password");
			}

			if (!this.$v.newPasswordConfirm.sameAsNewPassword) {
				errors.push("Does not match new password");
			}

			return errors;
		},
	},

	methods: {
		...mapMutations(["showNotification"]),
		clear: function () {
			// clear dirty flags
			this.$v.$reset();

			this.$refs.password.clearPassword();
			this.$refs.newPassword.clearPassword();
			this.$refs.newPasswordConfirm.clearPassword();
		},
		notify: function (data) {
			this.showNotification({
				message: data.message,
				status: data.status,
			});
		},
		save: function () {
			if (!this.$v.$invalid) {
				this.loading = "secondary";

				this.$http
					.post("/password-reset", {
						token: this.token,
						newPassword: this.newPassword,
					})
					.then((response) => {
						// display success
						this.notify(response.data);

						// navigate to the login page
						this.$router.push(response.data.nextPath);

						// clear the inputs
						this.clear();
					})
					.catch((error) => {
						// display error
						if (error.response) {
							if (error.response.data.message) {
								this.notify(error.response.data);
							} else {
								this.notify(error.response.data.error);
							}
						}
					})
					.finally(() => {
						this.loading = "";
					});
			} else {
				// set all to dirty
				this.$v.$touch();
			}
		},
		async validateLink(token, signature) {
			try {
				await this.$http.get(`/password-reset/${token}`, {
					params: { signature },
				});

				// store token
				this.token = token;

				return true;
			} catch (error) {
				this.notify(error.response.data);

				return false;
			}
		},
	},

	validations() {
		return {
			newPassword: {
				required,
				minLength: minLength(8),
				maxLength: maxLength(60),
			},
			newPasswordConfirm: {
				required,
				minLength: minLength(8),
				maxLength: maxLength(60),
				sameAsNewPassword() {
					return this.newPassword === this.newPasswordConfirm;
				},
			},
		};
	},
};
</script>

<style lang="scss"></style>
