<template>
	<v-container
		id="quality-control"
		height="100%"
		fluid
	>
		<v-overlay
			:model-value="loading"
			class="align-center justify-center"
		>
			<v-progress-circular
				indeterminate
				size="64"
			/>
		</v-overlay>

		<v-row
			class="mx-2 qc-row align-center justify-center"
			align-content="start"
		>
			Coming soon...
		</v-row>
		<!-- <v-row
			class="mx-2 qc-row"
			justify="space-around"
			align="start"
			align-content="start"
		>
			<v-col class="left-column-container">
				<v-container class="pa-0">
					<keep-alive>
						<component
							v-if="fileData"
							v-bind:is="currentComponent"
							:base64-data="fileData"
							:file-type="fileType"
							:page="page"
							:zoom-to="zoomTo"
						></component>
					</keep-alive>
				</v-container>
				<v-container class="trash-can-container pa-0">
					<v-icon @click="restoreTrash" class="restore-trash-icon"
						>mdi-restore</v-icon
					>
					<v-icon class="mb-1"> mdi-trash-can-outline </v-icon>
					<span
						>{{ this.trashSize }} data
						{{ this.pluralize("point", this.trashSize) }}
					</span>
				</v-container>
			</v-col>
			<v-col cols="3" class="tree-container">
				<v-card>
					<v-toolbar color="primary" dark flat fixed dense>
						<v-toolbar-title>
							<v-icon class="mb-1" start> mdi-database </v-icon>

							Collected Data
						</v-toolbar-title>
					</v-toolbar>

					<div class="overflow-y-auto collected-data-tree-container">
						<v-nested-draggable
							v-model="dataPoints"
							class="pa-2"
							:group="{ name: 'data', pull: ['tree'] }"
							:sort="false"
							@draggable-mouseover="updateViewer"
							@handle-drop="handleDrop"
							@trash-item="trashItem"
						></v-nested-draggable>
					</div>
				</v-card>
			</v-col>

			<v-col class="pa-0 qc-arrow">
				<v-icon class="mt-n5" size="x-large"> mdi-arrow-right </v-icon>
			</v-col>

			<v-col cols="5" class="tree-container">
				<v-card>
					<v-toolbar color="primary" dark flat dense>
						<v-toolbar-title>
							<v-icon class="mb-1" start> mdi-file-tree </v-icon>

							Dactyl Tree
						</v-toolbar-title>

						<v-btn
							color="secondary"
							location="right"
							fixed
							@click="addStudy"
						>
							Add Study

							<v-icon end> mdi-microscope </v-icon>
						</v-btn>
					</v-toolbar>

					<v-stepper v-model="currentStep" non-linear alt-labels>
						<v-stepper-header class="mb-1">
							<v-stepper-step
								key="step-0"
								:step="0"
								editable
								edit-icon="mdi-file-document-outline"
								:color="
									0 === currentStep ? 'secondary' : 'grey'
								"
								complete
							>
								Document Reference
							</v-stepper-step>

							<v-divider></v-divider>

							<template
								v-for="(step, key) in steps"
								:key="`step-${key + 1}`"
							>
								<v-stepper-step
									:step="key + 1"
									editable
									edit-icon="mdi-microscope"
									:color="
										key + 1 === currentStep
											? 'secondary'
											: 'grey'
									"
									complete
								>
									{{ step[0].name || step[0].placeholder }}
								</v-stepper-step>

								<v-divider
									v-if="key !== steps.length - 1"
									:key="key"
								></v-divider>
							</template>
						</v-stepper-header>

						<v-stepper-items>
							<v-stepper-content
								key="step-0-content"
								:step="0"
								class="pa-0"
							>
								<div class="pa-3 overflow-y-auto">
									<paper-info :paper="paper"></paper-info>
								</div>
							</v-stepper-content>

							<v-stepper-content
								v-for="(step, key) in steps"
								:key="`step-${key + 1}-content`"
								:step="key + 1"
								class="pa-0"
							>
								<div
									class="pa-3 overflow-y-auto dactyl-tree-container"
								>
									<v-nested-draggable
										v-model="steps[key]"
										:group="{
											name: 'tree',
											put: ['data', 'tree'],
										}"
										filter=".noDrag"
										:initialTop="-12"
										@reset-item="resetItem"
										@handle-drop="handleDrop"
									></v-nested-draggable>
								</div>
							</v-stepper-content>
						</v-stepper-items>
					</v-stepper>
				</v-card>
			</v-col>
		</v-row> -->
	</v-container>
</template>

<script>
// import VNestedDraggable from "@/components/VNestedDraggable";
// import VImageViewer from "@/components/VImageViewer";
// import VPdfViewer from "@/components/VPdfViewer";
// import PaperInfo from "@/pages/PaperManager/PaperInfo";

import _ from "lodash";
import { mapMutations } from "vuex";
import utils from "@/utils";
import { v4 as uuidv4 } from "uuid";
import pluralize from "pluralize";

export default {
	name: "QualityControl",

	components: {
		// VNestedDraggable,
		// VImageViewer,
		// VPdfViewer,
		// PaperInfo,
	},

	beforeRouteLeave(to, from, next) {
		// NOTE - The vue router beforeRouteLeave is only hit after the first attempt to
		// navigate away; a subsequent click will bypass this function.  To prevent data loss,
		// save after cancelling the first navigation.
		if (
			this.dirty &&
			!confirm("You have unsaved changes. Do you really want to leave?")
		) {
			this.saveQC();
			return next(false);
		}

		// emit signal
		this.$emit("leave-quality-control");

		return next();
	},
	emits: ["enter-quality-control", "leave-quality-control"],

	data() {
		return {
			base64Files: [],
			currentFile: null,
			currentStep: 0,
			dataPoints: [],
			initialDataPoints: [],
			initialQcData: null,
			loading: true,
			page: 1,
			steps: [],
			trashData: [],
			tags: [],
			zoomTo: null,
			trashSize: 0,
		};
	},

	computed: {
		currentComponent() {
			const file = this.base64Files[this.currentFile];

			if (file) {
				if (/^application\/pdf/.test(file.type)) {
					return "v-pdf-viewer";
				} else if (/^image\//.test(file.type)) {
					return "v-image-viewer";
				}
			}

			return "v-image-viewer";
		},
		dirty() {
			return this.$store.getters.dirty;
		},
		done() {
			return this.$store.getters.done;
		},
		exit() {
			return this.$store.getters.exit;
		},
		fileData() {
			return this.base64Files[this.currentFile]
				? this.base64Files[this.currentFile].data
				: null;
		},
		fileType() {
			return this.base64Files[this.currentFile]
				? this.base64Files[this.currentFile].type
				: null;
		},
		paper() {
			return this.$store.getters.qcPaper;
		},
		save() {
			return this.$store.getters.qcSave;
		},
		saveUUID() {
			return this.$store.getters.qcSaveUUID;
		},
	},

	watch: {
		save(val) {
			if (val) {
				this.saveQC();
			}
		},
	},

	mounted() {
		this.$emit("enter-quality-control");
		this.loadData();
		this.fetchTags();

		// initialize tree with default structure
		// TODO: is this needed? it's set elsewhere
		// document reference and one study
		// this.steps = [[this.getStudyTemplate("Study 1")]];
	},

	methods: {
		...mapMutations([
			"showNotification",
			"receiveSave",
			"clearDirty",
			"setDirty",
		]),
		getInterventionTemplate(name) {
			return {
				name: name,
				collapsed: false,
				noDrag: true,
				noDrop: true,
				items: [
					{
						name: "Start Time",
						noDrag: true,
						items: [],
					},
					{
						name: "Start Time Unit",
						noDrag: true,
						items: [],
					},
					{
						name: "End Time",
						noDrag: true,
						items: [],
					},
					{
						name: "End Time Unit",
						noDrag: true,
						items: [],
					},
					{
						name: "Drug Name",
						noDrag: true,
						items: [],
					},
					{
						name: "Drug Amount",
						noDrag: true,
						items: [],
					},
					{
						name: "Drug Amount Unit",
						noDrag: true,
						items: [],
					},
					{
						name: "Frequency",
						noDrag: true,
						items: [],
					},
					{
						name: "Route",
						noDrag: true,
						items: [],
					},
					{
						name: "Formulation",
						noDrag: true,
						items: [],
					},
				],
			};
		},
		getAdverseEventTemplate(name) {
			return {
				name: name,
				collapsed: false,
				noDrag: true,
				noDrop: true,
				items: [
					{
						name: "Adverse Event Name",
						noDrag: true,
						items: [],
					},
					{
						name: "Severity (Mild/Moderate/Severe)",
						noDrag: true,
						items: [],
					},
					{
						name: "Serious/SAE",
						noDrag: true,
						items: [],
					},
					{
						name: "Related",
						noDrag: true,
						items: [],
					},
					{
						name: "Subject Count",
						noDrag: true,
						items: [],
					},
					{
						name: "Subject Percent",
						noDrag: true,
						items: [],
					},
					{
						name: "Event Count",
						noDrag: true,
						items: [],
					},
					{
						name: "Description",
						noDrag: true,
						items: [],
					},
				],
			};
		},
		getMeasurementTemplate(name) {
			return {
				name: "",
				placeholder: name,
				editable: true,
				collapsed: false,
				link: "https://www.denney.ws/wiki/DACTYL/Templates/Measurement#Fields",
				items: [],
				// {
				// 	name  : "Number of Subjects Included in Measurement",
				// 	items : [],
				// },
				// {
				// 	name  : "Measurement Type",
				// 	items : [],
				// },
				// {
				// 	name  : "Measurement Unit",
				// 	items : [],
				// },
				// {
				// 	name  : "Measurement Time",
				// 	items : [],
				// },
				// {
				// 	name  : "Measurement Time Unit",
				// 	items : [],
				// },
				// {
				// 	name  : "Arithmetic Mean",
				// 	items : [],
				// },
				// {
				// 	name  : "Geometric Mean",
				// 	items : [],
				// },
				// {
				// 	name  : "Geometric Coefficient of Variation",
				// 	items : [],
				// },
				// {
				// 	name  : "Geometric Standard Deviation",
				// 	items : [],
				// },
				// {
				// 	name  : "Median",
				// 	items : [],
				// },
				// {
				// 	name  : "Minimum",
				// 	items : [],
				// },
				// {
				// 	name  : "Maximum",
				// 	items : [],
				// },
				// {
				// 	name  : "Standard Error",
				// 	items : [],
				// },
				// {
				// 	name  : "Confidence Interval Lower Bound",
				// 	items : [],
				// },
				// {
				// 	name  : "Confidence Interval Upper Bound",
				// 	items : [],
				// },
				// {
				// 	name  : "Percent Confidence Interval",
				// 	items : [],
				// },
				// {
				// 	name  : "25th Percentile",
				// 	items : [],
				// },
				// {
				// 	name  : "75th Percentile",
				// 	items : [],
				// },
				// {
				// 	name  : "Percent",
				// 	items : [],
				// },
				// {
				// 	name  : "Count",
				// 	items : [],
				// },
			};
		},
		getTreatmentTemplate(name) {
			return {
				name: "",
				placeholder: name,
				editable: true,
				collapsed: false,
				noDrag: true,
				noDrop: true,
				items: [
					{
						name: "Interventions",
						link: "https://www.denney.ws/wiki/DACTYL/Templates/Intervention",
						collapsed: false,
						noDrag: true,
						noDrop: true,
						items: [],
						actions: [
							{
								verb: "Add",
								name: "Intervention",
								icon: "mdi-plus",
								color: "secondary",
								//fn: this.getInterventionTemplate,
							},
						],
					},
					{
						name: "Results",
						link: "https://www.denney.ws/wiki/DACTYL/Templates/Results",
						collapsed: false,
						noDrag: true,
						noDrop: true,
						items: [],
						actions: [
							{
								verb: "Add",
								name: "Result Measurement",
								icon: "mdi-plus",
								color: "secondary",
								//fn: this.getMeasurementTemplate,
							},
						],
					},
					{
						name: "Adverse Events",
						link: "https://www.denney.ws/wiki/DACTYL/Templates/Adverse_Event",
						collapsed: false,
						noDrag: true,
						noDrop: true,
						items: [],
						actions: [
							{
								verb: "Add",
								name: "Adverse Event",
								icon: "mdi-plus",
								color: "secondary",
								//fn: this.getAdverseEventTemplate,
							},
						],
					},
				],
			};
		},
		getStudyTemplate(name) {
			return {
				name: "",
				placeholder: name,
				editable: true,
				link: "https://www.denney.ws/wiki/DACTYL/Templates/Study",
				collapsed: false,
				noDrag: true,
				noDrop: true,
				items: [
					{
						name: "Sponsor Study Number",
						noDrag: true,
						items: [],
					},
					{
						name: "ClinicalTrials.gov Identifier",
						noDrag: true,
						items: [],
					},
					{
						name: "Study Design",
						link: "https://www.denney.ws/wiki/DACTYL/Templates/Study_Design",
						collapsed: false,
						noDrag: true,
						items: [
							{
								name: "Blinding",
								noDrag: true,
								items: [],
							},
							{
								name: "Randomized",
								noDrag: true,
								items: [],
							},
							{
								name: "Cross-over/Parallel",
								noDrag: true,
								items: [],
							},
						],
					},
					{
						name: "Inclusion and Exclusion Criteria",
						link: "https://www.denney.ws/wiki/DACTYL/Templates/Inclusion_and_Exclusion_Criteria",
						collapsed: false,
						noDrag: true,
						noDrop: true,
						items: [
							{
								name: "Planned Number of Subjects Randomized",
								noDrag: true,
								items: [],
							},
							{
								name: "Planned Number of Subjects Completing the Study",
								noDrag: true,
								items: [],
							},
							{
								name: "Inclusion",
								link: "https://www.denney.ws/wiki/DACTYL/Templates/Inclusion",
								collapsed: false,
								noDrag: true,
								items: [
									{
										name: "Patient",
										noDrag: true,
										items: [],
									},
									{
										name: "Race",
										noDrag: true,
										items: [],
									},
								],
							},
							{
								name: "Exclusion",
								link: "https://www.denney.ws/wiki/DACTYL/Templates/Inclusion",
								collapsed: false,
								noDrag: true,
								items: [
									{
										name: "Patient",
										noDrag: true,
										items: [],
									},
									{
										name: "Race",
										noDrag: true,
										items: [],
									},
								],
							},
						],
					},
					{
						name: "Demographics",
						link: "https://www.denney.ws/wiki/DACTYL/Templates/Demographics",
						collapsed: false,
						noDrag: true,
						noDrop: true,
						items: [],
						actions: [
							{
								verb: "Add",
								name: "Demographic Measurement",
								icon: "mdi-plus",
								color: "secondary",
								//fn: this.getMeasurementTemplate,
							},
						],
					},
					{
						name: "Treatments",
						link: "https://www.denney.ws/wiki/DACTYL/Templates/Treatment",
						collapsed: false,
						noDrag: true,
						noDrop: true,
						actions: [
							{
								verb: "Add",
								name: "Treatment",
								icon: "mdi-plus",
								color: "secondary",
								//fn: this.getTreatmentTemplate,
							},
						],
						items: [this.getTreatmentTemplate("Treatment 1")],
					},
				],
			};
		},
		addStudy() {
			this.steps.push([
				this.getStudyTemplate(`Study ${this.steps.length + 1}`),
			]);
		},
		setDataPoints(saveData) {
			// loop through all saved data for this paper
			let initialIndex = 0;
			let collapseDataset = false;
			let collapseAxis = false;
			let saveIndex = 1;
			for (let i = 0; i < saveData.length; i++) {
				const rawAxes = saveData[i].axesColl;
				const rawDatasets = saveData[i].datasetColl;

				// loop through saved datasets to construct of tree
				const formattedAxes = [];
				for (const axis of rawAxes) {
					const formattedDatasets = [];
					const rawAxisDatasets = rawDatasets.filter(
						(dataset) => dataset.axesName === axis.name
					);

					for (const axisDataset of rawAxisDatasets) {
						// only pack the dataset if associated data points exist
						if (axisDataset.data.length) {
							// format data points
							const formattedPoints = [];
							for (const point of axisDataset.data) {
								// errors in data extraction can sometimes cause missing metadata; skip them
								if (!point.metadata) {
									continue;
								}

								// filter out rowIndex and uuid
								const keys = _.filter(
									Object.keys(point.metadata),
									utils.isNumber
								);

								const data = keys.map((key) => {
									// include all rows of the dialog and optionally the tag
									let tag = "";
									if (point.metadata[key].tag) {
										tag = `${point.metadata[key].tag?.name}`;
									}
									return {
										name: point.metadata[key].value,
										tag: tag,
									};
								});

								// define a data point (leaf node)
								if (point.metadata.uuid.value) {
									formattedPoints.push({
										type: "POINT",
										data: data,
										hoverable: true,
										initialIndex: initialIndex++,
										uuid: point.metadata.uuid.value,
										disabled: false,
										noDrop: true,
										noDrag: false,
										hideExpand: false,
										metadata: {
											x: point.x,
											y: point.y,
											file: axisDataset.file,
											page: axisDataset.page,
										},
										showRestore: false,
										showTrash: true,
									});
								}
							}

							// pack the dataset
							if (formattedPoints.length) {
								formattedDatasets.push({
									type: "DATASET",
									name: axisDataset.name,
									initialIndex: initialIndex++,
									collapsed: collapseDataset,
									disabled: false,
									noDrop: true,
									noDrag: false,
									hideExpand: false,
									items: formattedPoints,
									showRestore: false,
									showTrash: true,
									uuid: uuidv4(),
								});
								collapseDataset = true;
							}
						}
					}

					// only pack the axis if associated datasets exist
					if (formattedDatasets.length) {
						// assume all datasets of an axis has the same page and file
						formattedAxes.push({
							type: "AXIS",
							name: axis.name,
							collapsed: collapseAxis,
							disabled: false,
							noDrag: true,
							noDrop: true,
							hideExpand: false,
							hoverable: true,
							items: formattedDatasets,
							metadata: {
								file: rawAxisDatasets[0].file,
								page: rawAxisDatasets[0].page,
							},
							uuid: uuidv4(),
						});

						collapseAxis = true;
					}
				}

				if (formattedAxes.length) {
					this.dataPoints.push({
						type: "SAVE",
						name: `Save ${saveIndex}`,
						collapsed: false,
						noDrag: true,
						items: formattedAxes,
						uuid: uuidv4(),
					});

					saveIndex++;
				}
			}

			// Create a deep copy of the original state
			this.initialDataPoints = _.cloneDeep(this.dataPoints);
		},
		clear() {
			this.base64Files = [];
			this.currentFile = null;
			this.currentStep = 0;
			this.dataPoints = [];
			this.initialDataPoints = [];
			this.trashData = [];
			this.loading = true;
			this.page = 1;
			this.steps = [[this.getStudyTemplate("Study 1")]];
			this.tags = [];
			this.zoomTo = null;
		},
		loadData() {
			// get files and save data from server
			this.$http
				.get(`/papers/${this.paper.uuid}`)
				.then((response) => {
					this.clear();

					// convert base64 data to File objects
					response.data.fileData.forEach((data) => {
						this.base64Files.push({
							data: data.data,
							type: data.type,
							uuid: data.uuid,
						});

						// load first file with the correct viewer
						this.currentFile = 0;
					});

					let saveData = response.data.saveData ?? [];
					this.initialQcData = response.data.qcData;
					if (this.initialQcData) {
						this.dataPoints = this.initialQcData.entry_data.data;
						this.initialDataPoints =
							this.initialQcData.initial_entry_data.data;
						this.trashData = this.initialQcData.trash_data.data;
						this.steps = this.initialQcData.dactyl_tree.data;

						const trashPoints = utils.queryJsonByType({
							data: this.trashData,
							type: "POINT",
						});

						this.trashSize = trashPoints.length;
					} else {
						this.setDataPoints(saveData);
					}
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		fetchTags() {
			this.$http
				.get("/tags", {
					params: {
						flat: true,
						padded: true,
					},
				})
				.then((response) => {
					this.tags = [...response.data.tags];
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				});
		},
		getTags() {
			return this.tags;
		},
		updateViewer: _.debounce(function (metadata) {
			// set file if included
			if (metadata.file !== undefined) {
				this.currentFile = metadata.file;
			}

			// set page if included
			if (metadata.page !== undefined) {
				this.page = metadata.page;
			}

			// set x and y if included
			if (metadata.x !== undefined && metadata.y !== undefined) {
				this.zoomTo = {
					x: metadata.x,
					y: metadata.y,
				};
			} else {
				this.zoomTo = null;
			}
		}, 100),
		resetItem(item) {
			// Find the item's parent
			const initialParent = utils.queryJsonByUUID({
				uuid: item.uuid,
				data: this.initialDataPoints,
				parentLevel: 2,
			});

			const parent = utils.queryJsonByUUID({
				uuid: initialParent.uuid,
				data: this.dataPoints,
			});

			if (parent && parent.items) {
				// Show the trash icon, hide the restore icon
				this.$set(item, "showRestore", false);
				this.$set(item, "showTrash", true);

				// Re-enable dragging and trashing for any child data points
				if (item.items) {
					item.items.map((subItem) => {
						this.$set(subItem, "noDrag", false);
						this.$set(subItem, "showTrash", true);
					});
				}

				// Re-insert the item into the left column in the correct spot
				let insertIndex = parent.items.findIndex(
					(obj) => obj.initialIndex >= item.initialIndex
				);
				parent.items.splice(
					insertIndex === -1 ? parent.items.length : insertIndex,
					0,
					item
				);

				// If this is a data point (a leaf node), and the parent container is back
				// to its original state (all original leaf nodes present), then re-enable
				// trashing and dragging on the parent
				if (item.type === "POINT") {
					const initialParent = utils.queryJsonByUUID({
						uuid: parent.uuid,
						data: this.initialDataPoints,
					});

					if (parent.items.length === initialParent.items.length) {
						this.$set(parent, "showTrash", true);
						this.$set(parent, "noDrag", false);
					}
				}

				this.$set(parent, "hideExpand", false);
				this.$set(parent, "disabled", false);
			}

			// Remove the item from the right (study) column
			const stepParent = utils.queryJsonByUUID({
				uuid: item.uuid,
				data: this.steps,
				parentLevel: 2,
			});

			if (stepParent) {
				stepParent.items.splice(
					stepParent.items.findIndex(
						(point) => point.initialIndex === item.initialIndex
					),
					1
				);
			}

			this.setDirty();
		},
		trashItem(item) {
			// Exit if it's already in the trash
			const existingSearch = utils.queryJsonByUUID({
				uuid: item.uuid,
				data: this.trashData,
			});

			if (existingSearch) return;

			// Find the parent of the item being trashed
			const parent = utils.queryJsonByUUID({
				uuid: item.uuid,
				data: this.dataPoints,
				parentLevel: 2,
			});

			// Check if parent element already in trash
			const existingParent = this.trashData.find((element) => {
				return element.uuid === parent.uuid;
			});

			// If the parent element already exists in the trash (another of its children has
			// already been trashed), then just add the current item to it.  Otherwise, create
			// a copy of the parent item in the trash
			if (existingParent) {
				existingParent.items.push(item);
				this.trashSize++;
			} else {
				const parentClone = _.cloneDeep(parent);
				parentClone.items = [item];
				this.trashData.push(parentClone);
				if (item.items) {
					this.trashSize += item.items.length;
				} else {
					this.trashSize++;
				}
			}

			// Remove the item from the data
			parent.items.splice(
				parent.items.findIndex(
					(point) => point.initialIndex === item.initialIndex
				),
				1
			);

			// If this was a data point (leaf node), disable dragging and trashing
			// the parent
			if (item.type === "POINT") {
				this.$set(parent, "showTrash", false);
				this.$set(parent, "noDrag", true);
			}

			// If the parent is now empty, disable expanding
			if (parent.items.length === 0) {
				this.$set(parent, "hideExpand", true);
				this.$set(parent, "disabled", true);
			}

			this.setDirty();
		},

		restoreTrash() {
			this.trashData.forEach((element) => {
				const subItems = element.items;
				subItems.forEach(this.resetItem);
				const current = utils.queryJsonByUUID({
					uuid: element.uuid,
					data: this.dataPoints,
				});

				this.$set(current, "hideExpand", false);
				this.$set(current, "disabled", false);
			});

			this.trashData = [];
			this.trashSize = 0;
			this.setDirty();
		},
		handleDrop(item) {
			// Find the dropped item
			const itemResult = utils.queryJsonByUUID({
				uuid: item.uuid,
				data: this.steps,
			});

			// Hide the "drag" ability from any child items
			if (itemResult && itemResult.items) {
				itemResult.items.map((subItem) => {
					this.$set(subItem, "noDrag", true);
					this.$set(subItem, "showTrash", false);
				});
			}

			// Show "restore" and hide "trash" for the dropped element
			this.$set(item, "showRestore", true);
			this.$set(item, "showTrash", false);

			// disable dragging and trashing on the parent group
			const initialParentContainer = utils.queryJsonByUUID({
				uuid: item.uuid,
				data: this.initialDataPoints,
				parentLevel: 2,
			});

			const parentContainer = utils.queryJsonByUUID({
				uuid: initialParentContainer.uuid,
				data: this.dataPoints,
			});

			this.$set(parentContainer, "showTrash", false);
			this.$set(parentContainer, "noDrag", true);
			if (parentContainer.items.length === 0) {
				this.$set(parentContainer, "hideExpand", true);
				this.$set(parentContainer, "disabled", true);
			}

			this.setDirty();
		},
		addDactylVersion(obj) {
			obj.dactylVersion = this.$version;
			return obj;
		},
		saveQC() {
			const versionedTrash = {
				dactylVersion: this.$version,
				data: this.trashData,
			};
			const versionedTree = {
				dactylVersion: this.$version,
				data: this.steps,
			};
			const versionedData = {
				dactylVersion: this.$version,
				data: this.dataPoints,
			};
			const versionedInitialData = {
				dactylVersion: this.$version,
				data: this.initialDataPoints,
			};

			if (this.initialQcData) {
				this.$http
					.put(`/quality-control/${this.initialQcData.uuid}`, {
						dactylTree: versionedTree,
						done: this.done,
						entryData: versionedData,
						initialEntryData: versionedInitialData,
						paperId: this.paper.id,
						trashData: versionedTrash,
					})
					.then(() => {
						this.showNotification({
							message: "Save complete",
							status: "success",
						});
					})
					.catch((error) => {
						if (error.response) {
							this.showNotification(error.response.data);
						}
					})
					.finally(() => {
						const navigate = this.done || this.exit;

						// consume save event
						this.receiveSave();
						if (navigate) {
							// redirect to dashboard
							this.$router.push("/home/dashboard");
						}
					});
			} else {
				this.$http
					.post(`/quality-control`, {
						dactylTree: versionedTree,
						done: this.done,
						entryData: versionedData,
						initialEntryData: versionedInitialData,
						paperId: this.paper.id,
						trashData: versionedTrash,
					})
					.then(() => {
						this.showNotification({
							message: "Save complete",
							status: "success",
						});

						this.clearDirty();
					})
					.catch((error) => {
						if (error.response) {
							this.showNotification(error.response.data);
						}
					})
					.finally(() => {
						const navigate = this.done || this.exit;

						// consume save event
						this.receiveSave();
						if (navigate) {
							// redirect to dashboard
							this.$router.push("/home/dashboard");
						}
					});
			}
		},
		pluralize(text, count) {
			return pluralize(text, count);
		},
	},
};
</script>

<style scoped lang="scss">
.qc-row {
	height: calc(100vh - 84px);
}

.collected-data-tree-container {
	max-height: calc(100vh - 156px);
}

.dactyl-tree-container {
	max-height: calc(100vh - 237px);
}

.qc-arrow {
	display: flex;
	height: 100%;
	max-width: 40px;
	line-height: 60px;
}

.v-stepper__header {
	max-height: 77px;
}

.v-stepper__step {
	padding: 14px;
	font-size: 14px;
}

.left-column-container {
	display: flex;
	justify-content: space-evenly;
	flex-direction: column;
	height: 100%;
	width: 400px;
}

.tree-container {
	flex-grow: 1;
	max-width: 40%;
}

.trash-can-container {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: 100px;
	height: 150px;
	border: 1px solid rgba(110, 118, 129, 0.4);
	border-bottom-color: rgba(110, 118, 129, 0.4);
	border-radius: 6px;
	box-shadow: inset 0 -1px 0 rgba(110, 118, 129, 0.4);
	color: #484f58;
}

.restore-trash-icon {
	position: absolute;
	top: 5px;
	right: 5px;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	cursor: pointer;
}
.restore-trash-icon:hover {
	background-color: #f0f0f0;
}
</style>
