<template>
	<v-card>
		<v-card-title class="pa-0">
			<v-toolbar
				:class="dialog ? 'rounded-t' : ''"
				color="primary"
				dark
				flat
			>
				<v-toolbar-title>User manual</v-toolbar-title>

				<v-spacer v-if="dialog" />

				<v-btn
					v-if="dialog"
					icon
					theme="dark"
					@click="close"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>

				<v-btn
					v-if="dialog"
					icon
					theme="dark"
					:to="{ path: '/user-manual' }"
					target="_blank"
					@click="close"
				>
					<v-icon>mdi-open-in-new</v-icon>
				</v-btn>
			</v-toolbar>
		</v-card-title>

		<!-- eslint-disable vue/no-v-text-v-html-on-component vue/no-v-html -->
		<v-card-text
			id="user-manual-content"
			class="py-8 markdown-body"
			v-html="compiledMarkdown"
		>
		</v-card-text>
		<!--eslint-enable-->
	</v-card>
</template>

<script>
import MarkdownIt from "markdown-it";
import MIAbbreviation from "markdown-it-abbr";
import MIAnchor from "markdown-it-anchor";
import MIDefinitionList from "markdown-it-deflist";
import MIEmoji from "markdown-it-emoji";
import MIFootnote from "markdown-it-footnote";
import MISubscript from "markdown-it-sub";
import MISuperscript from "markdown-it-sup";

import userManualContent from "@/assets/docs/user_manual.md";

// set up markdown-it
const mdit = new MarkdownIt({
	html: true,
	linkify: true,
	typographer: true,
});

mdit.use(MIAbbreviation)
	.use(MIAnchor)
	.use(MIDefinitionList)
	.use(MIEmoji)
	.use(MIFootnote)
	.use(MISubscript)
	.use(MISuperscript);

// compile this only once
const compiledMarkdown = mdit.render(userManualContent);

export default {
	name: "UserManual",

	props: {
		dialog: Boolean,
	},

	emits: ["close"],

	data() {
		return {
			compiledMarkdown: compiledMarkdown,
		};
	},

	methods: {
		close() {
			this.$emit("close");
		},
	},
};
</script>

<style lang="scss">
@import "../assets/styles/github-markdown-light.css";
</style>
