import * as Elements from "./table-axis/elements.js";
import * as Functions from "./table-axis/functions.js";
import * as Repainters from "./table-axis/repainters.js";
import * as Tools from "./table-axis/tools.js";

/**
 * Initializes the custom table axis to WPD.
 * @param {VueComponent} dataEntryVM - DataEntry Vue instance
 */
function init(wpd, wpdWindow, wpdDocument, dataEntryVM, options) {
	wpd.tableAxis = {};

	const elementIDs = {
		plotTypeRadio: "r_table",
		infoPopup: "tableAxesInfo",
		tweakButton: "tweak-table-axis-calibration-button",
		areaSidebar: "table-axis-area-calibration-sidebar",
		rcSidebar: "table-axis-rows-columns-calibration-sidebar",
		detailsSidebar: "table-axis-details-calibration-sidebar",
		rowInput: "table-axis-row-input",
		colInput: "table-axis-col-input",
		headerlessInput: "table-axis-no-header-input",
		schemaInput: "table-axis-schema-input",
		tagGroupInput: "table-cell-tag-group-input",
	};

	// add html elements
	Elements.apply(wpd, wpdDocument, elementIDs, options);

	// add repainters
	Repainters.apply(wpd);

	// add tools
	Tools.apply(wpd, wpdWindow, wpdDocument, dataEntryVM, elementIDs);

	// add functions
	Functions.apply(wpd, wpdDocument, elementIDs);

	wpd.tableAxis.validateTableHeaders = function () {
		const activeAxes = wpd.tree.getActiveAxes();
		const metadata = activeAxes.getMetadata();

		if (
			!metadata.calibration ||
			!metadata.calibration.rows ||
			!metadata.calibration.cols
		) {
			return false;
		}

		const headerHasTag = (header) => {
			return (
				!!header &&
				Object.values(header).some((cell) => {
					return cell.tag && wpd.tagGroups.isKeyTag(cell.tag.name);
				})
			);
		};

		const rows = metadata.calibration.rows.headers;
		const cols = metadata.calibration.cols.headers;
		if (rows.length === 0 && cols.length === 0) {
			return false;
		}

		for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
			const rowHeader = rows[rowIndex];
			const rowHasHeader = headerHasTag(rowHeader);
			for (let colIndex = 0; colIndex < cols.length; colIndex++) {
				const colHeader = cols[colIndex];
				const colHasHeader = headerHasTag(colHeader);
				if (!colHeader && !rowHeader) continue;
				if (
					(!rowHasHeader && !colHasHeader) ||
					(rowHasHeader && colHasHeader)
				) {
					return false;
				}
			}
		}

		return true;
	};

	wpd.tableAxis.hasEmptyTableHeaders = function () {
		const activeAxes = wpd.tree.getActiveAxes();
		const metadata = activeAxes.getMetadata();

		if (
			!metadata.calibration ||
			!metadata.calibration.rows ||
			!metadata.calibration.cols
		) {
			return true;
		}

		const emptyRowHeaders = metadata.calibration.rows.headers.filter(
			(row) => !row
		);
		const emptyColHeaders = metadata.calibration.cols.headers.filter(
			(col) => !col
		);

		return emptyRowHeaders.length > 0 || emptyColHeaders.length > 0;
	};

	wpd.tableAxis.areTableHeadersEmpty = function () {
		const activeAxes = wpd.tree.getActiveAxes();
		const metadata = activeAxes.getMetadata();

		if (
			!metadata.calibration ||
			!metadata.calibration.rows ||
			!metadata.calibration.cols
		) {
			return true;
		}

		const rows = metadata.calibration.rows.headers.filter((row) => !!row);
		const cols = metadata.calibration.cols.headers.filter((col) => !!col);

		return rows.length === 0 && cols.length === 0;
	};

	wpd.tableAxis.cancelTableCalibration = function () {
		const activeAxis = wpd.tree.getActiveAxes();
		wpdDocument
			.getElementById("table-add-button")
			.classList.remove("pressed-button");

		wpd.tableAxis.closeCancelTableCalibrationDialog();
		if (activeAxis) {
			wpd.tree.deleteAxis(activeAxis.name);
		}
		wpd.sidebar.clear();
		wpd.graphicsWidget.removeTool();
		wpd.tree.selectPath("/Table");
	};

	wpd.tableAxis.acceptEmptyTableHeaders = function () {
		wpd.tree.refreshPreservingSelection();
		wpd.tableAxis.closeHasEmptyTableHeadersDialog();
	};

	wpd.tableAxis.closeInvalidTableHeadersDialog = function () {
		wpd.popup.close("invalid-table-headers-dialog");
	};

	wpd.tableAxis.showInvalidTableHeadersDialog = function () {
		wpd.popup.show("invalid-table-headers-dialog");
	};

	wpd.tableAxis.closeEmptyTableHeadersDialog = function () {
		wpd.popup.close("empty-table-headers-dialog");
	};

	wpd.tableAxis.showEmptyTableHeadersDialog = function () {
		wpd.popup.show("empty-table-headers-dialog");
	};

	wpd.tableAxis.closeHasEmptyTableHeadersDialog = function () {
		wpd.popup.close("has-empty-table-headers-dialog");
	};

	wpd.tableAxis.showHasEmptyTableHeadersDialog = function () {
		wpd.popup.show("has-empty-table-headers-dialog");
	};

	wpd.tableAxis.closeCancelTableCalibrationDialog = function () {
		wpd.popup.close("cancel-table-calibration-dialog");
	};

	wpd.tableAxis.showCancelTableCalibrationDialog = function () {
		wpd.popup.show("cancel-table-calibration-dialog");
	};
}

export { init };
