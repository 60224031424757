<template>
	<v-card
		class="d-flex flex-column pb-5"
		:loading="loading"
	>
		<v-toolbar
			color="primary"
			dark
			flat
		>
			<v-toolbar-title>{{ title }}</v-toolbar-title>

			<template #extension>
				<v-tabs
					v-model="currentTab"
					bg-color="transparent"
					color="secondary"
					slider-size="3"
					fixed-tabs
					grow
					selected-class="tabs-slider"
				>
					<v-tab
						v-for="tab in tabs"
						:key="tab.name"
						class="font-weight-bold"
					>
						{{ tab.name }}
					</v-tab>
				</v-tabs>
			</template>
		</v-toolbar>

		<v-tabs-window v-model="currentTab">
			<v-tabs-window-item
				v-for="tab in tabs"
				:key="tab.name"
			>
				<tag-group-list
					v-bind="tab"
					:base-tags="baseTags"
					@refresh="getTagGroups"
				/>
			</v-tabs-window-item>
		</v-tabs-window>
	</v-card>
</template>

<script>
import _ from "lodash";
import { mapMutations } from "vuex";

import TagGroupList from "@/pages/TagGroupManager/TagGroupList";

export default {
	name: "TagGroupTabs",

	components: {
		TagGroupList,
	},

	data() {
		return {
			baseTags: [],
			currentTab: null,
			loading: false,
			tabs: [
				{
					name: "Level 1",
					level: 1,
					description:
						"Beginner level of tag groups, intended for new source data extraction users.",
					tagGroups: [],
				},
				{
					name: "Level 2",
					level: 2,
					description:
						"Intermediate level of tag groups, intended for experienced source data extraction users.",
					tagGroups: [],
				},
				{
					name: "Level 3",
					level: 3,
					description:
						"Advanced level of tag groups, intended for specialized source data extraction and quality control users.",
					tagGroups: [],
				},
			],
			title: "Tag groups",
		};
	},

	mounted() {
		// get data
		this.getTagGroups();
		this.getBaseTags();
	},

	methods: {
		...mapMutations(["showNotification"]),
		getTagGroups() {
			this.loading = "secondary";

			this.$http
				.get("/tag-groups")
				.then((response) => {
					const tagGroupsByLevel = _.groupBy(
						response.data.tagGroups,
						"level"
					);

					this.tabs[0].tagGroups = tagGroupsByLevel["1"];
					this.tabs[1].tagGroups = tagGroupsByLevel["2"];
					this.tabs[2].tagGroups = tagGroupsByLevel["3"];
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		getBaseTags() {
			this.$http
				.get("/tags", {
					params: {
						flat: true,
					},
				})
				.then((response) => {
					this.baseTags = response.data.tags.map((tag) => {
						return {
							name: tag.name.trim(),
							uuid: tag.uuid,
							disabled: tag.disabled,
						};
					});
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				});
		},
	},
};
</script>
