<template>
	<v-autocomplete
		ref="autocomplete"
		v-model="roleSelect"
		:disabled="disabled"
		:items="roles"
		:loading="loading"
		:label="label"
		:messages="noResults"
		append-icon=""
		clearable
		closable-chips
		hide-no-data
		hint="Type in keywords to begin searching"
		item-color="secondary"
		:item-title="composedText"
		item-value="uuid"
		multiple
		persistent-hint
		prepend-icon="mdi-magnify"
		return-object
		density="compact"
		@update:model-value="updateRoles"
	>
		<template #chip="{ item, index }">
			<v-chip
				class="bg-secondary text-white"
				closable
				@click:close="removeItem(index)"
			>
				{{ item.title }}
			</v-chip>
		</template>
	</v-autocomplete>
</template>

<script>
import _ from "lodash";
import utils from "@/utils";

import { mapMutations } from "vuex";

export default {
	name: "RoleSearch",

	props: {
		defaultRoles: { type: Array, default: () => [] },
		disabled: Boolean,
		label: {
			default: "Search for roles",
			type: String,
		},
		modelValue: { type: Array, default: () => [] },
	},
	emits: ["update:modelValue", "role-updated", "update:roles-loading"],

	data() {
		return {
			loading: true,
			noResults: "",
			roles: [],
			roleSearch: null,
		};
	},

	computed: {
		roleSelect: {
			get() {
				return this.modelValue;
			},
			set(val) {
				this.$emit("update:modelValue", val);
			},
		},
	},

	watch: {
		roleSearch(val) {
			if (val) {
				this.queryRoles(val);
			}
		},
	},

	mounted() {
		this.roles = this.defaultRoles;
		this.queryRoles();
	},

	methods: {
		...mapMutations(["showNotification"]),
		clear() {
			this.doneLoading();
		},
		queryRoles: _.debounce(function () {
			this.loading = "secondary";

			this.$http
				.get("/roles", {
					params: {
						omitDeleted: true,
					},
				})
				.then((response) => {
					this.roles = response.data.roles.data;
				})
				.finally(() => {
					this.doneLoading();
				});
		}, 200),
		composedText(role) {
			return utils.truncateText(role.name, 100);
		},
		updateRoles(val) {
			this.$emit("role-updated", val);
		},
		doneLoading() {
			this.loading = false;
			this.$emit("update:roles-loading", false);
		},
		removeItem(index) {
			this.roleSelect = this.roleSelect.filter((_, i) => i !== index);
		},
	},
};
</script>

<style scoped lang="scss">
:deep(.v-field__input) {
	min-height: 60px;
	padding-top: 20px;
}
</style>
