<template>
	<v-row
		align-content="start"
		justify="start"
	>
		<v-col cols="12">
			<project-table />
		</v-col>
	</v-row>
</template>

<script>
import ProjectTable from "@/pages/ProjectManager/ProjectTable";

export default {
	name: "ProjectManager",

	components: {
		ProjectTable,
	},
};
</script>

<style scoped lang="scss">
.col {
	flex-grow: 0;
}
</style>
