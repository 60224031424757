<template>
	<v-container
		fluid
		fill-height
	>
		<v-row
			class="text-center flex-md-column flex-sm-row flex-column align-center pb-3 pt-3"
		>
			<v-col
				class="ml-0 ml-sm-5 my-lg-n10"
				cols="12"
				sm="3"
				md="4"
				lg="5"
			>
				<v-img
					src="@/assets/images/dactyl_library2_color_circle.png"
					lazy-src="@/assets/images/dactyl_library2_color_circle.gif"
					transition="fade-transition"
					max-height="800"
					eager
				>
					<template #placeholder>
						<v-row
							class="fill-height ma-0"
							align="center"
							justify="center"
						>
							<v-progress-circular
								indeterminate
								color="secondary-lighten-1"
							/>
						</v-row>
					</template>
				</v-img>
			</v-col>

			<v-divider
				vertical
				class="d-md-none d-sm-block d-none my-5 mx-5"
			/>

			<v-col
				class="d-md-block d-sm-flex flex-wrap mb-md-1 mb-sm-0 text-md-center text-sm-start col-md-8 col-sm-6 col-12"
			>
				<v-divider
					class="d-md-block d-sm-none d-block mb-md-6 mb-6 mx-12"
				/>

				<h1 class="text-h3 font-weight-light mb-2 text-primary">
					Welcome to <span class="font-weight-bold">DACTYL</span>
				</h1>

				<p class="subheading font-weight-regular">
					Please
					<a
						class="text-secondary cursor-pointer"
						@click="openLoginDialog"
						>log in
					</a>
					to continue.
				</p>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapMutations } from "vuex";

export default {
	name: "DactylWelcome",

	props: {
		login: Boolean,
		ssoFailure: {
			default: false,
			type: Boolean,
		},
		token: { type: String, default: "" },
	},
	emits: ["token", "update:login"],

	mounted() {
		if (this.login) {
			this.openLoginDialog();
		}

		if (this.ssoFailure) {
			this.showNotification({
				message:
					"SSO authentication failed, please contact Dactyl administrators",
				status: "error",
			});
		}

		if (this.token) {
			this.$emit("token", this.token);
		}
	},

	methods: {
		...mapMutations(["showNotification"]),
		openLoginDialog() {
			this.$emit("update:login", true);
		},
	},
};
</script>
