/**
 * Initializes WPD data points repainter.
 */
function init(wpd) {
	wpd.AlignmentCornersRepainter = class {
		constructor(calibration) {
			this._calibration = calibration;
			this.painterName = "AlignmentCornersRepainter";
		}

		onForcedRedraw() {
			wpd.graphicsWidget.resetData();
			this.onRedraw();
		}

		onRedraw() {
			if (this._calibration == null) {
				return;
			}

			let i, imagePos, imagePx, fillStyle;

			for (i = 0; i < this._calibration.getCount(); i++) {
				imagePos = this._calibration.getPoint(i);
				imagePx = {
					x: imagePos.px,
					y: imagePos.py,
				};

				if (this._calibration.isPointSelected(i)) {
					fillStyle = wpd.Colors.active;
				} else {
					fillStyle = wpd.Colors.good;
				}

				wpd.graphicsHelper.drawPoint(
					imagePx,
					fillStyle,
					this._calibration.labels[i],
					this._calibration.labelPositions[i]
				);
			}
		}
	};
}

export { init };
