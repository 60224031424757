/**
 * Initializes WPD Dataset.
 */
function init(wpd) {
	const originalClearAll = wpd.Dataset.prototype.clearAll;

	// select datasets group item after loading (for first load)
	wpd.Dataset.prototype.clearAll = function () {
		const metadata = this.getMetadata();

		originalClearAll.call(this);

		if (metadata.table && metadata.colIndex) {
			this.setMetadata(metadata);

			wpd.tree.selectDataset(wpd.tree.getActiveDataset().name);
		}
	};

	/**
	 * Gets all tag group UUIDs associated with data points in this dataset.
	 * @returns {array} Array of objects, each with uuid, name, and instanceUuid
	 */
	wpd.Dataset.prototype.getTagGroups = function () {
		return this.getAllPixels().map(
			(dataPoint) => dataPoint?.metadata?.tagGroup
		);
	};

	/**
	 * Gets the tag group UUID associated with the given data point.
	 * @param {number} index Index of data point within the dataset
	 * @returns
	 */
	wpd.Dataset.prototype.getTagGroup = function (index) {
		return this.getPixel(index)?.metadata?.tagGroup;
	};

	/**
	 * Associates the given tag group UUID with the given data point index.
	 * @param {number} index Index of data point within the dataset
	 * @param {object} tagGroup Tag group object to associate with data point
	 * @param {string} instanceUuid Tag group instance UUID to associate with
	 * 								data point
	 */
	wpd.Dataset.prototype.setTagGroup = function (
		index,
		tagGroup,
		instanceUuid
	) {
		const dataPoint = this.getPixel(index);

		if (tagGroup === null || tagGroup === undefined) {
			// completely remove the key if the passed in
			// tag group is null or undefined
			delete dataPoint?.metadata?.tagGroup;
		} else {
			// define the tag group data
			const tagGroupData = Object.assign({}, tagGroup, {
				instanceUuid,
			});

			// get the existing metadata if it exists and add tag group data
			let metadata = dataPoint.metadata || {};
			Object.assign(metadata, {
				tagGroup: tagGroupData,
			});

			// set the metadata for the data point
			this.setMetadataAt(index, metadata);
		}
	};

	/**
	 * Gets all indexes of data points associated with the given data point's
	 * tag group instance uuid within the dataset.
	 * @param {Number}	index	Index of data point with tag group instance uuid
	 * 							to search for
	 * @returns {Array}	Indexes of associated data points, including the given
	 * 					data point index
	 */
	wpd.Dataset.prototype.getTagGroupInstancePixelIndexes = function (index) {
		const allDataPoints = this.getAllPixels();

		// find all data points within the dataset with the same
		// tag group instance
		const instanceUuid = _.get(
			this.getPixel(index),
			"metadata.tagGroup.instanceUuid"
		);

		return _.reduce(
			allDataPoints,
			(accumulator, dataPoint, index) => {
				if (
					_.get(dataPoint, "metadata.tagGroup.instanceUuid") ===
					instanceUuid
				) {
					accumulator.push(index);
				}

				return accumulator;
			},
			[]
		);
	};
}

export { init };
