<template>
	<v-card
		class="d-flex flex-column pb-5"
		:disabled="!!loading || locked"
		:loading="loading"
	>
		<v-toolbar
			color="primary"
			dark
			flat
		>
			<v-toolbar-title>{{ title }}</v-toolbar-title>
		</v-toolbar>

		<v-card-text v-if="locked">
			<div class="mt-5 text-center">
				{{ placeholder }}
			</div>
		</v-card-text>

		<v-row v-else>
			<v-col
				cols="7"
				class="pt-8 pl-5 pb-5"
			>
				<paper-info :paper="paper" />
			</v-col>

			<v-col cols="5">
				<v-treeview
					base-color="black"
					class="mt-5 mb-6 mr-5"
					:items="tree"
					:open="open"
					item-children="files"
					item-key="uuid"
					item-title="name"
					expand-icon=""
					collapse-icon=""
					:activatable="false"
					:disabled="true"
					open-all
					return-object
				>
					<template #prepend="{ item }">
						<v-icon v-if="!item.mime_type">
							{{ "mdi-file-document-multiple" }}
						</v-icon>

						<v-icon v-else>
							{{ types[item.mime_type] }}
						</v-icon>
					</template>
				</v-treeview>
			</v-col>
		</v-row>

		<v-card-actions>
			<v-spacer />

			<v-btn
				class="bg-secondary text-white"
				elevation="2"
				type="button"
				:disabled="locked || !!loading"
				@click="start"
			>
				{{ actionButtonLabel }}
			</v-btn>

			<v-spacer />
		</v-card-actions>
	</v-card>
</template>

<script>
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { VTreeview } from "vuetify/labs/VTreeview";

import { mapMutations } from "vuex";

import PaperInfo from "@/pages/PaperManager/PaperInfo";

export default {
	name: "PaperStaging",

	components: {
		PaperInfo,
		VTreeview,
	},

	props: {
		paperSelected: Boolean,
		qcMode: Boolean,
	},

	data() {
		return {
			count: 0,
			inProgress: false,
			loading: false,
			locked: true,
			nextPapersText:
				"currently available after source data extraction on this paper is completed.",
			open: [],
			placeholder: "No paper selected.",
			tree: [],
			types: {
				"application/pdf": "mdi-file-pdf-box",
				"application/msword": "mdi-file-word-box",
				"image/bmp": "mdi-image",
				"image/jpeg": "mdi-image",
				"image/gif": "mdi-image",
				"image/png": "mdi-image",
				"image/tiff": "mdi-file-document",
			},
		};
	},

	computed: {
		actionButtonLabel() {
			const action = this.qcMode
				? "Quality Control"
				: "Source Data Extraction";
			return this.inProgress ? `Continue ${action}` : `Begin ${action}`;
		},
		paper() {
			const clone = _.cloneDeep(
				this.qcMode
					? this.$store.state.qcPaper
					: this.$store.state.paper
			);

			if (clone) {
				this.loadTree(clone);
			}

			return clone;
		},
		user() {
			return this.$store.getters.user;
		},
		title() {
			const type = this.qcMode
				? "quality control"
				: "source data extraction";
			return `Current paper for ${type}`;
		},
	},

	watch: {
		paper(val) {
			if (_.isEmpty(val)) {
				this.locked = true;
			} else {
				this.locked = false;
			}
		},
	},

	created() {
		this.getUserInProgressDataEntries();
	},

	methods: {
		...mapMutations([
			"clearPaper",
			"clearQCPaper",
			"setPaper",
			"setQCPaper",
			"setSaveUUID",
			"setQCSaveUUID",
			"showNotification",
		]),
		loadTree(paper) {
			this.tree = [paper];
			this.open = [paper.uuid];

			this.locked = false;
		},
		start() {
			this.loading = "secondary";
			if (this.qcMode) {
				this.startQualityControl();
			} else {
				this.startDataEntry();
			}

			this.loading = false;
		},
		startDataEntry() {
			this.$router.push("/data-entry");
		},
		startQualityControl() {
			this.$router.push("/quality-control");
		},
		getNextPaper() {
			this.loading = "secondary";

			this.$http
				.get("/papers/next", {
					params: {
						qcMode: !!this.qcMode,
						userUUID: this.user.uuid,
					},
				})
				.then((response) => {
					// if user cannot unselect papers or does not have a paper in progress, stage the next paper
					if (response.data.nextPaper) {
						if (this.qcMode) {
							this.setQCPaper(response.data.nextPaper);
						} else {
							this.setPaper(response.data.nextPaper);
						}

						// count is now decreased by 1
						this.count = parseInt(response.data.count, 10) - 1;
					} else {
						// set the count of available papers after the current one
						this.count = response.data.count;
					}
				})
				.catch((error) => {
					console.error(error.response);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		// TODO handle in-progress quality control work as well
		getUserInProgressDataEntries() {
			const params = {
				done: false,
			};

			if (this.user) {
				params.userUUID = this.user.uuid;
			}

			this.loading = "secondary";

			return this.$http
				.get("/data-entry/search", { params: params })
				.then((response) => {
					const dataEntries = response.data.dataEntries.data;

					let saveUUID;
					if (dataEntries.length > 0) {
						// If more than one in DATA_ENTRY status, take the oldest
						// one (server sort by updated_at asc)
						saveUUID = dataEntries[0].uuid;

						this.inProgress = true;

						// set the paper
						this.setPaper(dataEntries[0].paper);
					} else {
						// no in-progress data entries, create a new uuid
						saveUUID = uuidv4();

						this.inProgress = false;

						this.clearPaper();

						// get next paper
						this.getNextPaper();
					}

					this.setSaveUUID(saveUUID);
				})
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style scoped lang="scss">
:deep(.v-list-item--disabled) {
	opacity: 1;
	pointer-events: none;
}
:deep(.v-list.v-treeview .v-treeview-item:not(.v-list-group__header)) {
	padding-inline-start: calc(15px + var(--indent-padding)) !important;
}

:deep(.v-list.v-treeview .v-treeview-item .v-list-item__spacer) {
	width: 4px !important;
}
</style>
