/**
 * Injects custom CSS styles to WPD.
 */
function apply(wpdDocument) {
	// create a style node and inject it
	const style = wpdDocument.createElement("style");

	style.textContent = `
		@keyframes horizontal-shaking {
			0% { transform: translateX(0) }
			25% { transform: translateX(5px) }
			50% { transform: translateX(-5px) }
			75% { transform: translateX(5px) }
			100% { transform: translateX(0) }
		}

		input:focus, select:focus, button:focus {
			outline: 2px solid #f09d37 !important;
			outline-offset: -2px;
			border-radius: 2px;
			z-index: 999;
		}

		.disabled {
			color: gray !important;
		}

		.shake {
			animation: horizontal-shaking 0.3s 2;
			outline: 2px solid #f09d37;
			outline-offset: -2px;
		}

		.popupheading {
			background-color: #347b99 !important; /* dactyl blue */
			color: white !important;
		}

		.hidden {
			display: none;
		}

		.non-visible {
			visibility: hidden;
		}

		button:disabled, input[type="button"]:disabled {
			color: grey;
		}

		.error-message {
			color: red;
			font-size: 10px;
		}

		button:disabled:hover {
			background-color: #f1f1f1;
		}

		select {
			border: revert !important;
		}

		#add-dataset-popup {
			width: 650px !important;
		}

		#add-dataset-study-arm-inputs {
			margin: 15px 5px 0px 5px;
		}

		#add-new-dataset-study-arm-inputs {
			margin: 15px 5px 0px 5px;
		}
		.input-error {
			outline: 2px dotted red;
		}

		.add-dataset-row {
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			gap: 10px;
		}

		.edit-dataset-row {
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			gap: 10px;
			margin: 15px 5px 0px 5px;
		}

		.add-dataset-row-label {
			flex: none;
			width: 105px;
		}

		.add-dataset-row-label-rework {
			flex: none;
			width: 115px;
		}

		.edit-dataset-row-label-rework {
			flex: none;
			width: 110px;
		}

		.add-dataset-row-actions{
			flex: 0 1 auto;
			height: 45px;
		}

		.edit-dataset-row-actions{
			flex: 0 1 auto;
			height: 45px;
		}

		.add-dataset-study-arm-input {
			width: 200px;
			height: 18px !important;
		}

		.edit-dataset-study-arm-input {
			width: 200px;
			height: 18px !important;
		}

		.add-dataset-tag-group-input {
			width: 140px;
			height: 18px !important;
		}

		.edit-dataset-tag-group-input {
			width: 150;
			height: 18px !important;
		}

		.add-dataset-same-tag-groups {
			display: inline-flex;
			align-items: center;
			justify-content: space-between;
			height: 22px;
			width: 138px;
			font-size: 10px;
		}

		.add-dataset-same-tag-groups-checkbox {
			margin: 0;
		}

		.tree-folder {
			padding: 0 !important;
		}

		#left-side-container {
			border-right: 1px solid lightgray;
			position: relative;
			min-width: 250px;

			& > div:first-child {
				min-width: 246px;
			}
		}

		#canvasDiv {
			margin: 0 auto;
		}

		#sidebarContainer {
			border-left: 1px solid lightgray;
			padding-left: 5px;
		}

		.graph-axes-label-popup-table {
			margin: 0 auto;
			border-spacing: 5px 0;

			tr {
				height: 45px;

				th, td {
					vertical-align: top;
					text-align: center;
				}

				&:first-of-type {
					th, td {
						vertical-align: middle;
						font-size: 12px;
					}
				}
			}

			.row-header {
				width: 55px;
			}

			.axis-data-type {
				width: 200px;
			}

			.axis-unit {
				width: 120px;
			}

			.axis-decimals {
				width: 30px;
			}

			.axis-change-from-baseline {
				height: 16px;
				width: 16px;
			}
		}

		.axis-dataset-tree-list {
			outline: 1px dotted lightgray;
			margin-bottom: 5px;
		}

		.axis-dataset-tree-list-rework {
			margin-bottom: 5px;
		}

		.axis-dataset-tree-list-content {
			display: flex;
			justify-content: space-between;
			flex-wrap: nowrap;
			margin-top: 3px;
			margin-bottom: 3px;
			padding-left: 5px;
		}

		.axis-dataset-tree-row {
			display: inline-flex;
			justify-content: flex-end;
			gap: 1px;
		}

		.axis-dataset-tree-button {
			width: 22px;
			height: 22px;
			line-height: 8px;
		}

		.axis-dataset-add-button {
			width: 65px;
		}

		.tree-list-root {
			margin-right: 1px;
		}

		.tree-list-content {
			display: flex;
			justify-content: space-between;
		}

		.tree-list-content-rework {
			gap: 10px;
			flex-wrap: nowrap;
			margin: 3px 0 3px 0;
		}

		#graphicsContainer {
			background-image:
				linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee),
				linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee);
			background-size: 10px 10px;
			background-position: 0 0, 5px 5px;
		}

		.combobox {
			height: 38px;

			.dropdown-input {
				height: 18px;
				width: 180px;
			}
		}

		#center-container {
			display: flex;
			flex-flow: column nowrap;
			flex: 1 1 0%;
			overflow: hidden;
		}

		#center-toolbar-container {
			border-top: thin solid lightgray;
			width: 100%;
			height: 125px;
			position: relative;
			overflow: hidden;
			display: none;
		}

		#center-toolbar-content {
			display: flex;
			flex-flow: row nowrap;
			height: 100%;

			.title {
				display: flex;
				justify-content: space-between;
				width: calc(100% - 4px);
				background-color: #ddd;
				padding: 2px;
				margin-bottom: 5px;
				font-size: 13px;
			}

			.name .content {
				overflow: hidden;
				height: 100%;
				width: 100%;
				justify-content: center;
				align-items: center;
			}

			.content {
				display: flex;
				flex-flow: row wrap;
				overflow: auto;
				justify-content: flex-start;
				align-items: flex-start;

				input.collected {
					background-color: #afe1af; /* celadon */
				}

				input.key {
					border-color: #347b99; /* dactyl blue */
					color: #347b99; /* dactyl blue */
					cursor: help;

					&.required {
						border-color: #33b4a8; /* turquoise */
						color: #33b4a8; /* turquoise */
					}
				}
			}

			.data .collected-data-row {
				border: thin solid lightgray;
				border-radius: 6px;
				padding: 0 4px;
				margin: 0 1px 1px 0;
				font-size: 13px;

				.tag {
					color: gray;
				}
			}

			& > div {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				flex-flow: column nowrap;
				padding: 5px;
			}

			& > div.name {
				flex: 0 0 200px;
				border-right: thin solid lightgray;
			}

			& > div.tags {
				flex: 0 50%;
				border-right: thin solid lightgray;
			}

			& > div.data {
				flex: 0 50%;
			}
		}

		#mainContainer > .awesomplete > ul {
			transform-origin: bottom left;
			z-index: 100;

			&:before {
				content: "";
				position: absolute;
				top: unset;
				bottom: -0.55em;
				left: 1em;
				width: 0;
				height: 0;
				padding: 0.4em;
				background: white;
				border: inherit;
				border-left: 0;
				border-top: 0;
				transform: rotate(45deg);
				-webkit-transform: rotate(45deg);
			}
		}

		#tree-container {
			flex: 0 0 90px !important;
			overflow-y: hidden !important;
		}

		#left-side-container > div:first-child {
			max-height: unset !important;
			height: 100% !important;
		}

		#study-tree-display {
			position: relative;
			margin-top: auto;
			padding-top: 5px;
			height: 240px;
			border-top: thin solid lightgray;

			& > .study-list {
				overflow-y: auto;
				height: calc(100% - 25px);
			}

			& > .sidebar-title {
				margin: 0;
				font-size: 13px;
			}
		}

		#graph-item-tree-widget {
			flex: 0 1 calc(100vh - 335px);
			overflow-y: auto;
		}

		#table-item-tree-widget {
			flex: 0 1 calc(100vh - 335px);
			overflow-y: auto;
		}

		.draggable {
			position: absolute;
			height: 100%;
			width: 15px;
			top: 0;
			right: -5px;
			cursor: col-resize;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-end;

			&.horizontal {
				height: 15px;
				width: 100%;
				top: -5px;
				left: 0;
				cursor: row-resize;
				flex-direction: row;
				align-items: flex-start;
			}
		}

		.grip {
			background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSIjODg4Ij48L3JlY3Q+Cjwvc3ZnPg==") repeat, white;
			background-position: 40% 24%;
			width: 10px;
			height: 15px;
			border: thin solid lightgray;
			border-radius: 2px;
			padding: 1px;
			transition: opacity 0.3s;
			opacity: 0;
			margin-right: 4px;

			&.horizontal {
				background-position: 24% 40%;
				width: 15px;
				height: 10px;
				margin-top: 4px;
			}
		}

		.draggable:hover > .grip {
			opacity: 1;
		}

		.tree-folder.missing-definition, .tree-folder.orphan {
			color: #b71c1c; /* dactyl red */
			cursor: help;
		}

		.tree-folder.missing-definition:after {
			content: "[∄]";
		}

		.tree-folder.orphan:after {
			content: "[∌]";
		}

		.tree-folder.missing-definition.orphan:after {
			content: "[∄][∌]";
		}

		.tree-folder.tree-disabled {
			color: gray;
			pointer-events: none;
		}

		.tree-folder.tree-disabled:hover {
			cursor: default;
		}

		.tree-folder.tree-off-page {
			color: gray;
			font-style: italic;
		}

		.tree-folder-container {
			display: flex;
			gap: 5px;
		}

		input[type="button"].edit-button {
			background: none;
			border: none;
			padding: 0;
			font-size: 14px;
			cursor: pointer;
			color: inherit;
		}

		input[type="button"].create-study-button {
			margin-top: 10px;
		}

		.composite-key-selector-container {
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		.composite-key-awesomplete ul {
			width: 300px;
		}

		.composite-key-awesomplete ul li:not(:last-child) {
			border-bottom: 1px dotted #000;
		}

		.composite-key-selector-key {
			font-size: 14px;
		}

		.composite-key-selector-path-container {
			display: flex;
			flex-direction: column;
			gap: 3px;
		}

		.composite-key-selector-path {
			margin-left: 10px;
			color: gray;
			font-size: 12px;
			font-style: italic;
		}

		.rework-disabled {
			color: gray !important;
			pointer-events: none;
		}
	`;

	wpdDocument.head.append(style);

	// include library styles
	const link = wpdDocument.createElement("link");

	link.rel = "stylesheet";
	link.type = "text/css";
	link.href = "/awesomplete.css";

	wpdDocument.head.append(link);
}

export { apply };
