<template>
	<v-card
		class="d-flex flex-column"
		:class="{ 'pb-5': !nested }"
		:border="nested"
		:loading="submitting"
	>
		<v-toolbar
			color="primary"
			dark
			flat
			:dense="nested"
		>
			<v-toolbar-title v-if="editMode">
				{{ editTitle }}
			</v-toolbar-title>

			<v-toolbar-title v-else>
				<v-icon
					v-if="nested"
					class="mt-n1 mr-1"
				>
					mdi-briefcase-plus-outline
				</v-icon>

				{{ title }}
			</v-toolbar-title>

			<v-hover-icon
				v-if="!nested"
				class="mr-3"
			/>
		</v-toolbar>
		<v-form
			autocomplete="off"
			@submit.prevent
		>
			<v-card-text
				class="pb-0"
				:class="{ 'pa-10': !nested, 'px-8': nested }"
			>
				<v-row>
					<v-text-field
						v-model="name"
						:error-messages="nameErrors"
						:disabled="!!submitting"
						label="Project name"
						name="name"
						type="text"
						required
						class="col-12"
						@blur="v$.name.$touch()"
						@update:model-value="v$.name.$touch()"
					/>
				</v-row>

				<v-row>
					<v-col>
						<v-textarea
							v-model="description"
							:error-messages="descriptionErrors"
							:disabled="!name || !!submitting"
							label="Description"
							name="description"
							class="col-12"
							counter="255"
							rows="3"
							no-resize
							auto-grow
							clearable
							variant="outlined"
							@blur="v$.description.$touch()"
							@update:model-value="v$.name.$touch()"
						/>
					</v-col>
				</v-row>

				<v-row v-if="editMode">
					<v-col>
						<project-paper-table v-model="papers" />
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<organization-search
							v-model="selectedOrganizations"
							v-model:organizations-loading="organizationsLoading"
							v-model:organizations-missing="organizationsMissing"
							:disabled="!!loading"
							label="Associated organizations"
							@organization-updated="handleOrganizationUpdate"
						/>
					</v-col>
				</v-row>
			</v-card-text>

			<v-card-actions v-if="!noActions">
				<v-spacer />

				<v-btn
					v-if="editMode"
					elevation="2"
					class="bg-secondary text-white"
					type="submit"
					:disabled="
						v$.$invalid ||
						!name ||
						!!submitting ||
						!!organizationsLoading ||
						!!organizationsMissing
					"
					@click="update"
				>
					Update
				</v-btn>

				<v-btn
					v-else
					elevation="2"
					class="bg-secondary text-white"
					type="submit"
					:disabled="
						v$.$invalid ||
						!name ||
						!!submitting ||
						!!organizationsLoading ||
						!!organizationsMissing
					"
					@click="add"
				>
					Create
				</v-btn>

				<v-btn
					v-if="editMode"
					class="bg-grey"
					elevation="2"
					:disabled="!!submitting"
					@click="clear"
				>
					Revert
				</v-btn>

				<v-btn
					v-else
					class="bg-grey"
					elevation="2"
					:disabled="!!submitting"
					@click="clear"
				>
					Clear
				</v-btn>

				<v-btn
					:disabled="!!submitting"
					class="bg-grey"
					elevation="2"
					@click="cancel"
				>
					Cancel
				</v-btn>

				<v-spacer />
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import _ from "lodash";

import { mapMutations } from "vuex";
import { required, maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import ProjectPaperTable from "@/pages/ProjectManager/ProjectPaperTable";
import OrganizationSearch from "@/pages/Organizations/OrganizationSearch";
import VHoverIcon from "@/components/VHoverIcon";

export default {
	name: "ProjectEdit",

	components: {
		ProjectPaperTable,
		OrganizationSearch,
		VHoverIcon,
	},

	props: {
		defaultProject: {
			type: Object,
			default: () => ({}),
		},
		nested: Boolean,
		noActions: Boolean,
	},
	emits: ["refresh", "update:dirty", "update:invalid", "cancel"],

	setup() {
		return {
			v$: useVuelidate(),
		};
	},

	data() {
		return {
			editTitle: "Edit project",
			description: this.defaultProject
				? this.defaultProject.description
				: "",
			editMode: false,
			loading: false,
			name: this.defaultProject ? this.defaultProject.name : "",
			organizationsLoading: true,
			organizationsMissing: true,
			papers: [],
			selectedOrganizations:
				this.defaultProject && this.defaultProject.organizations
					? [...this.defaultProject.organizations]
					: [],
			submitting: false,
			title: "Create project",
			uuid: "",
		};
	},

	computed: {
		nameErrors() {
			if (!this.v$.name.$dirty) {
				return [];
			}

			return this.v$.name.$errors.map((error) => {
				switch (error.$validator) {
					case "required":
						return "Name is required";
					case "maxLength":
						return "Name is too long";
					default:
						return "Name is invalid";
				}
			});
		},
		descriptionErrors() {
			if (!this.v$.description.$dirty) {
				return [];
			}

			return this.v$.description.$errors.map((error) => {
				switch (error.$validator) {
					case "maxLength":
						return "Description is too long";
					default:
						return "Description is invalid";
				}
			});
		},
	},

	watch: {
		defaultProject() {
			this.loadProject();
		},
		"v$.$anyDirty": function (val) {
			this.$emit("update:dirty", val);
		},
		"v$.$anyError": function (val) {
			this.$emit("update:invalid", val);
		},
		"v$.$invalid": function (val) {
			this.$emit("update:invalid", val);
		},
	},

	mounted() {
		this.loadProject();
	},

	methods: {
		...mapMutations(["setSessionProjects", "showNotification"]),
		clear() {
			// clear dirty flags
			this.v$.$reset();

			if (_.isEmpty(this.defaultProject)) {
				this.name = "";
				this.description = "";
				this.papers = [];
				this.uuid = "";
				this.selectedOrganizations = [];

				this.editMode = false;
			} else {
				this.loadProject();
			}
		},
		cancel() {
			this.$emit("cancel");
		},
		add() {
			this.save("add");
		},
		update() {
			this.save("update");
		},
		save(mode) {
			this.saveRequest(mode)
				.then((response) => {
					// display success
					this.showNotification(response.data);
				})
				.catch((error) => {
					// set all to dirty
					this.v$.$touch();

					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					// update paper table
					this.$emit("refresh");

					this.submitting = false;
				});
		},
		saveRequest(mode) {
			if (!this.v$.$invalid) {
				if (!this.nested) {
					this.submitting = true;
				}
				const dispatch = {
					add: this.addProjectRequest,
					update: this.updateProjectRequest,
				};

				return dispatch[mode]();
			} else {
				return Promise.reject();
			}
		},
		addProjectRequest() {
			return this.$http.post("/projects", {
				name: this.name.trim(),
				description: this.description?.trim(),
				organizations: this.selectedOrganizations,
			});
		},
		updateProjectRequest() {
			return this.$http
				.put(`/projects/${this.uuid}`, {
					name: this.name.trim(),
					description: this.description?.trim(),
					organizations: this.selectedOrganizations,
				})
				.then((response) => {
					// update project papers if necessary
					if (!_.isEqual(this.papers, this.defaultProject.papers)) {
						return this.updateProjectPapersRequest();
					}
					return Promise.resolve(response);
				});
		},
		updateProjectPapersRequest() {
			return this.$http.put(`/projects/${this.uuid}/papers`, {
				paperUUIDs: this.papers.map((paper) => paper.uuid),
			});
		},
		loadProject() {
			if (!_.isEmpty(this.defaultProject)) {
				this.name = this.defaultProject.name;
				this.description = this.defaultProject.description;
				this.uuid = this.defaultProject.uuid;

				this.papers = [...this.defaultProject.papers];
				this.selectedOrganizations = [
					...this.defaultProject.organizations,
				];

				this.editMode = true;

				this.v$.$touch();
			} else {
				this.clear();
			}
		},
		handleOrganizationUpdate() {
			this.v$.selectedOrganizations.$touch();
		},
	},

	validations: {
		name: {
			required,
			maxLength: maxLength(255),
		},
		description: {
			maxLength: maxLength(255),
		},
		selectedOrganizations: {},
		selectedRoles: {},
	},
};
</script>

<style scoped lang="scss"></style>
