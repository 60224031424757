<template>
	<v-row>
		<v-col>
			<v-btn
				class="bg-secondary text-white"
				elevation="2"
				variant="text"
				to="/home/paper-manager"
			>
				<v-icon start> mdi-arrow-left </v-icon>

				Back
			</v-btn>
		</v-col>
	</v-row>

	<v-row
		align="start"
		align-content="start"
		justify="start"
	>
		<v-col cols="12">
			<file-table />
		</v-col>
	</v-row>
</template>

<script>
import FileTable from "@/pages/FileManager/FileTable";

export default {
	name: "FileManager",

	components: {
		FileTable,
	},
};
</script>

<style scoped lang="scss">
.col {
	flex-grow: 0;
}
</style>
