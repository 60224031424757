<template>
	<v-card class="d-flex flex-column pb-5">
		<v-toolbar
			color="primary"
			dark
			flat
		>
			<v-toolbar-title>{{ title }}</v-toolbar-title>
		</v-toolbar>

		<v-dialog
			v-model="invalidDialog"
			max-width="800px"
			transition="fade-transition"
		>
			<v-card>
				<v-toolbar
					color="primary"
					flat
				>
					<v-toolbar-title>Invalid Data Extraction</v-toolbar-title>
					<v-hover-icon class="mr-3" />
				</v-toolbar>

				<v-card-text
					class="pa-5 d-flex flex-sm-nowrap align-center justify-center validation-header-text"
				>
					Format Error: {{ validationData.message }}
				</v-card-text>

				<v-divider />

				<data-entry-validation-table
					:validation-data="validationData"
				></data-entry-validation-table>
				<v-card-actions class="py-5">
					<v-spacer />

					<v-btn
						class="bg-secondary text-white"
						elevation="2"
						@click="hideInvalidDialog"
					>
						Ok
					</v-btn>

					<v-spacer />
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-data-table-server
			v-model:options="options"
			class="completed-extractions-table"
			item-key="uuid"
			:headers="headers"
			:items="extractions"
			:loading="loading"
			:items-length="totalExtractions"
		>
			<template #item.completed_at="{ item }">
				{{ getCompletedTimestamp(item) }}
			</template>

			<template #item.projects="{ item }">
				<div class="d-flex flex-wrap my-2">
					<v-chip
						v-for="project of item.paper.projects"
						:key="project.uuid"
						size="x-small"
					>
						{{ project.name }}
					</v-chip>

					<v-tooltip
						v-if="!item.paper.projects.length"
						location="bottom"
						open-delay="500"
					>
						<template #activator="{ props }">
							<v-icon
								v-bind="props"
								color="error"
								class="cursor-default"
							>
								mdi-alert-circle
							</v-icon>
						</template>

						<span>No associated projects</span>
					</v-tooltip>
				</div>
			</template>

			<template #item.actions="{ item }">
				<v-tooltip
					location="bottom"
					open-delay="500"
				>
					<template #activator="{ props }">
						<v-hover v-slot="{ hover }">
							<v-icon
								v-bind="props"
								class="mr-2"
								:color="hover ? 'secondary' : ''"
								@click="download(item)"
							>
								mdi-download
							</v-icon>
						</v-hover>
					</template>

					<span>Download</span>
				</v-tooltip>
			</template>
		</v-data-table-server>
	</v-card>
</template>

<script>
import DataEntryValidationTable from "@/pages/DataEntry/DataEntryValidationTable";
import VHoverIcon from "@/components/VHoverIcon";

import { mapMutations } from "vuex";

export default {
	name: "CompletedExtractionTable",
	components: { DataEntryValidationTable, VHoverIcon },

	data() {
		return {
			loading: false,
			options: {
				itemsPerPage: 10,
				page: 1,
			},
			extractions: [],
			invalidDialog: false,
			title: "Completed Data Extractions",
			totalExtractions: 0,
			headers: [
				{
					title: "Paper name",
					value: "paper.name",
					width: "30%",
				},
				{
					title: "User",
					value: "user.email",
					align: "left",
					width: "150px",
				},
				{
					title: "Projects",
					value: "projects",
					align: "left",
					width: "150px",
				},
				{
					title: "Completed at",
					value: "completed_at",
					align: "left",
					divider: true,
					width: "200px",
				},
				{
					title: "Actions",
					value: "actions",
					align: "center",
					width: "95px",
					sortable: false,
				},
			],
			validationData: null,
		};
	},

	watch: {
		options: {
			deep: true,
			handler() {
				this.getCompletedExtractions();
			},
		},
	},

	methods: {
		...mapMutations(["showNotification"]),
		getCompletedTimestamp(item) {
			return new Date(item.completed_at).toLocaleString();
		},
		getCompletedExtractions() {
			this.loading = "secondary";

			const { filter, itemsPerPage, page, sortBy, sortDesc } =
				this.options;

			const params = {
				done: true,
				filter: filter,
				itemsPerPage: itemsPerPage,
				page: page,
				sortBy: sortBy,
				sortDesc: sortDesc,
			};
			this.$http
				.get("/data-entry/search", { params: params })
				.then((response) => {
					this.extractions = response.data.dataEntries.data;
					this.totalExtractions = parseInt(
						response.data.dataEntries.meta.total,
						10
					);
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		download(item) {
			this.$http
				.post(`/data-entry/${item.uuid}/validate`, {
					data: JSON.stringify(item.data),
				})
				.then((response) => {
					if (response.data.status === "error") {
						this.validationData = response.data;
						this.invalidDialog = true;
					} else {
						const data = response.data;
						const blob = new Blob([JSON.stringify(data, null, 2)], {
							type: "application/json",
						});

						const url = URL.createObjectURL(blob);
						const link = document.createElement("a");
						link.href = url;
						link.download = item.paper
							? item.paper.name
							: "Unknown paper";
						document.body.appendChild(link);

						link.click();

						document.body.removeChild(link);
						URL.revokeObjectURL(url);
					}
				})
				.catch((error) => {
					this.showNotification(error.response.data);
				});
		},
		hideInvalidDialog() {
			this.invalidDialog = false;
		},
	},
};
</script>

<style scoped lang="scss">
:deep(.completed-extractions-table) {
	&.v-data-table .v-table__wrapper td {
		font-size: 12px;
	}

	&.v-data-table .v-table__wrapper th {
		font-size: 12px;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.6);
		height: 48px;
	}
}
</style>
